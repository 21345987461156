import React, { useCallback, useState } from "react";
import { useToken } from "../../authContext";
import RenderPolicies from "./RenderPolicies";
import useGetTermsAndConditions from "../../customHooks/useGetAllPolicies";

const TermsAndConditions = () => {
  const authContext = useToken();
  const [policiesArray, setPoliciesArray] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState<any>(0);
  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const { policies, userTypePolicy } =
    useGetTermsAndConditions("pageDataSpecialist");

  const handleCheckboxChange = useCallback((index: any) => {
    setPoliciesArray((prevState: any) => {
      if (prevState.includes(index)) {
        return prevState.filter((item: any) => item !== index);
      } else {
        return [...prevState, index];
      }
    });
  }, []);

  const handleNextClick = () => {
    if (userTypePolicy.length > 0) {
      setCurrentSlide(
        (prevSlide: any) => (prevSlide + 1) % userTypePolicy.length
      );
    }
  };

  const handlePrevClick = () => {
    if (userTypePolicy.length > 0) {
      setCurrentSlide((prevSlide: any) => Math.max(prevSlide - 1, 0));
    }
  };
  return (
    <RenderPolicies
      policies={policies}
      specialistPolicies={userTypePolicy}
      policiesArray={policiesArray}
      currentSlide={currentSlide}
      specialistInfo={specialistInfo}
      onHandleCheckBoxChange={handleCheckboxChange}
      onHandleNextClick={handleNextClick}
      onHandlePrevClick={handlePrevClick}
    />
  );
};

export default TermsAndConditions;
