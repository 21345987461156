import React from "react";

interface BackIconProps {
  width?: number;
  height?: number;
}

const FilterIcon: React.FC<BackIconProps> = ({ width = 19, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.47 0H0L7.388 8.736V14.776L11.082 16.623V8.736L18.47 0Z"
        fill="#252525"
      />
    </svg>
  );
};

export default FilterIcon;
