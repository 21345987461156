import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface MonthlyAOVItem {
  month: string; // e.g. "2024-10"
  monthName: string; // e.g. "October"
  totalAmount: string; // e.g. "248.888889"
}

interface ChartProps {
  data?: MonthlyAOVItem[]; // The array from getMonthlyAverageOrderValue
}

const AverageOrderValueChart: React.FC<ChartProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">AVERAGE ORDER VALUE</h3>
          <p className="chart-subtitle">$0.00</p>
          {/* </div> */}
        </div>
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Extract labels (monthName) and values (totalAmount) from the data
  const labels = data.map((item) => item.monthName);
  const values = data.map((item) => parseFloat(item.totalAmount));

  // If you want to show the overall average in the subtitle:
  const overallAverage =
    values.length > 0
      ? (values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(2)
      : "0.00";

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">AVERAGE ORDER VALUE</h3>
          <p className="chart-subtitle">${overallAverage}</p>
        </div>
      </div>
      <CChartLine
        data={{
          labels: labels,
          datasets: [
            {
              label: "Average Order Value",
              borderColor: "#999",
              backgroundColor: "rgba(153, 153, 153, 0.2)",
              data: values,
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // Prefix y-axis values with a $ sign
                callback: (value) => `$${value}`,
              },
              // Remove hard-coded max so chart auto-scales
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default AverageOrderValueChart;
