import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { format, startOfWeek, endOfWeek, endOfMonth } from "date-fns";
import RenderSlotWindow from "./RenderSlotWindow";
import moment from "moment-timezone";
import { useToken } from "../../authContext";

interface DoctorProfile {
  doctor: { [key: string]: any };
  closeModal: () => void;
  categoryId: string;
  cartProductData: any;
}

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}

const SpecialistProduct: React.FC<DoctorProfile> = React.memo(
  ({ doctor, closeModal, categoryId, cartProductData }) => {
    const today = new Date();
    const [startDate, setStartDate] = useState<any>(startOfWeek(today));
    const [endDate, setEndDate] = useState<any>(endOfMonth(today));
    const [selectedMonth, setSelectedMonth] = useState<any>(
      format(today, "MMMM")
    );
    const [slots, setSlots] = useState<any>([]);
    const [selectedSlot, setSelectedSlot] = useState<any>("");
    const [productData, setProductData] = useState<any>("");
    const [productDetailsById, setProductDetailsById] = useState<any>("");

    const authCtx = useToken();
    const userInfo =
      typeof authCtx.userInfo === "string"
        ? JSON.parse(authCtx.userInfo)
        : authCtx.userInfo;
    const [userTimezone, setUserTimeZone] = useState<any>(userInfo?.timezone);

    useEffect(() => {
      getSpecialistBookingSlots(startDate, endDate, doctor?.id);
      getProductOfNewDr();
    }, [doctor?.id]);

    useEffect(() => {
      if (
        productData?.length !== 0 &&
        Object.entries(cartProductData).length !== 0
      ) {
        const productId = productData.find(
          (elem: any) => elem?.duration === cartProductData?.Duration
        );
        setProductDetailsById(productId);
      }
    }, [productData, cartProductData]);

    console.log(cartProductData);
    console.log(categoryId);
    console.log(doctor);
    console.log(productDetailsById);
    const previousDoctor = cartProductData?.doctorId;
    const duration = cartProductData?.Duration;
    const previousBookingSlot = null;
    const timezone = cartProductData?.timezone;
    const customerId = userInfo?.customerId;
    console.log({
      bookingSlotId: 1810,
      uuid: "605a365d-0401-11f0-b1a0-02c2e65cf86f",
      userId: null,
      meetingId: null,
      calendarDate: "2025-03-29",
      doctorId: 81,
      StartTime: "2025-03-28T21:30:000Z",
      EndTime: "2025-03-28T22:00:000Z",
      Duration: "0.50",
      calendarDay: "Saturday",
      isBooked: 0,
      name: null,
      status: "init",
      orderId: null,
      isPackage: null,
      packageId: null,
      amount: "0.00",
      onCreate: "2025-03-18T14:00:40.000Z",
      onUpdate: null,
      dname: "JEMMY",
      drUserID: 81,
      email: null,
    });

    const getProductOfNewDr = async () => {
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            doctorId: doctor?.id,
            categoryUuid: categoryId,
            isActive: 1,
            action: "command",
            command: [
              {
                agent: "productBySpecCategory",
                appName: "selfMaximized",
                folder: "specialist",
              },
            ],
          }
        );
        if (result?.data?.statusCode === 200) {
          setProductData(result?.data?.data);
        } else {
          setProductData([]);
        }
      } catch (err) {
        return err;
      }
    };

    const buySlot = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            appointmentId: 2,
            previousDoctor: cartProductData?.doctorId,
            previousBookingSlot: null,
            ip: "127.0.0.1",
            timezone: timezone,
            organizationId: "1",
            customerId: customerId,
            productId: productDetailsById?.id,
            doctorId: productDetailsById?.doctorid,
            success_url: "http://selfmax.lcl/success",
            cancel_url: "http://selfmax.lcl/cancel",
            cartUuid: "",
            cartId: "",
            qty: 1,
            orderMethod: "rebook",
            duration: cartProductData?.Duration,
            discountAmount: productDetailsById?.price,
            metadata: [
              {
                appointmentId: 2,
                previousDoctor: cartProductData?.doctorId,
                previousBookingSlot: null,
                productId: productDetailsById?.id,
                doctorId: productDetailsById?.doctorid,
                StartTime: selectedSlot?.StartTime,
                EndTime: selectedSlot?.EndTime,
                Duration: cartProductData?.Duration,
                Day: cartProductData?.calendarDay,
                Date: cartProductData?.calendarDate,
                categoryid: cartProductData?.categoryid,
                modeType: cartProductData?.modeType,
                timezone: timezone,
                meta_type: cartProductData?.meta_type,
                bookingSlotId: selectedSlot?.bookingSlotId,
                bookStatus: "1",
              },
            ],
            action: "command",
            command: [
              {
                agent: "createRebookingCart",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "addToCart",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "createOrder",
                appName: "selfMaximized",
                folder: "order",
              },
              {
                agent: "discountOffer",
                appName: "selfMaximized",
                folder: "payment",
              },
              {
                agent: "createCheckout",
                appName: "selfMaximized",
                folder: "payment",
              },
            ],
          },
          {
            headers: {
              "x-access-token": authCtx?.token,
            },
          }
        );

        const data = await res;
        console.log(data);
      } catch (err) {
        return err;
      }
    };

    const getSpecialistBookingSlots = async (
      newStartDate: any,
      newEndDate: any,
      doctorId: any
    ) => {
      await axios
        .post(`${process.env.REACT_APP_API}/pipeline`, {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "calendarDate",
                  operator: "between",
                  table: "BookingCalendar",
                  value: moment(newStartDate ? newStartDate : startDate).format(
                    "YYYY-MM-DD"
                  ),
                  opr: "AND",
                  value2: moment(newEndDate ? newEndDate : endDate).format(
                    "YYYY-MM-DD"
                  ),
                },
                {
                  field: "doctorId",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: doctorId,
                },
                {
                  field: "Duration",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: parseFloat(cartProductData?.Duration).toFixed(2),
                },
                {
                  field: "active",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: 1,
                },
              ],
              sort: [
                {
                  field: "calendarDate",
                  table: "BookingCalendar",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "specialistCalendarBooking",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        })
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            const responseData: Slot[] = result?.data?.data?.response || [];

            const getSlotDateTime = (startTime: string): moment.Moment => {
              return moment
                .tz(
                  startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                  "YYYY-MM-DDTHH:mm:ssZ", // Parse format
                  "UTC"
                )
                .tz(userTimezone);
            };

            const filteredSlots = responseData.filter((slot) => {
              const slotDateTime = getSlotDateTime(slot.StartTime);

              const now = moment.tz(userTimezone);

              return slot.isBooked === 0 && slotDateTime.isAfter(now);
            });

            const groupedSlotss: GroupedSlots = filteredSlots.reduce(
              (acc: GroupedSlots, slot: Slot) => {
                const slotDate = getSlotDateTime(slot.StartTime).format(
                  "YYYY-MM-DD"
                );
                acc[slotDate] = [...(acc[slotDate] || []), slot];
                return acc;
              },
              {}
            );
            const filteredSlotsArray = Object.entries(groupedSlotss)
              .map(([day, slots]: [string, Slot[]]) => ({
                dayOfWeek: moment(day, "YYYY-MM-DD").format("dddd"), // Convert to day of the week
                slots,
              }))
              .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

            setSlots(filteredSlotsArray);
          }
        })
        .catch((err) => {
          return err;
        });
    };

    const handleRangeSelection = useCallback(async (selectedRange: any) => {
      if (Array.isArray(selectedRange) && selectedRange.length === 2) {
        const [startDate, endDate] = selectedRange;
        setStartDate(startDate);
        setEndDate(endDate);
        await getSpecialistBookingSlots(startDate, endDate, "");
      } else {
        console.error("Invalid date range selected");
      }
    }, []);

    const handleMonthChange = useCallback(({ activeStartDate }: any) => {
      const monthName = format(activeStartDate, "MMMM"); // Get the month name
      setSelectedMonth(monthName);
    }, []);

    const passSlots = (selectedSlot: any) => {
      setSelectedSlot(selectedSlot);
    };

    return (
      <div className="black-background">
        <button className="btn btn-light mb-4" onClick={closeModal}>
          BACK
        </button>

        <RenderSlotWindow
          handleRangeSelection={handleRangeSelection}
          startDate={startDate}
          endDate={endDate}
          selectedMonth={selectedMonth}
          slots={slots}
          userTimezone={userTimezone}
          selectedSlot={selectedSlot}
          passSlots={passSlots}
          handleMonthChange={handleMonthChange}
        />

        <button className="btn btn-light mb-4 float-end" onClick={buySlot}>
          BUY SLOT
        </button>
      </div>
    );
  }
);

export default SpecialistProduct;
