import React, { useCallback, useEffect, useState } from "react";
import { useToken } from "../../authContext";
import CategoryList from "./Common/CategoryList";
import TodayAppointment from "./Common/TodayAppointment";
import WeeklyAppoinments from "./Common/WeeklyAppointments";
import axios from "axios";
import { toast } from "react-toastify";
import Modal from "../../SpecialistDashboard/Layout/Modal";
import moment from "moment-timezone";
import { format, startOfWeek, endOfWeek, endOfMonth } from "date-fns";
import FindADifferentSpecialist from "../Components/FindADifferentSpecialist";
import RenderSlotWindow from "../Components/RenderSlotWindow";

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}

interface UserAppointments {
  userAppointments: number;
}

interface BookLaterAppointments {
  userAppointmentsElem: any;
  handleOpen: (value: any) => void;
}

const BookLaterText: React.FC<UserAppointments> = ({ userAppointments }) => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <p className="mb-0 please-select-the-day pb-2 text-uppercase">
          BOOK YOUR SLOT HERE !
          <div className="d-flex justify-content-between">
            <div>PLEASE BOOK YOUR SLOTS BEFORE THE DEADLINE</div>
            TOTAL REMAINING APPOINTMENTS - {userAppointments}
          </div>
        </p>
      </div>
    </div>
  );
};

const BookLaterAppointments: React.FC<BookLaterAppointments> = ({
  userAppointmentsElem,
  handleOpen,
}) => {
  return (
    <div className="days-left-box">
      <span className="pb-2 font-14 mb-0 d-block">
        {userAppointmentsElem?.days_left} DAYS LEFT
      </span>
      <div className="days-left-inner d-flex">
        <div className="specialist-box d-flex">
          <div className="specilist-image">
            <img src={userAppointmentsElem?.profile} alt="#" />
          </div>
          <div className="specialist-info">
            <p className="text-uppercase mb-2 font-12">
              DR {userAppointmentsElem?.doctorName}{" "}
              <span className="specialist-gender-text d-block font-14">
                {userAppointmentsElem?.gender}
              </span>
            </p>
            <p className="text-uppercase font-16">
              {" "}
              {userAppointmentsElem?.categoryName} -{" "}
              {userAppointmentsElem?.bookingMode}
            </p>
          </div>
        </div>
        <div className="singular-appointment-box">
          <p className="text-uppercase mb-2 font-12">
            {userAppointmentsElem?.type}
          </p>
          <p className="text-uppercase mb-0 font-12">
            {userAppointmentsElem?.metadata ||
            userAppointmentsElem?.metadata[0]?.Duration ||
            userAppointmentsElem?.metadata?.Duration === 0.5
              ? "30 MIN "
              : userAppointmentsElem?.metadata ||
                userAppointmentsElem?.metadata[0]?.Duration ||
                userAppointmentsElem?.metadata?.Duration === 1.0
              ? "1 HR "
              : userAppointmentsElem?.metadata[0]?.Duration ||
                userAppointmentsElem?.metadata?.Duration}
            APPOINTMENT
          </p>

          <p className="text-uppercase mb-0 text-right font-12 book-slots-outer cursor_pointer">
            <span
              className="cursor-pointer"
              onClick={() => handleOpen(userAppointmentsElem)}
            >
              Book slots
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const UserBody: React.FC = () => {
  const [userAppointments, setUserAppointmentData] = useState<any>([]);
  const [open, setOpen] = useState<any>(false);
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [slots, setSlots] = useState<any>([]);
  const [cartProductData, setCartProductData] = useState<any>();
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [openDifferentSpecialistModal, setOpenDifferentSpecialistModal] =
    useState<boolean>(false);
  const authCtx = useToken();
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;
  const [userTimezone, setUserTimeZone] = useState<any>(userInfo?.timezone);

  useEffect(() => {
    userRemainingAppointment();
  }, []);

  const handleOpen: any = (cartProductData: any) => {
    setCartProductData(cartProductData);
    setOpen(true);
    getSpecialistBookingSlots(startDate, endDate, cartProductData?.doctorId);
  };

  const handleClose: any = () => {
    setOpen(false);
  };

  const handleRangeSelection = useCallback(async (selectedRange: any) => {
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate, "");
    } else {
      console.error("Invalid date range selected");
    }
  }, []);

  const handleMonthChange = useCallback(({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  }, []);

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };

  const getSpecialistBookingSlots = async (
    newStartDate: any,
    newEndDate: any,
    doctorId: any
  ) => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: moment(newStartDate ? newStartDate : startDate).format(
                  "YYYY-MM-DD"
                ),
                opr: "AND",
                value2: moment(newEndDate ? newEndDate : endDate).format(
                  "YYYY-MM-DD"
                ),
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: doctorId,
              },
              // {
              //   field: "Duration",
              //   operator: "eq",
              //   table: "BookingCalendar",
              //   value: parseFloat(
              //     cartProductData?.appointment_duration
              //   ).toFixed(2),
              // },
              {
                field: "Duration",
                operator: "eq",
                table: "BookingCalendar",
                value: 0.5,
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          const getSlotDateTime = (startTime: string): moment.Moment => {
            return moment
              .tz(
                startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                "YYYY-MM-DDTHH:mm:ssZ", // Parse format
                "UTC"
              )
              .tz(userTimezone);
          };

          const filteredSlots = responseData.filter((slot) => {
            const slotDateTime = getSlotDateTime(slot.StartTime);

            const now = moment.tz(userTimezone);

            return slot.isBooked === 0 && slotDateTime.isAfter(now);
          });

          const groupedSlotss: GroupedSlots = filteredSlots.reduce(
            (acc: GroupedSlots, slot: Slot) => {
              const slotDate = getSlotDateTime(slot.StartTime).format(
                "YYYY-MM-DD"
              );
              acc[slotDate] = [...(acc[slotDate] || []), slot];
              return acc;
            },
            {}
          );
          const filteredSlotsArray = Object.entries(groupedSlotss)
            .map(([day, slots]: [string, Slot[]]) => ({
              dayOfWeek: moment(day, "YYYY-MM-DD").format("dddd"), // Convert to day of the week
              slots,
            }))
            .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

          setSlots(filteredSlotsArray);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const userRemainingAppointment = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userID: userInfo.id,
          action: "command",
          command: [
            {
              agent: "remainAppointment",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUserAppointmentData(result?.data?.data);
        } else {
          setUserAppointmentData([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const bookSlot = async () => {
    if (!selectedSlot) {
      return toast.info("PLEASE SELECT SLOT FIRST", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast",
        bodyClassName: "custom-toast",
      });
    }
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          start_time: selectedSlot.StartTime,
          end_time: selectedSlot.EndTime,
          duration: selectedSlot.Duration,
          appointment_day: selectedSlot.calendarDay,
          appointment_date: selectedSlot.calendarDate,
          cartProductId: cartProductData.cpid,
          userId: userInfo.id,
          bookingSlotId: selectedSlot?.bookingSlotId,
          appointmentId: cartProductData?.id,
          isBooked: 1,
          onCreate: moment().tz(userTimezone).format("YYYY-DD-YY"),
          action: "command",
          command: [
            {
              agent: "updateSlot",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setOpen(false);
          toast.success("YOUR APPOINTMENT IS BOOKED SUCCESSFULLY!");
          setLoader(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  function getCssClass() {
    if (userAppointments?.length === 0) {
      return "col-lg-5";
    } else {
      return "col-lg-12";
    }
  }

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper">
        <div className="container pb-3 pt-3 pt-lg-0">
          <div className="fittness-wrapper-background-image-one">
            <div className="container px-0">
              <div className="row pb-3">
                <div className="col-lg-12">
                  <CategoryList />
                </div>
              </div>
              <div className="row g-2">
                <div className="col pb-3 pb-lg-0">
                  <div className="today-join-time-premimum-version-box p-3 today__card h-100">
                    <div className="row">
                      <div className={getCssClass()}>
                        <p className="mb-0 please-select-the-day pb-2 font-15 text-uppercase d-flex justify-content-between">
                          <TodayAppointment />
                        </p>
                        {userAppointments?.length > 0 ? (
                          <div className="mt-5">
                            <span className="pl-2 mt-0 pb-2 d-block">
                              WEELY APPOINTMENTS SCHEDULED
                            </span>
                            <WeeklyAppoinments />
                          </div>
                        ) : null}
                      </div>
                      {userAppointments?.length === 0 ? (
                        <div className="col-lg-7 px-0">
                          <div className="fittness-right-box p-4 fittness__right--box this__week__app__spec--dashboard">
                            <div className="row">
                              <div className="col-lg-12 px-0">
                                <p className="mb-0 please-select-the-day font-weight-bold this__week__appointments text-uppercase">
                                  This week appointments
                                </p>
                                <p className="mb-0 email-city-registration-date font-12 pb-3 text-uppercase">
                                  Join the session and make the most out of it
                                </p>
                              </div>
                            </div>
                            <div className="row doctor-dashboard-wrapper mb-3">
                              <WeeklyAppoinments />
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {userAppointments.length === 0 ? null : (
                  <div className="col">
                    <div className="fittness-right-box p-3 week__appoint__user--dashboard">
                      <BookLaterText
                        userAppointments={userAppointments?.length ?? 0}
                      />
                      <div className="row doctor-dashboard-wrapper doctor-dashboard-wrapper-new">
                        {userAppointments.length !== 0 &&
                        userAppointments !== null ? (
                          userAppointments.map((cp: any) => (
                            <BookLaterAppointments
                              userAppointmentsElem={cp}
                              key={cp?.id}
                              handleOpen={handleOpen}
                            />
                          ))
                        ) : (
                          <span>NO DATA AVAILABLE</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Modal isOpen={open}>
            <div tabIndex={-1}>
              <div className="modal-dialog model-md modal-dialog-centered user__calander__booking--list modal-xl modal-dialog-new">
                <div className="modal-content buy__product__modal py-3">
                  <div className="modal-header py-2">
                    <p className="reschedule__available__title mb-0 text_uppercase ml-3">
                      AVAILABLE SLOTS: DR. {cartProductData?.doctorName}
                    </p>

                    <button
                      className="reschedule__book mr-3"
                      onClick={handleClose}
                    >
                      <i className="fa fa-close"></i> CANCEL
                    </button>
                  </div>
                  <div className="modal-body">
                    <RenderSlotWindow
                      handleRangeSelection={handleRangeSelection}
                      startDate={startDate}
                      endDate={endDate}
                      selectedMonth={selectedMonth}
                      slots={slots}
                      userTimezone={userTimezone}
                      selectedSlot={selectedSlot}
                      passSlots={passSlots}
                      handleMonthChange={handleMonthChange}
                    />
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <button
                          className="reschedule__book mr-3"
                          onClick={() => {
                            setOpen(false); // Close the current modal
                            setOpenDifferentSpecialistModal(true);
                          }}
                        >
                          BOOK DIFFERENT SPECIALIST
                        </button>

                        {loader ? (
                          <div className="d-flex align-items-center">
                            <div
                              className="spinner-grow spinner-grow-sm text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                            <span className="text-dark font-weight-bold ml-2 letter-spacing">
                              LOADING
                            </span>
                          </div>
                        ) : (
                          <>
                            <button
                              className="reschedule__book mr-3"
                              onClick={bookSlot}
                            >
                              BOOK
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {openDifferentSpecialistModal && (
            <FindADifferentSpecialist
              cartProductData={cartProductData}
              isOpen={openDifferentSpecialistModal}
            />
          )}
          {/*  */}
        </div>
        {/* */}
      </main>
    </>
  );
};

export default UserBody;
