// apiHandler.ts
import axios, { AxiosResponse } from "axios";
import moment from "moment";

interface BookingDetailsParams {
  filters: any;
  offset: any;
  token: any;
  searchTerm?: string;
  excelDownload?: boolean;
}

export const getPaymentsDetails = async ({
  token,
  filters,
  offset,
  excelDownload = false,
}: BookingDetailsParams) => {
  try {
    // Structure the request differently based on if it's a download request
    const requestBody = {
      filter: {
        filter: !excelDownload
          ? {
              logic: "and",
              offset: offset ? (offset - 1) * 5 : 0,
              limit: 5,
              filters: filters,
              sort: [],
            }
          : undefined,
      },
      analytics: {},
      action: "command",
      command: [
        {
          agent: "getPaymentReport",
          appName: "selfMaximized",
          folder: "reports",
        },
      ],
      excel: excelDownload ? true : undefined,
    };

    const config = {
      headers: { "x-access-token": `${token}` },
      // Use standard JSON response type since the server returns JSON
      responseType: "json",
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody,
      config as any
    );

    // Handle Excel download response - special handling for Buffer data in JSON
    if (
      excelDownload &&
      response.data?.data?.data &&
      response.data.data.type === "Buffer"
    ) {
      // Convert the Buffer array back to binary data
      const bufferData = response.data.data.data;
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `payment_report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);

      return { success: true };
    }

    // Return the raw data for normal requests
    return response.data;
  } catch (error) {
    console.error("Error fetching Payment details:", error);
    throw error;
  }
};
export const getCancellationRefundDetails = async ({
  token,
  filters,
  offset,
  excelDownload = false,
}: BookingDetailsParams) => {
  try {
    // Structure the request differently based on if it's a download request
    const requestBody = {
      filter: {
        filter: !excelDownload
          ? {
              logic: "and",
              offset: offset ? (offset - 1) * 5 : 0,
              limit: 5,
              filters: filters,
              sort: [],
            }
          : undefined,
      },
      analytics: {},
      action: "command",
      command: [
        {
          agent: "getCancellationRefundReport",
          appName: "selfMaximized",
          folder: "reports",
        },
      ],
      excel: excelDownload ? true : undefined,
    };

    const config = {
      headers: { "x-access-token": `${token}` },
      // Use standard JSON response type since the server returns JSON
      responseType: "json",
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody,
      config as any
    );

    // Handle Excel download response - special handling for Buffer data in JSON
    if (
      excelDownload &&
      response.data?.data?.data &&
      response.data.data.type === "Buffer"
    ) {
      // Convert the Buffer array back to binary data
      const bufferData = response.data.data.data;
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `payment_report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);

      return { success: true };
    }

    // Return the raw data for normal requests
    return response.data;
  } catch (error) {
    console.error("Error fetching Payment details:", error);
    throw error;
  }
};
export const getSubscriptionsDetails = async ({
  token,
  filters,
  offset,
  excelDownload = false,
}: BookingDetailsParams) => {
  try {
    // Structure the request differently based on if it's a download request
    const requestBody = {
      filter: {
        filter: !excelDownload
          ? {
              logic: "and",
              offset: offset ? (offset - 1) * 5 : 0,
              limit: 5,
              filters: filters,
              sort: [],
            }
          : undefined,
      },
      analytics: {},
      action: "command",
      command: [
        {
          agent: "getSubscriptionReport",
          appName: "selfMaximized",
          folder: "reports",
        },
      ],
      excel: excelDownload ? true : undefined,
    };

    const config = {
      headers: { "x-access-token": `${token}` },
      // Use standard JSON response type since the server returns JSON
      responseType: "json",
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody,
      config as any
    );

    // Handle Excel download response - special handling for Buffer data in JSON
    if (
      excelDownload &&
      response.data?.data?.data &&
      response.data.data.type === "Buffer"
    ) {
      // Convert the Buffer array back to binary data
      const bufferData = response.data.data.data;
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `payment_report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);

      return { success: true };
    }

    // Return the raw data for normal requests
    return response.data;
  } catch (error) {
    console.error("Error fetching Payment details:", error);
    throw error;
  }
};
export const getBookingDetails = async ({
  token,
  filters,
  offset,
  excelDownload = false,
}: BookingDetailsParams) => {
  try {
    // Structure the request differently based on if it's a download request
    const requestBody = {
      filter: {
        filter: !excelDownload
          ? {
              logic: "and",
              offset: offset ? (offset - 1) * 5 : 0,
              limit: 5,
              filters: filters,
              sort: [],
            }
          : undefined,
      },
      analytics: {},
      action: "command",
      command: [
        {
          agent: "userBookingList",

          appName: "selfMaximized",

          folder: "reports",
        },
      ],
      excel: excelDownload ? true : undefined,
    };

    const config = {
      headers: { "x-access-token": `${token}` },
      // Use standard JSON response type since the server returns JSON
      responseType: "json",
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      requestBody,
      config as any
    );

    // Handle Excel download response - special handling for Buffer data in JSON
    if (
      excelDownload &&
      response.data?.data?.data &&
      response.data.data.type === "Buffer"
    ) {
      // Convert the Buffer array back to binary data
      const bufferData = response.data.data.data;
      const uint8Array = new Uint8Array(bufferData);
      const blob = new Blob([uint8Array], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Create and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `payment_report_${new Date().toISOString().split("T")[0]}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 100);

      return { success: true };
    }

    // Return the raw data for normal requests
    return response.data;
  } catch (error) {
    console.error("Error fetching Payment details:", error);
    throw error;
  }
};
