import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface MonthlyOrderItem {
  month: string; // "2024-10"
  monthName: string; // "October"
  totalMonthlyOrdersCount: number;
}

interface ChartProps {
  data?: MonthlyOrderItem[]; // The array from getTotalMonthlyOrder
}

const TotalOrdersChart: React.FC<ChartProps> = ({ data }) => {
  // Fallback if no data
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">TOTAL ORDERS</h3>
          <p className="chart-subtitle">$ 0.00</p>
        </div>
        {/* </div> */}
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Map the month names to an array of labels
  const labels = data.map((item) => item.monthName);
  // Map the order counts to an array of numeric values
  const orderValues = data.map((item) => item.totalMonthlyOrdersCount);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">TOTAL ORDERS</h3>
          {/* Optionally show the sum of orders in the subtitle */}
          <p className="chart-subtitle">
            {orderValues.reduce((acc, cur) => acc + cur, 0)} orders
          </p>
        </div>
      </div>
      <CChartLine
        data={{
          labels: labels,
          datasets: [
            {
              label: "Monthly Orders",
              borderColor: "#666",
              data: orderValues,
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // If you want a dynamic max:
                // max: Math.max(...orderValues) < 25 ? 25 : undefined,
                // Or remove to let chart auto-scale
              },
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default TotalOrdersChart;
