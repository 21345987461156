import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useToken } from "../authContext";

const useTotalCategoryLength = () => {
  const [totalCatLength, setTotalCatLength] = useState<any>([]);
  const authCtx = useToken();

  const fetchTotalCategoryLength = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": authCtx.token },
        }
      );

      if (response?.data?.statusCode === 200) {
        setTotalCatLength(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching category length:", error);
    }
  };

  useEffect(() => {
    fetchTotalCategoryLength();
  }, []);

  return { totalCatLength, fetchTotalCategoryLength };
};

export default useTotalCategoryLength;
