import React, { Suspense, useEffect, useState } from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Login from "./pages/AdminDashboard/AuthComponents/Login";
import Dashboard from "./pages/AdminDashboard/Layout/Dasboard";
import ForgotPassword from "./pages/AdminDashboard/AuthComponents/ForgotPassword";
import NewPassword from "./pages/AdminDashboard/AuthComponents/NewPassword";
import ErrorPage from "./pages/AdminDashboard/AuthComponents/Error";
import Body from "./pages/AdminDashboard/Layout/Body";
import UserList from "./pages/AdminDashboard/Menus/users/UserList";
// import Categories from "./pages/AdminDashboard/Menus/categories/Categories";
import UserDetails from "./pages/AdminDashboard/Menus/users/UserDetails";
import SpecialistList from "./pages/AdminDashboard/Menus/specialists/Specialist";
import SpecialistDetails from "./pages/AdminDashboard/Menus/specialists/SpecialistDetails";
import AddCategories from "./pages/AdminDashboard/Menus/categories/AddCategories";
import SpecialistDashboard from "./SpecialistDashboard/Layout/SpecialistDashboard";
import AuthContext, { useToken } from "./authContext";
import SpecialistBody from "./SpecialistDashboard/Layout/SpecialistBody";
// import RecentBlogs from "./pages/AdminDashboard/Menus/magazines/RecentBlogs";
import CreateBlog from "./pages/AdminDashboard/Menus/magazines/CreateBlog";
import SingleCategoryDetails from "./pages/AdminDashboard/Menus/categories/SingleCategoryDetails";
import EditCatogory from "./pages/AdminDashboard/Menus/categories/EditCategories";
import Shimmer from "./pages/AdminDashboard/Shimmer";
import EditBlog from "./pages/AdminDashboard/Menus/magazines/EditBlog";
import ApproveSpecialistDetails from "./pages/AdminDashboard/Menus/specialists/ApproveSpecialistDetails";
import Calender from "./SpecialistDashboard/components/Calender";
import CategoryWiseSpecialist from "./pages/AdminDashboard/Menus/specialists/CategoryWiseSpecialist";
// import RecentPackages from "./pages/AdminDashboard/Menus/packages/RecentPackages";
import CreatePackage from "./pages/AdminDashboard/Menus/packages/CreatePackage";
import CryptoJS from "crypto-js";
import SpecialistUserList from "./SpecialistDashboard/components/SpecialistUserList";
import SpecialistUserDetails from "./SpecialistDashboard/components/UserDetails";
import Analytics from "./pages/AdminDashboard/Menus/analytics/Analytics";
import AnalyticsDashboard from "./pages/AdminDashboard/Menus/analytics/dashboard/dashboard";
import Reviews from "./pages/AdminDashboard/Menus/reviews/Reviews";
import AnalyticsReport from "./pages/AdminDashboard/Menus/analytics/reports/reports";
import AdminAnalyticsDetails from "./pages/AdminDashboard/Menus/analytics/Details";
import CreateProduct from "./SpecialistDashboard/components/CreateProduct";
import UserDashboard from "./userDashboard/Layouts/UserDashboard";
import UserBody from "./userDashboard/Layouts/UserBody";
import SpecialistAnalytics from "./SpecialistDashboard/components/SpecialistAnalytics";
import ProductList from "./SpecialistDashboard/components/ProductList";
import SpecialistProfile from "./SpecialistDashboard/components/SpecialistProfile";
import UserCategories from "./userDashboard/Components/UserCategories";
import UserCategoriesDetailsPage from "./userDashboard/Components/UserCategoriesDetailsPage";
import MyBooking from "./userDashboard/Components/MyBooking";
import UserCategoriesSpecialistDetails from "./userDashboard/Components/UserCategoriesSpecialistDetails";
import UserSpecialist from "./userDashboard/Components/UserSpecialist";
import Meeting from "./SpecialistDashboard/components/Meeting";
import Setting from "./pages/AdminDashboard/Menus/setting/Setting";
import EditPackage from "./pages/AdminDashboard/Menus/packages/EditPackage";
import Modal from "./SpecialistDashboard/Layout/Modal";
import MoneyManagement from "./SpecialistDashboard/components/MoneyManagement";
import EditProduct from "./SpecialistDashboard/components/EditProduct";
import AddBankDetails from "./SpecialistDashboard/components/AddBankDetails";
import RatingPage from "./userDashboard/Components/RatingPage";
import NotificationList from "./SpecialistDashboard/Layout/NotificationList";
import OrderList from "./pages/AdminDashboard/Menus/orders/OrderList";
import UserOrderList from "./userDashboard/Components/UserOrderList";
import EmailTemplate from "./pages/AdminDashboard/Menus/updatePrompts/EmailTemplate";
import SpecialistSetting from "./SpecialistDashboard/components/SpecialistSetting";
import TermsAndConditions from "./SpecialistDashboard/Policies/TermsAndConditions";
import Policies from "./pages/AdminDashboard/Menus/policies/Policies";
import UserTermsAndConditions from "./SpecialistDashboard/Policies/UserTermsAndConditions";
import EditPage from "./pages/AdminDashboard/Menus/editpages/EditPage";
import UserSettings from "./userDashboard/Components/UserSettings";
import FaqList from "./pages/AdminDashboard/Menus/Faq/FaqList";
import CreateFaq from "./pages/AdminDashboard/Menus/Faq/CreateFaq";
import EditFaq from "./pages/AdminDashboard/Menus/Faq/EditFaq";
import CareerList from "./pages/AdminDashboard/Menus/Career/CareerList";
import CareerEdit from "./pages/AdminDashboard/Menus/Career/CareerEdit";
import CareerCreate from "./pages/AdminDashboard/Menus/Career/CareerCreate";
import WelcomeMessage from "./SpecialistDashboard/components/WelcomeMessage";
import RefundList from "./pages/AdminDashboard/Menus/refunds/RefundList";
import Notifications from "./pages/AdminDashboard/Menus/updatePrompts/Notifications";
import MySubscription from "./userDashboard/Components/MySubscription";
import UserSubscriptionDetails from "./userDashboard/Components/UserSubscriptionDetails";
import MarkMeetingAsComplete from "./SpecialistDashboard/components/MarkMeetingAsComplete";

const EnhancedComponent = SpecialistSetting(Setting);
const Categories = React.lazy(
  () => import("./pages/AdminDashboard/Menus/categories/Categories")
);
const RecentBlogs = React.lazy(
  () => import("./pages/AdminDashboard/Menus/magazines/RecentBlogs")
);

const RecentPackages = React.lazy(
  () => import("./pages/AdminDashboard/Menus/packages/RecentPackages")
);

const appRouter = createHashRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "termsandconditions",
    element: <TermsAndConditions />,
  },

  {
    path: "usertermsandconditions",
    element: <UserTermsAndConditions />,
  },
  {
    path: "/changepassword/:id",
    element: <NewPassword />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        path: "shimmer",
        element: <Shimmer />,
      },
      {
        path: "",
        element: <Body />,
      },
      {
        path: "create_faq",
        element: <CreateFaq />,
      },
      {
        path: "faq_list",
        element: <FaqList />,
      },
      {
        path: "edit_faq",
        element: <EditFaq />,
      },
      {
        path: "career_List",
        element: <CareerList />,
      },
      {
        path: "career_create",
        element: <CareerCreate />,
      },
      {
        path: "edit_career",
        element: <CareerEdit />,
      },
      {
        path: "refunds",
        element: <RefundList />,
      },

      {
        path: "users",
        element: <UserList />,
      },
      {
        path: "categories",
        element: (
          <Suspense fallback={<Shimmer />}>
            <Categories />
          </Suspense>
        ),
      },
      {
        path: "categories/categorydetails/:id",
        element: <SingleCategoryDetails />,
      },
      {
        path: "categories/categoryedit/:id",
        element: <EditCatogory />,
      },
      {
        path: "categories/addcategories",
        element: <AddCategories />,
      },
      {
        path: "users/userdetails/:id",
        element: <UserDetails />,
      },
      {
        path: "specialists",
        element: <SpecialistList />,
      },
      {
        path: "packages",
        element: (
          <Suspense fallback={<Shimmer />}>
            <RecentPackages />
          </Suspense>
        ),
      },
      {
        path: "packages/addpackage",
        element: <CreatePackage />,
      },
      {
        path: "packages/editpackage/:id",
        element: <EditPackage />,
      },
      {
        path: "categories/allspecialists",
        element: <CategoryWiseSpecialist />,
      },
      {
        path: "specialists/specialistdetails/:id",
        element: <SpecialistDetails />,
      },
      {
        path: "specialists/specialistsapprove/:id",
        element: <ApproveSpecialistDetails />,
      },
      {
        path: "magazine",
        element: (
          <Suspense fallback={<Shimmer />}>
            <RecentBlogs />
          </Suspense>
        ),
      },
      {
        path: "magazine/editblog/:id",
        element: <EditBlog />,
      },
      {
        path: "email_templates",
        element: <EmailTemplate />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "magazine/addblog",
        element: <CreateBlog />,
      },
      {
        path: "orderlist",
        element: <OrderList />,
      },
      {
        path: "analytics",
        element: <AnalyticsDashboard />,
      },
      {
        path: "analytics/reports",
        element: <AnalyticsReport />,
      },
      {
        path: "analytics/reports/:id",
        element: <AnalyticsReport />,
      },
      {
        path: "analytics/payouts",
        element: <Analytics />,
      },

      {
        path: "policies",
        element: <Policies />,
      },
      {
        path: "analytics/:id",
        element: <AdminAnalyticsDetails />,
      },
      {
        path: "pages",
        element: <EditPage />,
      },
      {
        path: "settings",
        element: <Setting data={""} name="" profile={""} />,
      },
      {
        path: "notificationlist",
        element: <NotificationList />,
      },
      {
        path: "reviews",
        element: <Reviews />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },
  {
    path: "*", // Catch-all for unmatched routes
    element: <ErrorPage />,
  },
]);

const specialistRouter = createHashRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "welcome",
    element: <WelcomeMessage />,
  },
  {
    path: "/changepassword/:id",
    element: <NewPassword />,
  },
  {
    path: "termsandconditions",
    element: <TermsAndConditions />,
  },

  {
    path: "/",
    element: <SpecialistDashboard />,
    children: [
      {
        path: "dashboard",
        element: <SpecialistBody />,
      },
      {
        path: "users",
        element: <SpecialistUserList />,
      },
      {
        path: "markascomplete",
        element: <MarkMeetingAsComplete />,
      },
      {
        path: "notificationlist",
        element: <NotificationList />,
      },
      {
        path: "modal",
        element: <Modal isOpen={true}>{""}</Modal>,
      },

      {
        path: "users/userdetails/:id",
        element: <SpecialistUserDetails />,
      },
      {
        path: "product",
        element: <ProductList />,
      },
      {
        path: "product/addproduct",
        element: <CreateProduct />,
      },
      {
        path: "specialistanalytics",
        element: <SpecialistAnalytics />,
      },
      {
        path: "/users/specialistprofile/:id",
        element: <SpecialistProfile />,
      },

      {
        path: "money-management",
        element: <MoneyManagement />,
      },
      {
        path: "specialistsettings",
        element: <EnhancedComponent />,
      },
      {
        path: "money-management/addbankdetails",
        element: <AddBankDetails />,
      },
      {
        path: "/product/editproduct/:id",
        element: <EditProduct />,
      },
      {
        path: "/dashboard/:id/:name",
        element: <Meeting />,
      },
    ],
  },
  {
    path: "/my-calendar",
    element: <Calender />,
  },

  {
    path: "/forgotpassword",
    element: <ForgotPassword />,
  },

  {
    path: "/login",
    element: <Login />,
  },

  {
    path: "*", // Catch-all for unmatched routes
    element: <ErrorPage />,
  },
]);

const userRouter = createHashRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "welcome",
    element: <WelcomeMessage />,
  },
  {
    path: "usertermsandconditions",
    element: <UserTermsAndConditions />,
  },
  {
    path: "/changepassword/:id",
    element: <NewPassword />,
  },
  {
    path: "/",
    element: <UserDashboard />,
    children: [
      {
        path: "dashboard",
        element: <UserBody />,
      },
      {
        path: "specialists",
        element: <SpecialistList />,
      },
      {
        path: "specialists/specialistdetails/:id",
        element: <SpecialistDetails />,
      },
      {
        path: "orderlist",
        element: <UserOrderList />,
      },
      {
        path: "wellness-category",
        element: <UserCategories />,
      },
      {
        path: "wellness-category/detailpage/:id",
        element: <UserCategoriesDetailsPage />,
      },
      {
        path: "mybooking",
        element: <MyBooking />,
      },
      {
        path: "my_subscription",
        element: <MySubscription />,
      },
      {
        path: "user_subscription_details",
        element: <UserSubscriptionDetails />,
      },
      {
        path: "userSettings",
        element: <UserSettings />,
      },
      {
        path: "usercategoriesspecialistdetails",
        element: <UserCategoriesSpecialistDetails />,
      },
      {
        path: "specialist_categorywise",
        element: <UserSpecialist />,
      },

      {
        path: "/ratingpage",
        element: <RatingPage />,
      },

      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/dashboard/:id/:name",
        element: <Meeting />,
      },
      {
        path: "*", // Catch-all for unmatched routes
        element: <ErrorPage />,
      },
      {
        path: "notificationlist",
        element: <NotificationList />,
      },
      {
        path: "*",
        element: <ErrorPage />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const authCtx = useToken();
  const authRole = authCtx?.role;

  let decryptedRole;
  if (
    authRole !== "admin" &&
    authRole !== "specialist" &&
    authRole !== "user"
  ) {
    const decryptUserRole = (encryptedRole: any, key: any) => {
      if (!encryptedRole) {
        return null; // or handle appropriately if null encryptedRole is not expected
      }
      const bytes = CryptoJS.AES.decrypt(encryptedRole, key);
      return bytes.toString(CryptoJS.enc.Utf8).trim(); // Trim the decrypted role
    };

    decryptedRole = decryptUserRole(authRole, "98760");
  }
  let resizeTimeout: any;

  function handleResize() {
    if (!resizeTimeout) {
      resizeTimeout = requestAnimationFrame(() => {
        resizeTimeout = null;
      });
    }
  }

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div className="App">
      {authRole === "admin" || decryptedRole === '"admin"' ? (
        <RouterProvider router={appRouter} />
      ) : authRole === "specialist" || decryptedRole === '"specialist"' ? (
        <RouterProvider router={specialistRouter} />
      ) : authRole === "user" || decryptedRole === '"user"' ? (
        <RouterProvider router={userRouter} />
      ) : (
        <RouterProvider router={appRouter} />
      )}
    </div>
  );
}

export default App;
