import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../pages/AdminDashboard/Shimmer";
import { Link, useNavigate } from "react-router-dom";
import noDataFoundImage from "../../../src/img/content/bg/page-not-found.png";
import moment from "moment";

const UserOrderList: React.FC = () => {
  const authContext = useToken();
  const [orderList, setOrderList] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [loader, setLoader] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<any>("all");
  // const [filterType, setFilterType] = useState<any>('');

  const userInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const pagination = async (type: any, totalPages: any) => {
    if (type === "next") {
      setPaginationCount((prevState: any) => prevState + 1);
      await getOrderList("", paginationCount + 1);
    } else if (type === "previous") {
      setPaginationCount((prevState: any) => prevState - 1);
      await getOrderList("", paginationCount - 1);
    } else if (type === "first") {
      setPaginationCount(1);
      await getOrderList("", 1);
    } else if (type === "last") {
      setPaginationCount(Math.ceil(totalPages));
      await getOrderList("", Math.ceil(totalPages));
    }
  };

  const getOrderList = async (filters: any, offset: any) => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: offset ? (offset - 1) * 10 : 0,
              limit: 10,
              filters: [
                {
                  field: "customerId",
                  operator: "eq",
                  table: "orders",
                  value: userInfo.id,
                },
              ],
              sort: [
                {
                  field: "id",
                  table: "orders",
                  dir: "asc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "orderList",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setOrderList(result?.data?.data);
        setTotalPages(orderList.length / 10);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!", err);
        setLoader(false);
      });
  };

  const getOrderListCb = useCallback(getOrderList, [authContext.token]);

  useEffect(() => {
    getOrderListCb("", "");
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper user-wrapper-inner overflow-auto">
        <div className="container pt-3 pt-lg-0">
          <div className="row">
            <div className="col-lg-12">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to={"#pills-all"}
                    className={`nav-link ${
                      checkedState === "all" ? "active" : ""
                    }  mr-4`}
                    id="pills-all-tab"
                    data-toggle="pill"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >
                    ALL
                  </Link>
                </li>
              </ul>
              <div className="tab-content pt-3 pt-lg-0" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab">
                    <div className="container pb-4 px-0">
                      <div className="col-lg-12 px-0">
                        <div className="tab w-100">
                          <div className="table-responsive user__all__active__inactive__card">
                            <table className="table table--lines table--striped">
                              {orderList.length !== 0 && orderList !== null ? (
                                <thead className="table__header table__header--sticky text-center">
                                  <tr>
                                    <th className="col-2">
                                      <span>Order Date</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Transaction Id</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Transaction Key</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Total</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Tax</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Grand Total</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Transaction Status</span>
                                    </th>
                                  </tr>
                                </thead>
                              ) : null}
                              <tbody className="text-center">
                                {orderList.length !== 0 &&
                                orderList !== null ? (
                                  orderList.map((elem: any) => (
                                    <tr className="table__row" key={elem.id}>
                                      <td className="table__td">
                                        <div className="media-item">
                                          <div className="media-item__right">
                                            <h5 className="media-item__title text-truncate font-14 pl-3">
                                              {moment(elem.orderDate).format(
                                                "YYYY-MM-DD"
                                              )}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-truncate font-14">
                                          {elem.transactionId}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.transactionKey}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.total}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.tax}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.grandTotal}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.transactionStatus}
                                        </span>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <div className="col-lg-12">
                                    <img
                                      src={noDataFoundImage}
                                      alt=""
                                      className="img-fluid user__all__active__inactive__card--image"
                                      style={{ width: "auto", height: "500px" }}
                                      loading="lazy"
                                    />
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="row mt-3 mt-lg-0">
                            <div className="col-lg-12 text-right">
                              <nav
                                aria-label="Page navigation example"
                                className="pagination__card"
                              >
                                <ul className="pagination">
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }`}
                                        onClick={() => pagination("first", "")}
                                        disabled={paginationCount === 1}
                                      >
                                        &laquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() =>
                                          pagination("previous", "")
                                        }
                                        disabled={paginationCount === 1}
                                      >
                                        Previous
                                      </button>
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount === 1
                                          ? "next__arrow--icon"
                                          : "text-white next--page"
                                      }  page-link previous--page `}
                                      onClick={() => pagination("previous", "")}
                                      disabled={paginationCount === 1}
                                    >
                                      Previous
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        orderList.length < 10
                                          ? "next--btn"
                                          : "text-white next--page"
                                      } page-link `}
                                      onClick={() => pagination("next", "")}
                                      disabled={
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        orderList.length < 10
                                      }
                                    >
                                      Next
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Next"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          orderList.length < 10
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  `}
                                        onClick={() =>
                                          pagination(
                                            "last",
                                            orderList.length / 10
                                          )
                                        }
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          orderList.length < 10
                                        }
                                      >
                                        &raquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          orderList.length < 10
                                            ? "next--btn"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() => pagination("next", "")}
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          orderList.length < 10
                                        }
                                      >
                                        Next
                                      </button>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserOrderList;
