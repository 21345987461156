import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getPaymentsDetails } from "../ApiServices/ReportsAPI";
import FilterIcon from "../../../../../../../src/img/content/FilterIcon";
import SearchIcon from "../../../../../../../src/img/content/SearchIcon";
import BackIcon from "../../../../../../../src/img/content/BackIcon";
import DownloadIcon from "../../../../../../../src/img/content/DownloadIcon";
import FilterDropdown from "./FilterDropdown";
import Shimmer from "../../../../Shimmer";
import { useToken } from "../../../../../../authContext";
import moment from "moment";
import Pagination from "./Pagination";

const filterMapping: Record<string, any> = {
  appointmentId: {
    field: "uuid",
    table: "BC",
    operator: "contain",
    value: "",
  },
  specialistName: {
    field: "name",
    table: "U",
    operator: "contain",
    value: "",
  },
  appointmentDateTime: {
    field: "startTime",
    table: "BC",
    operator: "contain",
    value: "",
  },
  paymentId: {
    field: "transactionId",
    table: "O",
    operator: "contain",
    value: "",
  },
  amount: {
    field: "grandTotal",
    table: "O",
    operator: "contain",
    value: "",
  },

  specialistPayoutDate: {
    field: "specialist_payout_date",
    table: "",
    operator: "contain",
    value: "",
  },
};
interface Payment {
  appointmentId: string;
  specialistName: string;
  appointmentDateTime: string;
  paymentId: string;
  amount: string;
  specialistPayoutDate: string;
}
interface headerData {
  totalGrossRevenue: string;
  totalNetRevenue: string;
  totalPlatformCommission: string;
  totalSpecialistEarnings: string;
}
const PaymentAnalyticsReport: React.FC = () => {
  const authContext = useToken();
  const [loader, setLoader] = useState(false);
  const [payment, setPayment] = useState<Payment[]>([]);
  const [headerData, setHeaderData] = useState<headerData[]>([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Multi-select filters
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>([]);
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);

  // Immediate vs Debounced search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Show/hide filter dropdown
  const [filterVisible, setFilterVisible] = useState(false);
  // Debounce effect for searchTerm
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPaginationCount(1); // reset to first page on new search
    }, 500); // adjust delay as needed

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Build final filters for the API
  const filterType = useMemo(() => {
    return appliedFilterKeys.map((key) => {
      const obj = { ...filterMapping[key] };
      obj.value = debouncedSearchTerm; // only apply after debounce
      return obj;
    });
  }, [appliedFilterKeys, debouncedSearchTerm]);

  // Filter options
  const filterOptions = [
    { label: "APPOINTMENT ID", value: "appointmentId" },
    { label: "SPECIALIST NAME", value: "specialistName" },
    { label: "PAYMENT ID", value: "paymentId" },
    { label: "AMOUNT", value: "amount" },
    // { label: "APT DATE", value: "appointmentDateTime" },
    // { label: "SPECIALIST PAYOUT DATE", value: "specialistPayoutDate" },
  ];

  // When user applies filters
  const handleFilterApply = () => {
    setAppliedFilterKeys(selectedFilterKeys);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // When user clears filters
  const handleFilterClear = () => {
    setSelectedFilterKeys([]);
    setAppliedFilterKeys([]);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // Toggling checkboxes
  const handleCheckboxChange = (updated: string[]) => {
    setSelectedFilterKeys(updated);
  };

  // Trigger fetch when filterType or pagination changes (and also if debouncedSearchTerm changes, but that's inside filterType's useMemo)
  const handleFetchPaymentDetails = async () => {
    try {
      setLoader(true);
      const responseData = await getPaymentsDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        searchTerm: "",
      });

      const rawPayments = responseData?.data || [];
      const formattedPayments: Payment[] = rawPayments?.responseCount?.map(
        (item: any) => {
          // Conditionally remove ":000" only if it exists
          const fixedStartTime = item?.bookingStartTime?.includes(":000")
            ? item.bookingStartTime.slice(0, -2)
            : item.bookingStartTime;

          const fixedEndTime = item?.bookingEndTime?.includes(":000")
            ? item.bookingEndTime.slice(0, -2)
            : item.bookingEndTime;

          // Parse dates using moment
          const startTime = moment.utc(fixedStartTime);
          const endTime = moment.utc(fixedEndTime);

          const specialistPayoutDate =
            item?.specialist_payout_date !== " " &&
            `${moment
              .utc(item?.specialist_payout_date?.trim())
              .format("DD/MM/YYYY")}\n${moment
              .utc(item?.specialist_payout_date?.trim())
              .format("hh:mm A")} `;
          return {
            appointmentId: item?.appointmentId || "-",
            specialistName: item?.specialistName?.toUpperCase() || "-",
            appointmentDateTime: `${startTime.format(
              "DD/MM/YYYY"
            )}\n${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")}`,
            paymentId: item?.paymentId || "-",
            amount: `$ ${item?.amount}` || "-",
            specialistPayoutDate: specialistPayoutDate || "-", // Handle empty payout date
          };
        }
      );
      const formattedheaderData: headerData[] = rawPayments?.headerData?.map(
        (item: any) => {
          return {
            totalGrossRevenue: item?.totalGrossRevenue || "0.00",
            totalNetRevenue: item?.totalNetRevenue || "0.00",
            totalPlatformCommission: item?.totalPlatformCommission || "0.00",
            totalSpecialistEarnings: item?.totalSpecialistEarnings || "0.00",
          };
        }
      );

      setHeaderData(formattedheaderData);
      setPayment(formattedPayments);
      setPaymentCount(responseData?.data?.totalCount || 0);
      setTotalPages(Math.ceil((responseData?.data?.totalCount || 0) / 4));
    } catch (error) {
      console.error("Failed to fetch payment details:", error);
    } finally {
      setLoader(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoader(true);
      await getPaymentsDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        excelDownload: true,
      });
    } catch (error) {
      console.error("Failed to download Excel:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleFetchPaymentDetails();
  }, [filterType, paginationCount]);

  // Pagination logic
  const pagination = (type: string, total: number) => {
    let newOffset = paginationCount;
    if (type === "next") {
      newOffset = paginationCount + 1;
    } else if (type === "previous") {
      newOffset = paginationCount - 1;
    } else if (type === "first") {
      newOffset = 1;
    } else if (type === "last") {
      newOffset = Math.ceil(total);
    }
    setPaginationCount(newOffset);
  };

  const columns: ColumnsType<Payment> = [
    {
      title: "APPOINTMENT ID",
      dataIndex: "appointmentId",
      key: "appointmentId",
      width: "14%",
    },
    {
      title: "SPECIALIST NAME",
      dataIndex: "specialistName",
      key: "specialistName",
      width: "14%",
    },
    {
      title: "APPOINTMENT DATE & TIME",
      dataIndex: "appointmentDateTime",
      key: "appointmentDateTime",
      width: "20%",
      // Render multiline with <br />
      render: (text: string) => (
        <div
          dangerouslySetInnerHTML={{
            __html: text.replace("\n", "<br />"),
          }}
        />
      ),
    },
    {
      title: "PAYMENT ID",
      dataIndex: "paymentId",
      key: "paymentId",
      width: "14%",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      width: "10%",
    },
    {
      title: "SPECIALIST PAYOUT DATE",
      dataIndex: "specialistPayoutDate",
      key: "specialistPayoutDate",
      width: "14%",
    },
  ];
  if (loader) return <Shimmer />;

  return (
    <div className="main-content">
      <Link to="/dashboard/analytics/reports" className="back-button">
        <BackIcon width={18} height={18} />
        <span className="font-18">PAYMENT ANALYTICS REPORT</span>
      </Link>

      {/* Summary Cards */}
      <div className="summary-cards">
        <div className="unique-card">
          <div className="unique-card-title">TOTAL GROSS REVENUE</div>
          <div className="unique-card-value">
            $ {headerData[0]?.totalGrossRevenue}
          </div>
        </div>
        <div className="unique-card">
          <div className="unique-card-title">TOTAL NET REVENUE</div>
          <div className="unique-card-value">
            $ {headerData[0]?.totalNetRevenue}
          </div>
        </div>
        <div className="unique-card">
          <div className="unique-card-title">TOTAL PLATFORM COMMISSION</div>
          <div className="unique-card-value">
            $ {headerData[0]?.totalPlatformCommission}
          </div>
        </div>
        <div className="unique-card">
          <div className="unique-card-title">TOTAL SPECIALIST EARNINGS</div>
          <div className="unique-card-value">
            $ {headerData[0]?.totalSpecialistEarnings}
          </div>
        </div>
      </div>

      <div className="ubdr-action-bar">
        <button
          className="ubdr-filter-button"
          onClick={() => {
            setFilterVisible(!filterVisible);
            setSelectedFilterKeys(appliedFilterKeys);
          }}
        >
          <FilterIcon width={18} height={18} />
        </button>

        {/* Multi-select FilterDropdown */}
        <FilterDropdown
          filterOptions={filterOptions}
          visible={filterVisible}
          selectedValues={selectedFilterKeys}
          onChange={handleCheckboxChange}
          onApply={handleFilterApply}
          onClear={handleFilterClear}
          onVisibleChange={setFilterVisible}
        />

        <div className="ubdr-search-bar">
          <span className="ubdr-search-icon">
            <SearchIcon width={16} height={16} />
          </span>
          <input
            type="text"
            className="ubdr-search-reports"
            placeholder="SEARCH..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="ubdr-report-button" onClick={downloadExcel}>
          <DownloadIcon width={18} height={18} />
          <span>REPORT</span>
        </button>
      </div>

      {/* Antd Table */}
      <div className="table-container-payment-anlytics-report">
        <Table<Payment>
          columns={columns}
          dataSource={payment}
          rowKey="appointmentId"
          pagination={false}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>
      <div className="pagination-container-payment">
        <Pagination
          pagination={pagination}
          paginationCount={paginationCount}
          setPaginationCount={setPaginationCount}
          totalPages={totalPages}
          list={payment}
          ListCount={paymentCount}
        />
      </div>
    </div>
  );
};

export default PaymentAnalyticsReport;
