// FilterDropdown.tsx (Multi-Select)
import React from "react";
import { Dropdown, Checkbox } from "antd";
import "./FilterDropdown.css";
interface FilterOption {
  label: string;
  value: string;
}

interface Props {
  filterOptions: FilterOption[];
  visible: boolean;
  selectedValues: string[]; // ARRAY of selected keys
  onChange: (updated: string[]) => void; // callback with the new array
  onApply: () => void;
  onClear: () => void;
  onVisibleChange: (v: boolean) => void;
}

const FilterDropdown: React.FC<Props> = ({
  filterOptions,
  visible,
  selectedValues,
  onChange,
  onApply,
  onClear,
  onVisibleChange,
}) => {
  if (!visible) return null;

  // Toggle an option’s selection
  const handleCheckboxToggle = (val: string) => {
    // if it’s already in selectedValues, remove it; else add it
    let updated: string[];
    if (selectedValues.includes(val)) {
      updated = selectedValues.filter((v) => v !== val);
    } else {
      updated = [...selectedValues, val];
    }
    onChange(updated);
  };

  const menu = (
    <div className="filter-dropdown-container">
      <div className="filter-dropdown-header">
        <h3>Filter</h3>
      </div>
      <div className="filter-dropdown-body">
        {filterOptions.map((option) => (
          <div key={option.value} className="filter-option">
            <Checkbox
              checked={selectedValues.includes(option.value)}
              onChange={() => handleCheckboxToggle(option.value)}
            >
              {option.label}
            </Checkbox>
          </div>
        ))}
      </div>
      <div className="filter-dropdown-footer">
        <button className="filter-clear-btn" onClick={onClear}>
          CLEAR
        </button>
        <button className="filter-apply-btn" onClick={onApply}>
          APPLY
        </button>
      </div>
    </div>
  );

  return (
    <Dropdown
      overlay={menu}
      visible={visible}
      trigger={["click"]}
      onVisibleChange={onVisibleChange}
    >
      <div />
    </Dropdown>
  );
};

export default FilterDropdown;
