import React, { useEffect, useState } from "react";
import { useToken } from "../../../authContext";
import axios from "axios";
import moment from "moment-timezone";
import Modal from "../../../SpecialistDashboard/Layout/Modal";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { format, startOfWeek, endOfWeek, endOfMonth } from "date-fns";
import { toast } from "react-toastify";

interface Slot {
  uuid: string;
  calendarDate: string;
  doctorId: number;
  StartTime: string;
  EndTime: string;
  Duration: string;
  calendarDay: string;
  isBooked: number;
  dname: string;
}

interface GroupedSlots {
  [day: string]: Slot[];
}
const WeeklyAppoinments: React.FC = () => {
  const [upcomingWeeklyBooking, setUpcomingWeeklyBooking] = useState<any>([]);
  const [loader, setLoader] = useState<any>(true);
  const [open, setOpen] = useState<any>(false);
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [slots, setSlots] = useState<any>([]);
  const [cartProductData, setCartProductData] = useState<any>();
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const authCtx = useToken();
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;
  const userTimeZone = userInfo && userInfo?.timezone;
  const userMenu =
    typeof authCtx.userMenu === "string"
      ? JSON.parse(authCtx.userMenu)
      : authCtx.userMenu;
  const dashboardMenuItem: any = userMenu.find(
    (item: any) => item.menu === "Dashboard"
  );

  const getWeeklyAppointments = async () => {
    setLoader(true);

    const dates: any = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const startDate = new Date(today);
      startDate.setDate(today.getDate() + i);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);

      const startYear = startDate.getFullYear();
      const startMonth = String(startDate.getMonth() + 1).padStart(2, "0");
      const startDay = String(startDate.getDate()).padStart(2, "0");

      const endYear = endDate.getFullYear();
      const endMonth = String(endDate.getMonth() + 1).padStart(2, "0");
      const endDay = String(endDate.getDate()).padStart(2, "0");

      dates.push({
        startDate: `${startYear}-${startMonth}-${startDay}`,
        endDate: `${endYear}-${endMonth}-${endDay}`,
      });
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: dates[0].startDate,
                opr: "AND",
                value2: dates[6].endDate,
              },
              {
                field: "uuid",
                table: "Users",
                operator: "eq",
                value: `${userInfo.uuid}`,
              },
            ],

            sort: [
              {
                field: "calendarDate",
                dir: "asc",
              },
            ],
          },
        },
        menuId: `${dashboardMenuItem.uuid}`,
        action: "command",
        command: [
          {
            agent: "userBooking",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );

    const newData = response.data.data;
    setUpcomingWeeklyBooking(newData);
    setLoader(false);
    let booking: any = newData && newData[0];
    const meetingUrl = booking?.meetingUrl;
    if (typeof meetingUrl === "string") {
      const meetingLink = JSON.parse(meetingUrl);
      console.log(meetingLink);
    } else {
      console.log(meetingUrl);
    }
  };

  useEffect(() => {
    getWeeklyAppointments();
  }, []);

  const handleOpen: any = (cartProductData: any) => {
    const currentTimeInUserTimezone = moment.tz(userTimeZone);
    const startTimeInUserTimezone = moment
      .tz(
        cartProductData?.startTime?.replace(/:000Z$/, ":00Z"),
        "YYYY-MM-DDTHH:mm:ssZ",
        "UTC"
      )
      .tz(userTimeZone);

    if (currentTimeInUserTimezone.isSameOrAfter(startTimeInUserTimezone)) {
      return false;
    }
    setCartProductData(cartProductData);
    setOpen(true);
    getSpecialistBookingSlots(startDate, endDate, cartProductData);
  };

  const handleClose: any = () => {
    setOpen(false);
  };

  const handleRangeSelection = async (selectedRange: any) => {
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate, "");
    } else {
      console.error("Invalid date range selected");
    }
  };

  const handleMonthChange = ({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  };

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };

  const getSpecialistBookingSlots = async (
    newStartDate: any,
    newEndDate: any,
    cp: any
  ) => {
    console.log(cp);
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: moment(newStartDate ? newStartDate : startDate).format(
                  "YYYY-MM-DD"
                ),
                opr: "AND",
                value2: moment(newEndDate ? newEndDate : endDate).format(
                  "YYYY-MM-DD"
                ),
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: cp?.doctorId,
              },
              {
                field: "Duration",
                operator: "eq",
                table: "BookingCalendar",
                value:
                  cp?.duration === parseFloat("0.50").toFixed(2) ? 0.5 : 1.0,
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          // Get the current date and time in the selected timezone
          const now = moment.tz(userTimeZone);

          // Helper function to preprocess StartTime and convert to localized datetime
          const getSlotDateTime = (startTime: string): moment.Moment => {
            return moment
              .tz(
                startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                "YYYY-MM-DDTHH:mm:ssZ", // Parse format
                "UTC"
              )
              .tz(userTimeZone);
          };

          // Filter out past dates and times
          const filteredSlots = responseData.filter((slot) => {
            const slotDateTime = getSlotDateTime(slot.StartTime);

            // Include only slots in the future
            return slot.isBooked === 0 && slotDateTime.isAfter(now);
          });

          // Group slots by their localized calendarDate
          const groupedSlots: GroupedSlots = filteredSlots.reduce(
            (acc: GroupedSlots, slot: Slot) => {
              const slotDate = getSlotDateTime(slot.StartTime).format(
                "YYYY-MM-DD"
              );
              acc[slotDate] = [...(acc[slotDate] || []), slot];
              return acc;
            },
            {}
          );

          // Organize slots into days with their corresponding slots
          const filteredSlotsArray = Object.entries(groupedSlots)
            .map(([day, slots]: [string, Slot[]]) => ({
              dayOfWeek: moment(day, "YYYY-MM-DD")
                .tz(userTimeZone)
                .format("dddd"), // Convert to day of the week
              slots,
            }))
            .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

          setSlots(filteredSlotsArray);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const bookSlot = async () => {
    console.log(cartProductData);
    console.log(selectedSlot);
    if (!selectedSlot) {
      return toast.info("PLEASE SELECT SLOT FIRST", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast",
        bodyClassName: "custom-toast",
      });
    }
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          start_time: selectedSlot.StartTime,
          end_time: selectedSlot.EndTime,
          duration: selectedSlot.Duration,
          appointment_day: selectedSlot.calendarDay,
          appointment_date: selectedSlot.calendarDate,
          cartProductId: "",
          userId: userInfo.id,
          bookingSlotId: selectedSlot?.bookingSlotId,
          appointmentId: selectedSlot?.bookingSlotId,
          isBooked: 1,
          onCreate: moment().tz(userTimeZone).format("YYYY-DD-YY"),
          action: "command",
          command: [
            {
              agent: "updateSlot",
              appName: "selfMaximized",
              folder: "order",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setOpen(false);
          toast.success("YOUR APPOINTMENT IS BOOKED SUCCESSFULLY!");
          setLoader(false);
          getWeeklyAppointments();
          return;
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  const currentMeetingStatus = (elem: any) => {
    const currentTimeInUserTimezone = moment.tz(userTimeZone); // Current time in user's timezone

    if (elem?.startTime) {
      const startTimeInUserTimezone = moment
        .tz(
          elem?.startTime?.replace(/:000Z$/, ":00Z"),
          "YYYY-MM-DDTHH:mm:ssZ",
          "UTC"
        )
        .tz(userTimeZone);

      if (currentTimeInUserTimezone.isSameOrAfter(startTimeInUserTimezone)) {
        return "ONGOING";
      } else {
        return "RESCHEDULE";
      }
    }
    return "UNKNOWN";
  };

  return (
    <>
      <div className="col-lg-12 mb-3">
        {upcomingWeeklyBooking?.length !== 0 &&
        upcomingWeeklyBooking !== null ? (
          upcomingWeeklyBooking?.map((elem: any) => (
            <div className="row doctor-dashboard_reshedule-box p-3 position-relative">
              <div className="col-8 border-left_new">
                <h2 className="mb-0 doctor-name font-16 mb-2">
                  DR - {elem?.dname.toUpperCase()}
                </h2>

                <p className="mb-0 date-time-day-wrapper d-inline-block p-2 font-14">
                  <span className="appoinments-booked-time font-weight-bold">
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(
                            elem.startTime.replace(/:000Z$/, ":00Z"),
                            userTimeZone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                    -
                    {userTimeZone && elem?.endTime
                      ? moment
                          .tz(
                            elem.endTime.replace(/:000Z$/, ":00Z"),
                            userTimeZone
                          )
                          .format("hh:mm A")
                      : "Date Not Available"}
                    <span className="pl-1">|</span>{" "}
                    {elem.duration === "0.50" ? "30 min" : "60 min"} |
                  </span>
                  <span className="appoinments-booked-day font-weight-bold pl-2 text-uppercase 1234">
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(elem.startTime.replace(/:000Z$/, ":00Z"), "UTC")
                          .tz(userTimeZone)
                          .format("YYYY-MM-DD")
                      : "Date Not Available"}{" "}
                    <span className="pr-1">|</span>
                    {userTimeZone && elem?.startTime
                      ? moment
                          .tz(elem.startTime.replace(/:000Z$/, ":00Z"), "UTC")
                          .tz(userTimeZone)
                          .format("dddd")
                      : "Date Not Available"}{" "}
                  </span>
                </p>
              </div>
              <div className="col-4 text-right">
                <span
                  className="reshedule-link font-14 cursor_pointer text-uppercase"
                  onClick={() => handleOpen(elem)}
                >
                  {currentMeetingStatus(elem)}
                </span>
              </div>
              <Modal isOpen={open}>
                <div tabIndex={-1}>
                  <div className="modal-dialog model-md modal-dialog-centered user__calander__booking--list modal-xl modal-dialog-new">
                    <div className="modal-content buy__product__modal py-3">
                      <div className="modal-header py-2">
                        <p className="reschedule__available__title mb-0 text_uppercase ml-3">
                          AVAILABLE SLOTS: DR. {elem?.dname?.toUpperCase()}
                        </p>

                        <button
                          className="reschedule__book mr-3"
                          onClick={handleClose}
                        >
                          <i className="fa fa-close"></i> CANCEL
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row border-top m-0 ">
                          <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                            <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                              Please select your time slot before we proceed
                            </p>
                            <div>
                              <div className="react-calendar-outer">
                                <Calendar
                                  selectRange={true}
                                  onChange={handleRangeSelection}
                                  value={[startDate, endDate]}
                                  onActiveStartDateChange={handleMonthChange} // Handle month change
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                            <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                              Available time slots for the month of{" "}
                              {selectedMonth}
                            </p>
                            <div className="book-overflow">
                              <div className="slot-outer text-uppercase text-white">
                                <div className="slot-inner row g-3">
                                  {slots.map((slot: any, index: any) => (
                                    <div
                                      key={index}
                                      className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3 gap-14"
                                    >
                                      <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-1">
                                        {slot.dayOfWeek}
                                      </h3>
                                      {slot?.slots
                                        .sort((a: any, b: any) => {
                                          const timeA = moment(
                                            a.StartTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            "YYYY-MM-DDTHH:mm:ssZ"
                                          ).tz(userTimeZone);

                                          const timeB = moment(
                                            b.StartTime.replace(
                                              /:000Z$/,
                                              ":00Z"
                                            ),
                                            "YYYY-MM-DDTHH:mm:ssZ"
                                          ).tz(userTimeZone);

                                          if (
                                            timeA.format("A") !==
                                            timeB.format("A")
                                          ) {
                                            return timeA.format("A") === "AM"
                                              ? -1
                                              : 1;
                                          }

                                          return timeA.diff(timeB);
                                        })
                                        .map((elem: any) => (
                                          <div
                                            className="slot-box slot-box-new col-md-6 cursor_pointer p-0"
                                            key={elem.uuid}
                                            onClick={() => passSlots(elem)}
                                          >
                                            <div
                                              className="slot-box-inner border pt-2 pb-2 pl-3 pr-3"
                                              style={{
                                                backgroundColor:
                                                  selectedSlot === elem
                                                    ? "white"
                                                    : "",
                                                color:
                                                  selectedSlot === elem
                                                    ? "black"
                                                    : "",
                                              }}
                                            >
                                              <h5 className="mb-0 explore-heading-two pb-0">
                                                AVAILABILITY | {elem?.Duration}{" "}
                                                HR
                                              </h5>
                                              <p
                                                className="mb-0 explore-heading-two pb-0"
                                                style={{
                                                  color:
                                                    selectedSlot === elem
                                                      ? "black"
                                                      : "white",
                                                }}
                                              >
                                                {userTimeZone && elem?.StartTime
                                                  ? moment
                                                      .tz(
                                                        elem.StartTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ),

                                                        userTimeZone
                                                      )
                                                      .format("hh:mm A")
                                                  : "Date Not Available"}
                                                <span className="to"> TO </span>
                                                {userTimeZone && elem?.EndTime
                                                  ? moment
                                                      .tz(
                                                        elem.EndTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ),

                                                        userTimeZone
                                                      )
                                                      .format("hh:mm A")
                                                  : "Date Not Available"}
                                              </p>
                                              <p
                                                className="mb-0 explore-heading-two pb-0"
                                                style={{
                                                  color:
                                                    selectedSlot === elem
                                                      ? "black"
                                                      : "white",
                                                }}
                                              >
                                                {userTimeZone && elem?.StartTime
                                                  ? moment
                                                      .tz(
                                                        elem.StartTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ),
                                                        "UTC"
                                                      )
                                                      .tz(userTimeZone)
                                                      .format("YYYY-MM-DD")
                                                  : "Date Not Available"}
                                                <span className="day-name-right floar-right">
                                                  {userTimeZone &&
                                                  elem?.StartTime
                                                    ? moment
                                                        .tz(
                                                          elem.StartTime.replace(
                                                            /:000Z$/,
                                                            ":00Z"
                                                          ),
                                                          "UTC"
                                                        )
                                                        .tz(userTimeZone)
                                                        .format("dddd")
                                                    : "Date Not Available"}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  ))}
                                </div>
                              </div>

                              <hr className="custom-hr custom-hr-white text-white"></hr>
                              {slots.length === 0 && (
                                <h4 className="text-white text-center letter_spacing text-uppercase">
                                  No slots available for selected date{" "}
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 text-right">
                            {loader ? (
                              <div className="d-flex align-items-center">
                                <div
                                  className="spinner-grow spinner-grow-sm text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                                <span className="text-dark font-weight-bold ml-2 letter-spacing">
                                  LOADING
                                </span>
                              </div>
                            ) : (
                              <>
                                <button
                                  className="reschedule__book mr-3"
                                  onClick={bookSlot}
                                >
                                  BOOK
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          ))
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <span className="not__available text-uppercase">
                No weekly appointments available
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WeeklyAppoinments;
