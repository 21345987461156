import React from "react";
import filterIcon from "../../../../img/content/icons/Icon feather-filter.svg";
import { Link } from "react-router-dom";
import Spinner from "../../../../loader/Spinner";

interface ManageDrs {
  specialistList: any;
  searchItem: any;
  toggleSpecialistSelection: any;
  selectField: any;
  handleSpecialist: any;
  selectedSpecialist: any;
  fieldName: any;
  categoryLoader: boolean;
}
const ManageDoctors: React.FC<ManageDrs> = ({
  specialistList,
  searchItem,
  toggleSpecialistSelection,
  selectField,
  selectedSpecialist,
  handleSpecialist,
  fieldName,
  categoryLoader,
}) => {
  const renderAreaOfExp = (areaOfExp: any): React.ReactNode => {
    let filteredAreaOfExp: any;

    if (areaOfExp) {
      const parsedAreaOfExp = JSON.parse(areaOfExp);
      filteredAreaOfExp = parsedAreaOfExp
        .map((elem: any) => elem.category)
        .join(" | ");
    }

    return filteredAreaOfExp ? filteredAreaOfExp : null;
  };
  return (
    <div className="col-lg-5 pt-3 pt-lg-0">
      <p className="mb-0 pb-2 font-12 text-uppercase">
        Manage Doctors for this domain
      </p>

      <div className="categories__right-bg categories-add-right p-2">
        <>
          {/* Search bar */}
          <div className="sticky--header">
            <div className="header__searchone magazine_wrapper-searchone d-flex align-items-center pt-3 pb-2">
              <div className="dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle toggle_dropdown--btn mr-2"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="global-dropdown-icon">
                    <img
                      src={filterIcon}
                      alt=""
                      className="img-fluid w-100"
                      style={{ width: "auto", height: "auto" }}
                      loading="lazy"
                    />
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown--menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  {["name", "title", "areaofexpertise", "education"].map(
                    (elem: any, index: any) => (
                      <Link
                        className="dropdown-item"
                        key={elem}
                        to={""}
                        onClick={() => selectField(elem)}
                      >
                        {elem?.toUpperCase()}
                      </Link>
                    )
                  )}
                </div>
              </div>

              <div className="header__search-mobile w-100">
                <form className="form-search" action="#" method="GET">
                  <div className="form-search__container">
                    <span className="form-search__icon-left filter__search--icon">
                      <svg viewBox="0 0 18 18" id="icon-search">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.1184 13.4407C11.1132 14.1763 9.87362 14.6106 8.53259 14.6106C5.17579 14.6106 2.45456 11.8894 2.45456 8.53258C2.45456 5.17577 5.17579 2.45455 8.53259 2.45455C11.8894 2.45455 14.6106 5.17577 14.6106 8.53258C14.6106 9.87378 14.1762 11.1135 13.4404 12.1188C13.4461 12.1242 13.4518 12.1297 13.4574 12.1353L15.2716 13.9495C15.6368 14.3147 15.6368 14.9067 15.2716 15.2719C14.9064 15.6371 14.3144 15.6371 13.9492 15.2719L12.135 13.4577C12.1294 13.4521 12.1238 13.4464 12.1184 13.4407ZM12.874 8.53258C12.874 10.9303 10.9303 12.874 8.53259 12.874C6.13488 12.874 4.19114 10.9303 4.19114 8.53258C4.19114 6.13486 6.13488 4.19113 8.53259 4.19113C10.9303 4.19113 12.874 6.13486 12.874 8.53258Z"
                        />
                      </svg>
                    </span>
                    <input
                      className="form-search__input custome__form__search--input text-dark text-uppercase"
                      type="text"
                      placeholder={`SEARCH BY ${fieldName}`}
                      value={searchItem}
                      name="fieldType"
                      onChange={handleSpecialist}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>

        {categoryLoader ? (
          <Spinner />
        ) : specialistList &&
          specialistList?.length !== 0 &&
          specialistList !== null ? (
          specialistList?.map((elem: any) => (
            <div className="row py-3 align-items-center" key={elem?.id}>
              <div className="col-10">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="media-item">
                    <div className="media-item__icon color-orange">
                      <div className="media-item__icon-text">WS</div>
                      <img
                        className="media-item__thumb"
                        src={elem?.profile}
                        alt="#"
                      />
                    </div>
                    <div className="media-item__right">
                      <h5 className="media-item__title font-13 mb-0 text-uppercase">
                        {
                          elem?.DoctorExperiences?.length > 0
                            ? `${elem?.DoctorExperiences[0]?.title} ${elem?.name} (${elem?.DoctorExperiences[0].education})`
                            : `${elem?.name}` // Fallback to just name if no experience
                        }
                      </h5>
                      <span className="font-12 expertise text-uppercase">
                        {renderAreaOfExp(
                          elem?.DoctorExperiences[0]?.areaOfExpertise
                        )}
                      </span>
                    </div>
                  </div>
                  <p className="mb-0 font-12">
                    {elem.DoctorExperiences &&
                    elem.DoctorExperiences.length > 0 &&
                    elem.DoctorExperiences[0].yearofexperience
                      ? `${elem.DoctorExperiences[0].yearofexperience} YEARS`
                      : "No data"}
                  </p>
                </div>
              </div>
              <div className="col-2">
                <div className="col-auto">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={selectedSpecialist.has(elem.id)}
                      onChange={() => toggleSpecialistSelection(elem.id)}
                    />
                    <span className="checkbox__marker">
                      <span className="checkbox__marker-icon">
                        <svg viewBox="0 0 14 12">
                          <path d="M11.7917 1.2358C12.0798 1.53914 12.0675 2.01865 11.7642 2.30682L5.7036 8.06439C5.40574 8.34735 4.93663 8.34134 4.64613 8.05084L2.22189 5.6266C1.92604 5.33074 1.92604 4.85107 2.22189 4.55522C2.51774 4.25937 2.99741 4.25937 3.29326 4.55522L5.19538 6.45734L10.7206 1.20834C11.024 0.920164 11.5035 0.93246 11.7917 1.2358Z" />
                        </svg>
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          ))
        ) : (
          <span className="no__slots--available">No Data Available</span>
        )}
      </div>
    </div>
  );
};

export default ManageDoctors;
