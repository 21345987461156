import React, { createContext, useContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import axios from "axios";
import axiosInstance from "./axiosInstance";

interface AuthContextProps {
  token: string | null;
  role: string | null;
  refreshToken: string | null;
  isLoggedIn: boolean;
  login: (userCredentials: UserCredentials, role: RoleData) => void;
  logout: () => void;
  currency: string;
  code: string;
  convertCurrency: (number: number) => string;
  userMenu: any;
  userInfo: any;
  organization: any;
}

interface UserCredentials {
  token: string;
  refreshToken: string;
  code: string;
  currencyCode: string;
}

interface RoleData {
  role: string;
}

const AuthContext = createContext<AuthContextProps>({
  token: "",
  role: "",
  refreshToken: "",
  isLoggedIn: false,
  login: (token, role) => {},
  logout: () => {},
  currency: "CAD",
  code: "en-US",
  convertCurrency: (number) => "",
  userMenu: [],
  userInfo: [],
  organization: [],
});

export const AuthContextProvider = (props: any) => {
  const initToken = localStorage.getItem("token");
  const initRole = localStorage.getItem("userRole");
  const initrefreshToken = localStorage.getItem("refreshToken");
  const codes = localStorage.getItem("code");
  const currencyCode = localStorage.getItem("currencyCode");
  const userMenus = localStorage.getItem("userMenu");
  const userInfos = localStorage.getItem("userInfo");
  const organizations = localStorage.getItem("organization");
  const [token, setToken] = useState<string | null>(initToken);
  const [userRole, setRole] = useState<string | null>(initRole);
  const [refreshToken, setrefreshToken] = useState<string | null>(
    initrefreshToken
  );
  const [currency, setCurrency] = useState<string>(currencyCode || "USD");
  const [code, setCode] = useState<string>(codes || "en-US");
  const [userMenu, setUserMenu] = useState(userMenus);
  const [userInfo, setUserInfo] = useState(userInfos);
  const [organization, setOrganization] = useState(organizations);
  const userIsLoggedIN = !!token;

  // useEffect(() => {
  //   if (token) {
  //     const interval = setInterval(() => {
  //       const refeshToken = async () => {
  //         const result = await axios.post(
  //           `${process.env.REACT_APP_API}/pipeline`,
  //           {
  //             action: "command",
  //             command: [
  //               {
  //                 agent: "refreshToken",
  //                 appName: "selfMaximized",
  //                 folder: "auth",
  //               },
  //             ],
  //           },
  //           {
  //             headers: {
  //               "x-access-refreshToken": refreshToken,
  //             },
  //           }
  //         );

  //         setRefreshToken2(result?.data);
  //         logginHandler(result?.data?.data, result?.data?.data?.role);
  //       };
  //       refeshToken();
  //     }, 3.5 * 60 * 1000); // 5 minutes in milliseconds

  //     // Cleanup function to clear the interval when the component unmounts or when the token changes
  //     return () => clearInterval(interval);
  //   }
  // }, [token, refreshToken]);

  useEffect(() => {
    if (!token || !refreshToken) return;

    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If token is expired (401 Unauthorized) and it's not retried yet
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true; // Prevent infinite loops

          try {
            const result = await axios.post(
              `${process.env.REACT_APP_API}/pipeline`,
              {
                action: "command",
                command: [
                  {
                    agent: "refreshToken",
                    appName: "selfMaximized",
                    folder: "auth",
                  },
                ],
              },
              {
                headers: {
                  "x-access-refreshToken": refreshToken,
                },
              }
            );

            logginHandler(result?.data?.data, result?.data?.data?.role);

            originalRequest.headers[
              "x-access-token"
            ] = `${result?.data?.data?.authTokens?.token}`;
            return axios(originalRequest);
          } catch (refreshError) {
            console.error("Token refresh failed:", refreshError);
            logoutHandler();
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, [token, refreshToken]);

  function logginHandler(userCredentials: any, roleData: any) {
    setToken(userCredentials?.authTokens?.token);
    setrefreshToken(userCredentials?.authTokens?.refreshToken);
    setCode("1");
    setRole(roleData);
    setCurrency(userCredentials?.userData?.organization[0]?.currencyCode);
    setUserMenu(userCredentials?.userData?.userMenus);
    setUserInfo(userCredentials?.userData?.userInfo);
    setOrganization(userCredentials?.userData?.organization);

    const secretKey = "98760";
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(roleData),
      secretKey
    ).toString();

    localStorage.setItem(
      "code",
      userCredentials?.userData?.organization[0]?.code
    );
    localStorage.setItem("userRole", encryptedData);
    localStorage.setItem(
      "currencyCode",
      userCredentials?.userData?.organization[0]?.currencyCode
    );
    localStorage.setItem("token", userCredentials?.authTokens?.token);
    localStorage.setItem(
      "refreshToken",
      userCredentials?.authTokens?.refreshToken
    );
    localStorage.setItem(
      "userMenu",
      JSON.stringify(userCredentials?.userData?.userMenus, null, 4)
    );
    localStorage.setItem(
      "userInfo",
      JSON.stringify(userCredentials?.userData?.userInfo, null, 4)
    );
    localStorage.setItem(
      "organization",
      JSON.stringify(userCredentials?.userData?.organization, null, 4)
    );
  }

  const logoutHandler = () => {
    setToken(null);
    setrefreshToken(null);
    localStorage.clear();
    localStorage.clear();
  };

  const handleCurrency = (number: number): string => {
    const formatter = new Intl.NumberFormat(code, {
      style: "currency",
      currency: currency,
    });
    return formatter.format(number);
  };

  const contextValue: AuthContextProps = {
    token: token,
    role: userRole,
    refreshToken: refreshToken,
    isLoggedIn: userIsLoggedIN,
    login: logginHandler,
    logout: logoutHandler,
    currency: currency,
    code: code,
    convertCurrency: handleCurrency,
    userMenu: userMenu,
    userInfo: userInfo,
    organization: organization,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useToken() {
  const authContext: any = useContext(AuthContext);
  if (authContext === undefined || authContext === null) {
    throw new Error("Token not defined");
  }
  return authContext;
}

export default AuthContext;
