import React, { useEffect, useState } from "react";
import { useToken } from "../../../authContext";
import { Link, useLocation } from "react-router-dom";
import useUserRefundList from "../../../customHooks/useUserRefundList";

interface SidebarProps {
  isActive: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isActive }) => {
  const authCtx = useToken();
  const [activeMenu, setActive] = useState<string>("");
  const [routeMenu, setMenuFromRoute] = useState<string>("");
  const [menuData, setMenuData] = useState<MenuItem[]>([]);
  const refundList = useUserRefundList();
  const location = useLocation();
  const pathname = location.pathname;

  interface SubMenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
  }

  interface MenuItem {
    id: number;
    uuid: string;
    menu: string;
    link: string;
    orderId: number;
    parentId: number;
    read: number;
    write: number;
    subMenu: SubMenuItem[];
  }

  useEffect(() => {
    if (authCtx.userMenu) {
      if (typeof authCtx.userMenu === "string") {
        setMenuData(JSON.parse(authCtx.userMenu));
      } else {
        setMenuData(authCtx.userMenu);
      }
    }
  }, [authCtx.userMenu]);

  useEffect(() => {
    let fieldName = pathname.split("/");
    if (fieldName.length <= 2) {
      setMenuFromRoute(fieldName[1]?.toLowerCase());
    } else {
      setMenuFromRoute(fieldName[2]?.toLowerCase());
    }
  }, [pathname, activeMenu]);

  const activeRoute = (type: string) => {
    setActive(type);
    setMenuFromRoute(type.toLowerCase());
  };

  const iconMap: any = {
    Dashboard: null,
    Categories: null,
    Specialists: null,
    Users: null,
    Magazine: null,
    Analytics: null,
    Settings: null,
  };

  const goToWebsite = () => {
    window.location.href = "/";
    return;
  };

  const filteredMenus = menuData.filter((elem: MenuItem) => {
    return (
      elem.menu.toLowerCase() !== "faq" &&
      elem.menu.toLowerCase() !== "career" &&
      elem.menu.toLowerCase() !== "policies" &&
      elem.menu.toLowerCase() !== "refunds"
    );
  });

  return (
    <aside
      className={`sidebar ${
        isActive ? "sidebar--show sidebar--translate" : ""
      } overflow-auto`}
    >
      <div className="sidebar__backdrop"></div>
      <div className="sidebar__container">
        <div className="sidebar__top pb-5 my-5">
          <div className="container container--sm">
            <span
              className="sidebar__logo d-flex justify-content-center"
              onClick={goToWebsite}
            >
              <img
                className="sidebar__logo-icon cursor_pointer"
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                alt="#"
                style={{ width: "auto", height: "auto" }}
                loading="lazy"
              />
            </span>
          </div>
        </div>

        <div className="sidebar__content" data-simplebar="data-simplebar">
          <nav className="sidebar__nav">
            <ul className="sidebar__menu">
              {filteredMenus.map((elem: MenuItem) => (
                <li
                  className="sidebar__menu-item"
                  key={elem.id}
                  onClick={() => activeRoute(elem.menu)}
                >
                  {/* Main Menu Link */}
                  <Link
                    to={
                      elem.menu === "Dashboard"
                        ? ""
                        : elem.menu === "Refunds"
                        ? "/dashboard/refunds-list"
                        : elem.menu === "Email Templates"
                        ? "/dashboard/email_templates"
                        : elem.menu.toLowerCase()
                    }
                    className={`sidebar__link active ${
                      activeMenu.toLowerCase() === elem.menu.toLowerCase() ||
                      routeMenu === elem.menu.toLowerCase()
                        ? ""
                        : "fixed_active"
                    }`}
                  >
                    <span className="sidebar__link-icon">
                      {iconMap[elem.menu] && (
                        <img
                          src={
                            activeMenu === elem.menu ||
                            routeMenu === elem.menu.toLowerCase()
                              ? iconMap[elem.menu].active
                              : iconMap[elem.menu].inactive
                          }
                          alt=""
                          className="img-fluid"
                          style={{ width: "18px", height: "18px" }}
                        />
                      )}
                    </span>
                    {elem.menu.toLowerCase() === "refunds" ? (
                      <div className="position-relative d-inline-block">
                        <span className="sidebar__link-text text-uppercase">
                          {elem.menu}
                        </span>
                        <span className="badge-circle">
                          {
                            refundList.filter((r: any) => r.chargeId !== null)
                              .length
                          }
                        </span>
                      </div>
                    ) : (
                      <span className="sidebar__link-text text-uppercase">
                        {elem.menu}
                      </span>
                    )}
                  </Link>

                  {/* Sub-menu Section */}
                  {elem?.menu !== "Settings" &&
                    elem?.subMenu &&
                    elem?.subMenu?.length > 0 && (
                      <ul className="sidebar__submenu">
                        {elem.subMenu.map((sub: SubMenuItem) => (
                          <li className="sidebar__submenu-item" key={sub.id}>
                            <Link
                              to={sub?.link?.toLowerCase()}
                              // className="sidebar__submenu-link"
                              className={`sidebar__link active ${
                                sub.link
                                  .toLowerCase()
                                  .startsWith(activeMenu.toLowerCase())
                                  ? ""
                                  : "fixed_active"
                              }`}
                              onClick={() => activeRoute(sub.menu)}
                            >
                              <span className="sidebar__link-text text-uppercase">
                                {sub.menu}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
