import React, { useEffect, useState } from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import moment from "moment-timezone";
import axios from "axios";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import backgroundFitnessImage from "../../../src/img/content/bg/fittness-bg.png";
import "react-datepicker/dist/react-datepicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { TimePicker } from "antd";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-calendar/dist/Calendar.css";
import Modal from "../Layout/Modal";
import CreateProduct from "./CreateProduct";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";
import BankDetails from "./BankDetails";
import { format, startOfWeek, endOfMonth } from "date-fns";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Calender: React.FC = () => {
  const [weekCount, setWeekCount] = useState<any>(0);
  const [selectedWeekdays, setSelectedWeekdays] = useState<Set<string>>(
    new Set()
  );
  const [timeSlotsArray, setTimeSlotsArray] = useState<any>([]);
  const [firstObjectValueArray, setFirstObjectValueArray] = useState<any>([]);
  const [startTime2, setStartTime] = useState<any>("");
  const [endTime2, setEndTime] = useState<any>(null);
  const [showStartTimePicker, setShowStartTimePicker] = useState<any>(true);
  const [showEndTimePicker, setShowEndTimePicker] = useState<any>(true);
  const [toggleState, setToggleState] = useState<any>(false);
  const [allDaysTimeSlots, setAllDaySlotsArray] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [updateLoader, setUpdateLoader] = useState<any>(false);
  const [slots, setSlots] = useState<any>("");
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [dayName, setDayName] = useState<any>("");
  const [selectedSlot, setSelectedSlot] = useState<any>("");
  const [differenceInDays, setDifferenceInDays] = useState<any>(0);
  const [dateType, setDateType] = useState<any>("");
  const [updateSlots, setUpdateSlots] = useState<any>([]);
  const [open, setIsOpen] = useState<any>(false);
  const [durationModal, setDurationModal] = useState<any>(false);
  const [showFromTime, setShowFromTime] = useState<any>(false);
  const [showToTime, setShowToTime] = useState<any>(false);
  const [addAvailability, setAddAvailability] = useState<any>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<"AM" | "PM">("AM");
  const [duration, setDuration] = useState(30); // Toggle between 30 mins and 1 hour
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [disabledSlots, setDisabledSlots] = useState<string[]>([]);
  const [counter, setCounter] = useState<any>(0);
  const [calendarType, setCalendarType] = useState<any>("");
  const hash = window.location.hash;
  const authContext: any = useToken();
  const [selectedTimezone, setSelectedTimeZone] = useState<any>("");
  const [timeZones, setTimeZones] = useState<any>([]);
  const today = new Date();
  const [startDate, setStartDate] = useState<any>(startOfWeek(today));
  const [endDate, setEndDate] = useState<any>(endOfMonth(today));
  const [createCalBtn, setShowCreateCalBtn] = useState<any>(null);
  const [createAndupdateCalloader, setCreateAndUpdateLoader] =
    useState<any>(false);
  const [groupedSlots, setGroupedSlots] = useState<any>([]);
  const [dAvail, setDAvail] = useState<any>([]);
  const [wkDays, setWkDays] = useState<any>([]);
  const [alertState, setAlertState] = useState<any>(false);
  const [daysAlertState, setDaysAlertState] = useState<any>(false);
  const [selectedMonth, setSelectedMonth] = useState<any>(
    format(today, "MMMM")
  );
  const [fromTime, setFromTime] = useState<any>(dayjs("12:00 AM", "hh:mm A"));
  const [toTime, setToTime] = useState(dayjs("01:00 AM", "hh:mm A"));
  const [payloadSlots, setPayloadSlots] = useState<any>([]);
  const [rangeSelected, setRangeSelected] = useState<any>(false);
  const [dateRange, setSelectedRange] = useState<any>("");
  let paramType: any = "";

  console.log(groupedSlots);
  interface Slot {
    uuid: string;
    calendarDate: string;
    doctorId: number;
    StartTime: string;
    EndTime: string;
    Duration: string;
    calendarDay: string;
    isBooked: number;
    dname: string;
  }

  interface GroupedSlots {
    [day: string]: Slot[];
  }
  const queryString = hash.split("?")[1];

  if (queryString) {
    const params = new URLSearchParams(queryString);
    paramType = params.get("type");
  } else {
    console.log("No query string found in the hash.");
  }

  useEffect(() => {
    checkCalendarStatus();
  }, []);

  useEffect(() => {
    if (selectedDate) {
      setDifferenceInDays(1);
    }
  }, [selectedDate, dateType]);

  const passSlots = (selectedSlot: any) => {
    setSelectedSlot(selectedSlot);
  };

  const checkCalendarStatus = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          action: "command",
          command: [
            {
              agent: "checkCalendar",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.data === 1) {
          setShowCreateCalBtn(1);
        } else {
          setShowCreateCalBtn(0);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const userInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const removeSlot = (id: number) => {
    setDAvail((prevState: any) =>
      prevState.filter((slot: any) => slot.id !== id)
    );

    setGroupedSlots((prevState: any) => {
      const newGroupedSlots = { ...prevState };
      Object.keys(newGroupedSlots).forEach((day) => {
        newGroupedSlots[day] = newGroupedSlots[day].filter(
          (slot: any) => slot.id !== id
        );
      });
      return newGroupedSlots;
    });
  };

  const addNewSlot = () => {
    if (!dayName) {
      return toast.info("PLEASE SELECT DAY FIRST", {
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="60"
            height="60"
            viewBox="0 0 30 30"
          >
            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
          </svg>
        ),
        className: "custom-toast text-uppercase",
        bodyClassName: "custom-toast",
      });
    }
    const firstKeyValue = groupedSlots[dayName];
    const lastSlot = firstKeyValue[firstKeyValue.length - 1];
    if (
      (firstKeyValue.length !== 0 && lastSlot?.startTime === null) ||
      lastSlot?.endTime === null
    ) {
      return false;
    }
    setDaysAlertState(false);
    if (wkDays.length === 0) {
      return setAlertState(true);
    }
    setStartTime("");
    setEndTime("");

    const newSlot = {
      id: counter,
      startTime: null,
      endTime: null,
      isEditingStart: true,
      isEditingEnd: true,
    };

    setAddAvailability((prevState: any) => [...prevState, newSlot]);

    setCounter((prev: any) => prev + 1);

    setGroupedSlots((prevSlots: any) => {
      const updatedSlots = prevSlots[dayName]
        ? [...prevSlots[dayName], newSlot]
        : [newSlot];

      return {
        ...prevSlots,
        [dayName]: updatedSlots,
      };
    });
  };

  // useEffect(() => {
  //   if (startTime2 !== "" && endTime2 !== "") {
  //     wkDays.forEach((elem: any) => {
  //       setDAvail((prevState: any) => [
  //         ...prevState.filter((elem: any)=> ),
  //         {
  //           id: Math.random(),
  //           day: elem,
  //           st: dayjs(startTime2).format("hh:mm A"),
  //           end: dayjs(endTime2).format("hh:mm A"),
  //         },
  //       ]);
  //     });
  //   }
  // }, [startTime2, endTime2]);
  useEffect(() => {
    if (startTime2 !== "" && endTime2 !== "") {
      wkDays.forEach((dayName: any) => {
        setDAvail((prevState: any) => {
          const newStart = dayjs(startTime2, "hh:mm A");
          const newEnd = dayjs(endTime2, "hh:mm A");

          // 🔍 Check if the new time slot overlaps with existing slots on the same day
          const isOverlapping = prevState.some((slot: any) => {
            if (slot.day !== dayName) return false; // Ignore different days

            const existingStart = dayjs(slot.st, "hh:mm A");
            const existingEnd = dayjs(slot.end, "hh:mm A");

            return (
              (newStart.isAfter(existingStart) &&
                newStart.isBefore(existingEnd)) ||
              (newEnd.isAfter(existingStart) && newEnd.isBefore(existingEnd)) ||
              (newStart.isBefore(existingStart) &&
                newEnd.isAfter(existingEnd)) || // New slot fully contains existing slot
              (newStart.isSame(existingStart) && newEnd.isSame(existingEnd)) || // Exact match case
              (newStart.isBefore(existingStart) &&
                newEnd.isAfter(existingStart) &&
                newEnd.isAfter(existingEnd)) // Explicitly added condition
            );
          });

          const overLapping2 = prevState.some((slot: any) => {
            if (slot.day !== dayName) return false; // Ignore different days

            const existingStart = dayjs(slot.st, "hh:mm A");
            const existingEnd = dayjs(slot.end, "hh:mm A");

            return (
              newStart.isBefore(existingStart) &&
              newEnd.isAfter(existingStart) &&
              newEnd.isAfter(existingEnd)
            );
          });
          if (isOverlapping || overLapping2) {
            toast.error(
              `TIME SLOT CLASHES WITH AN EXISTING SLOT ON ${dayName.toUpperCase()}!`
            );
            return prevState;
          }

          return [
            ...prevState,
            {
              id: Math.random(),
              day: dayName,
              st: newStart.format("hh:mm A"),
              end: newEnd.format("hh:mm A"),
            },
          ];
        });
      });
    }
  }, [startTime2, endTime2]);

  const handleArrayFromTimeChange = (id: number, time: any) => {
    setStartTime(time);

    setGroupedSlots((prevSlots: any) => {
      const daySlots = prevSlots[dayName] || [];

      const currentSlot = daySlots.find((slot: any) => slot.id === id);
      if (!currentSlot) return prevSlots;

      const newStartTime = dayjs(time, "hh:mm A");

      const isDuplicateStartTime = daySlots.some(
        (slot: any) =>
          slot.id !== id &&
          dayjs(slot.startTime, "hh:mm A").isSame(newStartTime)
      );

      if (isDuplicateStartTime) {
        toast.error("SELECTED START TIME IS SAME AS A PREVIOUSLY ADDED TIME!");
        return prevSlots;
      }

      const isOverlapping = daySlots.some(
        (slot: any) =>
          slot.id !== id &&
          newStartTime.isAfter(dayjs(slot.startTime, "hh:mm A")) &&
          newStartTime.isBefore(dayjs(slot.endTime, "hh:mm A"))
      );

      if (isOverlapping) {
        toast.error("SELECTED START TIME OVERLAPS WITH ANOTHER SLOT!");
        return prevSlots;
      }

      const updatedSlots: any = prevSlots[dayName]?.map((slot: any) =>
        slot.id === id
          ? {
              ...slot,
              startTime: newStartTime.format("hh:mm A"),
              endTime: "",
              isEditingStart: false,
            }
          : slot
      );

      return {
        ...prevSlots,
        [dayName]: updatedSlots || prevSlots[dayName] || [],
      };
    });
  };

  const handleArrayToTimeChange = (id: number, time: any) => {
    setEndTime(time);

    setGroupedSlots((prevSlots: any) => {
      const daySlots = prevSlots[dayName] || [];
      const currentSlot = daySlots.find((slot: any) => slot.id === id);
      if (!currentSlot) return prevSlots;

      const newEndTime = dayjs(time, "hh:mm A");

      const isDuplicateEndTime = daySlots.some(
        (slot: any) =>
          slot.id !== id && dayjs(slot.endTime, "hh:mm A").isSame(newEndTime)
      );

      if (isDuplicateEndTime) {
        toast.error("SELECTED END TIME IS SAME AS A PREVIOUSLY ADDED TIME!");

        const updatedSlots = daySlots.map((slot: any) =>
          slot.id === id ? { ...slot, isEditingEnd: false } : slot
        );

        return {
          ...prevSlots,
          [dayName]: [...updatedSlots],
        };
      }

      const isOverlapping = daySlots.some(
        (slot: any) =>
          (slot.id !== id &&
            newEndTime.isAfter(dayjs(slot.startTime, "hh:mm A")) &&
            newEndTime.isBefore(dayjs(slot.endTime, "hh:mm A"))) ||
          (newEndTime.isAfter(dayjs(slot.startTime, "hh:mm A")) &&
            newEndTime.isAfter(dayjs(slot.endTime, "hh:mm A")))
      );

      if (isOverlapping) {
        const updatedSlots = daySlots.map((slot: any) =>
          slot.id === id
            ? {
                ...slot,
                endTime: "",
                startTime: slot.startTime,
                isEditingEnd: true,
                isEditingStart: false,
              }
            : slot
        );

        return {
          ...prevSlots,
          [dayName]: [...updatedSlots],
        };
      }

      const updatedSlots = daySlots.map((slot: any) =>
        slot.id === id
          ? {
              ...slot,
              endTime: newEndTime.format("hh:mm A"),
              isEditingEnd: false,
            }
          : slot
      );

      return {
        ...prevSlots,
        [dayName]: [...updatedSlots],
      };
    });
  };

  useEffect(() => {
    dAvail.forEach((elem: any) => {
      if (elem.day !== dayName) {
        const filterDays = [...new Set(selectedWeekdays)];
        setWkDays((prevState: any) => [...filterDays]);
      }
    });

    const someDaysExist = dAvail.some((elem: any) => elem?.day === dayName);
    if (someDaysExist) {
      const filterDays = [...new Set(selectedWeekdays)];
      console.log(filterDays);
      setWkDays((prevState: any) => [...filterDays]);
    }
  }, [dayName, selectedWeekdays]);

  useEffect(() => {
    const groupedSlots = dAvail.reduce((acc: any, curr: any) => {
      if (!acc[curr.day]) {
        acc[curr.day] = [];
      }

      acc[curr.day].push({
        id: curr.id,
        startTime: curr.st,
        endTime: curr.end,
        isEditingStart: false,
        isEditingEnd: false,
      });

      return acc;
    }, {});

    setGroupedSlots(groupedSlots);
    setSelectedWeekdays(new Set());
    setWeekCount(0);
  }, [dAvail]);

  const toggleWeekday = (day: string) => {
    setAlertState(false);
    setDayName(day);

    const updatedSelectedWeekdays: any = new Set(selectedWeekdays);

    if (updatedSelectedWeekdays.has(day)) {
      updatedSelectedWeekdays.delete(day);
      setWkDays((prevDay: any) => prevDay.filter((elem: any) => elem !== day));
    } else {
      updatedSelectedWeekdays.add(day);
      setWkDays((prevDay: any) => [...prevDay, day]);
    }
    setSelectedWeekdays(updatedSelectedWeekdays);
    setWeekCount(updatedSelectedWeekdays.size);

    setGroupedSlots((prevSlots: any) => ({
      ...prevSlots,
      [day]: prevSlots[day] || [],
    }));

    setAddAvailability([]);
    setStartTime("");
    setEndTime("");
  };

  const AvailabilitySlots = () => {
    const slotsToRender =
      slotWise && typeof slotWise === "object"
        ? Object.entries(slotWise).map(([day, schedule]: [any, any]) => {
            return (
              <div key={day}>
                <h3 className="mt-3">{day.toUpperCase()}</h3>
                {schedule.map((slot: any) => (
                  <div
                    className="availability-appointment d-flex mt-2"
                    key={slot.id}
                  >
                    {/* Display the Start Time */}
                    <div className="availability-appointment-box d-flex">
                      <span>FROM</span>
                      <span className="ml-2">
                        {slot.startTime || "Select Start Time"}
                      </span>
                    </div>

                    {/* Display the End Time */}
                    <div className="availability-appointment-box d-flex">
                      <span className="pe-2 m-2">TO</span>
                      <span className="selected-time">
                        {slot.endTime || "Select End Time"}
                      </span>
                    </div>

                    {/* Remove Slot Button */}
                  </div>
                ))}
              </div>
            );
          })
        : [];

    return <div>{slotsToRender}</div>;
  };

  const getSpecialistBookingSlots = async (
    newStartDate: any,
    newEndDate: any
  ) => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "calendarDate",
                operator: "between",
                table: "BookingCalendar",
                value: moment(newStartDate ? newStartDate : startDate).format(
                  "YYYY-MM-DD"
                ),
                opr: "AND",
                value2: moment(newEndDate ? newEndDate : endDate).format(
                  "YYYY-MM-DD"
                ),
              },
              {
                field: "doctorId",
                operator: "eq",
                table: "BookingCalendar",
                value: userInfo.id,
              },
              {
                field: "active",
                operator: "eq",
                table: "BookingCalendar",
                value: 1,
              },
            ],
            sort: [
              {
                field: "calendarDate",
                table: "BookingCalendar",
                dir: "asc",
              },
            ],
          },
        },
        action: "command",
        command: [
          {
            agent: "specialistCalendarBooking",
            appName: "selfMaximized",
            folder: "order",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const responseData: Slot[] = result?.data?.data?.response || [];

          const getSlotDateTime = (startTime: string): moment.Moment => {
            return moment
              .tz(
                startTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
                "YYYY-MM-DDTHH:mm:ssZ", // Parse format
                "UTC"
              )
              .tz(selectedTimezone.value);
          };

          const filteredSlots = responseData.filter((slot) => {
            const slotDateTime = getSlotDateTime(slot.StartTime);

            const now = moment.tz(selectedTimezone.value);

            return slot.isBooked === 0 && slotDateTime.isAfter(now);
          });

          const groupedSlotss: GroupedSlots = filteredSlots.reduce(
            (acc: GroupedSlots, slot: Slot) => {
              const slotDate = getSlotDateTime(slot.StartTime).format(
                "YYYY-MM-DD"
              );
              acc[slotDate] = [...(acc[slotDate] || []), slot];
              return acc;
            },
            {}
          );
          const filteredSlotsArray = Object.entries(groupedSlotss)
            .map(([day, slots]: [string, Slot[]]) => ({
              dayOfWeek: moment(day, "YYYY-MM-DD").format("dddd"), // Convert to day of the week
              slots,
            }))
            .filter((daySlots) => daySlots.slots.length > 0); // Only include days with available slots

          setSlots(filteredSlotsArray);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const weekdays: any = [
    { name: "Mon", abbreviation: "Monday" },
    { name: "Tue", abbreviation: "Tuesday" },
    { name: "Wed", abbreviation: "Wednesday" },
    { name: "Thu", abbreviation: "Thursday" },
    { name: "Fri", abbreviation: "Friday" },
    { name: "Sat", abbreviation: "Saturday" },
    { name: "Sun", abbreviation: "Sunday" },
  ];

  let dayWiseTimeSlotsArray: any = [];

  if (timeSlotsArray) {
    dayWiseTimeSlotsArray.push(timeSlotsArray);
  }

  // Initialize timeSlotsArray with empty arrays for all days of the week
  const weekdays2 = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  weekdays2.forEach((day: any) => {
    // Check if the day exists in the current timeSlotsArray
    if (Object.prototype.hasOwnProperty.call(allDaysTimeSlots, day)) {
      // If the day exists, leave it as is
    } else {
      // If the day doesn't exist, set its value to an empty array
      allDaysTimeSlots[day] = [];
    }
  });

  useEffect(() => {
    if (allDaysTimeSlots) {
      const mappingObjects = allDaysTimeSlots;
      const slotsArray = Object.keys(mappingObjects);
      const firstObjectValueArray = mappingObjects[slotsArray[0]];

      setFirstObjectValueArray(firstObjectValueArray);
    }
  }, [allDaysTimeSlots]);

  const createAndUpdateCalendar = async (calendarType: any) => {
    const slotsArray = Object.entries(payloadSlots);
    const firstKey = Object.keys(groupedSlots)[0]; // Get the first key
    const firstKeyValue = groupedSlots[dayName];
    setCalendarType(calendarType);
    if (slotsArray.length <= 0) {
      return setAlertState(true);
    } else if (
      firstKey &&
      Array.isArray(groupedSlots[firstKey]) &&
      groupedSlots[firstKey].length === 0
    ) {
      return setDaysAlertState(true);
    } else if (
      firstKeyValue &&
      firstKeyValue[0]?.startTime === null &&
      firstKeyValue[0]?.endTime === null
    ) {
      return false;
    } else {
      setAlertState(false);
    }
    setCreateAndUpdateLoader(true);

    const updateCal: any = [
      {
        agent: "updateCalendar",
        appName: "selfMaximized",
        folder: "specialist",
      },
      {
        agent: "initBookingSloat",
        appName: "selfMaximized",
        folder: "specialist",
      },
    ];

    const createCal: any = [
      {
        agent: "createCalendar",
        appName: "selfMaximized",
        folder: "specialist",
      },
      {
        agent: "initBookingSloat",
        appName: "selfMaximized",
        folder: "specialist",
      },
    ];
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          scheduel: payloadSlots,
          repeatUptoWeek: 3,
          timezone: selectedTimezone.value,
          menuId: "cce692df-7d87-48b9-8fcf-974ef927bf44",
          action: "command",
          command: calendarType === "update" ? updateCal : createCal,
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setCreateAndUpdateLoader(false);
        if (result?.data?.status === "failed") {
          return toast.info(
            "CALENDAR IS ALREADY CREATED. IF YOU WANT TO UPDATE CALENDAR CLICK ON UPDATE CALENDAR BUTTON"
          );
        } else {
          setAllDaySlotsArray([]);
          toast.success(
            `SLOTS ${
              calendarType === "update" ? "UPDATED" : "CREATED"
            } SUCCESSFULLY!`
          );
          const priceSetUpTab = document.getElementById("profile-tab");
          if (priceSetUpTab && calendarType !== "update") {
            priceSetUpTab.click();
          }
          checkCalendarStatus();
          setGroupedSlots([]);
          setDAvail([]);
          setWeekCount(0);
          setWkDays([]);
          setPayloadSlots([]);
          setAllDaySlotsArray([]);
          setSelectedWeekdays(new Set());
        }
      })
      .catch((err) => {
        setCreateAndUpdateLoader(false);
      });
  };

  async function defaultCalendarSlot() {
    await getSpecialistBookingSlots(startDate, endDate);
  }

  const toggleCalendar = () => {
    defaultCalendarSlot();
    setToggleState(!toggleState);
    setWeekCount(0);
    setDifferenceInDays(0);
    if (!dateType) {
      setDateType("");
      setSelectedDate(null);
      setSlots([]);
    }
  };

  const fitnessbackgroundimage = {
    backgroundImage: `url(${backgroundFitnessImage})`,
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  useEffect(() => {
    setDifferenceInDays(0);
    setWeekCount(0);
    setSlots([]);
    setUpdateSlots([]);
  }, [dateType]);

  const updateOrDeleteSlots = async () => {
    let currentDate = new Date(selectedDate);

    let calendarDate = currentDate.toISOString().slice(0, 10);

    setUpdateLoader(true);

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          times: updateSlots,
          start: calendarDate,
          end: calendarDate,
          menuId: "cce692df-7d87-48b9-8fcf-974ef927bf44",
          action: "command",
          command: [
            {
              agent: "prepareCalendar",
              appName: "selfMaximized",
              folder: "specialist",
            },
            {
              agent: "addCalendar",
              appName: "selfMaximized",
              folder: "specialist",
            },
            {
              agent: "addNewBookingSloat",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then(async (result) => {
        if (result?.data?.status === "success") {
          toast.success(result?.data?.message);
          setUpdateLoader(false);
          setUpdateSlots([]);
        }
      })
      .catch((err) => {
        setUpdateLoader(false);
      });
  };

  type Slots = {
    start: string;
    end: string;
    duration: number;
  };

  type GroupedSlot = {
    [key: string]: { startTime: string; endTime: string }[];
  };

  async function generateTimeSlots(
    groupedSlots: GroupedSlot,
    selectedTz: string
  ): Promise<{ [key: string]: Slots[] }> {
    const response: { [key: string]: Slots[] } = {};

    Object.keys(groupedSlots).forEach((weekday) => {
      const slots = groupedSlots[weekday];
      response[weekday] = [];

      slots.forEach((slot) => {
        const localStartTime = moment.tz(slot.startTime, "hh:mm A", selectedTz);
        const localEndTime = moment.tz(slot.endTime, "hh:mm A", selectedTz);

        let current = localStartTime.clone();

        // Generate 30-minute slots
        while (current.isBefore(localEndTime)) {
          let endSlot = current.clone().add(30, "minutes");
          if (endSlot.isAfter(localEndTime)) break; // Avoid overflow

          response[weekday].push({
            start: current.format("hh:mm A"),
            end: endSlot.format("hh:mm A"),
            duration: 0.5,
          });
          current.add(30, "minutes");
        }

        // Reset current to start time for 1-hour slots
        current = localStartTime.clone();

        // Generate 1-hour slots
        while (current.isBefore(localEndTime)) {
          let endSlot = current.clone().add(60, "minutes");
          if (endSlot.isAfter(localEndTime)) break; // Avoid overflow

          response[weekday].push({
            start: current.format("hh:mm A"),
            end: endSlot.format("hh:mm A"),
            duration: 1,
          });
          current.add(60, "minutes");
        }
      });
    });

    return response;
  }

  useEffect(() => {
    const result = generateTimeSlots(groupedSlots, selectedTimezone.value);
    result.then((res) => setPayloadSlots(res));
  }, [groupedSlots]);

  const handleClear = () => {
    setSelectedSlots([]);
    setDisabledSlots([]);
  };

  const handleSave = () => {
    if (weekCount || differenceInDays) {
      toast.success("Slots are added successfully!");
      setDateType("");
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (paramType === "price_set_up") {
      const priceSetUpTab = document.getElementById("profile-tab");
      if (priceSetUpTab) {
        priceSetUpTab.click();
      }
    }
  }, [paramType]);

  useEffect(() => {
    getGlobalTimeZones();
  }, []);

  const handleRangeSelection = async (selectedRange: any) => {
    if (selectedRange) {
      setRangeSelected(true);
      setSelectedRange(selectedRange);
    }
    if (Array.isArray(selectedRange) && selectedRange.length === 2) {
      const [startDate, endDate] = selectedRange;
      setStartDate(startDate);
      setEndDate(endDate);
      await getSpecialistBookingSlots(startDate, endDate);
    } else {
      console.error("Invalid date range selected");
    }
  };

  const handleMonthChange = ({ activeStartDate }: any) => {
    const monthName = format(activeStartDate, "MMMM"); // Get the month name
    setSelectedMonth(monthName);
  };

  const getGlobalTimeZones = async () => {
    const timezones: any = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        action: "command",
        command: [
          {
            agent: "timezoneList",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      }
    );
    const response: any = await timezones?.data?.data;
    setTimeZones(response);
  };

  const mappedOptions =
    timeZones &&
    timeZones
      .sort((a: any, b: any) => a?.timezone.localeCompare(b?.timezone))
      .map((option: any) => ({
        value: option?.timezone,
        label: option?.timezone,
      }));

  useEffect(() => {
    async function getIp() {
      let currentTz: any = "";

      const getTimeZoneFromStorage = localStorage.getItem(
        "specialistSelectedTimezone"
      );
      if (!getTimeZoneFromStorage) {
        const response = await axios.get("https://ipapi.co/json/");
        if (response?.data && response?.data?.timezone) {
          currentTz = {
            value: response?.data?.timezone,
            label: response?.data?.timezone,
          };
        }
        setSelectedTimeZone(currentTz);
        localStorage.setItem(
          "specialistSelectedTimezone",
          JSON.stringify(currentTz)
        );
        localStorage.removeItem("userSelectedTimezone");
      } else {
        setSelectedTimeZone(
          getTimeZoneFromStorage && JSON.parse(getTimeZoneFromStorage)
        );
      }
    }
    getIp();
  }, []);

  const handleTimeZone = (selectedOption: any) => {
    setSelectedTimeZone(selectedOption);
    localStorage.setItem(
      "specialistSelectedTimezone",
      JSON.stringify(selectedOption)
    );
    localStorage.removeItem("userSelectedTimezone");
  };

  const slotWise = dAvail.reduce((acc: any, curr: any) => {
    const { day, st, end, id } = curr;

    // If the day does not exist in the accumulator, create it
    if (!acc[day]) {
      acc[day] = [];
    }

    // Push the formatted slot object into the appropriate day
    acc[day].push({
      id,
      startTime: st,
      endTime: end,
      isEditingStart: false,
      isEditingEnd: false,
    });

    return acc;
  }, {});

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .ant-picker-time-panel {
              background-color: #fff;
            }
              .ant-picker-ranges{
              background: #fff;
               color: #000;
              }
             
    .ant-picker-dropdown {
    background-color: #ffffff !important;
    border: 1px solid #fff !important;
    border-radius: 8px;
}
          `,
        }}
      />
      {durationModal && (
        <Modal isOpen={open}>
          <div tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered user__calander__booking--list modal-lg">
              <div className="modal-content modal-content-transparent buy__product__modal py-3 rounded-0">
                <h5 className="text-white">
                  PLEASE SELECT DURATION OF APPOINTMENT FOR WHICH YOU WANT TO
                  SET YOUR CALENDAR
                </h5>
                <div className="modal-header p-0">
                  <div
                    className="btn-group btn-group-toggle custom-button-gp"
                    data-toggle="buttons"
                  >
                    <label
                      className={`btn btn-secondary ${
                        duration === 30 ? "active" : ""
                      }`}
                      onClick={() => setDuration(30)}
                    >
                      30 mins
                    </label>
                    <label
                      className={`btn btn-secondary ${
                        duration === 60 ? "active" : ""
                      }`}
                      onClick={() => setDuration(60)}
                    >
                      1 hour
                    </label>
                  </div>
                </div>
                <div className="modal-body modal-body-black p-3 p-lg-5">
                  <div className="container p-0">
                    <div className="row">
                      <div className="col-md-12 text-center"></div>
                    </div>

                    <div className="row mt-3">
                      <button
                        type="button"
                        className="btn btn-light btn-close btn-sm bg-transparent custom-close-btn"
                        data-bs-dismiss="modal"
                        onClick={() => setIsOpen(false)}
                      >
                        X
                      </button>
                      <div className="col-md-12 text-left">
                        <div
                          className="btn-group btn-group-toggle"
                          data-toggle="buttons"
                        >
                          <label
                            className={`btn btn-light btn-light-new rounded-0"  ${
                              selectedPeriod === "AM" ? "active" : ""
                            }`}
                            onClick={() => setSelectedPeriod("AM")}
                          >
                            AM
                          </label>
                          <label
                            className={`btn btn-light rounded-0" btn-light-two ${
                              selectedPeriod === "PM" ? "active" : ""
                            }`}
                            onClick={() => setSelectedPeriod("PM")}
                          >
                            PM
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className="col-md-12 text-right">
                        <button
                          className="btn btn-outline-light mr-2 clear-btn"
                          onClick={handleClear}
                        >
                          CLEAR
                        </button>
                        <button
                          className="btn btn-outline-light"
                          onClick={handleSave}
                        >
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}

      <div className="fittness-wrapper overflow-auto">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="selfmaximized-logo-wrapper py-2">
                <p style={{ width: "auto", height: "auto" }}></p>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div
            className="px-0 px-lg-5 py-0 py-lg-5 tab-bg-image"
            style={fitnessbackgroundimage}
          >
            <div className="container-fluid fitness__inner__wrapper fitness__inner__wrapper_new calender-and-pricing-popup">
              <div className="row">
                <div className="col-lg-12">
                  <div className="d-flex ">
                    <Link to="/dashboard">
                      <button className="btn btn-light">BACK</button>
                    </Link>
                  </div>

                  <p className="mb-0 py-3 text-uppercase text-center">
                    TO GET STARTED, PLEASE SET UP YOUR CALENDER & PRICING
                  </p>
                  <ul
                    className="nav nav-tabs custom-common-tabs border-0 justify-content-center pb-4"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link nav-link-custom active`}
                        id="home-tab"
                        data-bs-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        {/* Calendar */}1
                      </a>
                      <span className="text-uppercase text-ceter">
                        Calender
                      </span>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link nav-link-custom`}
                        id="profile-tab"
                        data-bs-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="true"
                      >
                        {/* Calendar */}2
                      </a>
                      <span className="text-uppercase text-ceter">
                        PRICE SET UP
                      </span>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className={`nav-link`}
                        id="bank-tab"
                        data-bs-toggle="tab"
                        href="#bank"
                        role="tab"
                        aria-controls="bank"
                        aria-selected="false"
                      >
                        {/* Price set up */}3
                      </a>
                      <span className="text-uppercase text-ceter">
                        ADD BANK DETAILS
                      </span>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row">
                        {toggleState ? (
                          <div className="col-lg-12">
                            <div className="fittness-right-box p-3">
                              <div className="row pt-2">
                                <div className="col-lg-12">
                                  <div className="fittness-right-box">
                                    <div className="row pt-2">
                                      <div className="col-lg-12 px-3">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          className="mr-3 mb-2 cursor_pointer"
                                          onClick={() =>
                                            setToggleState(!toggleState)
                                          }
                                        >
                                          <path d="M19 12H5m7-7l-7 7 7 7"></path>
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-3">
                                  <div className="row border-top m-0 ">
                                    <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
                                      <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                                        Please select your time slot before we
                                        proceed
                                      </p>
                                      <div>
                                        <div className="react-calendar-outer">
                                          <Calendar
                                            selectRange={true}
                                            onChange={handleRangeSelection}
                                            value={[startDate, endDate]}
                                            onActiveStartDateChange={
                                              handleMonthChange
                                            } // Handle month change
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
                                      <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
                                        Available time slots for the month of{" "}
                                        {selectedMonth}
                                      </p>
                                      <div className="book-overflow">
                                        <div className="slot-outer text-uppercase text-white">
                                          <div className="slot-inner row g-3">
                                            {slots.map(
                                              (slot: any, index: any) => (
                                                <div
                                                  key={index}
                                                  className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3 gap-14"
                                                >
                                                  <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-1">
                                                    {slot.dayOfWeek}
                                                    {/* {
                                                      moment()
                                                        .tz("UTC") // Start with UTC time
                                                        .day(1) // Set it to Monday
                                                        .tz(
                                                          selectedTimezone.value
                                                        ) // Convert to selected timezone
                                                        .format("dddd") // Get the full day name
                                                    } */}
                                                  </h3>
                                                  {slot?.slots
                                                    .sort((a: any, b: any) => {
                                                      const timeA = moment(
                                                        a.StartTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ), // Fix extra milliseconds
                                                        "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                                      ).tz(
                                                        selectedTimezone.value
                                                      );

                                                      const timeB = moment(
                                                        b.StartTime.replace(
                                                          /:000Z$/,
                                                          ":00Z"
                                                        ), // Fix extra milliseconds
                                                        "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
                                                      ).tz(
                                                        selectedTimezone.value
                                                      );

                                                      // Prioritize AM before PM
                                                      if (
                                                        timeA.format("A") !==
                                                        timeB.format("A")
                                                      ) {
                                                        return timeA.format(
                                                          "A"
                                                        ) === "AM"
                                                          ? -1
                                                          : 1;
                                                      }

                                                      // Sort by time within the same period
                                                      return timeA.diff(timeB);
                                                    })
                                                    .map((elem: any) => (
                                                      <div
                                                        className="slot-box slot-box-new col-md-6 cursor_pointer p-0"
                                                        key={elem.uuid}
                                                        onClick={() =>
                                                          passSlots(elem)
                                                        }
                                                      >
                                                        <div
                                                          className="slot-box-inner border pt-2 pb-2 pl-3 pr-3"
                                                          style={{
                                                            backgroundColor:
                                                              selectedSlot ===
                                                              elem
                                                                ? "white"
                                                                : "",
                                                            color:
                                                              selectedSlot ===
                                                              elem
                                                                ? "black"
                                                                : "",
                                                          }}
                                                        >
                                                          <h5 className="mb-0 explore-heading-two pb-0">
                                                            AVAILABILITY |{" "}
                                                            {elem?.Duration} HR
                                                          </h5>
                                                          <p
                                                            className="mb-0 explore-heading-two pb-0"
                                                            style={{
                                                              color:
                                                                selectedSlot ===
                                                                elem
                                                                  ? "black"
                                                                  : "white",
                                                            }}
                                                          >
                                                            {selectedTimezone &&
                                                            elem?.StartTime
                                                              ? moment
                                                                  .tz(
                                                                    elem.StartTime.replace(
                                                                      /:000Z$/,
                                                                      ":00Z"
                                                                    ),

                                                                    selectedTimezone.value
                                                                  )
                                                                  .format(
                                                                    "hh:mm A"
                                                                  )
                                                              : "Date Not Available"}
                                                            <span className="to">
                                                              {" "}
                                                              TO{" "}
                                                            </span>
                                                            {selectedTimezone &&
                                                            elem?.EndTime
                                                              ? moment
                                                                  .tz(
                                                                    elem.EndTime.replace(
                                                                      /:000Z$/,
                                                                      ":00Z"
                                                                    ),

                                                                    selectedTimezone.value
                                                                  )
                                                                  .format(
                                                                    "hh:mm A"
                                                                  )
                                                              : "Date Not Available"}
                                                          </p>
                                                          <p
                                                            className="mb-0 explore-heading-two pb-0"
                                                            style={{
                                                              color:
                                                                selectedSlot ===
                                                                elem
                                                                  ? "black"
                                                                  : "white",
                                                            }}
                                                          >
                                                            {selectedTimezone &&
                                                            elem?.StartTime
                                                              ? moment
                                                                  .tz(
                                                                    elem.StartTime.replace(
                                                                      /:000Z$/,
                                                                      ":00Z"
                                                                    ),
                                                                    "UTC"
                                                                  )
                                                                  .tz(
                                                                    selectedTimezone.value
                                                                  )
                                                                  .format(
                                                                    "YYYY-MM-DD"
                                                                  )
                                                              : "Date Not Available"}
                                                            <span className="day-name-right floar-right">
                                                              {selectedTimezone &&
                                                              elem?.StartTime
                                                                ? moment
                                                                    .tz(
                                                                      elem.StartTime.replace(
                                                                        /:000Z$/,
                                                                        ":00Z"
                                                                      ),
                                                                      "UTC"
                                                                    )
                                                                    .tz(
                                                                      selectedTimezone.value
                                                                    )
                                                                    .format(
                                                                      "dddd"
                                                                    )
                                                                : "Date Not Available"}
                                                            </span>
                                                          </p>
                                                        </div>
                                                      </div>
                                                    ))}
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>

                                        <hr className="custom-hr custom-hr-white text-white"></hr>
                                        {slots.length === 0 && (
                                          <h4 className="text-white text-center letter_spacing text-uppercase">
                                            No slots available for selected date{" "}
                                          </h4>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="col-lg-6">
                              <div className="fittness-left-box p-3 h-100">
                                <div className="please-enter-the-no-of-working-box desktop-none mobile-show p-2 mb-3">
                                  <div className="days-a-week d-flex justify-content-center align-items-center">
                                    <div className="day-with-text d-flex justify-content-center align-items-center px-4">
                                      <span className=" dates">
                                        {weekCount || differenceInDays}
                                      </span>
                                      <span className="days-a-week-text pl-3 font-weight-bold">
                                        {weekCount === 1 ||
                                        differenceInDays === 1
                                          ? "day"
                                          : "days"}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="text-uppercase mb-2">
                                  NOTE - Your calendar will be set indefinitely
                                  based on the weekdays you have selected. The
                                  availability slots will repeat every week on
                                  the same days, and no end date will be
                                  applied.
                                </div>
                                <div className="pl-0 ml-0">
                                  <label>YOUR TIMEZONE</label>
                                  <Select
                                    value={selectedTimezone}
                                    onChange={handleTimeZone}
                                    options={mappedOptions}
                                    isSearchable={true}
                                    name="areaOfExp"
                                    className="text-dark letter_spacing col-lg-4 mb-4 text-uppercase pl-0 ml-0"
                                    styles={{
                                      control: (provided: any, state: any) => ({
                                        ...provided,
                                        fontSize: "16px",
                                        color: "inherit",
                                        backgroundColor: "#fff",
                                        letterSpacing: "1px",
                                        border: state.isFocused
                                          ? "2px solid black"
                                          : "2px solid transparent", // Custom border style
                                        boxShadow: state.isFocused
                                          ? "none"
                                          : "none", // Remove box-shadow
                                        "&:hover": {
                                          borderColor: "black", // Border color on hover
                                        },
                                      }),
                                      option: (provided: any, state: any) => ({
                                        ...provided,
                                        color:
                                          state.isFocused || state.isSelected
                                            ? "white"
                                            : "black", // Change color to black when focused or selected
                                        backgroundColor: state.isFocused
                                          ? "#000"
                                          : "#fff", // Background color when focused
                                      }),
                                      singleValue: (provided: any) => ({
                                        ...provided,
                                        color: "#000", // Set color of the selected option to white
                                      }),
                                      input: (provided: any) => ({
                                        ...provided,
                                        color: "black",
                                      }),
                                      placeholder: (provided: any) => ({
                                        ...provided,
                                        color: "white",
                                      }),
                                      menu: (provided: any) => ({
                                        ...provided,
                                        backgroundColor: "#000",
                                      }),
                                    }}
                                    placeholder="SEARCH..."
                                  />
                                </div>
                                <p
                                  className={`${
                                    alertState === true
                                      ? "text-danger"
                                      : "text-white"
                                  } text-uppercase mb-1`}
                                >
                                  Please select days according to your
                                  availablity
                                </p>

                                <div className="row pb-2 pb-lg-0 mb-3 days-wrapper-left-outer mb-0">
                                  <div className="col-lg-12">
                                    <div className="days-wrapper-left-bx days-wrapper-left-new d-flex justify-content-around position-relative mb-0">
                                      {weekdays.map((day: any, index: any) => (
                                        <p
                                          key={index}
                                          className={`week-days font-weight-bold d-inline-block mb-0 font-14 ${
                                            selectedWeekdays.has(
                                              day?.abbreviation
                                            )
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            toggleWeekday(day?.abbreviation)
                                          }
                                        >
                                          {day?.name?.toUpperCase()}
                                        </p>
                                      ))}
                                      <div className="days-a-week d-flex justify-content-center align-items-center mobile-none-desktop-show">
                                        <div className="day-with-text d-flex justify-content-center align-items-center px-4">
                                          <span className=" dates">
                                            {weekCount || differenceInDays}
                                          </span>
                                          <span className="days-a-week-text pl-3 font-weight-bold">
                                            {weekCount === 1 ||
                                            differenceInDays === 1
                                              ? "day"
                                              : "days"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <label className="mt-2">
                                    {[...new Set(selectedWeekdays)].map(
                                      (elem: any) => {
                                        return (
                                          <span
                                            key={elem} // Add a key for each element in the list
                                            className="text-uppercase p-2 d-inline-block border bg-white text-dark mx-1 my-1"
                                            style={{
                                              wordWrap: "break-word", // Word wrap styling
                                            }}
                                          >
                                            {elem}
                                          </span>
                                        );
                                      }
                                    )}
                                  </label>
                                </div>

                                <>
                                  <label
                                    className={`text-uppercase mb-3 pt-4 pb-0 ${
                                      daysAlertState
                                        ? "text-danger"
                                        : "text-white"
                                    }`}
                                  >
                                    PLEASE SELECT AVAILABILITY FOR THE
                                    APPOINTMENT
                                  </label>

                                  <div className="availability-appointment-outer d-flex flex-column gap-2">
                                    {/* Check if groupedSlots and groupedSlots[dayName] exist before rendering */}
                                    {groupedSlots &&
                                    groupedSlots[dayName] &&
                                    groupedSlots[dayName].length > 0
                                      ? groupedSlots[dayName].map(
                                          (elem: any) => (
                                            <div
                                              className="availability-appointment d-flex"
                                              key={elem.id}
                                            >
                                              <div className="availability-appointment-box d-flex">
                                                <span>FROM</span>
                                                {elem.isEditingStart ? (
                                                  <span className="ml-2 cursor_pointer">
                                                    <TimePicker
                                                      value={
                                                        elem.startTime
                                                          ? dayjs(
                                                              elem.startTime,
                                                              "hh:mm A"
                                                            )
                                                          : null
                                                      }
                                                      format="hh:mm A"
                                                      use12Hours
                                                      style={{
                                                        color: "white",
                                                      }}
                                                      minuteStep={30}
                                                      onChange={(time) =>
                                                        handleArrayFromTimeChange(
                                                          elem.id,
                                                          time
                                                        )
                                                      }
                                                    />
                                                  </span>
                                                ) : (
                                                  <>
                                                    <span className="ml-2">
                                                      {elem.startTime
                                                        ? elem.startTime
                                                        : "Select Start Time"}
                                                    </span>
                                                  </>
                                                )}
                                              </div>

                                              <div className="availability-appointment-box d-flex">
                                                <span className="pe-2 m-2">
                                                  TO{" "}
                                                </span>
                                                {elem.isEditingEnd ? (
                                                  <TimePicker
                                                    value={
                                                      elem.endTime
                                                        ? dayjs(
                                                            elem.endTime,
                                                            "hh:mm A"
                                                          )
                                                        : null
                                                    }
                                                    style={{
                                                      backgroundColor: "white",
                                                    }}
                                                    format="hh:mm A"
                                                    use12Hours
                                                    minuteStep={30}
                                                    onChange={(time) =>
                                                      handleArrayToTimeChange(
                                                        elem.id,
                                                        time
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <span className="selected-time">
                                                    {elem.endTime
                                                      ? elem.endTime
                                                      : "Select End Time"}
                                                  </span>
                                                )}
                                              </div>

                                              <div
                                                className="increment-decrement-box d-flex"
                                                onClick={() =>
                                                  removeSlot(elem.id)
                                                }
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width={24}
                                                  height={24}
                                                  fill="currentColor"
                                                  className="bi bi-file-minus"
                                                  viewBox="0 0 16 16"
                                                >
                                                  <path d="M5.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
                                                </svg>
                                              </div>
                                              <span className="pr-2">
                                                {dayName}
                                              </span>
                                            </div>
                                          )
                                        )
                                      : null}

                                    <span
                                      className="cursor_pointer add-slot-button"
                                      onClick={addNewSlot}
                                    >
                                      ADD SLOTS
                                    </span>
                                  </div>
                                </>
                              </div>
                            </div>
                            <div className="col-lg-6 fittness-right-box-outer">
                              <div className="fittness-right-box p-3 h-100">
                                <div className="d-flex justify-content-end custom-gap-10">
                                  <button
                                    type="button"
                                    className="set-btn common-btn-style common-btn-black me-3 custom-gap-15"
                                    onClick={toggleCalendar}
                                  >
                                    VIEW CALENDAR
                                  </button>
                                </div>
                                <AvailabilitySlots />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row pt-4">
                        <div className="col-lg-12 d-flex justify-content-end">
                          {loader || updateLoader ? (
                            <button
                              className="set-btn font-weight-bold"
                              disabled
                            >
                              <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                              <span className="font-weight-bold">
                                LOADING...
                              </span>
                            </button>
                          ) : !loader && !dateType && !toggleState ? (
                            <>
                              {createCalBtn === 0 ? (
                                <button
                                  className="set-btn font-weight-bold common-btn-style text-uppercase"
                                  style={{ marginBottom: "70px" }}
                                  onClick={() =>
                                    createAndUpdateCalendar("create")
                                  }
                                >
                                  {createAndupdateCalloader &&
                                  calendarType === "create" &&
                                  createCalBtn === 0 ? (
                                    <div className="d-flex">
                                      <div
                                        className="spinner-grow spinner-grow-sm text-dark"
                                        role="status"
                                      >
                                        <span className="sr-only text-dark">
                                          LOADING...
                                        </span>
                                      </div>
                                      <span className="text-dark letter_spacing pl-1">
                                        LOADING
                                      </span>
                                    </div>
                                  ) : (
                                    "CREATE"
                                  )}
                                </button>
                              ) : (
                                <button
                                  className="set-btn font-weight-bold common-btn-style ml-2 text-uppercase"
                                  style={{ marginBottom: "70px" }}
                                  onClick={() => {
                                    createAndUpdateCalendar("update");
                                  }}
                                >
                                  {createAndupdateCalloader &&
                                  calendarType === "update" &&
                                  createCalBtn === 1 ? (
                                    <div className="d-flex">
                                      <div
                                        className="spinner-grow spinner-grow-sm text-dark"
                                        role="status"
                                      >
                                        <span className="sr-only text-dark">
                                          LOADING...
                                        </span>
                                      </div>
                                      <span className="text-dark letter_spacing pl-1">
                                        LOADING
                                      </span>
                                    </div>
                                  ) : (
                                    "UPDATE"
                                  )}
                                </button>
                              )}
                            </>
                          ) : !updateLoader &&
                            dateType &&
                            updateSlots.length ? (
                            <button
                              className="set-btn font-weight-bold mb-2"
                              onClick={updateOrDeleteSlots}
                            >
                              <span className="font-weight-bold">UPDATE</span>
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <CreateProduct />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="bank"
                      role="tabpanel"
                      aria-labelledby="bank-tab"
                    >
                      <BankDetails />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calender;
