import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useToken } from "../../authContext";
import Shimmer from "../../pages/AdminDashboard/Shimmer";
import Swal from "sweetalert2";

const ProductList: React.FC = () => {
  const [productData, setProductData] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const authContext = useToken();

  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const deleteProduct = async (id: Number) => {
    Swal.fire({
      title: "ARE YOU SURE?",
      text: "YOU WON'T BE ABLE TO REVERT THIS!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, DELETE IT!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
              doctorid: specialistInfo.id,
              uuid: id,
              isActive: 2,
              action: "command",
              command: [
                {
                  agent: "deleteProduct",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
              ],
            },
            {
              headers: { "x-access-token": `${authContext.token}` },
            }
          )
          .then((result) => {
            if (result?.data?.statusCode === 200) {
              toast.success("PRODUCT DELETED SUCCESSFULLY!");
            }
          })
          .catch((err) => {
            toast.error("Something went wrong", err);
          });
        Swal.fire({
          title: "DELETED!",
          text: "YOUR PRODUCT HAS BEEN DELETED.",
          icon: "success",
        });
        getProductList();
      }
    });
  };

  const getProductList = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          doctorid: specialistInfo.id,
          isActive: 1,
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          action: "command",
          command: [
            {
              agent: "productListDrExp",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setProductData(result?.data?.data);
          setLoader(false);
        } else {
          setProductData([]);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Seomthing went wrong", err);
      });
  };

  useEffect(() => {
    getProductList();
  }, [authContext.token]);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content categories-wrpper user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row pt-5 pt-lg-0">
            <div className="col-lg-12 px-0">
              <ul
                className="nav nav-pills my__bookings__tabs justify-content-sm-between"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <Link
                    to={"#pills-all"}
                    className="nav-link mr-4 active"
                    id="pills-all-tab"
                    data-toggle="pill"
                  >
                    ALL
                  </Link>
                </li>
              </ul>
              {/* New Tab Content */}
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab">
                    <div className="container mb-0 px-0">
                      <div className="row pb-4">
                        <div className="col-lg-12 d-flex justify-content-end pt-3 pt-lg-0">
                          <Link to={"/my-calendar?type=price_set_up"}>
                            <button className="categories__add-btn rounded-0 add-btn btn__border--radius">
                              <svg
                                className="icon-icon-plus mr-1"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                />
                              </svg>
                              <span className="text-dark font-weight-bold add-btn">
                                ADD
                              </span>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="container pb-4 px-0">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="tab-content" id="pills-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="pills-home"
                              role="tabpanel"
                              aria-labelledby="pills-home-tab"
                            >
                              <div className="tab table-responsive">
                                <table className="table table--lines table--striped">
                                  <thead className="table__header table__header--sticky text-center">
                                    <tr>
                                      <th className="text-truncate col-2">
                                        <span className="font-14 ">
                                          Short title
                                        </span>
                                      </th>
                                      <th className="text-truncate col-2">
                                        <span className="font-14">
                                          Duration
                                        </span>
                                      </th>
                                      <th className="text-truncate col-2">
                                        <span className="font-14 ">Price</span>
                                      </th>

                                      <th className="text-truncate col-2">
                                        <span className="font-14">
                                          Specialist
                                        </span>
                                      </th>
                                      <th className="table__actions text-truncate col-2" />
                                    </tr>
                                  </thead>
                                  <tbody className="text-center">
                                    {productData.length !== 0 &&
                                    productData !== null ? (
                                      productData.map((elem: any) => (
                                        <tr className="table__row">
                                          <td className="table__td col-2">
                                            <div className="media-item">
                                              <div className="media-item__right">
                                                <h5 className="media-item__title text-truncate font-14 text-truncate pl-3 pl-lg-0">
                                                  {elem.shorttitle.toUpperCase()}
                                                </h5>
                                              </div>
                                            </div>
                                          </td>
                                          <td className="table__td text-truncate col-2">
                                            <span className="text-grey text-truncate font-14">
                                              {elem?.duration} VIRTUAL
                                              APPOINTMENT
                                            </span>
                                          </td>
                                          <td className="table__td text-truncate col-2">
                                            <span className="text-truncate font-14">
                                              <span className="text-white pr-1">
                                                $
                                              </span>
                                              {elem?.price}
                                            </span>
                                          </td>

                                          <td className="table__td text-truncate col-2">
                                            <span className="text-grey text-truncate font-14 text-uppercase">
                                              {elem?.name}
                                            </span>
                                          </td>
                                          <td className="table__td table__actions col-2">
                                            <div className="items-more font-14 user__view--more text-truncate pr-0 pr-lg-4 text-uppercase">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                onClick={() =>
                                                  deleteProduct(elem?.uuid)
                                                }
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                className="feather feather-trash text-black ml-2 cursor_pointer"
                                              >
                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                <path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
                                                <path d="M10 11v6"></path>
                                                <path d="M14 11v6"></path>
                                                <line
                                                  x1="9"
                                                  y1="6"
                                                  x2="15"
                                                  y2="6"
                                                ></line>
                                                <line
                                                  x1="12"
                                                  y1="2"
                                                  x2="12"
                                                  y2="6"
                                                ></line>
                                              </svg>
                                              <Link
                                                to={`/product/editproduct/${elem.uuid}`}
                                                state={elem}
                                              >
                                                {" "}
                                                Edit
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <span className="text-uppercase">
                                        No products available
                                      </span>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ProductList;
