import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";

import Filter from "../../../../../../../src/img/content/FilterIcon";
import Search from "../../../../../../../src/img/content/SearchIcon";
import ArrowLeft from "../../../../../../../src/img/content/BackIcon";
import Download from "../../../../../../../src/img/content/DownloadIcon";
import FilterDropdown from "./FilterDropdown";
import { useToken } from "../../../../../../authContext";
import Shimmer from "../../../../Shimmer";
import { getCancellationRefundDetails } from "../ApiServices/ReportsAPI";
import moment from "moment";
import Pagination from "./Pagination";

const filterMapping: Record<string, any> = {
  userName: {
    field: "name",
    table: "U",
    operator: "contain",
    value: "",
  },
  specialistName: {
    field: "name",
    table: "S",
    operator: "contain",
    value: "",
  },
  purchasedType: {
    field: "appnt_type",
    table: "C",
    operator: "contain",
    value: "",
  },
  orderId: {
    field: "uuid",
    table: "O",
    operator: "contain",
    value: "",
  },
  amount: {
    field: "grandTotal",
    table: "O",
    operator: "contain",
    value: "",
  },
};
interface Cancellation {
  userName: string;
  specialistName: string;
  type: string;
  orderId: string;
  reason: string;
  slotDateTime: string;
  amount: string;
  status: string;
}

// 1) Define a helper function:
function ellipsis(str: string, maxLength: number): string {
  if (!str) return "";
  if (str.length <= maxLength) return str;
  return str.slice(0, maxLength) + "...";
}

const columns: ColumnsType<Cancellation> = [
  {
    title: "USER NAME",
    dataIndex: "userName",
    key: "userName",
  },
  {
    title: "SPECIALIST NAME",
    dataIndex: "specialistName",
    key: "specialistName",
  },
  {
    title: "TYPE",
    dataIndex: "purchasedType",
    key: "purchasedType",
  },
  {
    title: "ORDER ID",
    dataIndex: "orderId",
    key: "orderId",
  },
  {
    title: "REASON",
    dataIndex: "rejectionReason",
    key: "rejectionReason",
    render: (text: string) => (
      <Tooltip title={text || ""}>
        <div>{ellipsis(text || "", 10)}</div>
      </Tooltip>
    ),
  },
  {
    title: "SLOT DATE & TIME",
    dataIndex: "slotDateTime",
    key: "slotDateTime",
    // Render multiline with <br /> - Add null check to prevent the error
    render: (text: string) => {
      if (!text) return null; // Return null or some default content if text is undefined
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: text.replace("\n", "<br />"),
          }}
        />
      );
    },
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (status: string) => {
      // Return a colored status label
      let statusClass = "status-process";
      if (status === "SUCCESS") statusClass = "status-success";
      else if (status === "REFUND") statusClass = "status-refund";
      else if (status === "IN PROCESS") statusClass = "status-process";

      return (
        <span className={`cansellation-report-status ${statusClass}`}>
          {status || ""}
        </span>
      );
    },
  },
];

const CancellationReport: React.FC = () => {
  const authContext = useToken();
  const [loader, setLoader] = useState(false);
  const [cancellations, setCancellations] = useState<Cancellation[]>([]);
  const [cancellationCount, setCancellationCount] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Multi-select filters
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>([]);
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);

  // Immediate vs Debounced search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Show/hide filter dropdown
  const [filterVisible, setFilterVisible] = useState(false);

  // Debounce effect for searchTerm
  useEffect(() => {
    const controller = new AbortController(); // Abort previous requests
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPaginationCount(1);
      handleFetchPaymentDetails(); // Trigger API after debounce
    }, 1000);

    return () => {
      clearTimeout(timer);
      controller.abort(); // Cancel unfinished requests
    };
  }, [searchTerm]);

  // Build final filters for the API
  const filterType = useMemo(() => {
    return appliedFilterKeys.map((key) => {
      const obj = { ...filterMapping[key] };
      obj.value = debouncedSearchTerm; // only apply after debounce
      return obj;
    });
  }, [appliedFilterKeys, debouncedSearchTerm]);

  // Filter options
  const filterOptions = [
    { label: "USER NAME", value: "userName" },
    { label: "SPECIALIST NAME", value: "specialistName" },
    { label: "type", value: "purchasedType" },
    { label: "ORDER ID", value: "orderId" },
    { label: "AMOUNT", value: "amount" },
  ];

  // When user applies filters
  const handleFilterApply = () => {
    setAppliedFilterKeys(selectedFilterKeys);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // When user clears filters
  const handleFilterClear = () => {
    setSelectedFilterKeys([]);
    setAppliedFilterKeys([]);
    setPaginationCount(1);
    setFilterVisible(false);
  };
  // Utility function to check null, undefined, empty string, or space-only strings
  const isValidData = (value: any): boolean => {
    return (
      value !== null && value !== undefined && value.toString().trim() !== ""
    );
  };

  // Utility function to format values with fallback
  const formatValue = (value: any, fallback: string = "-") => {
    return isValidData(value) ? value.toString().trim() : fallback;
  };

  // Toggling checkboxes
  const handleCheckboxChange = (updated: string[]) => {
    setSelectedFilterKeys(updated);
  };
  // Trigger fetch when filterType or pagination changes (and also if debouncedSearchTerm changes, but that's inside filterType's useMemo)
  const handleFetchPaymentDetails = async () => {
    try {
      setLoader(true);
      const responseData = await getCancellationRefundDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        searchTerm: "",
      });

      const rawPayments = responseData?.data || [];

      const formattedPayments: Cancellation[] = rawPayments?.map(
        (item: any) => {
          const fixedStartTime = formatValue(item?.bookingStartTime).includes(
            ":000"
          )
            ? item.bookingStartTime.slice(0, -2)
            : formatValue(item?.bookingStartTime);

          const fixedEndTime = formatValue(item?.bookingEndTime).includes(
            ":000"
          )
            ? item.bookingEndTime.slice(0, -2)
            : formatValue(item?.bookingEndTime);

          const startTime = moment.utc(fixedStartTime);
          const endTime = moment.utc(fixedEndTime);

          const specialistPayoutDate =
            isValidData(item?.specialist_payout_date) &&
            item.specialist_payout_date !== " "
              ? `${moment
                  .utc(item.specialist_payout_date)
                  .format("DD/MM/YYYY")}\n${moment
                  .utc(item.specialist_payout_date)
                  .format("hh:mm A")}`
              : "-";

          return {
            userName: formatValue(item?.userName).toUpperCase(),
            appointmentId: formatValue(item?.appointmentId),
            specialistName: formatValue(item?.specialistName).toUpperCase(),
            slotDateTime: `${startTime.format(
              "DD/MM/YYYY"
            )}\n${startTime.format("hh:mm A")} - ${endTime.format("hh:mm A")}`,
            paymentId: formatValue(item?.paymentId),
            amount: `$ ${formatValue(item?.amount)}`,
            specialistPayoutDate,
            orderId: formatValue(item?.orderId),
            purchasedType: formatValue(item?.purchasedType).toUpperCase(),
            rejectionReason: formatValue(item?.rejectionReason).toUpperCase(),
            status: formatValue(item?.status),
          };
        }
      );

      setCancellations(formattedPayments);
      setCancellationCount(responseData?.data?.totalCount || 0);
      setTotalPages(Math.ceil((responseData?.data?.totalCount || 0) / 4));
    } catch (error) {
      console.error("Failed to fetch cancellationReport details:", error);
    } finally {
      setLoader(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoader(true);
      await getCancellationRefundDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        excelDownload: true,
      });
    } catch (error) {
      console.error("Failed to download Excel:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleFetchPaymentDetails();
  }, [filterType, paginationCount]);

  // Pagination logic
  const pagination = (type: string, total: number) => {
    let newOffset = paginationCount;
    if (type === "next") {
      newOffset = paginationCount + 1;
    } else if (type === "previous") {
      newOffset = paginationCount - 1;
    } else if (type === "first") {
      newOffset = 1;
    } else if (type === "last") {
      newOffset = Math.ceil(total);
    }
    setPaginationCount(newOffset);
  };

  if (loader) return <Shimmer />;

  return (
    <div className="cancellation-container">
      {/* Back button/link */}
      <Link to="/dashboard/analytics/reports" className="cr-back-button">
        <ArrowLeft width={18} height={18} />
        <span>REQUEST FOR CANCELLATION/REFUND REPORT</span>
      </Link>

      {/* Action bar (filter, search, report) */}

      <div className="ubdr-action-bar">
        <button
          className="ubdr-filter-button"
          onClick={() => {
            setFilterVisible(!filterVisible);
            setSelectedFilterKeys(appliedFilterKeys);
          }}
        >
          <Filter width={18} height={18} />
        </button>

        {/* Multi-select FilterDropdown */}
        <FilterDropdown
          filterOptions={filterOptions}
          visible={filterVisible}
          selectedValues={selectedFilterKeys}
          onChange={handleCheckboxChange}
          onApply={handleFilterApply}
          onClear={handleFilterClear}
          onVisibleChange={setFilterVisible}
        />

        <div className="ubdr-search-bar">
          <span className="ubdr-search-icon">
            <Search width={16} height={16} />
          </span>
          <input
            type="text"
            className="ubdr-search-reports"
            placeholder="SEARCH..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="ubdr-report-button" onClick={downloadExcel}>
          <Download width={18} height={18} />
          <span>REPORT</span>
        </button>
      </div>

      {/* Table container */}
      <div className="cr-table-container">
        <Table<Cancellation>
          columns={columns}
          dataSource={cancellations}
          rowKey="orderId"
          pagination={false}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>
      <Pagination
        pagination={pagination}
        paginationCount={paginationCount}
        setPaginationCount={setPaginationCount}
        totalPages={totalPages}
        list={cancellations}
        ListCount={cancellationCount}
      />
    </div>
  );
};

export default CancellationReport;
