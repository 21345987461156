import React, { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import Select from "react-select";
import "../Dashboard.css";

type CalendarAnalyticsProps = {
  backgroundColor: string;
  onChange: ({ startDate, endDate }: any) => void;
};

const options = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "currentWeek", label: "Current Week" },
  { value: "currentMonth", label: "Current Month" },
  { value: "currentYear", label: "Current Year" },
  { value: "LastYear", label: "Last Year" },
  { value: "custom", label: "Custom" },
];

const CalendarAnalytics: React.FC<CalendarAnalyticsProps> = ({
  backgroundColor,
  onChange,
}) => {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const pathName = location.pathname;
  // Example default to year start/end
  const currentYearStart = dayjs().startOf("year");
  const currentYearEnd = dayjs().endOf("year");
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [range, setRange] = useState<{ startDate: any; endDate: any }>({
    startDate: localStorage.getItem("startDate"),
    endDate: localStorage.getItem("endDate"),
  });

  useEffect(() => {
    if (selectedValue && selectedValue !== "custom") {
      getDateRange(selectedValue);
    } else if (selectedValue === "custom") {
      // If localStorage had dates for custom, let's pull them
      const startDate = localStorage.getItem("startDate");
      const endDate = localStorage.getItem("endDate");
      if (startDate && endDate) {
        setRange({ startDate, endDate });
      } else {
        setRange({
          startDate: currentYearStart.format("YYYY-MM-DD"),
          endDate: currentYearEnd.format("YYYY-MM-DD"),
        });
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    const localStorageValue = localStorage.getItem("selectedValue");
    const localStorageValueFormatted =
      localStorageValue === null
        ? null
        : { value: localStorageValue, label: localStorageValue };

    const defaultValue = { value: "LastYear", label: "Last Year" };
    if (localStorageValueFormatted?.value) {
      handleSelectionChange(localStorageValueFormatted);
    } else {
      handleSelectionChange(defaultValue);
    }
  }, []);

  const handleSelectionChange = (selectedOption: any) => {
    setSelectedValue(selectedOption.value);
    localStorage.setItem("selectedValue", selectedOption.value);
  };

  const handleSetRange = (startDate: any, endDate: any) => {
    setRange({ startDate, endDate });
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    onChange({ startDate, endDate });
  };

  // Example date formatting
  const formatDate = (date: Date) => date.toISOString().split("T")[0];

  // Example range logic
  const getDateRange = (type: string) => {
    const today = new Date();
    let startDate, endDate;

    switch (type) {
      case "today": {
        startDate = endDate = formatDate(today);
        break;
      }
      case "yesterday": {
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);
        startDate = formatDate(yesterday);
        endDate = formatDate(yesterday);
        break;
      }
      case "currentWeek": {
        // Using dayjs to calculate the start and end of the current week
        startDate = dayjs().startOf("week").format("YYYY-MM-DD");
        endDate = dayjs().endOf("week").format("YYYY-MM-DD");
        break;
      }
      case "currentMonth": {
        startDate = dayjs().startOf("month").format("YYYY-MM-DD");
        endDate = dayjs().endOf("month").format("YYYY-MM-DD");
        break;
      }
      case "currentYear": {
        startDate = dayjs().startOf("year").format("YYYY-MM-DD");
        endDate = dayjs().endOf("year").format("YYYY-MM-DD");
        break;
      }
      case "LastYear": {
        // Calculate the entire previous year using UTC for consistency
        startDate = formatDate(
          new Date(Date.UTC(today.getUTCFullYear() - 1, 0, 1))
        );
        endDate = formatDate(
          new Date(Date.UTC(today.getUTCFullYear() - 1, 11, 31))
        );
        break;
      }
      default:
        startDate = endDate = formatDate(today);
        break;
    }
    handleSetRange(startDate, endDate);
  };
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      width: "168px",
      height: "31px",
      minHeight: "31px",
      fontSize: "14px",
      //   border: "1px solid #444",
      backgroundColor: "#fff", // Dark control background
      color: "#000",
      boxShadow: "none", // Remove default focus outline
      "&:hover": {
        border: "1px solid #555", // Slightly lighter on hover
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "#000", // White text for the selected value
      textTransform: "uppercase",
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "#aaa", // Gray placeholder text
      textTransform: "uppercase",
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: "#ccc",
      padding: 0,
      "&:hover": {
        color: "#fff",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#3a3a3a", // Dark gray background for dropdown
      border: "1px solid #444",
      borderRadius: "4px",
      boxShadow: "none",
      zIndex: 10,
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: 0,
      maxHeight: "200px", // Optionally limit menu height
      overflowY: "auto",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      padding: "10px 12px",
      cursor: "pointer",
      fontSize: "14px",
      textTransform: "uppercase",
      color: "#fff", // Default white text
      backgroundColor: state.isSelected
        ? "#555" // Dark selection background
        : state.isFocused
        ? "#4a4a4a" // Lighter gray on hover/focus
        : "transparent",
      "&:active": {
        backgroundColor: "#666", // Active click color
      },
    }),
  };

  // Hide default indicators
  const DropdownIndicator = () => null;
  const IndicatorSeparator = () => null;

  return (
    <div className="timer-filter">
      {/* The Select control */}
      <Select
        value={options.find((option) => option.value === selectedValue)}
        onChange={handleSelectionChange}
        options={options}
        styles={customStyles}
        placeholder="Select an option"
        components={{ DropdownIndicator, IndicatorSeparator }}
        className="custom-select-container"
      />

      {/* Render RangePicker inline when "custom" is selected */}
      {selectedValue === "custom" && (
        <Space direction="horizontal" size={12}>
          <div className="dark-range-picker-container">
            <RangePicker
              showTime={false}
              format="YYYY-MM-DD"
              className="h-[34px] w-56 "
              value={[dayjs(range?.startDate), dayjs(range?.endDate)]}
              onChange={(value, dateString) => {
                if (value) {
                  handleSetRange(dateString[0], dateString[1]);
                } else {
                  handleSetRange(
                    currentYearStart.format("YYYY-MM-DD"),
                    currentYearEnd.format("YYYY-MM-DD")
                  );
                }
              }}
            />
          </div>
        </Space>
      )}
    </div>
  );
};

export default CalendarAnalytics;
