import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import Select from "react-select";

const UserSettings = () => {
  const authContext = useToken();
  const getUserInfo = localStorage.getItem("userInfo");
  const getUserId =
    typeof getUserInfo === "string" ? JSON.parse(getUserInfo) : getUserInfo;
  const [userData, setUserData] = useState<any>({
    firstname: "",
    lastname: "",
    country: "",
    phone: "",
  });
  const [userDetails, setUserDetails] = useState<any>([]);
  const [validationError, setValidationError] = useState<any>(false);
  const [countriesList, setCountriesList] = useState<any>([]);
  const [userCountry, setUserCountry] = useState<any>([]);

  useEffect(() => {
    getUserDetails();
    getCountryCodeList();
    getUserData();
  }, [authContext.token]);

  const getUserData = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userId: getUserId.id,
          action: "command",
          command: [
            {
              agent: "userDatailInfo",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUserDetails(result?.data?.data?.userData[0]);
        } else {
          setUserDetails([]);
        }
      })
      .catch((err) => {
        if (err) {
          return err;
        }
      });
  };

  const getUserDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          command: JSON.stringify([
            {
              agent: "getUserProfile",
              appName: "selfMaximized",
              folder: "users",
            },
          ]),
        },
        headers: { "x-access-token": authContext.token },
      })
      .then((result) => {
        if (result?.data?.code === 1) {
          setUserData(result?.data?.data[0]);
        } else {
          setUserData([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const handleUserProfile = (event: any) => {
    const { name, value } = event.target;
    setUserData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  console.log(userData);
  const updateUser = async () => {
    // "password":"SelfAdmin@2023",
    // "confirmPassword":"SelfAdmin@2023",
    // "oldPassword":"Amol@123",

    if (
      !userData?.firstname ||
      !userData?.lastname ||
      userData?.country === undefined ||
      !userData?.phone
    ) {
      setValidationError(true);
      return true;
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            email: getUserId.email,
            data: {
              name: `${userData?.firstname + userData?.lastname} `,
              role: "user",
              country: userData?.country,
              profile: "{}",
            },
            customer: {
              firstname: userData?.firstname,
              lastname: userData?.lastname,
              gender: "male",
              phone: userData?.phone,
              dob: "2024-01-01",
              country: userData?.country,
            },
            menuId: "f68ae19c-352a-4da5-bf72-6b0e31064e01",
            action: "command",
            command: [
              {
                agent: "editUser",
                appName: "selfMaximized",
                folder: "users",
              },
            ],
          },
          {
            headers: { "x-access-token": `${authContext.token}` },
          }
        )
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            return toast.success("User updated successfully.");
          } else {
            return;
          }
        })
        .catch((err) => {
          return err;
        });
    }
  };

  const getCountryCodeList = async () => {
    await axios
      .post(`${process.env.REACT_APP_API}/pipeline`, {
        action: "command",
        command: [
          {
            agent: "countryList",
            appName: "selfMaximized",
            folder: "masters",
          },
        ],
      })
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCountriesList(result?.data?.countries);
        } else {
          setCountriesList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const mappedOptions = countriesList.map((option: any) => ({
    value: option.name,
    label: ` ${option.name}`,
  }));

  const handleCountry = (selectedOption: any) => {
    setUserCountry("");
    setUserData((prevState: any) => ({
      ...prevState,
      country: selectedOption.label,
    }));
  };

  console.log(userData?.country);

  useEffect(() => {
    const country = userData && userData?.country;
    const pushCountry = [];
    pushCountry.push(country);
    const modifiedCountry =
      pushCountry &&
      pushCountry.map((elem: any, index: any) => {
        return { value: index, label: elem };
      });
    setUserCountry(modifiedCountry);
  }, [userData?.country]);

  return (
    <main className="page-content doctot-detail-user_wrapper overflow-auto">
      <div className="container pb-3 pt-5 pt-lg-0">
        <div className="row">
          <div className="col-lg-12 text-right px-0 pb-3">
            <button className="edit__profile" onClick={updateUser}>
              <span className="text-dark font-weight-bold add-btn">
                <i className="fa fa-save pr-2"></i>SAVE
              </span>
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="below-are-you">BELOW ARE YOUR PROFILE DETAILS</p>
            <div className="border-bottom-line">
              <p className="mb-2">PERSONAL DETAILS</p>
            </div>
          </div>
        </div>
        <div className="row pt-5">
          <div className="col-lg-12">
            <form className="personal-details-form">
              <div className="row d-flex align-items-center">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="email">
                        <span className="text-white pe-1 vertical-top pr-1">
                          *
                        </span>
                        EMAIL
                      </label>
                      <p className="mb-0 font-14  text-transform-unset">
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                  <div className="row pt-4">
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="name">
                        <span className="text-white pe-1 vertical-top pr-1">
                          *
                        </span>
                        FIRST NAME
                      </label>
                      <input
                        type="text"
                        pattern="[A-Za-z\s]+"
                        className="form-control"
                        name="firstname"
                        value={userData?.firstname}
                        autoComplete="off"
                        onChange={handleUserProfile}
                      />
                      <span className="text-danger registration__error--msg">
                        {validationError &&
                          !userData?.firstname &&
                          `Please enter first name`}
                      </span>
                    </div>
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="name">
                        <span className="text-white pe-1 vertical-top pr-1">
                          *
                        </span>
                        LAST NAME
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastname"
                        value={userData?.lastname}
                        autoComplete="off"
                        onChange={handleUserProfile}
                      />
                      <span className="text-danger registration__error--msg">
                        {validationError &&
                          !userData?.lastname &&
                          `Please enter last name`}
                      </span>
                    </div>
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="cnumber">
                        <span className="text-white pe-1 vertical-top pr-1">
                          *
                        </span>
                        CONTACT NUMBER
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        value={userData?.phone}
                        autoComplete="off"
                        onChange={handleUserProfile}
                      />
                      <span className="text-danger registration__error--msg">
                        {validationError &&
                          !userData?.phone &&
                          `Please enter contact number`}
                      </span>
                    </div>
                    <div className="col-lg-6 pb-3">
                      <label htmlFor="cnumber">
                        <span className="text-white pe-1 vertical-top pr-1">
                          *
                        </span>
                        COUNTRY
                      </label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name="country"
                        value={userData?.country}
                        autoComplete="off"
                        onChange={handleUserProfile}
                      /> */}
                      <Select
                        value={userCountry ? userCountry : userData?.country}
                        onChange={handleCountry}
                        options={mappedOptions}
                        isSearchable={true}
                        name="language"
                        className="text-dark text-uppercase"
                        styles={{
                          control: (provided: any, state: any) => ({
                            ...provided,
                            fontSize: "16px",
                            color: "inherit",
                            backgroundColor: "#3A3838",
                            letterSpacing: "1px",
                            border: state.isFocused
                              ? "2px solid black"
                              : "2px solid transparent", // Custom border style
                            boxShadow: state.isFocused ? "none" : "none", // Remove box-shadow
                            "&:hover": {
                              borderColor: "black", // Border color on hover
                            },
                          }),
                          option: (provided: any, state: any) => ({
                            ...provided,
                            color:
                              state.isFocused || state.isSelected
                                ? "black"
                                : "white", // Change color to black when focused or selected
                            backgroundColor: state.isFocused
                              ? "#ffffff"
                              : "#3A3838", // Background color when focused
                          }),
                          singleValue: (provided: any) => ({
                            ...provided,
                            color: "white", // Set color of the selected option to white
                          }),
                          input: (provided: any) => ({
                            ...provided,
                            color: "white",
                          }),
                          placeholder: (provided: any) => ({
                            ...provided,
                            color: "white",
                          }),
                          menu: (provided: any) => ({
                            ...provided,
                            backgroundColor: "#3A3838",
                          }),
                        }}
                        placeholder="Search..."
                      />
                      <span className="text-danger registration__error--msg">
                        {validationError &&
                          userData?.country === undefined &&
                          `Please enter country`}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  );
};

export default UserSettings;
