import React, { useEffect, useState } from "react";
import { useToken } from "../../../authContext";
import axios from "axios";
import Shimmer from "../../../pages/AdminDashboard/Shimmer";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import PersistentTimer from "../../../SpecialistDashboard/Layout/CountDownTimer";

const TodayAppointment: React.FC = () => {
  const [upcommingBooking, setUpcommingBooking] = useState<any>([]);
  const [loader, setLoader] = useState<any>(true);
  const authCtx = useToken();
  const userInfo =
    typeof authCtx.userInfo === "string"
      ? JSON.parse(authCtx.userInfo)
      : authCtx.userInfo;
  const userMenu =
    typeof authCtx.userMenu === "string"
      ? JSON.parse(authCtx.userMenu)
      : authCtx.userMenu;
  const dashboardMenuItem: any = userMenu.find(
    (item: any) => item.menu === "Dashboard"
  );

  const userTimeZone = userInfo?.timezone;

  const getDailyAppointments = async () => {
    setLoader(true);

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      // Get month and day with padding because getMonth() returns 0-11 and getDate() can return 1-31
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 3,
            filters: [
              {
                field: "calendarDate",
                operator: "eq",
                value: `${getCurrentDate()}`,
              },
              {
                field: "uuid",
                table: "Users",
                operator: "eq",
                value: `${userInfo.uuid}`,
              },
            ],

            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: `${dashboardMenuItem.uuid}`,
        action: "command",
        command: [
          {
            agent: "userBooking",
            appName: "selfMaximized",
            folder: "users",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );

    const newData = response.data.data;
    setUpcommingBooking(newData);
    setLoader(false);
  };

  useEffect(() => {
    getDailyAppointments();
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <div className="col-lg-12">
      <span className="today today-custom mt-1 d-block pb-2">TODAY</span>
      {/* {upcommingBooking?.length !== 0 && upcommingBooking !== null ? (
        upcommingBooking?.map((item: any, index: any) => (
          <div
            className="row join-reshedule-time-premimum-version-box py-3 mb-3"
            key={item.id}
          >
            <div className="col-8">
              <div className="row">
                <div className="col-8">
                  <div className="row px-0">
                    <div className="col-6">
                      <p className="start-time-wrapper_one mb-0 text-center">
                        <span className="font-12 font-weight-bold">
                          {userTimeZone && item?.startTime
                            ? moment
                                .tz(
                                  item.startTime.replace(/:000Z$/, ":00Z"),
                                  userTimeZone
                                )
                                .format("hh:mm A")
                            : "Date Not Available"}
                        </span>
                      </p>
                    </div>
                    <div className="col-6 px-0">
                      <p className="mb-0 trainer-name-degree-name">
                        <span className="mb-0 font-12 d-block">
                          DR - {item?.dname}
                        </span>
                        <span className="mb-0 font-12 today-doctor-categaory-title mt-2">
                          {item?.name?.toUpperCase()}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4 text-right">
              <p
                className="font-12 font-weight-bold text-uppercase cursor_pointer text-success"
                onClick={() => {
                  const currentTimeInUserTimezone = moment.tz(userTimeZone); // Current time in user's timezone

                  if (item?.startTime) {
                    const startTimeInUserTimezone = moment
                      .tz(
                        item.startTime.replace(/:000Z$/, ":00Z"),
                        "YYYY-MM-DDTHH:mm:ssZ",
                        "UTC"
                      )
                      .tz(userTimeZone);

                    if (
                      currentTimeInUserTimezone.isSameOrAfter(
                        startTimeInUserTimezone
                      )
                    ) {
                      window.location.href =
                        `${process.env.REACT_APP_LOCALHOST_URL_VIDEO}` +
                        "meeting/" +
                        item?.meetingId +
                        "/" +
                        userInfo?.name +
                        "?id=" +
                        userInfo.id;
                    } else {
                      return toast.info("MEETING IS NOT YET STARTED!", {
                        icon: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="60"
                            height="60"
                            viewBox="0 0 30 30"
                          >
                            <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                          </svg>
                        ),
                        className: "custom-toast",
                        bodyClassName: "custom-toast",
                      });
                    }
                  } else {
                    toast.error("START TIME NOT AVAILABLE");
                  }
                }}
              >
                Join
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <span className="not__available text-uppercase">
              No today's appointments available
            </span>
          </div>
        </div>
      )} */}

      {upcommingBooking?.length !== 0 && upcommingBooking !== null ? (
        upcommingBooking?.map((item: any, index: any) => {
          // Parse Start and End Times in UTC
          const startTimeUTC = moment.tz(
            item.startTime.replace(/:000Z$/, ":00Z"),
            "YYYY-MM-DDTHH:mm:ssZ",
            "UTC"
          );

          const endTimeUTC = moment.tz(
            item.endTime.replace(/:000Z$/, ":00Z"),
            "YYYY-MM-DDTHH:mm:ssZ",
            "UTC"
          );

          const nowUTC = moment.utc(); // Current UTC time

          // Check if current time is within the slot
          const isCurrentSlot =
            nowUTC.isSameOrAfter(startTimeUTC) && nowUTC.isBefore(endTimeUTC);

          return (
            <div
              className="row join-reshedule-time-premimum-version-box py-3 mb-3"
              key={item.id}
            >
              <div className="col-8">
                <div className="row">
                  <div className="col-8">
                    <div className="row px-0">
                      <div className="col-6">
                        <p className="start-time-wrapper_one mb-0 text-center">
                          <span className="font-12 font-weight-bold">
                            {userTimeZone && item?.startTime
                              ? moment
                                  .tz(
                                    item.startTime.replace(/:000Z$/, ":00Z"),
                                    userTimeZone
                                  )
                                  .format("hh:mm A")
                              : "Date Not Available"}
                          </span>
                        </p>
                      </div>
                      <div className="col-6 px-0">
                        <p className="mb-0 trainer-name-degree-name">
                          <span className="mb-0 font-12 d-block">
                            DR - {item?.dname}
                          </span>
                          <span className="mb-0 font-12 today-doctor-categaory-title mt-2">
                            {item?.name?.toUpperCase()}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Join Button */}
              <div className="col-4 text-right">
                <p
                  className="font-12 font-weight-bold text-uppercase cursor_pointer text-success"
                  onClick={() => {
                    const currentTimeInUserTimezone = moment.tz(userTimeZone); // Current user timezone time

                    if (item?.startTime) {
                      const startTimeInUserTimezone = moment
                        .tz(
                          item.startTime.replace(/:000Z$/, ":00Z"),
                          "YYYY-MM-DDTHH:mm:ssZ",
                          "UTC"
                        )
                        .tz(userTimeZone);

                      // If time to join
                      if (
                        currentTimeInUserTimezone.isSameOrAfter(
                          startTimeInUserTimezone
                        )
                      ) {
                        window.location.href =
                          `${process.env.REACT_APP_LOCALHOST_URL_VIDEO}` +
                          "meeting/" +
                          item?.meetingId +
                          "/" +
                          userInfo?.name +
                          "?id=" +
                          userInfo.id;
                      } else {
                        // If trying to join early
                        return toast.info("MEETING IS NOT YET STARTED!", {
                          icon: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width="60"
                              height="60"
                              viewBox="0 0 30 30"
                            >
                              <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                            </svg>
                          ),
                          className: "custom-toast",
                          bodyClassName: "custom-toast",
                        });
                      }
                    } else {
                      toast.error("START TIME NOT AVAILABLE");
                    }
                  }}
                >
                  Join
                </p>
              </div>

              {/* Persistent Timer for Current Slot */}
              {isCurrentSlot && (
                <div className="mt-2">
                  <PersistentTimer
                    duration={parseFloat(item?.duration)}
                    startTime={item.startTime.replace(/:000Z$/, ":00Z")}
                    endTime={item.endTime.replace(/:000Z$/, ":00Z")}
                    timezone={userTimeZone}
                  />
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <span className="not__available text-uppercase">
              No today's appointments available
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TodayAppointment;
