import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useToken } from "../../../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../Shimmer";
import { Link, useNavigate } from "react-router-dom";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";

const UserList: React.FC = () => {
  const authContext = useToken();
  const [userData, setUserList] = useState<any>([]);
  const [paginationCount, setPaginationCount] = useState<any>(1);
  const [userListCount, setUserListCount] = useState<any>("");
  const [totalPages, setTotalPages] = useState<any>(0);
  const [loader, setLoader] = useState<any>([]);
  const [checkedState, setCheckedState] = useState<any>("all");
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<any>("");

  const handleUserList = (type: any) => async (event: any) => {
    event.preventDefault();
    let filters = [];
    if (type === "active") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 1,
      });
    } else if (type === "inactive") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 0,
      });
    } else if (type === "all") {
      filters = [];
    }

    setFilterType(filters);
    setCheckedState(type);
    setPaginationCount(1);
    await getUserList(filters, 1);
  };

  const goToUserDetailsPage = (id: any, uuid: any) => {
    navigate(`/dashboard/users/userdetails/${id}`, { state: { id, uuid } });
  };

  const pagination = async (type: any, totalPages: any) => {
    if (type === "next") {
      setPaginationCount((prevState: any) => prevState + 1);
      await getUserList(filterType, paginationCount + 1);
    } else if (type === "previous") {
      setPaginationCount((prevState: any) => prevState - 1);
      await getUserList(filterType, paginationCount - 1);
    } else if (type === "first") {
      setPaginationCount(1);
      await getUserList(filterType, 1);
    } else if (type === "last") {
      setPaginationCount(Math.ceil(totalPages));
      console.log(Math.ceil(totalPages));
      await getUserList(filterType, Math.ceil(totalPages));
    }
  };

  const getUserList = async (filters: any, offset: any) => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: offset ? (offset - 1) * 6 : 0,
              limit: 6,
              filters: filters,
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
          action: "command",
          command: [
            {
              agent: "userDataList",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setUserList(result?.data?.data);
        setUserListCount(result?.data?.totalCount);
        setTotalPages(userListCount / 6);
        setLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong!", err);
        setLoader(false);
      });
  };

  const userList = useCallback(getUserList, [authContext.token, userListCount]);

  useEffect(() => {
    userList("", "");
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper user-wrapper-inner overflow-auto">
        <div className="container pt-3 pt-lg-0">
          <div className="row">
            <div className="col-lg-12">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" onClick={handleUserList("all")}>
                  <Link
                    to={"#pills-all"}
                    className={`nav-link ${
                      checkedState === "all" ? "active" : ""
                    }  mr-4`}
                    id="pills-all-tab"
                    data-toggle="pill"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >
                    ALL
                  </Link>
                </li>
                <li className="nav-item" onClick={handleUserList("active")}>
                  <Link
                    to={"#pills-active"}
                    className={`nav-link ${
                      checkedState === "active" ? "active" : ""
                    } mr-4`}
                    id="pills-active-tab"
                    data-toggle="pill"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    ACTIVE
                  </Link>
                </li>
                <li className="nav-item" onClick={handleUserList("inactive")}>
                  <Link
                    to={"#pills-inactive"}
                    className={`nav-link ${
                      checkedState === "inactive" ? "active" : ""
                    }`}
                    id="pills-inactive-tab"
                    data-toggle="pill"
                  >
                    INACTIVE
                  </Link>
                </li>
              </ul>
              <div className="tab-content pt-3 pt-lg-0" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab">
                    <div className="container pb-4 px-0">
                      <div className="col-lg-12 px-0">
                        <div className="tab w-100">
                          <div className="table-responsive user__all__active__inactive__card">
                            <table className="table table--lines table--striped">
                              {userData.length !== 0 && userData !== null ? (
                                <thead className="table__header table__header--sticky text-center">
                                  <tr>
                                    <th className="col-2">
                                      <span>Name</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Email</span>
                                    </th>
                                    <th className="col-2">
                                      <span>Mobile No</span>
                                    </th>
                                    <th className="table__actions col-2"></th>
                                  </tr>
                                </thead>
                              ) : null}
                              <tbody className="text-center">
                                {userData.length !== 0 && userData !== null ? (
                                  userData.map((elem: any) => (
                                    <tr className="table__row" key={elem.id}>
                                      <td className="table__td">
                                        <div className="media-item">
                                          <div className="media-item__right">
                                            <h5 className="media-item__title text-truncate font-14 pl-3 text-uppercase">
                                              {elem.name}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-truncate font-14 text-transforn-unset">
                                          {elem.email}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem.phone}
                                        </span>
                                      </td>
                                      <td className="table__td table__actions text-center">
                                        <div
                                          className="items-more font-14 user__view--more text-truncate text-uppercase"
                                          onClick={() =>
                                            goToUserDetailsPage(
                                              elem.id,
                                              elem.uuid
                                            )
                                          }
                                        >
                                          View
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <div className="col-lg-12">
                                    <img
                                      src={noDataFoundImage}
                                      alt=""
                                      className="img-fluid user__all__active__inactive__card--image"
                                      style={{ width: "auto", height: "500px" }}
                                      loading="lazy"
                                    />
                                  </div>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="row mt-3 mt-lg-0">
                            <div className="col-lg-12 text-right">
                              <nav
                                aria-label="Page navigation example"
                                className="pagination__card"
                              >
                                <ul className="pagination">
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }`}
                                        onClick={() => pagination("first", "")}
                                        disabled={paginationCount === 1}
                                      >
                                        &laquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() =>
                                          pagination("previous", "")
                                        }
                                        disabled={paginationCount === 1}
                                      >
                                        Previous
                                      </button>
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount === 1
                                          ? "next__arrow--icon"
                                          : "text-white next--page"
                                      }  page-link previous--page `}
                                      onClick={() => pagination("previous", "")}
                                      disabled={paginationCount === 1}
                                    >
                                      Previous
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        userData.length < 6
                                          ? "next--btn"
                                          : "text-white next--page"
                                      } page-link `}
                                      onClick={() => pagination("next", "")}
                                      disabled={
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        userData.length < 6
                                      }
                                    >
                                      Next
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Next"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  `}
                                        onClick={() =>
                                          pagination("last", userListCount / 6)
                                        }
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                        }
                                      >
                                        &raquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                            ? "next--btn"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() => pagination("next", "")}
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                        }
                                      >
                                        Next
                                      </button>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserList;
