import React from "react";

// Define props with optional width/height (numbers)
interface BackIconProps {
  width?: number;
  height?: number;
}

const BackIcon: React.FC<BackIconProps> = ({ width = 8, height = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 12L0 6L6 0L7.4 1.4L2.8 6L7.4 10.6L6 12Z" fill="white" />
    </svg>
  );
};

export default BackIcon;
