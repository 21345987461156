import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useToken } from "../../authContext";

const BankDetails = () => {
  const [bankDetails, setBankDetails] = useState<any>({
    bank: "",
    branch: "",
    country: "",
    city: "",
    swiftCode: "",
  });
  const [editBtn, setEditBtnDisabled] = useState<any>(false);
  const [loader, setLoader] = useState<any>(false);
  const [showBtn, setShowBtn] = useState<any>(false);
  const authContext = useToken();
  const userInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;
  const handleBankingDetails = (event: any) => {
    const { name, value } = event.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  const publishBankDetails = async (type: any) => {
    let errorMessage = "";

    if (
      !bankDetails?.bank ||
      !bankDetails?.branch ||
      !bankDetails?.country ||
      !bankDetails?.city ||
      !bankDetails?.swiftCode
    ) {
      let anyErrorFound = false;

      if (!bankDetails?.bank) {
        errorMessage += "Bank is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !bankDetails?.branch) {
        errorMessage += "Branch is mandatory. ";
        anyErrorFound = true;
      }
      if (!anyErrorFound && !bankDetails?.country) {
        errorMessage += "Country is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !bankDetails?.city) {
        errorMessage += "City is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !bankDetails?.swiftCode) {
        errorMessage += "Swift code is mandatory. ";
        anyErrorFound = true;
      }
      // If all fields are empty
      if (
        !bankDetails?.bank &&
        !bankDetails?.branch &&
        !bankDetails?.country &&
        !bankDetails?.city &&
        !bankDetails?.swiftCode
      ) {
        errorMessage = "All fields are mandatory.";
      }
    }

    if (errorMessage === "") {
      // If no error, proceed with your code
    } else {
      return toast.warning(errorMessage);
    }

    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          bank: bankDetails.bank,
          branch: bankDetails.branch,
          country: bankDetails.country,
          city: bankDetails.city,
          swiftCode: bankDetails.swiftCode,
          action: "command",
          command: [
            {
              agent: "specialistBank",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          setBankDetails({
            bank: "",
            branch: "",
            country: "",
            city: "",
            swiftCode: "",
          });
          if (type === "update") {
            setShowBtn(false);
            setEditBtnDisabled(true);
            getBankDetails();
            setLoader(false);

            return toast.success("Bank details updated successfully");
          } else {
            setLoader(false);
            return toast.success(result?.data?.message);
          }
        } else {
          setLoader(false);

          toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);

        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getBankDetails();
  }, []);

  const getBankDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 1,
              filters: [
                {
                  field: "userId",
                  operator: "eq",
                  table: "UserBankDetails",
                  value: userInfo.id,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "bankAccountList",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.status === "success") {
          setBankDetails(result?.data?.data[0]);
          if (result?.data?.data[0]) {
            setEditBtnDisabled(true);
          }
        } else {
          setBankDetails([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const showUpdateAndCancelBtn = () => {
    setShowBtn(true);
    setEditBtnDisabled(false);
  };

  const cancelBtn = () => {
    setShowBtn(false);
    setEditBtnDisabled(true);
    getBankDetails();
  };

  useEffect(() => {
    if (bankDetails && bankDetails === undefined) {
      setShowBtn(true);
    }
  }, [showBtn]);
  return (
    <>
      <div className="col-lg-12">
        <h2 className="lets-fill-basic mb-0">LETS FILL BANK DETAILS</h2>
        <div className="border-bottom-line mt-3">
          <p className="mb-2">BANK DETAILS</p>
        </div>
      </div>
      {/* <main className="page-content doctot-detail-user_wrapper"> */}
      <div className="container pb-3">
        <div className="row">
          {!editBtn && !showBtn ? (
            <div className="col-lg-12 text-right px-0 pb-3 mt-4">
              <button
                type="button"
                className="font-weight-bold publish-btn product__publish__btn"
                onClick={() => publishBankDetails("publish")}
              >
                {loader ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "PUBLISH"
                )}
              </button>
            </div>
          ) : !showBtn && editBtn && bankDetails?.bank !== "" ? (
            <div className="col-lg-12 text-right px-0 pb-3 mt-4">
              <button
                type="button"
                className="font-weight-bold publish-btn product__publish__btn"
                onClick={showUpdateAndCancelBtn}
              >
                {loader ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "EDIT"
                )}
              </button>
            </div>
          ) : null}
          {showBtn ? (
            <div className="col-lg-12 text-right px-0 pb-3 mt-4">
              <button
                type="button"
                className="font-weight-bold publish-btn product__publish__btn"
                onClick={cancelBtn}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="font-weight-bold publish-btn product__publish__btn ml-3"
                onClick={() => publishBankDetails("update")}
              >
                UPDATE
              </button>
            </div>
          ) : null}
        </div>
        <div className="row user_details-inner_wrapper pb-4">
          <div className="col-lg-12">
            <div className="row pb-4 py-4">
              <div className="col-12">
                <div className="form-group mb-0">
                  <p className="mb-0 short__title__product font-14 pb-1">
                    Bank
                  </p>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="shortTitleProduct"
                    name="bank"
                    autoComplete="off"
                    onChange={handleBankingDetails}
                    value={bankDetails?.bank || ""}
                    disabled={editBtn}
                  />
                </div>
              </div>
              <div className="col-12 pt-3">
                <p className="mb-0 short__title__product font-14 pb-1">
                  Branch
                </p>
                <input
                  type="text"
                  className="form-control w-100"
                  id="shortTitleProduct"
                  name="branch"
                  autoComplete="off"
                  onChange={handleBankingDetails}
                  value={
                    bankDetails && bankDetails.branch !== ""
                      ? bankDetails.branch
                      : ""
                  }
                  disabled={editBtn}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <label className="short__title__product pb-1 font-14">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="shortTitleProduct"
                  name="country"
                  autoComplete="off"
                  onChange={handleBankingDetails}
                  value={
                    bankDetails && bankDetails.country !== ""
                      ? bankDetails.country
                      : ""
                  }
                  disabled={editBtn}
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6 pt-3 pt-lg-0">
                    <div className="form-group mb-0">
                      <p className="mb-0 short__title__product font-14 pb-1">
                        City
                      </p>
                      <input
                        type="text"
                        className="form-control w-100"
                        id="price"
                        name="city"
                        autoComplete="off"
                        onChange={handleBankingDetails}
                        value={
                          bankDetails && bankDetails.city !== ""
                            ? bankDetails.city
                            : ""
                        }
                        disabled={editBtn}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 pt-3 pt-lg-0">
                    <div className="form-group mb-0mt-4">
                      <p className="mb-0 short__title__product font-14 pb-1">
                        Swift Code
                      </p>
                      <input
                        type="text"
                        className="form-control w-100"
                        id="compareatprice"
                        name="swiftCode"
                        autoComplete="off"
                        onChange={handleBankingDetails}
                        value={
                          bankDetails && bankDetails.swiftCode !== ""
                            ? bankDetails.swiftCode
                            : ""
                        }
                        disabled={editBtn}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
