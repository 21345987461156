import axios from "axios";
import { useEffect, useState } from "react";

const useMeetingDetails = (meetingId: string) => {
  const [meetingDetails, setMeetingDetails] = useState<any>();
  const token = localStorage.getItem("token");
  useEffect(() => {
    getMeetingDetails();
  }, []);

  const getMeetingDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          meetingId: meetingId,
          action: "command",
          command: [
            {
              agent: "getMeetingDetails",
              appName: "selfMaximized",
              folder: "videoSDK",
            },
          ],
        },
        {
          headers: { "x-access-token": `${token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setMeetingDetails(result?.data?.data[0]);
        } else {
          setMeetingDetails([]);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  return meetingDetails;
};

export default useMeetingDetails;
