import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useToken } from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";

const CreateBlog: React.FC = () => {
  const [blogInput, setBlogInput] = useState<any>({ description: "" });
  const [inputValue, setInputValue] = useState<any>("");
  const [blogInputVal, setBlogTitleInputValue] = useState<any>("");
  const [blogAuthorInputVal, setBlogAuthorInputValue] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [blogTitleErrMsg, setBlogTitleErrorMessage] = useState<any>("");
  const [blogAutherErrMsg, setBlogAuthorErrorMessage] = useState<string>("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>([]);
  const [specialistId, setSpecialistId] = useState<any>("");
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [imgSource, setImgSource] = useState<any>("");
  const [imgFile, setImgFile] = useState<any>("");
  const authCtx = useToken();
  const [loader, setLoader] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any>([]);
  const [authorName, setShowAuthorName] = useState<any>(false);
  const navigate = useNavigate();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSource(imageSrc);
        setImgFile(file);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSource("");
        setImgFile(null);
        event.target.files = null;
      }
    }
  };

  const handleChange = (event: any) => {
    const value = event.target.value;

    if (value.length > 40) {
      setErrorMessage("Please enter only up to 40 letters.");
    } else {
      setInputValue(value);
      setErrorMessage("");
    }
  };

  const handleBlogTitle = (event: any) => {
    const value = event.target.value;

    if (value.length > 70) {
      setBlogTitleErrorMessage("Please enter only up to 70 letters.");
    } else {
      setBlogTitleInputValue(value.toUpperCase());
      setBlogTitleErrorMessage("");
    }
  };

  const handleBlogAuthor = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setBlogAuthorInputValue(value.toUpperCase());
    setBlogAuthorErrorMessage("");
    // if (name && value === '') {
    //     setBlogAuthorErrorMessage('Please enter author name.');
    // } else {

    // }
  };

  const handleBlogDetails = (event: any) => {
    const { name, value } = event.target;
    setBlogInput({ ...blogInput, [name]: value });
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
  };

  const handleSpecialistId = (e: any) => {
    const selectedValue = e.target.value;
    if (selectedValue && selectedValue !== "OTHER") {
      const [name, id, uuid] = selectedValue.split(",");
      setSpecialistId(selectedValue);
      setShowAuthorName(false);
    } else {
      setSpecialistId("");
      setShowAuthorName(true);
    }
  };

  const splittedSpecialistId = specialistId.split(",");

  const [name, id, uuid] = specialistId.split(",");
  console.log(name, uuid);
  console.log(blogAuthorInputVal);
  console.log(splittedSpecialistId, "splitted_specialist_id");

  const createBlog = async () => {
    let errorMessage = "";

    if (
      !blogInputVal ||
      !inputValue ||
      !blogInput.description ||
      categoryId.length === 0 ||
      !imgSource ||
      !blogAuthorInputVal ||
      !splittedSpecialistId.length
    ) {
      let anyErrorFound = false;

      if (!blogInputVal) {
        errorMessage += "Blog title is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !inputValue) {
        errorMessage += "Summary is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && categoryId.length === 0) {
        errorMessage += "Category is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !blogInput.description) {
        errorMessage += "Description is mandatory. ";
        anyErrorFound = true;
      }

      if (!anyErrorFound && !imgSource) {
        errorMessage += "Image is mandatory. ";
        anyErrorFound = true;
      }
      if (
        !anyErrorFound &&
        !blogAuthorInputVal &&
        !splittedSpecialistId.length
      ) {
        errorMessage = "Author name is mandatory.";
        anyErrorFound = true;
      }

      if (
        !blogInputVal &&
        !inputValue &&
        !blogInput.description &&
        categoryId.length === 0 &&
        !imgSource &&
        (!blogAuthorInputVal || !splittedSpecialistId.length)
      ) {
        errorMessage = "All fields are mandatory.";
      }
    }

    if (errorMessage === "") {
      // If no error, proceed with your code
    } else {
      return toast.warning(errorMessage);
    }

    setLoader(true);

    await fileUpload(imgFile);

    let imageUrl = "";
    if (fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    } else {
      return;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          title: `${blogInputVal}`,
          description: `${blogInput.description}`,
          image: `${imageUrl}`,
          summary: `${inputValue}`,
          author: `${blogAuthorInputVal ? blogAuthorInputVal : name}`,
          author_uuid: `${blogAuthorInputVal ? "" : uuid}`,
          authorId: `${blogAuthorInputVal ? "" : uuid}`,
          otherAuthor: blogAuthorInputVal ? true : false,
          categoriesId: categoryId,
          handle: "",
          publish: 1,
          metadescription: "",
          metakeyword: "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf5",
          action: "command",
          command: [
            {
              agent: "createBlogs",
              appName: "selfMaximized",
              folder: "blogs",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((response) => {
        toast.success("Blog Created!");
        blogInput.blogTitle = "";
        blogInput.oneLineSummary = "";
        blogInput.description = "";
        setCategoryId([]);
        setImgSource("");
        setImgFile("");
        setFileResponse("");
        setLoader(false);
        navigate("/dashboard/magazine");
      })
      .catch((err) => {
        toast.error(err);
        setLoader(false);
      });
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const getSpecialistList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [
                {
                  field: "role",
                  operator: "eq",
                  value: "specialist",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "specialistLists",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result.data.statusCode === 200) {
          setSpecialistList(result.data.data);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const getCategoryList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "isActive",
                  operator: "eq",
                  value: 1,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        const data = result.data.data;
        setCategoryList(data);
      })
      .catch((err) => {
        toast.error(`Something went wrong, ${err}`);
      });
  };

  const getCatList = useCallback(getCategoryList, [authCtx.token]);
  const getSpecList = useCallback(getSpecialistList, [authCtx.token]);

  // const handleCategoryId = (e: any) => {
  //   if (categoryId.includes(e.target.value)) {
  //     setCategoryId(categoryId.filter((id: any) => id !== e.target.value));
  //   } else {
  //     setCategoryId((prevCatId: any) => [...prevCatId, e.target.value]);
  //   }
  // };

  const handleStyle = (id: any) => {
    if (categoryId.includes(id)) {
      setCategoryId(categoryId.filter((catId: any) => catId !== id));
    } else {
      setCategoryId((catId: any) => [...catId, id]);
    }
  };

  useEffect(() => {
    getCatList();
    getSpecList();
  }, [getCatList, getSpecList]);

  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
              <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                {loader ? (
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn"
                    disabled
                  >
                    <span className="spinner-border spinner-border-sm mr-2 text-dark create__blog--publish"></span>
                    LOADING...
                  </button>
                ) : (
                  <button
                    type="button"
                    className="font-weight-bold publish-btn magazine--btn"
                    onClick={createBlog}
                  >
                    PUBLISH
                  </button>
                )}
              </div>
              <section
                className="tabs-wrapper magazine_wrapper-tabs"
                id="magazine_wrapper-view_more"
              >
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs edit__blog__tab">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="">
                        <span className="material-icons text-uppercase">
                          Create Blog
                        </span>{" "}
                      </label>
                      <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                        <div className="row mb-0">
                          <div className="col-lg-12 px-0">
                            <label
                              htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                              id="blogTitle text-white blog__title--edit"
                              className="text-uppercase pb-0"
                            >
                              Blog Title
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-9 pl-0 pr-0 pr-lg-3">
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                className="form-control w-100"
                                id="blogTitle"
                                name="blogTitle"
                                value={blogInputVal}
                                onChange={handleBlogTitle}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                              <span className="text-danger mt-4 error--message">
                                {blogTitleErrMsg}
                              </span>
                            </div>
                            <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                One line summary
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase"
                                id="oneLineSummary"
                                name="oneLineSummary"
                                value={inputValue}
                                onChange={handleChange}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                              <span className="text-danger mt-4 error--message">
                                {errorMessage}
                              </span>
                            </div>
                            {/* <div className="select-category">
                              <div className="col-lg-6 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  id="blogTitle text-white"
                                  className="text-uppercase pb-0"
                                >
                                  Select category
                                </label>
                                <select
                                  className="select-category_inner p-2 w-50 h-50 text-uppercase"
                                  id="create__blog__select--category"
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    border: "none",
                                    fontSize: "14px",
                                  }}
                                  onClick={handleCategoryId}
                                >
                                  <option disabled selected>
                                    Select Category
                                  </option>
                                  {categoryList.length !== 0 &&
                                  categoryList !== null ? (
                                    categoryList.map((elem: any) => (
                                      <option
                                        className={`font-12 category-inner_box p-2 mb-2 mb-lg-0 ${
                                          categoryId === elem.id
                                            ? ""
                                            : "text-white"
                                        }`}
                                        style={{
                                          color: "white",
                                          display: "inline-block",
                                        }}
                                        key={elem.id}
                                        value={elem.id}
                                        id="createCategory"
                                      >
                                        <p className="text-white">
                                          {elem.name}
                                        </p>
                                      </option>
                                    ))
                                  ) : (
                                    <div className="col-lg-12 text-center">
                                      <span className="text-white font-14 text-uppercase">
                                        No Data Available
                                      </span>
                                    </div>
                                  )}
                                </select>
                              </div>
                            </div> */}
                            <div className="select-category">
                              <div className="col-lg-12 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  className="text-uppercase pb-0"
                                  id="blogTitle text-white"
                                >
                                  Select category
                                </label>
                                <div
                                  className="select-category_inner p-2"
                                  id="edit__blog__select--category"
                                >
                                  {categoryList.length !== 0 &&
                                  categoryList !== null
                                    ? categoryList.map((elem: any) => (
                                        <p
                                          onClick={() => handleStyle(elem.id)}
                                          className={`font-12 category-inner_box p-2 mb-3 mb-lg-0 text-white ${
                                            categoryId.includes(elem.id)
                                              ? "text-dark"
                                              : ""
                                          }`}
                                          style={{
                                            backgroundColor: `${
                                              categoryId.includes(elem.id)
                                                ? "#fff"
                                                : "#4a4a4a"
                                            }`,
                                            display: "inline-block",
                                          }}
                                          key={elem.id}
                                          id="magazineCategory"
                                        >
                                          {elem.name}
                                        </p>
                                      ))
                                    : null}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4 mb-md-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control text-uppercase"
                                id="exampleFormControlTextarea1"
                                rows={4}
                                name="description"
                                value={blogInput.description}
                                onChange={handleBlogDetails}
                                onKeyDown={handleKeyDownEvent}
                              ></textarea>
                            </div>
                            <div className="form-group mb-0 d-flex mb-4">
                              <div className="col-lg-6 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  id="blogTitle text-white"
                                  className="text-uppercase pb-0"
                                >
                                  Select authors
                                </label>
                                <select
                                  className="select-category_inner p-2 w-50 h-50 text-uppercase"
                                  id="create__blog__select--category"
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    border: "none",
                                    fontSize: "14px",
                                  }}
                                  onChange={handleSpecialistId}
                                >
                                  <option disabled selected>
                                    Select authors
                                  </option>

                                  {specialistList.length
                                    ? specialistList.map((elem: any) => (
                                        <option
                                          className={`font-12 category-inner_box p-2 mb-2 mb-lg-0 ${
                                            categoryId === elem.id
                                              ? ""
                                              : "text-white"
                                          }`}
                                          style={{
                                            color: "white",
                                            display: "inline-block",
                                          }}
                                          key={elem.id}
                                          value={`${elem.name},${elem.id},${elem.uuid}`}
                                          id="createCategory"
                                        >
                                          {elem.name.toUpperCase()}
                                        </option>
                                      ))
                                    : null}
                                  <option className="category-inner_box">
                                    OTHER
                                  </option>
                                </select>
                              </div>
                              {authorName && (
                                <div className="col-lg-4">
                                  <label
                                    htmlFor="exampleFormControlTextarea1"
                                    id="blogTitle text-white"
                                    className="text-uppercase pb-0"
                                  >
                                    Author Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control w-100"
                                    id="blogAuthor"
                                    name="blogAuthor"
                                    value={blogAuthorInputVal}
                                    onChange={handleBlogAuthor}
                                    autoComplete="off"
                                    onKeyDown={handleKeyDownEvent}
                                  />
                                  <span className="text-danger mt-4 error--message">
                                    {blogAutherErrMsg}
                                  </span>
                                </div>
                              )}
                              <div className="col-lg-4">
                                <input
                                  type="hidden"
                                  className="form-control w-100"
                                  id="authorId"
                                  name="authorId"
                                  value={specialistId}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 add-blog-wrapper_magazine p-0"
                            onClick={handleTriggerClick}
                          >
                            <div className="add-new-blog--create d-flex justify-content-center align-items-center position-relative">
                              {imgSource ? (
                                <img
                                  src={imgSource}
                                  alt=""
                                  className="magazine__create__blog--image w-100"
                                  style={{ width: "auto", height: "auto" }}
                                />
                              ) : (
                                <Link to={""} className="font-13 text-center">
                                  <svg
                                    className="icon-icon-plus mb-2"
                                    viewBox="0 0 14 14"
                                    height={40}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                    />
                                  </svg>
                                  <p className="add-the-eatured-photos text-uppercase">
                                    Add the featured photos
                                  </p>
                                </Link>
                              )}
                              <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default CreateBlog;
