import React, { useState, useEffect, useContext, useCallback } from "react";
import axios, { AxiosResponse } from "axios";
import { useToken } from "../../../../authContext";
import { useNavigate } from "react-router-dom";
import Shimmer from "../../Shimmer";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";
import { toast } from "react-toastify";

const SpecialistList: React.FC = () => {
  const [modal, setModal] = useState<any>(false);
  const [userId, setUserId] = useState<any>("");
  const [bankUuid, setBankUuid] = useState<any>("");
  const [transactionNo, setTransactionNo] = useState<any>("");
  const [fileResponse, setFileResponse] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any>("");
  const [paginationCount, setPaginationCount] = useState<any>(1);
  const [specialistListCount, setSpecialistListCount] = useState<any>("");
  const [totalPages, setTotalPages] = useState<any>(0);
  const [checkedState, setCheckedState] = useState<any>("active");
  const [payoutRequestlist, setPayoutRequestList] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [transactionMethod, setTransactionMethod] = useState<any>("");
  const authContext = useToken();
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<any>([]);

  const handleSpecialistList = (type: any) => async (event: any) => {
    setPayoutRequestList([]);
    event.preventDefault();
    let filters = [];
    if (type === "active") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 1,
      });
    }

    setFilterType({
      field: "isActive",
      operator: "eq",
      value: 1,
    });
    setCheckedState(type);
    setPaginationCount(1);
    await getSpecialistList(filters, 1);
  };

  const pagination = async (type: any, totalPages: any) => {
    if (type === "next") {
      setPaginationCount((prevState: any) => prevState + 1);
      await getSpecialistList(
        [
          {
            field: "isActive",
            operator: "eq",
            value: 1,
          },
        ],
        paginationCount + 1
      );
    } else if (type === "previous") {
      setPaginationCount((prevState: any) => prevState - 1);
      await getSpecialistList(
        [
          {
            field: "isActive",
            operator: "eq",
            value: 1,
          },
        ],
        paginationCount - 1
      );
    } else if (type === "first") {
      setPaginationCount(1);
      await getSpecialistList(
        [
          {
            field: "isActive",
            operator: "eq",
            value: 1,
          },
        ],
        1
      );
    } else if (type === "last") {
      setPaginationCount(Math.ceil(totalPages));
      await getSpecialistList(
        {
          field: "isActive",
          operator: "eq",
          value: 1,
        },
        Math.ceil(totalPages)
      );
    }
  };

  const getSpecialistList = async (filters: any, offset: any) => {
    setLoader(true);
    const response: AxiosResponse<any> = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: offset ? (offset - 1) * 6 : 0,
            limit: 6,
            filters: filters
              ? filters
              : [{ field: "isActive", operator: "eq", value: 1 }],
            sort: [
              {
                field: "id",
                dir: "desc",
                table: "Users",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authContext.token}` },
      }
    );
    setSpecialistList(response.data.data);
    setSpecialistListCount(response?.data?.totalCount);
    setTotalPages(Math.ceil(response?.data?.totalCount / 6)); // Calculate totalPages based on totalCount directly
    setLoader(false);
  };

  const getList = useCallback(getSpecialistList, [
    authContext.token,
    specialistListCount,
  ]);

  const goToAnalyticsDetailsPage = (
    id: number,
    specialistList: any,
    filterType: any
  ) => {
    navigate(`/dashboard/analytics/${id}`, {
      state: { specialistList, filterType },
    });
  };

  useEffect(() => {
    getList("", "");
  }, []);

  const handlePayoutRequestList = async (type: any) => {
    setSpecialistList([]);
    setCheckedState(type);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            logic: "and",
            offset: 0,
            limit: 500,
            filters: [
              {
                field: "approved",
                table: "LeaderData",
                operator: "eq",
                value: "0",
              },
            ],
            sort: [
              {
                field: "id",
                table: "LeaderData",
                dir: "desc",
              },
            ],
          },
          action: "command",
          command: [
            {
              agent: "leadgar",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setPayoutRequestList(result?.data?.data);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const openModal = (Id: any, uuid: any) => {
    setUserId(Id);
    setBankUuid(uuid);
    setTransactionMethod("");
    setTransactionNo("");
    setFileResponse("");
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    await fileUpload(file);
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authContext.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
  };

  const requestPayout = async (type: any) => {
    if (transactionMethod === "" && transactionNo === "") {
      return toast.warning("Please fill atleast any one field");
    }

    let appOrRej = 0;
    if (type === "accept") {
      appOrRej = 1;
    } else if (type === "reject") {
      appOrRej = 2;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: bankUuid,
          userId: userId,
          approved: appOrRej,
          meta: {
            transactionId: transactionNo,
            transactionProof: fileResponse?.data?.url,
            method: transactionMethod,
          },
          action: "command",
          command: [
            {
              agent: "approvePayout",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then(async (result) => {
        if (result?.data?.statusCode === 200) {
          await closeModal();
          handlePayoutRequestList("activepr");
          return toast.success(result?.data?.message);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const handleTransactionNo = (event: any) => {
    setTransactionNo(event.target.value);
  };

  const handleTransactionMethod = (event: any) => {
    setTransactionMethod(event.target.value);
  };

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper specialists__wrapper categories-wrpper user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row pb-lg-0">
            <div className="col-lg-12 px-0">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs justify-content-sm-between"
                id="pills-tab"
                role="tablist"
              >
                <li
                  className="nav-item"
                  onClick={handleSpecialistList("active")}
                >
                  <span
                    className={`nav-link ${
                      checkedState === "active" ? "active" : ""
                    } mr-4 cursor_pointer`}
                    id="pills-active-tab"
                    data-toggle="pill"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    ACTIVE
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => handlePayoutRequestList("activepr")}
                >
                  <span
                    className={`nav-link ${
                      checkedState === "activepr" ? "active" : ""
                    } mr-4 cursor_pointer`}
                    id="pills-active-tab"
                    data-toggle="pill"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    SPECIALIST PAYOUT REQUESTS
                  </span>
                </li>
              </ul>
              {/* New tab Container */}
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab table-responsive">
                    {payoutRequestlist.length === 0 ? (
                      <table className="table table--lines table--striped active">
                        <thead className="table__header table__header--sticky text-center">
                          <tr>
                            <th className="text-truncate">
                              <span>Name</span>
                            </th>
                            <th className="text-truncate">
                              <span>Gender</span>
                            </th>
                            <th className="text-truncate text-transform-unset">
                              <span>Email- Id</span>
                            </th>
                            <th className="text-truncate">
                              <span>Mobile No.</span>
                            </th>
                            <th className="table__actions text-truncate"></th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {specialistList.length !== 0 &&
                          specialistList !== null ? (
                            specialistList.map((elem: any) => (
                              <tr className="table__row" key={elem.id}>
                                <td className="table__td">
                                  <div className="media-item">
                                    <div className="media-item__right">
                                      <h5 className="media-item__title text-truncate font-14 pl-2 pl-lg-0 text-uppercase">
                                        {elem?.name}
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                                <td className="table__td">
                                  <span className="text-grey text-truncate font-14 text-uppercase">
                                    {elem?.gender}
                                  </span>
                                </td>
                                <td className="table__td">
                                  <span className="text-truncate font-14 text-transform-unset">
                                    {elem?.email}
                                  </span>
                                </td>
                                <td className="table__td">
                                  <span className="text-grey text-truncate font-14">
                                    {elem?.phone}
                                  </span>
                                </td>

                                <td className="table__td table__actions">
                                  <div
                                    className="items-more font-14 user__view--more text-truncate pr-1 pr-md-5 text-uppercase"
                                    onClick={() =>
                                      goToAnalyticsDetailsPage(
                                        elem?.uuid,
                                        elem,
                                        filterType
                                      )
                                    }
                                  >
                                    View
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="row">
                              <div className="col-lg-12">
                                <img
                                  src={noDataFoundImage}
                                  alt=""
                                  className="img-fluid user__all__active__inactive__card--image"
                                  style={{ width: "auto", height: "500px" }}
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <table className="table table--lines table--striped active">
                        <thead className="table__header table__header--sticky text-center">
                          <tr>
                            <th className="text-truncate">
                              <span>Name</span>
                            </th>
                            <th className="text-truncate">
                              <span>Bank Name</span>
                            </th>
                            <th className="text-truncate text-transform-unset">
                              <span>Email- Id</span>
                            </th>
                            <th className="text-truncate">
                              <span>Branch</span>
                            </th>
                            <th className="text-truncate">
                              <span>City/Country</span>
                            </th>
                            <th className="text-truncate">
                              <span>Swift Code</span>
                            </th>
                            <th className="text-truncate">
                              <span>Amount</span>
                            </th>
                            <th className="text-truncate">
                              <span>Status</span>
                            </th>
                            <th className="text-truncate">
                              <span>Approve Request</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {payoutRequestlist.length !== 0 &&
                          payoutRequestlist !== null ? (
                            payoutRequestlist.map((elem: any) => (
                              <tr className="table__row" key={elem.id}>
                                <td className="table__td">
                                  <div className="media-item">
                                    <div className="media-item__right">
                                      <h5 className="media-item__title text-truncate font-14 pl-2 pl-lg-0">
                                        {elem?.name}
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                                <td className="table__td">
                                  <span className="text-grey text-truncate font-14">
                                    {elem?.bank}
                                  </span>
                                </td>
                                <td className="table__td">
                                  <span className="text-truncate font-14 text-transform-unset">
                                    {elem?.email}
                                  </span>
                                </td>
                                <td className="table__td">
                                  <span className="text-grey text-truncate font-14">
                                    {elem?.branch}
                                  </span>
                                </td>
                                <td className="table__td">
                                  <span className="text-grey text-truncate font-14">
                                    {elem?.city}/{elem?.country}
                                  </span>
                                </td>
                                <td className="table__td table__actions">
                                  <span className="text-grey text-truncate font-14">
                                    {elem?.swiftCode}
                                  </span>
                                </td>
                                <td className="table__td table__actions text-center">
                                  <span className="text-grey text-truncate font-14 ">
                                    {elem?.amount}
                                  </span>
                                </td>
                                <td className="table__td table__actions">
                                  <span className="text-grey text-truncate font-14">
                                    <span
                                      className={`badge badge-${
                                        elem?.approved === 2
                                          ? "danger"
                                          : elem?.approved === 1
                                          ? "success"
                                          : "primary"
                                      } p-1 text-white`}
                                    >
                                      {elem?.approved === 2
                                        ? "REJECTED"
                                        : elem?.approved === 1
                                        ? "APPROVED"
                                        : "INIT"}
                                    </span>
                                  </span>
                                </td>
                                <td className="table__td table__actions text-center">
                                  <span className="text-grey text-truncate font-14">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="2em"
                                      height="2em"
                                      viewBox="0 0 18 18"
                                      className="pl-2 cursor_pointer "
                                      onClick={() =>
                                        openModal(elem?.userID, elem?.uuid)
                                      }
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M9.5 13a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0"
                                      />
                                    </svg>
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <div className="row">
                              <div className="col-lg-12">
                                <img
                                  src={noDataFoundImage}
                                  alt=""
                                  className="img-fluid user__all__active__inactive__card--image"
                                  style={{ width: "auto", height: "500px" }}
                                  loading="lazy"
                                />
                              </div>
                            </div>
                          )}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {specialistList.length !== 0 ? (
            <div className="row">
              <div className="col-lg-12 text-right px-0 my-3">
                <nav
                  aria-label="Page navigation example"
                  className="pagination__card"
                >
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" aria-label="Previous">
                        <button
                          aria-hidden="true"
                          className={`${
                            paginationCount === 1
                              ? "next__arrow--icon"
                              : "text-white"
                          }`}
                          onClick={() => pagination("first", "")}
                          disabled={paginationCount === 1}
                        >
                          &laquo;
                        </button>
                        <button
                          className={`${
                            paginationCount === 1
                              ? "next__arrow--icon"
                              : "text-white"
                          }  sr-only`}
                          onClick={() => pagination("previous", "")}
                          disabled={paginationCount === 1}
                        >
                          Previous
                        </button>
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className={`${
                          paginationCount === 1
                            ? "next__arrow--icon"
                            : "text-white next--page"
                        }  page-link previous--page `}
                        onClick={() => pagination("previous", "")}
                        disabled={paginationCount === 1}
                      >
                        Previous
                      </button>
                    </li>
                    <li className="page-item">
                      <button
                        className={`${
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                            ? "next--btn"
                            : "text-white next--page"
                        } page-link `}
                        onClick={() => pagination("next", "")}
                        disabled={
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                        }
                      >
                        Next
                      </button>
                    </li>
                    <li className="page-item">
                      <button className="page-link" aria-label="Next">
                        <button
                          aria-hidden="true"
                          className={`${
                            paginationCount === Math.ceil(totalPages) ||
                            specialistList.length < 6
                              ? "next__arrow--icon"
                              : "text-white"
                          }  `}
                          onClick={() =>
                            pagination("last", specialistListCount / 6)
                          }
                          disabled={
                            paginationCount === Math.ceil(totalPages) ||
                            specialistList.length < 6
                          }
                        >
                          &raquo;
                        </button>
                        <button
                          className={`${
                            paginationCount === Math.ceil(totalPages) ||
                            specialistList.length < 6
                              ? "next--btn"
                              : "text-white"
                          }  sr-only`}
                          onClick={() => pagination("next", "")}
                          disabled={
                            paginationCount === Math.ceil(totalPages) ||
                            specialistList.length < 6
                          }
                        >
                          Next
                        </button>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          ) : null}

          {modal ? (
            <div
              style={{
                zIndex: "9999999",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#707070",
                opacity: 1,
                justifyContent: "center",
              }}
            >
              <div className="modal-dialog modal__dialog--wrapper">
                <div className="modal-content payout__pop--wrapper py-3">
                  <div className="modal-header">
                    <h4 className="text-dark payout--proof mb-0">
                      Payout Proof
                    </h4>
                    <button
                      type="button"
                      className="close payout__close--btn"
                      onClick={closeModal}
                    >
                      &times;
                    </button>
                  </div>

                  <div className="modal-body pb-0">
                    <input
                      type="number"
                      placeholder="Enter transaction id"
                      className="transaction__id__method mb-3"
                      value={transactionNo}
                      onChange={handleTransactionNo}
                    />
                    <input
                      type="text"
                      placeholder="Enter transaction method"
                      className="transaction__id__method"
                      value={transactionMethod}
                      onChange={handleTransactionMethod}
                    />
                    <input
                      type="file"
                      className="no__file--chosen mt-3"
                      accept=".pdf,.png,.jpg,.jpeg"
                      onChange={handleFileChange}
                    />
                  </div>

                  <div className="modal-footer d-flex">
                    <button
                      type="button"
                      className="approve__reject--btn"
                      onClick={() => requestPayout("accept")}
                    >
                      APPROVE
                    </button>
                    <button
                      type="button"
                      className="approve__reject--btn"
                      onClick={() => requestPayout("reject")}
                    >
                      REJECT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
};

export default SpecialistList;
