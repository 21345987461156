import { useEffect, useState } from "react";
import { useToken } from "../../authContext";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import Shimmer from "../../pages/AdminDashboard/Shimmer";

const MySubscription = () => {
  const [subscriptionList, setSubscriptionList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const authContext = useToken();
  const userInfo = localStorage.getItem("userInfo");

  useEffect(() => {
    if (!authContext?.token) return;
    getSubscriptionList();
  }, [authContext.token]);

  const parsedUserInfo =
    typeof userInfo === "string" ? JSON.parse(userInfo) : "";

  const getSubscriptionList = async () => {
    try {
      setLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            filter: {
              filter: {
                logic: "and",
                offset: 0,
                limit: 5,
                filters: [
                  {
                    field: "userId",
                    table: "ss",
                    operator: "eq",
                    value: parsedUserInfo?.id,
                  },
                ],
                sort: [
                  {
                    field: "userId",
                    dir: "desc",
                  },
                ],
              },
            },
            action: "command",
            command: [
              {
                agent: "userSubscriptionLists",
                appName: "selfMaximized",
                folder: "order",
              },
            ],
          },
          { headers: { "x-access-token": authContext.token } }
        )
        .then((res: any) => {
          if (res.data.statusCode === 200) {
            setSubscriptionList(res.data.data.response);
          }
        });
    } catch (error) {
      console.log(error, false);
    } finally {
      setLoading(false);
    }
  };
  console.log("Response...", subscriptionList);

  return loading ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content categories-wrpper user_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive user__all__active__inactive__card">
                <table className="table table--lines table--striped">
                  <thead className="table__header table__header--sticky text-center">
                    <tr>
                      <th className="col-2">
                        <span className="text-truncate">DOCTOR NAME</span>
                      </th>
                      <th className="col-2">
                        <span className="text-truncate">
                          NUMBER OF APPOINTMENTS
                        </span>
                      </th>
                      <th className="col-2">
                        <span className="text-truncate">START DATE</span>
                      </th>
                      <th className="col-2">
                        <span className="text-truncate">CURRENCY</span>
                      </th>
                      <th className="col-2">
                        <span className="text-truncate">AMOUNT</span>
                      </th>

                      <th className="col-2">
                        <span>Status</span>
                      </th>
                      <th className="col-2">{/* <span>RECURRING</span> */}</th>

                      {/* <th className="table__actions col-2"></th> */}
                    </tr>
                  </thead>

                  <tbody className="text-center">
                    {subscriptionList && subscriptionList?.length > 0 ? (
                      subscriptionList?.map((item, index) => (
                        <>
                          <tr className="table__row" key={index}>
                            <td className="table__td">
                              <div className="media-item">
                                <div className="media-item__right">
                                  <h5 className="media-item__title text-truncate font-14 pl-3 text-uppercase">
                                    {item?.name}
                                  </h5>
                                </div>
                              </div>
                            </td>
                            <td className="table__td">
                              <span className="text-truncate font-14 text-transforn-unset">
                                {item?.numberOfAppointments}
                              </span>
                            </td>
                            <td className="table__td">
                              <span className="text-grey text-truncate font-14">
                                {moment(item?.startDate).format("YYYY-MM-DD")}
                              </span>
                            </td>
                            <td className="table__td">
                              <span className="text-grey text-truncate font-14">
                                {item?.subscriptionData?.currency}
                              </span>
                            </td>
                            <td className="table__td">
                              <span className="text-grey text-truncate font-14">
                                {item?.subscriptionData?.plan?.amount
                                  ? (
                                      item?.subscriptionData?.plan?.amount / 100
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    })
                                  : "$0.00"}
                              </span>
                            </td>
                            <td className="table__td table__actions text-center">
                              <div className="items-more font-14 d-flex justify-content-center user__view--more text-truncate text-uppercase">
                                <span
                                  className={`${
                                    item?.isActive ? "bg-success" : "bg-danger"
                                  } text-white status__active__inactive`}
                                >
                                  {item?.isActive === 1 ? "Active" : "Inactive"}
                                </span>
                              </div>
                            </td>
                            <td className="table__td table__actions text-center">
                              <div className="items-more font-14 user__view--more text-truncate text-uppercase">
                                <Link
                                  to={"/user_subscription_details"}
                                  state={item}
                                >
                                  VIEW
                                </Link>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <div className="text-white">
                          <p className="mb-0 no__data_available">
                            NO DATA AVAILABLE
                          </p>
                        </div>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MySubscription;
