import React from "react";
import "../Dashboard.css";

interface SalesBreakdownData {
  totalGrossSales: string;
  totalSalesWithoutTax: string;
  totalDiscountAmount: string;
  totalTaxAmount: string; // not currently displayed in your UI, but available if needed
}

interface SalesBreakdownProps {
  data?: SalesBreakdownData[]; // The array from getTotalSalesBreakDown
}

const SalesBreakdown: React.FC<SalesBreakdownProps> = ({ data }) => {
  // If no data provided or empty array, render fallback (or you could return null)
  if (!data || data.length === 0) {
    return (
      <div className="sales-breakdown">
        <h3 className="breakdown-title">TOTAL SALES BREAKDOWN</h3>
        <p>No breakdown data found</p>
      </div>
    );
  }

  // We’ll destructure the first item (since your example only has one object)
  const {
    totalGrossSales = "0.00",
    totalDiscountAmount = "0.00",
    totalSalesWithoutTax = "0.00",
    totalTaxAmount = "0.00",
  } = data[0] || {};

  // Convert strings to numbers if desired, or just display them.
  // You can also do parseFloat(...) if you want to format further.

  return (
    <div className="sales-breakdown chart-card-large total-sales-over-time">
      <h3 className="breakdown-title">TOTAL SALES BREAKDOWN</h3>
      <div className="breakdown-items">
        <div className="breakdown-item">
          <span>GROSS SALES</span>
          <span>${parseFloat(totalGrossSales).toLocaleString()}</span>
        </div>
        <div className="breakdown-item">
          <span>DISCOUNTS</span>
          <span>${parseFloat(totalDiscountAmount).toLocaleString()}</span>
        </div>
        {/* <div className="breakdown-item">
          <span>RETURNS</span>

          <span>$0.00</span>
        </div> */}
        <div className="breakdown-item">
          <span>NET SALES</span>
          <span>${parseFloat(totalSalesWithoutTax).toLocaleString()}</span>
        </div>
        {/* <div className="breakdown-item">
          <span>RETURN FEES</span>

          <span>$0.00</span>
        </div> */}
        <div className="breakdown-item">
          <span>TAXES</span>
          <span>${parseFloat(totalTaxAmount).toLocaleString()}</span>
        </div>{" "}
        {/* <div className="breakdown-item">
          <span>TOTAL SALES</span>

          <span>$0.00</span>
        </div> */}
      </div>
    </div>
  );
};

export default SalesBreakdown;
