import React, { useCallback, useEffect, useState } from "react";
import DummyImage from "../../card.png";
import axios from "axios";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import Shimmer from "../../pages/AdminDashboard/Shimmer";

const MyBooking: React.FC = () => {
  const authContext = useToken();

  const userInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;

  const [userWiseCategories, setUserWiseCategories] = useState<any>([]);
  const [specialistList, setSpecialistList] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>("");
  const [categoryName, setCategoryName] = useState<any>("");
  const [appointmentList, setUserAppointmentList] = useState<any>([]);
  const [days, setDays] = useState<any>([]);
  const [varDayName, setVarDayName] = useState<any>("");
  const [slots, setSlots] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);

  const getBookedCategoriesUserWise = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 10,
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          userId: userInfo.id,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userCategories",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setUserWiseCategories(result?.data?.data?.categoriesData);
          setLoader(false);
        } else {
          setUserWiseCategories([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getBookedCategoriesUserWiseCB = useCallback(
    getBookedCategoriesUserWise,
    [authContext.token]
  );

  useEffect(() => {
    getBookedCategoriesUserWiseCB();
  }, [getBookedCategoriesUserWiseCB]);

  const getSpecialistCatWise = async (categoryId: any, categoryName: any) => {
    setCategoryId(categoryId);
    setCategoryName(categoryName);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 10,
              sort: [
                {
                  field: "doctorId",
                  table: "CategoriesData",
                  dir: "desc",
                },
              ],
            },
          },
          categoriesId: categoryId,
          userId: userInfo.id,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userCategoriesOrderData",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result.data.code === 1) {
          setSpecialistList(result?.data?.data?.categoriesData);
        } else {
          setSpecialistList([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    if (userWiseCategories.length > 0 && userWiseCategories[0]?.id) {
      getSpecialistCatWise(
        userWiseCategories[0].id,
        userWiseCategories[0].name
      );
    }
  }, [userWiseCategories]);

  useEffect(() => {
    const getWeekDays = () => {
      const daysArray = [];
      const today = new Date();
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay()); // Start of the current week

      for (let i = 0; i < 7; i++) {
        const day = new Date(startOfWeek);
        day.setDate(startOfWeek.getDate() + i);
        daysArray.push(day);
      }

      return daysArray;
    };

    setDays(getWeekDays());
  }, []);

  const getUserAppointmentList = async () => {
    const currentDate = new Date();
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 5,
              filters: [
                {
                  field: "calendarDate",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: "2024-10-23",
                },
                {
                  field: "uuid",
                  table: "Users",
                  operator: "eq",
                  value: userInfo.uuid,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userBooking",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setUserAppointmentList(result?.data?.data);
          setLoader(false);
        } else {
          setUserAppointmentList([]);
          setLoader(false);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        setLoader(false);
        return toast.error("Something went wrong", err);
      });
  };

  const getUserAppointmentListCB = useCallback(getUserAppointmentList, [
    authContext.token,
  ]);

  useEffect(() => {
    getUserAppointmentListCB();
  }, [getUserAppointmentListCB]);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDate = new Date();
  const currentDayName = daysOfWeek[currentDate.getDay()];

  const getSlotsAccordingToDay = async (date: any) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const currentDate = new Date(date);
    const varDayName = daysOfWeek[currentDate.getDay()];
    setVarDayName(varDayName);
    await getCalendarListAccordingToDay(date);
  };

  const getCalendarListAccordingToDay = async (selectedDate: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: [
                {
                  field: "calendarDate",
                  operator: "eq",
                  table: "BookingCalendar",
                  value: "2024-10-23",
                },
                {
                  field: "uuid",
                  table: "Users",
                  operator: "eq",
                  value: userInfo.uuid,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userBooking",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setSlots(result?.data?.data);
        } else {
          setSlots([]);
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content categories-wrpper user_wrapper">
        <div className="container pt-3 pt-lg-0">
          <div className="row equal-height-row">
            <div className="col-lg-6 px-0">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs"
                id="pills-tab-one"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="nav-link active px-0 mr-4"
                    id="pills-home-tab"
                    data-toggle="pill"
                    href="#pills-home"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    SPECIALISTS
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link px-0 mr-4"
                    id="pills-profile-tab"
                    data-toggle="pill"
                    href="#pills-profile"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    PACKAGES
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  <div className="container-fluid px-0">
                    <div className="row pb-4">
                      <div className="col-lg-12 pt-3 pb-4">
                        <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium ">
                          CATEGORY
                        </h1>
                      </div>

                      {userWiseCategories.length !== 0 &&
                      userWiseCategories !== null ? (
                        userWiseCategories.map((elem: any) => (
                          <div
                            className="col-lg-3 col-md-6 col-6 position-relative pb-3"
                            key={elem.id}
                            onClick={() =>
                              getSpecialistCatWise(elem.id, elem.name)
                            }
                          >
                            <div
                              className={`wellness_catagory_card ${
                                categoryId === elem.id
                                  ? "wellness_catagory_card--active"
                                  : ""
                              } position-relative`}
                            >
                              <div className="explore__our__top__interacting__image__block">
                                <img
                                  src={elem.image}
                                  alt=""
                                  className="img-fluid wellness_catagory_card_image w-100"
                                  style={{ width: "auto", height: "auto" }}
                                  loading="lazy"
                                />
                              </div>
                              <div className="explore__our__top__interacting__content w-100">
                                <h2 className="mb-0 explore__our__top__interacting__title font-12 text-center">
                                  {elem.name.toUpperCase()}
                                </h2>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="col-lg-12 text-center mb-3">
                          <span className="not__available">
                            No bookings available
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <div className="my__bookings__left__second__card p-3 mx-3">
                          <div className="row">
                            {specialistList[0] && specialistList !== null ? (
                              <>
                                <div className="col-lg-4 col-md-6 col-6 pb-3 pb-lg-0">
                                  <div className="my__bookings__left__card__image">
                                    <img
                                      src={
                                        specialistList.length !== 0
                                          ? specialistList[0].profile
                                          : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                                      }
                                      alt=""
                                      className="img-fluid w-100 my__bookings__left__card__img"
                                      style={{ width: "auto", height: "auto" }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-8 col-md-6 col-6">
                                  <div className="col-lg-12 px-0 pb-3">
                                    <p className="mb-0 medium--font my__bookings__left__second__card__header">
                                      {specialistList.length !== 0
                                        ? specialistList[0].areaofexpertise.toUpperCase()
                                        : 0}
                                    </p>
                                  </div>
                                  <div className="d-none d-lg-block">
                                    <div className="row">
                                      <div className="col-lg-12 table-responsive specialist__details">
                                        <table className="table mb-0 table-bordered w-100">
                                          <tbody>
                                            <tr>
                                              <td className="align-top max-width-130">
                                                <p className="mb-0 details_title font-12  specialist-user_details">
                                                  Name :
                                                </p>
                                              </td>
                                              <td>
                                                <p className="mb-0 details_title font-12 text-left my__booking--name">
                                                  {specialistList.length !== 0
                                                    ? specialistList[0].name.toUpperCase()
                                                    : 0}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="align-top max-width-130">
                                                <p className="mb-0 details_title font-12  specialist-user_details">
                                                  Years of experience :
                                                </p>
                                              </td>
                                              <td>
                                                <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                                  {specialistList.length !== 0
                                                    ? specialistList[0].yearofexperience.toUpperCase()
                                                    : 0}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="align-top max-width-130">
                                                <p className="mb-0 details_title font-12  specialist-user_details">
                                                  Language :
                                                </p>
                                              </td>
                                              <td>
                                                <p className="mb-0 details_title font-12  text-left my__booking--name">
                                                  {specialistList.length !== 0
                                                    ? specialistList[0].language.toUpperCase()
                                                    : 0}
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="col-lg-12 mb-3 text-center d-flex justify-content-center">
                                <span className="not__available">
                                  No specialists available
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  <div className="container-fluid px-0">
                    <div className="row pb-4">
                      <div className="col-lg-12 pt-3 pb-4">
                        <h1 className="mb-0 explore__our__top__interacting__header font-weight-bold font__extra__medium ">
                          FITNESS PACKAGE
                        </h1>
                      </div>
                      <div className="col-lg-3 col-md-6 col-6 position-relative pb-4 pb-lg-0">
                        <div className="wellness_catagory_card wellness_catagory_card--active position-relative">
                          <div className="explore__our__top__interacting__image__block">
                            <img
                              src={DummyImage}
                              alt=""
                              className="img-fluid wellness_catagory_card_image w-100"
                              style={{ width: "auto", height: "auto" }}
                              loading="lazy"
                            />
                          </div>
                          <div className="explore__our__top__interacting__content w-100">
                            <h2 className="mb-0 explore__our__top__interacting__title extra--small text-center">
                              MENTAL HEALTH
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 px-0">
                        <div className="my__bookings__left__second__card p-3 mx-3">
                          <div className="row">
                            <div className="col-lg-4 col-md-6 col-6 pb-3 pb-lg-0">
                              <div className="my__bookings__left__card__image">
                                <img
                                  src={DummyImage}
                                  alt=""
                                  className="img-fluid w-100 my__bookings__left__card__img"
                                  style={{ width: "auto", height: "auto" }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-6">
                              <div className="col-lg-12 px-0 pb-3">
                                <p className="mb-0 medium--font my__bookings__left__second__card__header">
                                  BIOPSYCHOLOGY | COGNITIVE PSYCHOLOGY |
                                  COUNSELING PSYCHOLOGY
                                </p>
                              </div>
                              <div className="d-none d-lg-block">
                                <div className="row">
                                  <div className="col-lg-12 table-responsive specialist__details">
                                    <table className="table mb-0 table-bordered w-100">
                                      <tbody>
                                        <tr>
                                          <td className="align-top max-width-130">
                                            <p className="mb-0 details_title font-12  specialist-user_details">
                                              Name :
                                            </p>
                                          </td>
                                          <td>
                                            <p className="mb-0 details_title font-12 text-left my__booking--name">
                                              Tony Stark Sangli Tasgaon
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="align-top max-width-130">
                                            <p className="mb-0 details_title font-12  specialist-user_details">
                                              Years of experience :
                                            </p>
                                          </td>
                                          <td>
                                            <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                              10
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="align-top max-width-130">
                                            <p className="mb-0 details_title font-12  specialist-user_details">
                                              Language :
                                            </p>
                                          </td>
                                          <td>
                                            <p className="mb-0 details_title font-12  text-left my__booking--name">
                                              Marathi
                                            </p>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 d-block d-lg-none">
                              <div className="table-responsive specialist__details">
                                <table className="table mb-0 table-bordered w-100">
                                  <tbody>
                                    <tr>
                                      <td className="align-top max-width-130">
                                        <p className="mb-0 details_title font-12  specialist-user_details">
                                          Name :
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0 details_title font-12 text-left my__booking--name">
                                          Tony Stark Sangli
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="align-top max-width-130">
                                        <p className="mb-0 details_title font-12  specialist-user_details">
                                          Years of experience :
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0 details_title font-12 pb-1 text-left my__booking--name">
                                          10
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="align-top max-width-130">
                                        <p className="mb-0 details_title font-12 specialist-user_details">
                                          Language :
                                        </p>
                                      </td>
                                      <td>
                                        <p className="mb-0 details_title font-12 text-left my__booking--name">
                                          Marathi
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <div className="specialist-container_wrapper-right-top px-3 pb-3 mb-3 h-100">
                <div className="row align-items-center py-2">
                  <div className="col-6">
                    <p className="today font-14 mb-0">Today</p>
                  </div>
                  <div className="col-6">
                    <p className="days-a-week mb-0 font-12 d-flex align-items-center justify-content-end">
                      <span className="days pr-2 font-weight-bold">07</span>days
                      a week
                    </p>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-lg-12">
                    <p className="time-slots mb-0 font-12">
                      Slots available today
                    </p>
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-lg-12 days-wrapper">
                    <div className="days-wrapper-inner d-flex justify-content-between align-items-center ">
                      {days.map((day: any, index: any) => (
                        <li
                          key={index}
                          className={`days-show text ${
                            day
                              .toLocaleDateString("en-US", { weekday: "long" })
                              .slice(0, 3) ===
                            (varDayName
                              ? varDayName.slice(0, 3)
                              : currentDayName.slice(0, 3))
                              ? "active"
                              : ""
                          }`}
                          onClick={() =>
                            getSlotsAccordingToDay(
                              `${day.getFullYear()}-${(day.getMonth() + 1)
                                .toString()
                                .padStart(2, "0")}-${day
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`
                            )
                          }
                        >
                          {day
                            .toLocaleDateString("en-US", { weekday: "long" })
                            .slice(0, 3)}
                        </li>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row">
                  {slots.length !== 0 && slots !== null ? (
                    slots.map((elem: any) => (
                      <div className="col-lg-6 col-md-6 col-6 pb-4">
                        <div className="available-wrapper p-2 pl-3 ">
                          <div className="d-flex justify-content-between">
                            <p className="available-heading font-12 mb-0">
                              Booked
                            </p>
                            <p className="available-heading font-12 mb-0">
                              {elem.calendarDate}
                            </p>
                          </div>
                          <p className="available-heading font-12 mb-0">
                            DR. {elem.dname.toUpperCase()}
                          </p>
                          <p className="mb-0 font-16 font-weight-normal">
                            <span className="font-weight-bold">
                              {elem.startTime} - {elem.endTime}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-lg-12 text-center">
                      <span className="not__available">No slots available</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/*----*/}
          </div>
          {/* ----------My Booking List----- */}
          <div className="row pt-3">
            <div className="col-lg-12 px-0">
              <div className="tab table-responsive">
                <table className="table table--lines table--striped ">
                  <colgroup>
                    <col className="colgroup-1" />
                    <col className="colgroup-2" />
                    <col className="colgroup-3" />
                    <col className="colgroup-4" />
                    <col className="colgroup-5" />
                    <col className="colgroup-6" />
                    <col />
                  </colgroup>
                  <thead className="table__header table__header--sticky text-center">
                    <tr>
                      <th className="text-truncate">
                        <span>Specialist</span>
                      </th>
                      <th className="text-truncate">
                        <span>Duration</span>
                      </th>
                      <th className="text-truncate">
                        <span>Total Amount Spent</span>
                      </th>
                      <th className="text-truncate">
                        <span>Phone No</span>
                      </th>
                      <th className="text-truncate">
                        <span>Email</span>
                      </th>
                      <th className="text-truncate">
                        <span>Wellness Category</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className={`w-100 ${
                      specialistList.length === 0 || specialistList !== null
                        ? "no_data"
                        : ""
                    }`}
                  >
                    {specialistList.length !== 0 && specialistList !== null ? (
                      specialistList.map((elem: any) => (
                        <tr className="table__row" key={elem.id}>
                          <td className="table__td">
                            <div className="media-item">
                              <div className="media-item__right">
                                <h5 className="media-item__title text-truncate font-14 text-truncate pl-3">
                                  {elem.name.toUpperCase()}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-grey text-truncate font-14">
                              {elem.duration} HR Meeting
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-truncate font-14">
                              {elem.grandTotal}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <span className="text-grey text-truncate font-14">
                              {elem.phone}
                            </span>
                          </td>
                          <td className="table__td text-truncate">
                            <div className="table__status text-truncate font-14">
                              {elem.email}
                            </div>
                          </td>
                          <td className="table__td table__actions text-center">
                            <div className="items-more font-14 user__view--more text-truncate">
                              <span>{categoryName}</span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <span className="not__available mt-3">
                        No booking data available
                      </span>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MyBooking;
