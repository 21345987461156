import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../../authContext";
import { toast } from "react-toastify";
import useBroadCatList from "../../../../customHooks/useBroadCatList";

const EditPackage: React.FC = () => {
  const id = useParams();
  const [loader, setLoader] = useState<any>("");
  const authContext = useToken();
  const [packageId, setPackages] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryList, setPackageList] = useState<any>([]);
  const [packageData, setPackageData] = useState<any>("");
  const [packageImg, setPackageImg] = useState<any>("");
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [imgSource, setImgSource] = useState<any>("");
  const [imgFile, setImgFile] = useState<any>("");
  const [isActive, setIsActive] = useState<any>(
    packageData && packageData.publish === 1 ? true : false
  );
  const [fileLoader, setFileLoader] = useState<any>(false);
  const navigate = useNavigate();
  const broadCategoryList = useBroadCatList();

  const handlePackageDetails = (event: any) => {
    const { name, value } = event.target;
    let limitedValue = value;

    if (name === "summary" && value.length > 40) {
      limitedValue = value.slice(0, 40); // Limiting the input value to 40 characters
      setErrorMessage("Please enter only up to 40 letters.");
    } else {
      setErrorMessage("");
    }

    setPackageData({
      ...packageData,
      [name]:
        name === "title"
          ? value.toUpperCase()
          : name === "broadCategory"
          ? value.toUpperCase()
          : limitedValue,
    });
  };

  console.log(packageData);

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    setIsActive(packageData.publish === 1 ? true : false);
  }, [packageData.publish]);

  const handleFileChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSource(imageSrc);
        setImgFile(file);
        setFileLoader(true);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSource("");
        setImgFile(null);
        event.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authContext.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
    setFileLoader(false);
  };

  const editPackage = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 1,
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: 1,
                },
                {
                  field: "uuid",
                  operator: "eq",
                  value: `${id.id}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "packageList",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setPackageData(result?.data?.data[0]);
        setPackageImg(result?.data?.data[0].image);
        const packageIds: any = [];
        result?.data?.data[0].category.map((elem: any) =>
          packageIds.push(elem.categoryid)
        );
        setPackages(packageIds);
      })
      .catch((err) => {
        toast.error("Something went wrong," + err);
      });
  };

  console.log(packageData);

  const editPackageCallback = useCallback(editPackage, [
    authContext?.token,
    id?.id,
  ]);

  const getCategoriesNameList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setPackageList(result?.data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong," + err);
      });
  };

  const categoryListCallback = useCallback(getCategoriesNameList, [
    authContext.token,
  ]);

  const handleStyle = (id: any) => {
    if (packageId.includes(id)) {
      setPackages(packageId.filter((elemId: any) => elemId !== id));
    } else {
      setPackages([...packageId, id]);
    }
  };

  useEffect(() => {
    editPackageCallback();
    categoryListCallback();
  }, [editPackageCallback, categoryListCallback]);

  const updatePackage = async () => {
    if (imgSource) {
      await fileUpload(imgFile);
    }

    let imageUrl = "";
    if (imgSource && fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    }

    let errorMessage = "";

    if (!packageImg && !imgSource) {
      errorMessage += "Image is mandatory. ";
      return toast.warning(errorMessage);
    }

    const existBroadCategory = packageData?.broadCategory
      ? packageData?.broadCategory
      : packageData?.broad_category_id;

    if (
      !packageData.title ||
      !packageData.description ||
      !packageData.summary ||
      !existBroadCategory ||
      packageId.length === 0
    ) {
      if (!packageData.title) {
        errorMessage += "Package title is mandatory. ";
      }

      if (!packageData.summary) {
        errorMessage += "Summary is mandatory. ";
      }

      if (!packageData.description) {
        errorMessage += "Description is mandatory. ";
      }
      if (packageId.length === 0) {
        errorMessage += "Select category. ";
      }

      if (!existBroadCategory) {
        errorMessage += "Broad category is mandatory. ";
      }

      if (
        !packageData.title &&
        !imgSource &&
        packageId.length === 0 &&
        !packageData.description &&
        !packageImg &&
        !packageData.summary &&
        !existBroadCategory
      ) {
        errorMessage = "All fields are mandatory.";
      }

      if (packageData.title) {
        if (
          !packageData.description &&
          packageId.length === 0 &&
          !imgSource &&
          !packageImg &&
          !packageData.summary &&
          !existBroadCategory
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (packageData.description) {
        if (
          !packageData.title &&
          packageId.length === 0 &&
          !imgSource &&
          !packageImg &&
          !packageData.summary &&
          !existBroadCategory
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (packageData.summary) {
        if (
          !packageData.title &&
          !packageData.description &&
          !imgSource &&
          !packageImg &&
          packageId.length === 0 &&
          !existBroadCategory
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (categoryList.length !== 0) {
        if (
          !packageData.title &&
          !packageData.description &&
          !imgSource &&
          !packageImg &&
          !packageData.summary &&
          !existBroadCategory
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (imgSource || packageImg) {
        if (
          !packageData.title &&
          !packageData.description &&
          packageId.length === 0 &&
          !packageData.summary &&
          !existBroadCategory
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (existBroadCategory) {
        if (
          !packageData.title &&
          !packageData.description &&
          packageId.length === 0 &&
          !packageData.summary &&
          !imgSource &&
          !packageImg
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      return toast.warning(errorMessage);
    }

    setLoader(true);

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: `${id.id}`,
          title: `${packageData.title}`,
          description: `${packageData.description}`,
          image: `${imageUrl || packageImg}`,
          summary: `${packageData.summary}`,
          duration: `${packageData.duration}`,
          categoriesId: packageId,
          publish: isActive ? 1 : 0,
          price: 0,
          broad_category_id: Number(
            packageData?.broadCategory
              ? packageData?.broadCategory
              : packageData?.broad_category_id
          ),
          metadescription: "",
          metakeyword: "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf8",
          action: "command",
          command: [
            {
              agent: "updatePackage",
              appName: "selfMaximized",
              folder: "packages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        toast.success(
          `${
            isActive
              ? "Blog edited successfully!"
              : "Blog deactivated successfully!"
          }`
        );
        navigate("/dashboard/packages");
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err);
      });
  };

  const deleteImage = () => {
    setImgSource("");
    setPackageImg("");
    setFileLoader(false);
    setFileResponse([]);
  };

  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
              <div>
                <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                  {loader ? (
                    <button
                      type="button"
                      className="font-weight-bold publish-btn magazine--btn active__btn--edit"
                      disabled
                    >
                      <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                      LOADING...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="font-weight-bold publish-btn magazine--btn active__btn--edit"
                      onClick={updatePackage}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-end active__inactive--switch">
                  <label
                    className={`switch publish-btn magazine--btn ${
                      isActive ? "active" : "inactive"
                    }`}
                  >
                    <input
                      type="checkbox"
                      onChange={toggleSwitch}
                      checked={isActive}
                    />
                    <span className="slider round"></span>
                    <span
                      className={`status ${
                        isActive ? "mr-4" : "ml-4 text-dark"
                      }`}
                    >
                      {isActive ? "Active" : "Inactive"}
                    </span>
                  </label>
                </div>
              </div>
              <section
                className="tabs-wrapper magazine_wrapper-tabs"
                id="magazine_wrapper-view_more"
              >
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs edit__blog__tab">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1" className="">
                        <span className="material-icons text-uppercase">
                          EDIT Package
                        </span>{" "}
                      </label>
                      <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                        <div className="row mb-4 mb-md-0">
                          <div className="col-lg-3 custom-select-v1-outer p-0 mb-3">
                            <div className="form-group mb-0 P-3">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                SELECT BROAD CATEGORY
                              </label>
                              <div id="createProduct">
                                <select
                                  id="select"
                                  className="text-uppercase custom-select-v1"
                                  name="broadCategory"
                                  value={
                                    packageData?.broadCategory
                                      ? packageData?.broadCategory
                                      : packageData.broad_category_id
                                  }
                                  onChange={handlePackageDetails}
                                  onKeyDown={handleKeyDownEvent}
                                >
                                  <option value="" disabled>
                                    SELECT BROAD CATEGORY
                                  </option>
                                  {broadCategoryList.length !== 0 &&
                                  broadCategoryList !== null ? (
                                    broadCategoryList.map((elem: any) => (
                                      <option key={elem?.id} value={elem?.id}>
                                        {elem?.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="0" disabled selected>
                                      CATEGORY NOT AVAILABLE
                                    </option>
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-9 pl-0 pr-0 pr-lg-3">
                            <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                PACKAGE NAME
                              </label>
                              <input
                                type="text"
                                className="form-control w-100"
                                id="blogTitle"
                                name="title"
                                value={packageData.title}
                                onChange={handlePackageDetails}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                            </div>
                            <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                One line summary
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase"
                                id="oneLineSummary"
                                name="summary"
                                value={packageData.summary}
                                onChange={handlePackageDetails}
                                autoComplete="off"
                                onKeyDown={handleKeyDownEvent}
                              />
                              <span className="text-danger mt-4 error--message">
                                {errorMessage}
                              </span>
                            </div>
                            <div className="select-category">
                              <div className="col-lg-12 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  id="blogTitle text-white"
                                  className="text-uppercase pb-0"
                                >
                                  Select category
                                </label>
                                <div
                                  className="select-category_inner p-2"
                                  id="create__blog__select--category"
                                >
                                  {categoryList.length !== 0 &&
                                  categoryList !== null ? (
                                    categoryList.map((elem: any) => (
                                      <p
                                        onClick={() => handleStyle(elem.id)}
                                        className={`font-12 category-inner_box p-2 mb-3 mb-lg-0 ${
                                          packageId.includes(elem.id)
                                            ? ""
                                            : "text-white"
                                        }`}
                                        style={{
                                          backgroundColor: `${
                                            packageId.includes(elem.id)
                                              ? "#fff"
                                              : "#4a4a4a"
                                          }`,
                                          display: "inline-block",
                                        }}
                                        key={elem.id}
                                        id="createCategory"
                                      >
                                        {elem.name}
                                      </p>
                                    ))
                                  ) : (
                                    <span className="text-white text-uppercase">
                                      No Data Available
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4 mb-md-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                id="blogTitle text-white"
                                className="text-uppercase pb-0"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control text-uppercase"
                                id="exampleFormControlTextarea1"
                                rows={4}
                                name="description"
                                value={packageData.description}
                                onChange={handlePackageDetails}
                                onKeyDown={handleKeyDownEvent}
                              ></textarea>
                            </div>
                          </div>
                          <div
                            className="col-lg-3 add-blog-wrapper_magazine p-0"
                            onClick={handleTriggerClick}
                          >
                            <div
                              className="cancel__image cursor_pointer"
                              onClick={deleteImage}
                            >
                              <svg
                                className="d-flex justify-content-end text-white"
                                id="icon-cross"
                                viewBox="0 0 14 14"
                                height={40}
                              >
                                <path d="M4.91 3.992C5.14252 4.21583 7.41432 6.56773 7.41432 6.56773C7.53837 6.68788 7.60068 6.84423 7.60068 7.00058C7.60068 7.15694 7.53837 7.31329 7.41432 7.43234C7.41432 7.43234 5.14252 9.78533 4.91 10.0081C4.67747 10.2319 4.25916 10.2473 4.01162 10.0081C3.76352 9.76997 3.74405 9.43696 4.01162 9.14455L6.09596 7.00058L4.01162 4.85661C3.74405 4.5642 3.76352 4.23065 4.01162 3.992C4.25916 3.75281 4.67747 3.76762 4.91 3.992Z" />
                                <path d="M9.08999 3.992C8.85747 4.21583 6.58566 6.56773 6.58566 6.56773C6.46162 6.68788 6.39931 6.84423 6.39931 7.00058C6.39931 7.15694 6.46162 7.31329 6.58566 7.43234C6.58566 7.43234 8.85747 9.78533 9.08999 10.0081C9.32251 10.2319 9.74083 10.2473 9.98837 10.0081C10.2365 9.76997 10.2559 9.43696 9.98837 9.14455L7.90402 7.00058L9.98837 4.85661C10.2559 4.5642 10.2365 4.23065 9.98837 3.992C9.74083 3.75281 9.32251 3.76762 9.08999 3.992Z" />
                              </svg>
                            </div>
                            <div className="add-new-blog--create d-flex justify-content-center align-items-center position-relative">
                              {/* {packageImg || imgSource ? (
                                <>
                                  <img
                                    src={packageImg || imgSource}
                                    alt=""
                                    className=" w-100 edit__blog__featured--image"
                                    style={{ width: "auto", height: "auto" }}
                                    loading="lazy"
                                  />
                                </>
                              ) :  */}
                              {fileLoader && !fileResponse?.data?.url ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : fileResponse?.data?.url || packageImg ? (
                                <img
                                  src={packageImg || imgSource}
                                  alt=""
                                  className=" w-100"
                                />
                              ) : (
                                <Link to={""} className="font-13 text-center">
                                  <svg
                                    className="icon-icon-plus mb-2"
                                    viewBox="0 0 14 14"
                                    height={40}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                    />
                                  </svg>
                                  <p className="add-the-eatured-photos">
                                    Add the featured photos
                                  </p>
                                </Link>
                              )}
                              {packageImg ? null : (
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  autoComplete="off"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditPackage;
