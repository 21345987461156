import React from "react";
import { CChartBar } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface MonthlyRepeatedCustomerItem {
  month: string; // "2024-10"
  monthName: string; // "October"
  repeatedCustomersCount: number;
}

interface ChartProps {
  data?: MonthlyRepeatedCustomerItem[]; // from getMonthlyRepeatedCustomer
}

const ReturningCustomerRateChart: React.FC<ChartProps> = ({ data }) => {
  // Fallback if no data
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">RETURNING CUSTOMER RATE</h3>
          <p className="chart-subtitle">$ 0.00</p>
        </div>
        {/* </div> */}
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>{" "}
      </div>
    );
  }

  // Build the labels array from the monthName property
  const labels = data.map((item) => item.monthName);
  // Build the dataset array from repeatedCustomersCount
  const values = data.map((item) => item.repeatedCustomersCount);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">RETURNING CUSTOMER RATE</h3>
          {/* Optionally calculate the total repeated customers */}
          <p className="chart-subtitle">
            {values.reduce((sum, val) => sum + val, 0)} returning customers
          </p>
        </div>
      </div>
      <CChartBar
        data={{
          labels,
          datasets: [
            {
              label: "Returning Customers",
              backgroundColor: "#999",
              data: values,
              barThickness: 20,
              borderRadius: 6,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // remove the $ if these are customers and not dollar amounts
                callback: (value) => value,
              },
              // Dynamically set max or let Chart.js handle it
              // max: Math.max(...values) < 30 ? 30 : undefined,
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default ReturningCustomerRateChart;
