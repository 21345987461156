import React, { useState } from "react";
import useMeetingDetails from "../../customHooks/useMeetingDetails";
import Swal from "sweetalert2";
import axios from "axios";
import { useToken } from "../../authContext";
import { useNavigate } from "react-router-dom";

const MarkMeetingAsComplete = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const hash = window.location.hash;
  const queryString = hash.split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const meetingId = urlParams.get("meetingid");
  const slotUuid = urlParams.get("slotuuid");
  const authContext = useToken();
  const userInfo = localStorage.getItem("userInfo");
  const parseuserInfo =
    typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
  const navigate = useNavigate();
  const meetingDetails = useMeetingDetails(`${meetingId}`);

  const handleUpdateStatus = () => {
    Swal.fire({
      title: "ARE YOU SURE YOU WANT TO MARK IT AS COMPLETE?",
      text: "YOU WON'T BE ABLE TO REVERT THIS!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "CONFIRM!",
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        await axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              uuid: slotUuid,
              status: "1",
              date: meetingDetails?.startTime.split("T")[0],
              time: meetingDetails?.startTime,
              specialistid: parseuserInfo.id,
              menuId: "cce692df-7d87-48b9-8fcf-974ef927bf42",
              action: "command",
              command: [
                {
                  agent: "bookingComplete",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
                {
                  agent: "bookingCompleteMail",
                  appName: "selfMaximized",
                  folder: "specialist",
                },
              ],
            },
            {
              headers: { "x-access-token": `${authContext?.token}` },
            }
          )
          .then((result) => {
            if (result.data.statusCode === 200) {
              Swal.fire({
                title: "COMPLETED",
                text: "AMOUNT HAS BEEN SUCCESSFULLY ADDED TO YOU WALLET.THANK YOU!",
                icon: "success",
              });
              setLoader(false);
              navigate("/dashboard");
            }
          })
          .catch((err) => {
            setLoader(false);
            return err;
          });
      }
    });
  };

  return (
    <main className="page-content overflow-auto" style={{ height: "auto" }}>
      <div className="container">
        <div className="row rating__wrapper py-5">
          <div className="col-lg-12 py-3">
            <div className="rating-container">
              <div className="">
                <h4 className="how__you__rate rate__size text-uppercase">
                  UPDATE THIS APPOINTMENT AS COMPLETE WITH USER
                </h4>
              </div>

              <div className="text-center">
                <button
                  className="btn btn-dark btn-sm"
                  onClick={handleUpdateStatus}
                  disabled={loader}
                >
                  {loader ? "LOADING..." : "UPDATE STATUS"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MarkMeetingAsComplete;
