import axios from "axios";
import React, { useState } from "react";
import { useToken } from "../../../../authContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { puckData } from "../../data";
import { puckDataPageNames } from "../../data";

const EditPage = () => {
  const [pages, setPages] = useState<any>(puckDataPageNames);
  const [pagePuckData, setPagePuckData] = useState<any>(puckData);
  const authContext = useToken();

  const passPageId = async (pageId: any, pageName: any) => {
    const passData = pagePuckData.find((page: any) => page.name === pageName)
      ?.data[0];
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: pageName,
          data: passData,
          action: "command",
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          command: [
            {
              agent: "createPage",
              appName: "selfMaximized",
              folder: "pages",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success("Page created successfully");
          window.open(`/pedit?pageName=${pageName}`, "_blank");
        } else {
          window.open(`/pedit?pageName=${pageName}`, "_blank");
        }
      })
      .catch((err) => {
        window.open(`/pedit?pageName=${pageName}`, "_blank");
      });
  };

  return (
    <main className="page-content user_wrapper user-wrapper-inner overflow-auto">
      <div className="container pt-3 pt-lg-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive user__all__active__inactive__card">
              <table className="table table--lines table--striped">
                <thead className="table__header table__header--sticky text-center">
                  <tr>
                    <th className="col-2">
                      <span>Editable Pages</span>
                    </th>
                    <th className="col-2">
                      <span>Last Edited on</span>
                    </th>
                    <th className="col-2">
                      <span>Pages</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {pages && pages.length > 0 ? (
                    pages.map((elem: any, index: any) => {
                      return (
                        <tr className="table__row" key={index}>
                          <td className="table__td">
                            <div className="media-item">
                              <div className="media-item__right">
                                <h5 className="media-item__title text-truncate font-14 pl-3">
                                  {elem.pageName}
                                </h5>
                              </div>
                            </div>
                          </td>
                          <td className="table__td">
                            <span className="text-truncate font-14">
                              {elem.pageCreatedOn}
                            </span>
                          </td>
                          <td className="table__td table__actions text-center">
                            {elem.pageName === "CAREER" ? (
                              <Link
                                to={"/dashboard/career_list"}
                                className="items-more font-14 user__view--more text-truncate text-decoration-underline"
                              >
                                EDIT
                              </Link>
                            ) : elem.pageName === "FAQ" ? (
                              <Link
                                to={"/dashboard/faq_list"}
                                className="items-more font-14 user__view--more text-truncate text-decoration-underline"
                              >
                                EDIT
                              </Link>
                            ) : elem.pageName === "POLICIES" ? (
                              <Link
                                to={"/dashboard/policies"}
                                className="items-more font-14 user__view--more text-truncate text-decoration-underline"
                              >
                                EDIT
                              </Link>
                            ) : (
                              <div
                                className="items-more font-14 user__view--more text-truncate text-decoration-underline"
                                onClick={() => {
                                  passPageId(elem.id, elem.pageName);
                                }}
                              >
                                EDIT
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3}>No pages available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditPage;
