import React from "react";

// Define props with optional width/height (numbers)
interface BackIconProps {
  width?: number;
  height?: number;
}

const FilterIcon: React.FC<BackIconProps> = ({ width = 21, height = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8798 9.145C17.8798 10.7559 17.4022 12.3307 16.5072 13.6701C15.6122 15.0096 14.3401 16.0535 12.8518 16.67C11.3635 17.2865 9.72581 17.4478 8.14584 17.1335C6.56586 16.8192 5.11456 16.0435 3.97546 14.9044C2.83636 13.7653 2.06063 12.314 1.74635 10.734C1.43207 9.15404 1.59337 7.51635 2.20985 6.02804C2.82632 4.53974 3.87029 3.26766 5.20973 2.37268C6.54917 1.4777 8.12392 1 9.73485 1C11.895 1 13.9667 1.85813 15.4942 3.38562C17.0217 4.9131 17.8798 6.98481 17.8798 9.145Z"
        stroke="#75767A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.5901 19.0002L15.8711 15.2812"
        stroke="#75767A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
