import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useToken } from "../../authContext";
import Swal from "sweetalert2";

const UserSubscriptionDetails = () => {
  const location = useLocation();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>([]);
  const authContext = useToken();

  useEffect(() => {
    if (!authContext?.token) return;
    getSubscriptionDetails();
  }, [authContext?.token]);

  const getSubscriptionDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          subscriptionId: location?.state?.subscriptionId,
          action: "command",
          command: [
            {
              agent: "getSubscriptionDetails",
              appName: "selfMaximized",
              folder: "subscriptions",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authContext?.token,
          },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setSubscriptionDetails(result?.data?.data);
        } else {
          setSubscriptionDetails({});
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const cancelSubscription = async () => {
    Swal.fire({
      title: "ARE YOU SURE?",
      text: "YOU WON'T BE ABLE TO REVERT THIS!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES, CANCEL IT!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API}/pipeline`,
            {
              subscriptionID: location?.state?.subscriptionId,
              action: "command",
              command: [
                {
                  agent: "userDeleteSubscription",
                  appName: "selfMaximized",
                  folder: "order",
                },
              ],
            },
            {
              headers: {
                "x-access-token": authContext?.token,
              },
            }
          )
          .then((result) => {
            if (result?.data?.statusCode === 200) {
              Swal.fire({
                title: "DELETED!",
                text: "YOUR SUBSCRIPTION HAS BEEN CANCELLED SUCCESSFULLY!",
                icon: "success",
              });
              getSubscriptionDetails();
            }
          })
          .catch((err) => {
            return err;
          });
      }
    });
  };

  return (
    <>
      <main className="page-content categories-wrpper user_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="subscription__card p-4 rounded shadow-lg"
                style={{
                  background: "rgba(255, 255, 255, 0.1)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                }}
              >
                {/* Header */}
                <div className="d-flex align-items-center justify-content-between pb-3 border-bottom border-light">
                  <span
                    className={`status_active fw-bold text-white`}
                    style={{ fontSize: "1.1rem" }}
                  >
                    STATUS -
                    <span className="text-white">
                      {subscriptionDetails?.subscription_status === 1
                        ? " ACTIVE"
                        : " INACTIVE"}
                    </span>
                  </span>
                </div>
                <h4 className="my-4 text-light text-center fw-bold text-uppercase">
                  Remaining Appointments:{" "}
                  <span className="text-warning">
                    {subscriptionDetails?.pendingAppn}
                  </span>
                </h4>

                <ul className="list-unstyled">
                  <li
                    className="appointment-item p-4 d-flex justify-content-between align-items-center bg-dark rounded-3 shadow-sm mb-3 position-relative"
                    style={{
                      transition: "0.3s ease-in-out",
                      cursor: "pointer",
                    }}
                  >
                    <div className="appointment-details">
                      <p className="mb-0 text-white text-uppercase status_active">
                        Doctor:{" "}
                        <span className="fw-bold">
                          {subscriptionDetails?.specialistName}
                        </span>
                      </p>
                      <p className="mb-0 text-white text-uppercase status_active">
                        Recurring:{" "}
                        <span className="">
                          {subscriptionDetails?.recurring_date}
                        </span>
                      </p>
                      <p className="mb-0 text-white text-uppercase status_active">
                        Category:{" "}
                        <span className="text-white text-uppercase status_active">
                          {subscriptionDetails?.category_name}
                        </span>
                      </p>
                      <p className="mb-0 text-white text-uppercase status_active">
                        Duration:{" "}
                        <span className="text-white text-uppercase status_active">
                          {subscriptionDetails?.product_duration === "0.50"
                            ? "30 MIN"
                            : "60 MIN"}
                        </span>
                      </p>
                      <p className="mb-0 text-white text-uppercase status_active">
                        BOOKING DATE:{" "}
                        <span className="text-white text-uppercase status_active">
                          {subscriptionDetails?.booking_date}
                        </span>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <button
                className="btn btn-danger border bg-transparent btn-sm fw-bold px-3 shadow-sm"
                onClick={cancelSubscription}
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserSubscriptionDetails;
