import React from "react";
import { CChartBar } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface CohortItem {
  month: string;
  monthName: string;
  repeatedCustomersCount: number;
  totalVisits: number;
}

interface ChartProps {
  data?: CohortItem[];
}

const CustomerCohortAnalysisChart: React.FC<ChartProps> = ({ data }) => {
  // If no data, render a fallback message (or you can show a no-data image)
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        <div>
          <h3 className="chart-title">CUSTOMER COHORT ANALYSIS</h3>
          <p className="chart-subtitle"></p>
        </div>
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Map the data to labels and dataset values
  const labels = data.map((item) => item.monthName);
  const repeatedData = data.map((item) => item.repeatedCustomersCount);
  const visitsData = data.map((item) => item.totalVisits);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">CUSTOMER COHORT ANALYSIS</h3>
          <p className="chart-subtitle">Repeated Customers vs Total Visits</p>
        </div>
      </div>
      <CChartBar
        data={{
          labels: labels,
          datasets: [
            {
              label: "Repeated Customers",
              backgroundColor: "#999",
              hoverBackgroundColor: "#999",
              borderColor: "#999",
              data: repeatedData,
              barThickness: 20,
              borderRadius: 6,
            },
            {
              label: "Total Visits",
              backgroundColor: "#ccc",
              hoverBackgroundColor: "#ccc",
              borderColor: "#ccc",
              data: visitsData,
              barThickness: 20,
              borderRadius: 6,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: true,
              position: "top", // or 'bottom', etc.
              labels: {
                padding: 10, // spacing around each legend item
                boxWidth: 20, // size of the color box
                boxHeight: 10,
                color: "#fff",
              },
            },
            tooltip: {},
          },
          scales: {
            x: {
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // Since these values are counts, we simply display them as-is.
                callback: (value) => value,
              },
              min: 0,
              max:
                Math.max(...repeatedData, ...visitsData) < 30 ? 30 : undefined,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default CustomerCohortAnalysisChart;
