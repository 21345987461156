import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useToken } from "../../../../authContext";

const CareerEdit = () => {
  const navigate = useNavigate();
  const authContext = useToken();
  const location = useLocation();
  const [career, setCareer] = useState<any>({
    title: "",
    description: "",
    summary: "",
  });

  const careerId = location?.state?.careerId;

  useEffect(() => {
    getDataById();
  }, []);

  const handleFields = (e: any) => {
    setCareer({ ...career, [e.target.name]: e.target.value });
  };

  const updateCareer = async () => {
    if (career.title === "") {
      return toast.warning("Please add title first");
    }
    if (career.description === "") {
      return toast.warning("Please add description first");
    }
    if (career.summary === "") {
      return toast.warning("Please add summary first");
    }
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          id: careerId,
          data: {
            summary: `${career.summary}`,
            title: `${career.title}`,
            description: `${career.description}`,
            meta: "{}",
          },
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "editCareer",
              appName: "selfMaximized",
              folder: "career",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success("Career updated successfully!");
          navigate("/dashboard/career_List");
        } else {
          return;
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  const getDataById = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          id: careerId,
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "careerById",
              appName: "selfMaximized",
              folder: "career",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setCareer(result?.data?.data[0]);
        } else {
          setCareer([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return (
    <main className="page-content user_wrapper overflow-auto">
      <div className="container pt-5 pt-lg-0">
        <div className="row">
          <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
            <div>
              <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                <button
                  type="button"
                  className="font-weight-bold publish-btn magazine--btn "
                  onClick={updateCareer}
                >
                  UPDATE
                </button>
              </div>
              {/* <div className="d-flex justify-content-end active__inactive--switch">
                <label
                  className={`switch publish-btn magazine--btn ${
                    isActive ? "active" : "inactive"
                  }`}
                >
                  <input
                    type="checkbox"
                    onChange={toggleSwitch}
                    checked={isActive}
                  />
                  <span className="slider round"></span>
                  <span
                    className={`status ${isActive ? "mr-4" : "ml-4 text-dark"}`}
                  >
                    {isActive ? "Active" : "Inactive"}
                  </span>
                </label>
              </div> */}
            </div>
            <section
              className="tabs-wrapper magazine_wrapper-tabs"
              id="magazine_wrapper-view_more"
            >
              <div className="tabs-container">
                <div className="tabs-block">
                  <div className="tabs edit__blog__tab">
                    <input type="radio" name="tabs" id="tab1" checked />
                    <label htmlFor="tab1 pb-2">
                      <span className="material-icons text-uppercase">
                        Edit Career
                      </span>{" "}
                    </label>
                    <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                      <div className="row mb-0">
                        <div className="col-lg-12 px-0">
                          <label
                            htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                            id="blogTitle text-white blog__title--edit"
                            className="text-uppercase pb-0"
                          >
                            Career Title
                          </label>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control w-100"
                              id="blogTitle"
                              name="title"
                              value={
                                career.title
                                  ? career.title.toUpperCase()
                                  : career.title
                              }
                              onChange={handleFields}
                              autoComplete="off"
                            />
                            <span className="text-danger mt-4 error--message"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-0">
                        <div className="col-lg-12 pl-0 pr-0 pr-lg-3">
                          <div className="form-group mb-0">
                            <label
                              htmlFor="exampleFormControlInput2 mb-0 pb-3"
                              id="blogTitle text-white"
                              className="text-uppercase pb-0"
                            >
                              One line summary
                            </label>
                            <input
                              type="text"
                              className="form-control w-100"
                              id="oneLineSummary"
                              name="summary"
                              value={
                                career.summary
                                  ? career.summary.toUpperCase()
                                  : career.summary
                              }
                              autoComplete="off"
                              onChange={handleFields}
                            />
                            <span className="text-danger mt-4 error--message"></span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-0">
                        <div className="col-lg-12 pl-0 pr-0 pr-lg-3">
                          <div className="form-group mb-4 mb-md-0">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              id="blogTitle text-white"
                              className="text-uppercase pb-0"
                            >
                              Career Description
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows={4}
                              name="description"
                              value={
                                career.description
                                  ? career.description.toUpperCase()
                                  : career.description
                              }
                              onChange={handleFields}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CareerEdit;
