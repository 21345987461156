import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToken } from "../../../../authContext";
import { toast } from "react-toastify";

const EditBlog: React.FC = () => {
  const id = useParams();
  const [loader, setLoader] = useState<any>("");
  const authContext = useToken();
  const [categoriesId, setCategories] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [categoryList, setCategoryList] = useState<any>([]);
  const [blogData, setBlogData] = useState<any>("");
  const [blogImg, setBlogImg] = useState<any>("");
  const [fileResponse, setFileResponse] = useState<any>([]);
  const [imgSource, setImgSource] = useState<any>("");
  const [imgFile, setImgFile] = useState<any>("");
  const [specialistId, setSpecialistId] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any>([]);
  const [authorName, setShowAuthorName] = useState<any>(false);
  const [blogAuthorInputVal, setBlogAuthorInputValue] = useState<string>("");
  const [blogAutherErrMsg, setBlogAuthorErrorMessage] = useState<string>("");
  const [imagePath, setImgUrl] = useState<any>("");
  const [imageLoader, setImageLoader] = useState<any>(false);

  const [isActive, setIsActive] = useState<any>(
    blogData.publish === 1 ? true : false
  );
  const navigate = useNavigate();
  const authCtx = useToken();

  useEffect(() => {
    getSpecialistList();
  }, []);

  const handleBlogDetails = (event: any) => {
    const { name, value } = event.target;
    let limitedValue = value;

    if (name === "summary" && value.length > 40) {
      limitedValue = value.slice(0, 40); // Limiting the input value to 40 characters
      setErrorMessage("Please enter only up to 40 letters.");
    } else {
      setErrorMessage("");
    }

    setBlogData({
      ...blogData,
      [name]: name === "title" ? value.toUpperCase() : limitedValue,
    });
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSource(imageSrc);
        setImgFile(file);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSource("");
        setImgFile(null);
        event.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    setImageLoader(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authContext.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response) {
      setImgUrl(response?.data?.data?.url);
    }
    setImageLoader(false);

    await setFileResponse(response?.data);
  };

  const deleteImage = () => {
    setBlogImg("");
    setImgSource("");
  };

  useEffect(() => {
    setIsActive(blogData.publish === 1 ? true : false);
  }, [blogData.publish]);

  const editBlog = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 1,
              filters: [
                {
                  field: "publish",
                  operator: "eq",
                  value: "1",
                },
                {
                  field: "uuid",
                  operator: "eq",
                  value: `${id.id}`,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          action: "command",
          command: [
            {
              agent: "blogList",
              appName: "selfMaximized",
              folder: "blogs",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setBlogData(result?.data?.data[0]);
        setBlogImg(result?.data?.data[0].image);
        const categoryIds: any = [];
        result?.data?.data[0].category.map((elem: any) =>
          categoryIds.push(elem.categoryid)
        );
        setCategories(categoryIds);
      })
      .catch((err) => {
        toast.error("Something went wrong," + err);
      });
  };

  const editBlogCallback = useCallback(editBlog, [authContext.token, id.id]);

  const getCategoriesNameList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "categoryLists",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        setCategoryList(result?.data?.data);
      })
      .catch((err) => {
        toast.error("Something went wrong," + err);
      });
  };

  const categoryListCallback = useCallback(getCategoriesNameList, [
    authContext.token,
  ]);

  const handleStyle = (id: any) => {
    if (categoriesId.includes(id)) {
      setCategories(categoriesId.filter((elemId: any) => elemId !== id));
    } else {
      setCategories([...categoriesId, id]);
    }
  };

  useEffect(() => {
    editBlogCallback();
    categoryListCallback();
  }, [editBlogCallback, categoryListCallback]);

  const [name, uuid] = specialistId.split(",");

  const updateBlog = async () => {
    // if (imgSource) {
    //   await fileUpload(imgFile);
    // }
    let imageUrl = "";
    if (imgSource && fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    }

    let errorMessage = "";

    if (!blogImg && !imageUrl) {
      errorMessage += "Image is mandatory. ";
      return toast.warning(errorMessage);
    }

    if (
      !blogData.title ||
      !blogData.description ||
      !blogData.summary ||
      categoriesId.length === 0
    ) {
      if (!blogData.title) {
        errorMessage += "Blog title is mandatory. ";
      }

      if (!blogData.summary) {
        errorMessage += "Blog summary is mandatory. ";
      }

      if (!blogData.description) {
        errorMessage += "Description is mandatory. ";
      }
      if (categoriesId.length === 0) {
        errorMessage += "Select category. ";
      }

      if (
        !blogData.title &&
        !imgSource &&
        categoriesId.length === 0 &&
        !blogData.description &&
        !blogImg &&
        !blogData.summary
      ) {
        errorMessage = "All fields are mandatory.";
      }

      if (blogData.title) {
        if (
          !blogData.description &&
          categoriesId.length === 0 &&
          !imgSource &&
          !blogImg &&
          !blogData.summary
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (blogData.description) {
        if (
          !blogData.title &&
          categoriesId.length === 0 &&
          !imgSource &&
          !blogImg &&
          !blogData.summary
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (blogData.summary) {
        if (
          !blogData.title &&
          !blogData.description &&
          !imgSource &&
          !blogImg &&
          categoriesId.length === 0
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }

      if (categoryList.length !== 0) {
        if (
          !blogData.title &&
          !blogData.description &&
          !imgSource &&
          !blogImg &&
          !blogData.summary
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (imgSource || blogImg) {
        if (
          !blogData.title &&
          !blogData.description &&
          categoriesId.length === 0 &&
          !blogData.summary
        ) {
          errorMessage = "All fields are mandatory.";
        }
      }
      if (blogData?.author === "") {
        errorMessage = "Author is mandatory.";
      }

      return toast.warning(errorMessage);
    }

    setLoader(true);

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: `${id.id}`,
          title: `${blogData.title}`,
          description: `${blogData.description}`,
          image: `${imageUrl || blogImg}`,
          summary: `${blogData.summary}`,
          author: `${
            blogAuthorInputVal
              ? blogAuthorInputVal
              : name
              ? name
              : blogData.author
          }`,
          handle: "",
          author_uuid: blogAuthorInputVal
            ? ""
            : specialistId
            ? specialistId.split(",")[2]
            : blogData.author_uuid,
          categoriesId: categoriesId,
          otherAuthor: blogAuthorInputVal ? true : false,
          publish: isActive ? 1 : 0,
          metadescription: "",
          metakeyword: "",
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf5",
          action: "command",
          command: [
            {
              agent: "updateBlogs",
              appName: "selfMaximized",
              folder: "blogs",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        toast.success(
          `${
            isActive
              ? "Blog edited successfully!"
              : "Blog deactivated successfully!"
          }`
        );
        navigate("/dashboard/magazine");
        setLoader(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSpecialistId = (e: any) => {
    if (e.target.value === "OTHER") {
      setSpecialistId("");
      setShowAuthorName(true);
    } else {
      setSpecialistId(e.target.value);
      setShowAuthorName(false);
    }
  };

  const getSpecialistList = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              filters: [
                {
                  field: "role",
                  operator: "eq",
                  value: "specialist",
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "specialistLists",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authCtx.token}` },
        }
      )
      .then((result) => {
        if (result.data.statusCode === 200) {
          setSpecialistList(result.data.data);
        } else {
          toast.error(result.data.message);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleBlogAuthor = (event: any) => {
    const value = event.target.value;
    setBlogAuthorInputValue(value.toUpperCase());
    setBlogAuthorErrorMessage("");
  };
  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row">
            <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
              <div>
                <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2">
                  {loader ? (
                    <button
                      type="button"
                      className="font-weight-bold publish-btn magazine--btn active__btn--edit"
                      disabled
                    >
                      <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                      LOADING...
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="font-weight-bold publish-btn magazine--btn active__btn--edit"
                      onClick={updateBlog}
                    >
                      UPDATE
                    </button>
                  )}
                </div>
                <div className="d-flex justify-content-end active__inactive--switch">
                  <label
                    className={`switch publish-btn magazine--btn ${
                      isActive ? "active" : "inactive"
                    }`}
                  >
                    <input
                      type="checkbox"
                      onChange={toggleSwitch}
                      checked={isActive}
                    />
                    <span className="slider round"></span>
                    <span
                      className={`status ${
                        isActive ? "mr-4" : "ml-4 text-dark"
                      }`}
                    >
                      {isActive ? "Active" : "Inactive"}
                    </span>
                  </label>
                </div>
              </div>
              <section
                className="tabs-wrapper magazine_wrapper-tabs"
                id="magazine_wrapper-view_more"
              >
                <div className="tabs-container">
                  <div className="tabs-block">
                    <div className="tabs edit__blog__tab">
                      <input type="radio" name="tabs" id="tab1" checked />
                      <label htmlFor="tab1 pb-2">
                        <span className="material-icons text-uppercase">
                          Edit Blog
                        </span>{" "}
                      </label>
                      <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                        <div className="row mb-0">
                          <div className="col-lg-12 px-0">
                            <label
                              htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                              id="blogTitle text-white blog__title--edit"
                              className="text-uppercase pb-0"
                            >
                              Blog Title
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-9 pl-0 pr-0 pr-lg-3">
                            <div className="form-group mb-0">
                              <input
                                type="text"
                                value={blogData.title}
                                onChange={handleBlogDetails}
                                className="form-control w-100"
                                id="blogTitle"
                                name="title"
                                autoComplete="off"
                              />
                            </div>
                            <div className="form-group mb-0">
                              <label
                                className="text-uppercase pb-0"
                                htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                id="blogTitle text-white"
                              >
                                One line summary
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 text-uppercase"
                                id="oneLineSummary"
                                value={blogData.summary}
                                onChange={handleBlogDetails}
                                name="summary"
                                autoComplete="off"
                              />
                              <span className="text-danger mt-4 error--message">
                                {errorMessage}
                              </span>
                            </div>
                            <div className="select-category">
                              <div className="col-lg-12 px-0">
                                <label
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  className="text-uppercase pb-0"
                                  id="blogTitle text-white"
                                >
                                  Select category
                                </label>
                                <div
                                  className="select-category_inner p-2"
                                  id="edit__blog__select--category"
                                >
                                  {categoryList.length !== 0 &&
                                  categoryList !== null ? (
                                    categoryList.map((elem: any) => (
                                      <p
                                        onClick={() => handleStyle(elem.id)}
                                        className={`font-12 category-inner_box p-2 mb-3 mb-lg-0 text-white ${
                                          categoriesId.includes(elem.id)
                                            ? "text-dark"
                                            : ""
                                        }`}
                                        style={{
                                          backgroundColor: `${
                                            categoriesId.includes(elem.id)
                                              ? "#fff"
                                              : "#4a4a4a"
                                          }`,
                                          display: "inline-block",
                                        }}
                                        key={elem.id}
                                        id="magazineCategory"
                                      >
                                        {elem.name}
                                      </p>
                                    ))
                                  ) : (
                                    <span className="text-white text-uppercase">
                                      No Data Available
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4 mb-md-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                className="text-uppercase pb-0"
                                id="blogTitle text-white"
                              >
                                Description
                              </label>
                              <textarea
                                className="form-control text-uppercase"
                                value={blogData.description}
                                onChange={handleBlogDetails}
                                id="exampleFormControlTextarea1"
                                rows={4}
                                name="description"
                              ></textarea>
                            </div>
                            {/* <div className="form-group mb-0">
                              <label
                                htmlFor="exampleFormControlTextarea1"
                                id="blogTitle text-white"
                              >
                                Author Name{" "}
                              </label>
                              <input
                                type="text"
                                className="form-control w-100"
                                id="blogAuthor"
                                value={blogData.author}
                                onChange={handleBlogDetails}
                                name="author"
                                autoComplete="off"
                              />
                              <span className="text-danger mt-4 error--message">
                                {errorMessage}
                              </span>
                            </div> */}
                            <div className="form-group mb-0 d-flex mb-4">
                              <div className="col-lg-4 px-0">
                                <label
                                  className="text-uppercase mb-0"
                                  htmlFor="exampleFormControlInput2 mb-0 pb-3"
                                  id="blogTitle text-white"
                                >
                                  Select authors
                                </label>
                                <select
                                  className="select-category_inner p-2 w-75 h-50"
                                  id="create__blog__select--category"
                                  style={{
                                    color: "white",
                                    padding: "10px",
                                    border: "none",
                                    fontSize: "14px",
                                  }}
                                  onChange={handleSpecialistId}
                                >
                                  <option disabled selected>
                                    {blogData.author
                                      ? blogData.author.toUpperCase()
                                      : "SELECT AUTHOR"}
                                  </option>

                                  {specialistList.length !== 0 &&
                                  specialistList !== null ? (
                                    specialistList.map((elem: any) => (
                                      <option
                                        className={`font-12 category-inner_box p-2 mb-2 mb-lg-0 `}
                                        style={{
                                          color: "white", // Always setting color to white
                                          display: "inline-block",
                                        }}
                                        key={elem.id}
                                        value={`${elem.name},${elem.id},${elem.uuid}`}
                                        id="createCategory"
                                      >
                                        <p className="text-white">
                                          {elem.name.toUpperCase()}
                                        </p>
                                      </option>
                                    ))
                                  ) : (
                                    <div className="col-lg-12 text-center">
                                      <span className="text-white font-14">
                                        No Data Available
                                      </span>
                                    </div>
                                  )}
                                  <option className="category-inner_box">
                                    OTHER
                                  </option>
                                </select>
                              </div>
                              {authorName && (
                                <div className="col-lg-4">
                                  <label
                                    htmlFor="exampleFormControlTextarea1"
                                    id="blogTitle text-white"
                                  >
                                    Author Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control w-100 letter_spacing"
                                    id="blogAuthor"
                                    name="blogAuthor"
                                    value={blogAuthorInputVal}
                                    onChange={handleBlogAuthor}
                                    autoComplete="off"
                                    // onKeyDown={handleKeyDownEvent}
                                  />
                                  <span className="text-danger mt-4 error--message">
                                    {blogAutherErrMsg}
                                  </span>
                                </div>
                              )}
                              <div className="col-lg-4">
                                <input
                                  type="hidden"
                                  className="form-control w-100"
                                  id="blogAuthor"
                                  name="authorId"
                                  value={specialistId}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 add-blog-wrapper_magazine p-0 mt-4 mt-lg-0">
                            <div
                              className="add-new-blog--edit d-flex justify-content-center align-items-center position-relative edit__blog__featured__image"
                              onClick={handleTriggerClick}
                            >
                              {imageLoader ? (
                                <div
                                  className="spinner-border text-light"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : blogImg || imgSource ? (
                                <img
                                  src={blogImg || imagePath}
                                  alt=""
                                  className=" w-100 edit__blog__featured--image cursor_pointer"
                                  style={{ width: "auto", height: "auto" }}
                                  loading="lazy"
                                />
                              ) : (
                                <Link to={""} className="font-13 text-center">
                                  <svg
                                    className="icon-icon-plus mb-2 cursor_pointer"
                                    viewBox="0 0 14 14"
                                    height={40}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                    />
                                  </svg>
                                  <p className="add-the-eatured-photos">
                                    Add the featured photos
                                  </p>
                                </Link>
                              )}
                              {/* {blogImg || imgSource ? (
                                <img
                                  src={blogImg || imagePath}
                                  alt=""
                                  className=" w-100 edit__blog__featured--image"
                                  style={{ width: "auto", height: "auto" }}
                                  loading="lazy"
                                />
                              ) : imageLoader ? (
                                <>
                                 
                                  <p>LOADING....</p>
                                </>
                              ) : (
                                <Link to={""} className="font-13 text-center">
                                  <svg
                                    className="icon-icon-plus mb-2"
                                    viewBox="0 0 14 14"
                                    height={40}
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                                    />
                                  </svg>
                                  <p className="add-the-eatured-photos">
                                    Add the featured photos
                                  </p>
                                </Link>
                              )} */}
                              {blogImg ? null : (
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  onChange={handleFileChange}
                                  style={{ display: "none" }}
                                  autoComplete="off"
                                />
                              )}
                            </div>
                            <div
                              className="cancel__image cursor_pointer"
                              onClick={deleteImage}
                            >
                              <svg
                                className="d-flex justify-content-end text-white"
                                id="icon-cross"
                                viewBox="0 0 14 14"
                                height={40}
                              >
                                <path d="M4.91 3.992C5.14252 4.21583 7.41432 6.56773 7.41432 6.56773C7.53837 6.68788 7.60068 6.84423 7.60068 7.00058C7.60068 7.15694 7.53837 7.31329 7.41432 7.43234C7.41432 7.43234 5.14252 9.78533 4.91 10.0081C4.67747 10.2319 4.25916 10.2473 4.01162 10.0081C3.76352 9.76997 3.74405 9.43696 4.01162 9.14455L6.09596 7.00058L4.01162 4.85661C3.74405 4.5642 3.76352 4.23065 4.01162 3.992C4.25916 3.75281 4.67747 3.76762 4.91 3.992Z" />
                                <path d="M9.08999 3.992C8.85747 4.21583 6.58566 6.56773 6.58566 6.56773C6.46162 6.68788 6.39931 6.84423 6.39931 7.00058C6.39931 7.15694 6.46162 7.31329 6.58566 7.43234C6.58566 7.43234 8.85747 9.78533 9.08999 10.0081C9.32251 10.2319 9.74083 10.2473 9.98837 10.0081C10.2365 9.76997 10.2559 9.43696 9.98837 9.14455L7.90402 7.00058L9.98837 4.85661C10.2559 4.5642 10.2365 4.23065 9.98837 3.992C9.74083 3.75281 9.32251 3.76762 9.08999 3.992Z" />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditBlog;
