import React from "react";
import Slots from "./Slots";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

interface RangeSelectionProps {
  handleRangeSelection: (value: any) => void;
  startDate: string;
  endDate: string;
  selectedMonth: string;
  slots: any;
  userTimezone: string;
  selectedSlot: any;
  passSlots: (value: any) => void;
  handleMonthChange: (value: any) => void;
}
const RenderSlotWindow: React.FC<RangeSelectionProps> = ({
  handleRangeSelection,
  startDate,
  endDate,
  selectedMonth,
  selectedSlot,
  userTimezone,
  passSlots,
  slots,
  handleMonthChange,
}) => {
  return (
    <div className="row border-top m-0 ">
      <div className="col-md-4 border-end pt-4 pb-4 pe-lg-4 pe-md-4 pe-0 ps-0 react-calendar-outer">
        <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
          Please select your time slot before we proceed
        </p>
        <div>
          <div className="react-calendar-outer">
            <Calendar
              selectRange={true}
              onChange={handleRangeSelection}
              value={[startDate, endDate]}
              onActiveStartDateChange={handleMonthChange} // Handle month change
            />
          </div>
        </div>
      </div>

      <div className="col-md-8 ps-0 ps-md-4 ps-lg-4 pe-0 pt-4 pb-4">
        <p className="mb-0 text-white reschedule__please--select pb-3 text-uppercase">
          Available time slots for the month of {selectedMonth}
        </p>
        <div className="book-overflow">
          <div className="slot-outer text-uppercase text-white">
            <div className="slot-inner row g-3">
              {slots.map((slot: any, index: any) => (
                <Slots
                  key={index}
                  slot={slot}
                  userTimezone={userTimezone}
                  selectedSlot={selectedSlot}
                  passSlots={passSlots}
                />
              ))}
            </div>
          </div>

          <hr className="custom-hr custom-hr-white text-white"></hr>
          {slots.length === 0 && (
            <h4 className="text-white text-center letter_spacing text-uppercase">
              No slots available for selected date{" "}
            </h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderSlotWindow;
