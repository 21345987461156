import React from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";

interface Report {
  id: number;
  name: string;
}

const reports: Report[] = [
  { id: 1, name: "PAYMENT ANALYTICS REPORT" },
  { id: 2, name: "USER BOOKING DETAILS WITH DOCTOR" },
  { id: 3, name: "REQUEST FOR CANCELLATION/REFUND REPORT" },
  { id: 4, name: "ACTIVE/CANCELLATION USER SUBSCRIPTION REPORT" },
];

const columns: ColumnsType<Report> = [
  {
    title: "SR NO.",
    dataIndex: "id",
    key: "id",
    width: "10%",
  },
  {
    title: "REPORT NAME",
    dataIndex: "name",
    key: "name",
    width: "70%",
  },
  {
    title: "",
    key: "action",
    width: "20%",
    render: (_, record) => (
      <Link
        to={`/dashboard/analytics/reports/${record.id}`}
        className="viewbtn viewbtn-view"
      >
        VIEW
      </Link>
    ),
  },
];

const ReportsList: React.FC = () => {
  return (
    <div className="reports-list-container">
      <Table<Report>
        columns={columns}
        dataSource={reports}
        rowKey="id"
        pagination={false}
        // Add custom row classes for striped effect
        rowClassName={(_, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        className="table-container"
      />
    </div>
  );
};

export default ReportsList;
