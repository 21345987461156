import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useToken } from "../../authContext";

const SpecialistUserDetails: React.FC = () => {
  const authContext = useToken();
  const [userDetails, setUserDetails] = useState<any>([]);
  const { id } = useParams();

  useEffect(() => {
    getUserDetails();
  }, [authContext.token]);

  const getUserDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userId: id,
          action: "command",
          command: [
            {
              agent: "userDatailInfo",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setUserDetails(result?.data?.data?.userData[0]);
        } else {
          setUserDetails([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper user_wrapper">
        <div className="container pb-3 pt-5 pt-lg-0 mt-3 mt-lg-0">
          <div className="row user_details-inner_wrapper specialist__user__details__wrapper">
            {/* table top */}
            <div className="row col-lg-12">
              <div className="col-lg-5">
                <div className="row py-4">
                  <div className="col-lg-3 pb-4 pb-lg-0">
                    <div className="thomas-img">
                      <img
                        src={`${
                          userDetails.profile !== null
                            ? userDetails.profile
                            : "https://www.ihna.edu.au/blog/wp-content/uploads/2022/10/user-dummy.png"
                        }`}
                        alt=""
                        className="img-fluid user__specialist__profile"
                        style={{ width: "auto", height: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <p className="mb-0 details_title font-18 text-white">
                      {userDetails.name}
                    </p>
                    <p className="mb-0 email-city-registration-date font-14 pb-1">
                      {userDetails.role}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="row pb-4 py-4">
                  <div className="col-lg-4 pb-3 pb-lg-0">
                    <p className="mb-0 email-city-registration-date font-12 pb-1">
                      Email
                    </p>
                    <p className="mb-0 details_title font-14 pb-1 text-white text-transform-unset">
                      {userDetails.email}
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-6 pl-3 pl-lg-5">
                        <p className="mb-0 email-city-registration-date font-12 pb-1">
                          Phone
                        </p>
                        <p className="mb-0 details_title font-14 pb-1 text-white">
                          {userDetails.phone}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table */}
            <div className="col-lg-12 custome__border__top">
              <p className="mb-0 color-description pt-3 pb-2">Patient List</p>
              {/* New Table */}
              <div className="tab table-responsive">
                <table className="table table--lines table--striped">
                  <colgroup>
                    <col className="colgroup-1" />
                    <col className="colgroup-2" />
                    <col className="colgroup-3" />
                    <col className="colgroup-4" />
                    <col className="colgroup-5" />
                    <col className="colgroup-6" />
                    <col />
                  </colgroup>
                  <thead className="table__header table__header--sticky text-center">
                    <tr>
                      <th className="text-truncate">
                        <span className="font-14">Patient name</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Subscription</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Price</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Payment Due Date</span>
                      </th>
                      <th className="text-truncate">
                        <span className="font-14">Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr className="table__row">
                      <td className="table__td">
                        <div className="media-item">
                          <div className="media-item__right">
                            <h5 className="media-item__title text-truncate font-14 text-truncate pl-3 pl-lg-0">
                              Akshay Mahajan
                            </h5>
                          </div>
                        </div>
                      </td>
                      <td className="table__td text-truncate">
                        <span className="text-grey text-truncate font-14">
                          Primium
                        </span>
                      </td>
                      <td className="table__td text-truncate">
                        <span className="text-truncate font-14">2500/-</span>
                      </td>
                      <td className="table__td text-truncate">
                        <span className="text-grey text-truncate font-14">
                          31 Dec 2023
                        </span>
                      </td>
                      <td className="table__td text-truncate">
                        <div className="table__status text-truncate font-14">
                          Pending
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SpecialistUserDetails;
