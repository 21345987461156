import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useToken } from "../../../../authContext";
import ManageDoctors from "./ManageDoctor";
import useTotalCategoryLength from "../../../../customHooks/useTotalCategoriesLength";

const AddCategories: React.FC = () => {
  const authCtx = useToken();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [domainTypes, setDomainTypes] = useState<any>({
    domainName: "",
    domainSummary: "",
  });
  const [fileResponse, setFileResponse] = useState<any>("");
  const [imgSrc, setImgSrc] = useState<any>("");
  const [specialistList, setSpecialistList] = useState<any[]>([]);
  const [searchItem, setSearchItem] = useState<any>("");
  const [fieldName, setFieldName] = useState<any>("");
  const [selectedSpecialist, setSelectedSpecialist] = useState<any>(new Set());
  const [loader, setLoader] = useState<any>("");
  const [categoryLoader, setCategoryLoader] = useState<any>([]);
  const { totalCatLength, fetchTotalCategoryLength } = useTotalCategoryLength();

  useEffect(() => {
    if (!searchItem) {
      getSpecialistList("");
    }
  }, [authCtx.token]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getSpecialistList(searchItem);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchItem]);

  const lastId = totalCatLength.length !== 0 && totalCatLength.slice(-1);
  const nextId =
    totalCatLength.length !== 0 && lastId.length !== 0 && lastId[0].id + 1;

  const handleSpecialist = async (e: any) => {
    e.preventDefault();
    const specialistSearchValue = e.target.value;
    setSearchItem(specialistSearchValue);
  };

  const handleKeyDownEvent = async (e: any) => {
    if (e.keyCode === 32 && e.target.value.trim() === "") {
      e.preventDefault();
    }
  };

  const getSpecialistList = async (specialistSearchValue: string) => {
    let filters = [];
    let tableName = fieldName === "" ? "name" : fieldName;
    if (specialistSearchValue) {
      filters.push(
        {
          field: "isActive",
          operator: "eq",
          value: "1",
        },
        {
          field: `${tableName}`,
          operator: "contain",
          table: `${
            tableName === "title" ||
            tableName === "areaofexpertise" ||
            tableName === "education"
              ? "DoctorExperiences"
              : "Doctors"
          }`,
          value: `${specialistSearchValue}`,
        }
      );
    } else {
      filters = [
        {
          field: "isActive",
          operator: "eq",
          value: "1",
        },
      ];
    }
    setCategoryLoader(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 250,
            filters: filters,
            sort: [
              {
                field: "id",
                dir: "desc",
                table: "Users",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authCtx.token}` },
      }
    );
    if (response?.data?.statusCode === 200) {
      setSpecialistList(response?.data?.data);
      setCategoryLoader(false);
    } else {
      setCategoryLoader(false);
    }
  };

  const toggleSpecialistSelection = (id: any) => {
    setSelectedSpecialist((prevSelected: any) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleDomainTypes = (event: any) => {
    const { name, value } = event.target;
    setDomainTypes((prevState: any) => ({
      ...prevState,
      [name]: name === "domainName" ? value.toUpperCase() : value,
    }));
  };

  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current?.click();
    }
  };

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (allowedTypes.includes(file.type)) {
        const imageSrc = URL.createObjectURL(file);
        setImgSrc(imageSrc);
        await fileUpload(file);
      } else {
        toast.error("Please select a PNG, JPG, or JPEG file.");
        setImgSrc("");
        event.target.files = null;
      }
    }
  };

  const fileUpload = async (imgFile: any) => {
    const formData = new FormData();
    formData.append("file", imgFile);
    formData.append("action", "formcommand");
    formData.append(
      "command",
      '[ { "agent": "FileUpload", "appName": "selfMaximized", "folder": "fileSystem" } ]'
    );
    formData.append("docType", "profile");

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      formData,
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    await setFileResponse(response?.data);
  };

  const addCategories = async () => {
    let specialistsId: number[] = [];
    let ids: number[] = [];

    if (
      !domainTypes.domainName ||
      !domainTypes.domainSummary ||
      !fileResponse ||
      !imgSrc
    ) {
      let errorMessage = "";

      if (!domainTypes.domainSummary) {
        errorMessage += "Domain summary is mandatory. ";
      }
      if (!domainTypes.domainName) {
        errorMessage += "Domain name is mandatory. ";
      }
      if (!imgSrc) {
        errorMessage += "Select the image first. ";
      }

      if (!domainTypes.domainName && !domainTypes.domainSummary && !imgSrc) {
        errorMessage = "All fields are mandatory except specialist";
      }

      if (domainTypes.domainName) {
        if (!domainTypes.domainSummary && !imgSrc) {
          errorMessage = "All fields are mandatory except specialist.";
        }
      }
      if (domainTypes.domainSummary) {
        if (!domainTypes.domainName && !imgSrc) {
          errorMessage = "All fields are mandatory except specialist.";
        }
      }
      if (imgSrc) {
        if (!domainTypes.domainName && !domainTypes.domainSummary) {
          errorMessage = "All fields are mandatory except specialist.";
        }
      }

      return toast.warning(errorMessage);
    }

    let imageUrl = "";
    if (fileResponse.status === "success") {
      imageUrl = fileResponse?.data?.url;
    } else {
      return;
    }

    ids = specialistList.filter((specialist) =>
      selectedSpecialist.has(specialist.id)
    );
    if (ids) {
      ids.forEach((elem: any) => {
        specialistsId.push(elem.id);
      });
    } else {
      specialistsId = [];
    }
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: `${domainTypes.domainName}`,
          description: `${domainTypes.domainSummary}`,
          ip: "127.0.0.1",
          isActive: 1,
          image: imageUrl,
          doctor: specialistsId,
          position_id: nextId,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
          action: "command",
          command: [
            {
              agent: "createCategory",
              appName: "selfMaximized",
              folder: "category",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authCtx.token}`,
          },
        }
      )
      .then(async (response) => {
        if (response.data.statusCode === 200) {
          setDomainTypes({ domainName: "", domainSummary: "" });
          setImgSrc("");
          toast.success("Category Added!");
          setLoader(false);
          navigate("/dashboard/categories");
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
        setLoader(false);
      });
  };

  const selectField = async (fieldName: any) => {
    setFieldName(fieldName);
    setSearchItem("");
  };
  return (
    <>
      <main className="page-content categories-wrpper overflow-auto">
        <div className="container pt-3 pt-lg-0">
          <div className="row pb-4">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 text-right">
              {loader ? (
                <button
                  className="categories__add-btn btn__border--radius publish-btn category-publish--btn"
                  disabled
                >
                  <span className="spinner-border spinner-border-sm mr-2 text-dark"></span>
                  <span className="font-weight-bold"> LOADING...</span>
                </button>
              ) : (
                <button
                  className="categories__add-btn publish-btn category-publish--btn btn__border--radius"
                  onClick={addCategories}
                >
                  <span className="font-weight-bold">PUBLISH</span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="container pb-4 categories_publish-wrapper">
          <div className="py-2 categories_publish-wrapper-inner p-3 p-lg-3">
            <div className="row">
              <div className="col-lg-7">
                <div className="form-group">
                  <label htmlFor="exampleFormControlInput1 mb-0 pb-3">
                    WELLNESS CATEGORY
                  </label>
                  <input
                    type="text"
                    className="form-control w-100"
                    id="domainName"
                    value={domainTypes.domainName}
                    name="domainName"
                    onChange={handleDomainTypes}
                    autoComplete="off"
                    onKeyDown={handleKeyDownEvent}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleFormControlTextarea1">
                    WELLNESS SUMMARY
                  </label>
                  <textarea
                    className="form-control text-uppercase"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    value={domainTypes.domainSummary}
                    name="domainSummary"
                    onChange={handleDomainTypes}
                    autoComplete="off"
                    onKeyDown={handleKeyDownEvent}
                  ></textarea>
                </div>
                <p className="mb-0 font-12 pb-2">CHOOSE FEATURED IMAGE</p>
                <div
                  className="d-flex justify-content-center pb-3 categories__publish position-relative add__categories__upload__image"
                  onClick={handleTriggerClick}
                >
                  <img
                    src={imgSrc}
                    alt=""
                    style={{ width: "auto", height: "auto" }}
                    className={
                      imgSrc ? `categories__publish__upload__image w-100` : ""
                    }
                  />
                  <div>
                    <div>
                      <div className="font-10 text-center mt-5">
                        {imgSrc ? (
                          ""
                        ) : (
                          <svg
                            className="icon-icon-plus "
                            viewBox="0 0 14 14"
                            height={40}
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.00738 2.18188C7.36472 2.18188 6.84374 2.70286 6.84374 3.34552V6.83643H3.35291C2.71025 6.83643 2.18927 7.35741 2.18927 8.00007C2.18927 8.64273 2.71025 9.16371 3.35291 9.16371H6.84374V12.6546C6.84374 13.2973 7.36472 13.8182 8.00738 13.8182C8.65004 13.8182 9.17102 13.2973 9.17102 12.6546V9.16371H12.662C13.3047 9.16371 13.8256 8.64273 13.8256 8.00007C13.8256 7.35741 13.3047 6.83643 12.662 6.83643H9.17102V3.34552C9.17102 2.70286 8.65004 2.18188 8.00738 2.18188Z"
                            />
                          </svg>
                        )}
                        <p className="mb-0 font-14 text-uppercase">
                          {imgSrc ? "" : "Add the featured photo"}
                        </p>
                      </div>
                    </div>

                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                </div>
              </div>
              <ManageDoctors
                specialistList={specialistList}
                searchItem={searchItem}
                toggleSpecialistSelection={toggleSpecialistSelection}
                selectField={selectField}
                handleSpecialist={handleSpecialist}
                selectedSpecialist={selectedSpecialist}
                fieldName={fieldName}
                categoryLoader={categoryLoader}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCategories;
