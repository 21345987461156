import React, { useState, useEffect, useCallback } from "react";
import axios, { AxiosResponse } from "axios";
import { useToken } from "../../../../authContext";
import { Link, useNavigate } from "react-router-dom";
import Shimmer from "../../Shimmer";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";

const SpecialistList: React.FC = () => {
  const [specialistList, setSpecialistList] = useState<any>("");
  const [paginationCount, setPaginationCount] = useState<any>(1);
  const [specialistListCount, setSpecialistListCount] = useState<any>("");
  const [totalPages, setTotalPages] = useState<any>(0);
  const [checkedState, setCheckedState] = useState<any>("all");
  const [loader, setLoader] = useState<any>(false);
  const authContext = useToken();
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState<any>([]);

  const handleSpecialistList = (type: any) => async (event: any) => {
    event.preventDefault();
    let filters = [];
    if (type === "active") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 1,
      });
    } else if (type === "inactive") {
      filters.push({
        field: "isActive",
        operator: "eq",
        value: 2,
      });
    } else if (type === "all") {
      filters = [];
    }

    setFilterType(filters);
    setCheckedState(type);
    setPaginationCount(1);
    await getSpecialistList(filters, 1);
  };

  const pagination = async (type: any, totalPages: any) => {
    if (type === "next") {
      setPaginationCount((prevState: any) => prevState + 1);
      await getSpecialistList(filterType, paginationCount + 1);
    } else if (type === "previous") {
      setPaginationCount((prevState: any) => prevState - 1);
      await getSpecialistList(filterType, paginationCount - 1);
    } else if (type === "first") {
      setPaginationCount(1);
      await getSpecialistList(filterType, 1);
    } else if (type === "last") {
      setPaginationCount(Math.ceil(totalPages));
      console.log(Math.ceil(totalPages), filterType);
      console.log(specialistListCount);
      await getSpecialistList(filterType, Math.ceil(totalPages));
    }
  };

  const getSpecialistList = async (filters: any, offset: any) => {
    setLoader(true);
    const response: AxiosResponse<any> = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: offset ? (offset - 1) * 6 : 0,
            limit: 6,
            filters: filters,
            sort: [
              {
                field: "id",
                dir: "desc",
                table: "Users",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf3",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authContext.token}` },
      }
    );
    console.log("responsresponseresponseresponsee", response?.data?.totalCount);

    setSpecialistList(response.data.data);
    setSpecialistListCount(response?.data?.totalCount);
    setTotalPages(Math.ceil(response?.data?.totalCount / 6)); // Calculate totalPages based on totalCount directly
    setLoader(false);
  };

  const getList = useCallback(getSpecialistList, [
    authContext.token,
    specialistListCount,
  ]);

  const goToSpecialistDetailsPage = (
    id: number,
    specialistList: any,
    filterType: any
  ) => {
    navigate(`specialistDetails/${id}`, {
      state: { specialistList, filterType },
    });
  };

  useEffect(() => {
    getList("", "");
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper specialists__wrapper categories-wrpper user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row pb-lg-0">
            <div className="col-lg-12 px-0">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs justify-content-sm-between"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item" onClick={handleSpecialistList("all")}>
                  <Link
                    to={"#pills-all"}
                    className={`nav-link ${
                      checkedState === "all" ? "active" : ""
                    }  mr-4`}
                    id="pills-all-tab"
                    data-toggle="pill"
                    aria-controls="pills-all"
                    aria-selected="true"
                  >
                    ALL
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={handleSpecialistList("active")}
                >
                  <Link
                    to={"#pills-active"}
                    className={`nav-link ${
                      checkedState === "active" ? "active" : ""
                    } mr-4`}
                    id="pills-active-tab"
                    data-toggle="pill"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    ACTIVE
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={handleSpecialistList("inactive")}
                >
                  <Link
                    to={"#pills-inactive"}
                    className={`nav-link ${
                      checkedState === "inactive" ? "active" : ""
                    }`}
                    id="pills-inactive-tab"
                    data-toggle="pill"
                  >
                    INACTIVE
                  </Link>
                </li>
              </ul>
              {/* New tab Container */}
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab table-responsive">
                    <table className="table table--lines table--striped active">
                      <colgroup>
                        <col className="colgroup-1" />
                        <col className="colgroup-3" />
                        <col className="colgroup-4" />
                        <col className="colgroup-5" />
                        <col className="colgroup-6" />
                        <col />
                      </colgroup>
                      <thead className="table__header table__header--sticky text-center">
                        <tr>
                          <th className="text-truncate">
                            <span>Name</span>
                          </th>
                          <th className="text-truncate">
                            <span>Gender</span>
                          </th>
                          <th className="text-truncate">
                            <span>Email- Id</span>
                          </th>
                          <th className="text-truncate">
                            <span>Mobile No.</span>
                          </th>

                          <th className="table__actions text-truncate"></th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {specialistList.length !== 0 &&
                        specialistList !== null ? (
                          specialistList.map((elem: any) => (
                            <tr className="table__row" key={elem.id}>
                              <td className="table__td">
                                <div className="media-item">
                                  <div className="media-item__right">
                                    <h5 className="media-item__title text-truncate font-14 pl-2 pl-lg-0 text-uppercase">
                                      {elem.name}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td className="table__td">
                                <span className="text-grey text-truncate font-14 text-uppercase">
                                  {elem.gender}
                                </span>
                              </td>
                              <td className="table__td">
                                <span className="text-truncate font-14 text-transform-unset">
                                  {elem.email}
                                </span>
                              </td>
                              <td className="table__td">
                                <span className="text-grey text-truncate font-14">
                                  {elem.phone}
                                </span>
                              </td>

                              <td className="table__td table__actions">
                                <div
                                  className="items-more font-14 user__view--more text-truncate pr-1 pr-md-5 text-uppercase"
                                  onClick={() =>
                                    goToSpecialistDetailsPage(
                                      elem.uuid,
                                      elem,
                                      filterType
                                    )
                                  }
                                >
                                  View
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <img
                                src={noDataFoundImage}
                                alt=""
                                className="img-fluid user__all__active__inactive__card--image"
                                style={{ width: "auto", height: "500px" }}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 text-right px-0 my-3">
              <nav
                aria-label="Page navigation example"
                className="pagination__card"
              >
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" aria-label="Previous">
                      <button
                        aria-hidden="true"
                        className={`${
                          paginationCount === 1
                            ? "next__arrow--icon"
                            : "text-white"
                        }`}
                        onClick={() => pagination("first", "")}
                        disabled={paginationCount === 1}
                      >
                        &laquo;
                      </button>
                      <button
                        className={`${
                          paginationCount === 1
                            ? "next__arrow--icon"
                            : "text-white"
                        }  sr-only`}
                        onClick={() => pagination("previous", "")}
                        disabled={paginationCount === 1}
                      >
                        Previous
                      </button>
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className={`${
                        paginationCount === 1
                          ? "next__arrow--icon"
                          : "text-white next--page"
                      }  page-link previous--page `}
                      onClick={() => pagination("previous", "")}
                      disabled={paginationCount === 1}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <button
                      className={`${
                        paginationCount === Math.ceil(totalPages) ||
                        specialistList.length < 6
                          ? "next--btn"
                          : "text-white next--page"
                      } page-link `}
                      onClick={() => pagination("next", "")}
                      disabled={
                        paginationCount === Math.ceil(totalPages) ||
                        specialistList.length < 6
                      }
                    >
                      Next
                    </button>
                  </li>
                  <li className="page-item">
                    <button className="page-link" aria-label="Next">
                      <button
                        aria-hidden="true"
                        className={` ${
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                            ? "next__arrow--icon"
                            : "text-white"
                        }  `}
                        onClick={() =>
                          pagination("last", specialistListCount / 6)
                        }
                        disabled={
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                        }
                      >
                        &raquo;
                      </button>
                      <button
                        className={`${
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                            ? "next--btn"
                            : "text-white"
                        }  sr-only`}
                        onClick={() => pagination("next", "")}
                        disabled={
                          paginationCount === Math.ceil(totalPages) ||
                          specialistList.length < 6
                        }
                      >
                        Next
                      </button>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SpecialistList;
