import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import Shimmer from "../../Shimmer";
import ViewCalendar from "../../../../ViewCalendar";
import { useToken } from "../../../../authContext";

const SpecialistDetails: React.FC = () => {
  const location = useLocation();
  const authCtx = useToken();
  const specialistData = location.state?.specialistList;
  const catWiseSpecialist = location.state?.catWiseSpecialist;
  const filterType = location.state?.filterType;
  const [showFullDescription, setShowFullDescription] = useState<any>(false);
  const [loader, setLoader] = useState<any>(false);
  const [specialistDetails, setSpecialistDetails] = useState<any>([]);
  const [specialistDetailsExp, setSpecialistDetailsExp] = useState<any>([]);
  const [specialistBio, setSpecialistBio] = useState<any>("");
  const [truncatedSpecialistBio, setTruncatedSpecialistBio] = useState<any>("");
  const [showData, setShowData] = useState<any>(false);
  const { id } = useParams();
  const [isActive, setIsActive] = useState<any>(false);

  const togglespecialist = async () => {
    await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        uuid: `${specialistData.uuid ?? id}`,
        isActive: isActive ? 2 : 1,
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        role: "specialist",
        email: `${specialistData.email}`,
        baseLink: `${process.env.REACT_APP_LOCALHOST_URL_APP}/#`,
        action: "command",
        command: [
          {
            agent: "approveSpecialist",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
        },
      }
    );
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
    togglespecialist();
  };

  useEffect(() => {
    // togglespecialist()
  }, [isActive]);

  const getSpecialistDetails = async () => {
    setLoader(true);

    let filters: any = [];
    if (catWiseSpecialist === "catWiseSpecialist") {
      filters.push({
        field: "uuid",
        operator: "eq",
        value: id,
      });
    } else if (filterType && filterType.length !== 0) {
      filters.push(filterType[0], {
        field: "uuid",
        operator: "eq",
        value: id,
      });
    } else {
      filters.push({
        field: "uuid",
        operator: "eq",
        value: id,
      });
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 1,
            filters: filters,
            sort: [
              {
                field: "id",
                table: "Users",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "specialistListsData",
            appName: "selfMaximized",
            folder: "specialist",
          },
        ],
      },
      {
        headers: {
          "x-access-token": `${authCtx.token}`,
        },
      }
    );
    if (response) {
      setSpecialistDetails(response?.data?.data[0]);
      setSpecialistDetailsExp(response?.data?.data[0].DoctorExperiences);
      setLoader(false);
      response?.data?.data[0]?.isActive == 1
        ? setIsActive(true)
        : setIsActive(false);
    }

    // Extracting first 2-3 lines from the description

    if (response?.data?.data[0].bio.split(" ").length > 20) {
      setSpecialistBio(response?.data?.data[0].bio.split("\n").join("\n"));
      let sentences = response?.data?.data[0].bio.match(/[^\.!\?]+[\.!\?]+/g); // Match sentences
      setTruncatedSpecialistBio(sentences.slice(0, 3).join(" ")); // Take the first three sentences and join them
    } else if (response?.data?.data[0].bio.split(" ").length < 20) {
      setSpecialistBio(response?.data?.data[0].bio);
      setShowData(true);
    }
  };

  const getSpecialist = useCallback(getSpecialistDetails, [
    authCtx.token,
    filterType,
  ]);
  useEffect(() => {
    getSpecialist();
  }, [getSpecialist]);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const renderLanguage = (language: any): React.ReactNode => {
    let filteredLanguage: any;
    if (language) {
      const parsedLang = JSON.parse(language);
      filteredLanguage = parsedLang.map((elem: any) => elem).join(" | ");
    }
    return filteredLanguage ? filteredLanguage : null;
  };

  const renderAreaOfExp = (areaOfExp: any): React.ReactNode => {
    let filteredAreaOfExp: any;

    if (areaOfExp) {
      const parsedAreaOfExp = JSON.parse(areaOfExp);
      filteredAreaOfExp = parsedAreaOfExp
        .map((elem: any) => elem.category)
        .join(" | ");
    }

    return filteredAreaOfExp ? filteredAreaOfExp : null;
  };

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper overflow-auto ">
        <div className="container pt-5 pt-lg-0">
          <div className="mb-3 d-flex justify-content-end">
            <label className={`switch ${isActive ? "active" : "inactive"}`}>
              <input
                type="checkbox"
                onChange={toggleSwitch}
                checked={isActive}
              />
              <span className="slider round"></span>
              <span
                className={`status ${isActive ? "mr-4" : "ml-4 text-dark"}`}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
          </div>
          <div className="specialist-container_wrapper rounded-lg">
            <div className="row pb-4 rounded-lg">
              <div className="col-lg-7 specialist-container_wrapper-left-part mb-3 mb-lg-0">
                <div className="row specialist-container_wrapper-left p-3 mb-3  border-bottom">
                  <div className="col-lg-3 col-md-4 pb-3 pb-lg-0 h-100">
                    <div className="thumb specialist__doctor--image w-100">
                      <img
                        src={specialistDetails.profile}
                        alt=""
                        className="w-100"
                        style={{ width: "auto", height: "auto" }}
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-12 table-responsive specialist__details">
                            <table className="table mb-0 table-bordered w-100">
                              <tbody>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Name :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12  text-left email-id">
                                      {specialistDetails.name}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Phone no :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12 pb-1 text-left">
                                      {specialistDetails.phone}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Email ID :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12  text-left text-transforn-unset">
                                      {specialistDetails.email}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Years of experience :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12  text-left">
                                      {specialistDetailsExp.length === 0
                                        ? "No Data Avaiable"
                                        : specialistDetailsExp[0]
                                            .yearofexperience}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Language :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12  text-left">
                                      {renderLanguage(
                                        specialistDetailsExp[0]?.language
                                      )}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-top max-width-130">
                                    <p className="mb-0 details_title font-12  specialist-user_details">
                                      Gender :
                                    </p>
                                  </td>
                                  <td>
                                    <p className="mb-0 details_title font-12  text-left">
                                      {specialistDetails.gender}
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row bio-text-wrapper pb-3 align-items-center">
                  <div className="col-lg-12">
                    <p className="mb-0 font-14 text-uppercase">Bio</p>
                  </div>
                </div>
                <div className="row bio-wrapper align-items-center pb-4">
                  <div className="col-lg-8 col-md-7 border-right ">
                    {!showData && (
                      <p className="mb-0 font-14">
                        {showFullDescription
                          ? specialistBio
                          : truncatedSpecialistBio}
                      </p>
                    )}
                    {showData && (
                      <p className="mb-0 font-14">{specialistDetails.bio}</p>
                    )}
                    {!showData &&
                      specialistBio.length > truncatedSpecialistBio.length && (
                        <button onClick={toggleDescription}>
                          {showFullDescription ? (
                            <span className="font-14 view__more__less text-primary">
                              Less
                            </span>
                          ) : (
                            <span className="font-14 view__more__less text-primary">
                              More
                            </span>
                          )}
                        </button>
                      )}
                  </div>
                  <div className="col-lg-4 col-md-5 col-6">
                    <div className="certifications-download">
                      <Link
                        to={`${
                          specialistDetailsExp.length === 0
                            ? "No Data Avaiable"
                            : specialistDetailsExp[0].file
                        }`}
                        className="d-flex justify-content-between align-items-center p-1"
                      >
                        <div>
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            className="text-danger ml-1"
                          />
                          <span className="font-12 ml-2">Certifications</span>
                        </div>

                        <svg
                          className="icon-download mr-1 download--symbol"
                          viewBox="0 0 16 16"
                        >
                          <path d="M17.1765 8.64706H13.4118V3H7.76471V8.64706H4L10.5882 15.2353L17.1765 8.64706ZM4 17.1176V19H17.1765V17.1176H4Z" />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row here-is-what_describe pb-3">
                  <div className="col-lg-12 pb-3">
                    <p className="mb-0 here-is-what-title font-14 text-uppercase">
                      Here’s what describes me the best…
                    </p>
                  </div>
                </div>
                <div className="row my-experties_wrapper text-uppercase">
                  <div className="col-lg-12 pb-2">
                    <p className="mb-0 here-is-what-title text-uppercase">
                      My expertise
                    </p>
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      {specialistDetailsExp.length !== 0 ? (
                        specialistDetailsExp.map((elem: any) => (
                          <div className="col-6" key={elem.id}>
                            <ul className="p-0">
                              <li className="my-experties_sub font-12">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="6"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-circle text-white"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg>{" "}
                                <span className="pl-1">
                                  {renderAreaOfExp(elem?.areaOfExpertise)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        ))
                      ) : (
                        <p>No Data Available</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
              <div className="col-lg-5 specialist-container_wrapper-right-part px-0 px-lg-3">
                <ViewCalendar doctorId={specialistDetails.id} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal modal-compact modal-success scrollbar-thin"
          id="addProductSuccess"
          data-simplebar
        >
          <div className="modal__overlay" data-dismiss="modal"></div>
          <div className="modal__wrap">
            <div className="modal__window">
              <div className="modal__content">
                <div className="modal-header w-100 d-flex justify-content-between align-items-center">
                  <div>
                    <Link to={""} className="select-domain-back-arrow">
                      <svg className="icon-icon-keyboard-left">
                        <use xlinkHref="#icon-keyboard-left"></use>
                      </svg>
                    </Link>
                  </div>
                  <h5
                    className="modal-title font-12 font-weight-bold"
                    id="staticBackdropLabel"
                  >
                    Select Domain
                  </h5>
                  <button
                    type="button"
                    className="close"
                    id="more-specialist-close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal__body pb-4 pt-0 select-domain-wrapper-more-specialist">
                  <div className="modal__container py-5 py-lg-2">
                    <div className="header__search pt-2">
                      <form className="form-search" action="#" method="GET">
                        <div className="form-search__container">
                          <span className="form-search__icon-left">
                            <svg className="icon-icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </span>
                          <input
                            className="form-search__input"
                            type="text"
                            placeholder="Search Domain"
                          />
                        </div>
                      </form>
                    </div>
                    <div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                      <div className="custom-control custom-checkbox d-flex justify-content-between p-0 my-3">
                        <p className="mb-0 font-13">Nutrition</p>
                        <label className="checkbox checkbox--circle">
                          <input type="checkbox" />
                          <span className="checkbox__marker">
                            <span className="checkbox__marker-icon">
                              <svg viewBox="0 0 21 18">
                                <path
                                  className="border"
                                  d="M16.3809 4.29875C16.1581 3.94957 15.6944 3.84715 15.3452 4.06998C14.9961 4.29281 14.8936 4.7565 15.1165 5.10568L16.3809 4.29875ZM12.9024 2.88868C13.2513 3.11197 13.7151 3.01016 13.9384 2.66128C14.1617 2.3124 14.0599 1.84857 13.711 1.62528L12.9024 2.88868ZM16.25 9C16.25 13.0041 13.0041 16.25 9 16.25V17.75C13.8325 17.75 17.75 13.8325 17.75 9H16.25ZM9 16.25C4.99594 16.25 1.75 13.0041 1.75 9H0.25C0.25 13.8325 4.16751 17.75 9 17.75V16.25ZM1.75 9C1.75 4.99594 4.99594 1.75 9 1.75V0.25C4.16751 0.25 0.25 4.16751 0.25 9H1.75ZM15.1165 5.10568C15.8341 6.23014 16.25 7.56535 16.25 9H17.75C17.75 7.27102 17.2478 5.65706 16.3809 4.29875L15.1165 5.10568ZM9 1.75C10.4381 1.75 11.7762 2.16793 12.9024 2.88868L13.711 1.62528C12.3507 0.754648 10.7331 0.25 9 0.25V1.75Z"
                                />
                                <path d="M18.0275 2.42896C18.3156 2.7323 18.3033 3.21181 18 3.49998L8.7036 12.0645C8.40574 12.3475 7.93663 12.3415 7.64613 12.051L5.22189 9.62673C4.92604 9.33088 4.92604 8.85121 5.22189 8.55536C5.51774 8.25951 5.99741 8.25951 6.29326 8.55536L8.19538 10.4575L16.9564 2.4015C17.2598 2.11333 17.7393 2.12563 18.0275 2.42896Z" />
                              </svg>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-compact__buttons d-flex justify-content-end">
                  <button
                    className="modal-compact__button button specialists-submit-btn"
                    data-dismiss="modal"
                  >
                    <span className="button__text">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SpecialistDetails;
