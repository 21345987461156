import React from "react";
import "../Dashboard.css";

interface AnalyticsItem {
  repeatedCustomersCount: number;
  totalGrossSales: string; // or number if your API returns numeric
  totalOrdersCount: number;
  totalSuccessedOrders: number;
}

interface DataProps {
  data: AnalyticsItem[]; // an array of AnalyticsItem
}

const StatsGrid: React.FC<DataProps> = ({ data }) => {
  // If there's no data or empty array, we can safely return null or a loading message
  if (!data || data.length === 0) {
    return null;
  }

  // Destructure the first (and presumably only) object
  const {
    repeatedCustomersCount,
    totalGrossSales,
    totalOrdersCount,
    totalSuccessedOrders,
  } = data[0];

  // Optionally calculate the returning customer rate
  // E.g., repeatedCustomersCount / totalOrdersCount * 100
  const returningCustomerRate =
    totalOrdersCount > 0
      ? ((repeatedCustomersCount / totalOrdersCount) * 100).toFixed(2)
      : "0";

  return (
    <div className="stats-grid">
      <div className="stat-card">
        <h3 className="stat-title">GROSS SALES</h3>
        {/* For currency, you could add a .toLocaleString() or similar */}
        <div className="stat-value">
          $ {parseFloat(totalGrossSales).toLocaleString()}
        </div>
      </div>

      <div className="stat-card">
        <h3 className="stat-title">RETURNING CUSTOMER RATE</h3>
        {/* Show the calculated percentage */}
        <div className="stat-value">{returningCustomerRate}%</div>
      </div>

      <div className="stat-card">
        <h3 className="stat-title">ORDERS FULFILLED</h3>
        <div className="stat-value">{totalSuccessedOrders}</div>
      </div>

      <div className="stat-card">
        <h3 className="stat-title">ORDERS</h3>
        <div className="stat-value">{totalOrdersCount}</div>
      </div>
    </div>
  );
};

export default StatsGrid;
