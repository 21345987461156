// TimeFilter.tsx
import React from "react";
import "../Dashboard.css";
import CalendarAnalytics from "./CalenderAnalytics";

interface TimeFilterProps {
  handleDateChange: (date: { startDate: string; endDate: string }) => void;
}

const TimeFilter: React.FC<TimeFilterProps> = ({ handleDateChange }) => {
  return (
    <div className="time-filter">
      <CalendarAnalytics
        backgroundColor="#000000"
        onChange={handleDateChange}
      />
    </div>
  );
};

export default TimeFilter;
