import axios from "axios";
import React from "react";
import { useToken } from "../../../../authContext";
import { toast } from "react-toastify";
import noDataFoundImage from "../../../../../src/img/content/bg/page-not-found.png";
import useUserRefundList from "../../../../customHooks/useUserRefundList";

const RefundList = () => {
  const authContext = useToken();

  const refundList = useUserRefundList();

  const createRefund = async (payload: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          action: "command",
          doctorId: payload?.doctorId,
          userId: payload?.userId,
          chargeId: payload?.chargeId,
          orderId: payload?.orderId,
          amount: payload?.amount,
          command: [
            {
              agent: "createRefund",
              appName: "selfMaximized",
              folder: "admin",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          return toast.success(result?.data?.message);
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("SOMETHING WENT WRONG", err);
      });
  };

  return (
    <main className="page-content user_wrapper user-wrapper-inner overflow-auto">
      <div className="container pt-3 pt-lg-0">
        <div className="row">
          <div className="col-lg-12">
            <ul
              className="nav nav-pills mb-3 my__bookings__tabs"
              id="pills-tab"
              role="tablist"
            ></ul>
            <div className="tab-content pt-3 pt-lg-0" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-all"
                role="tabpanel"
                aria-labelledby="pills-all-tab"
              >
                <div className="tab">
                  <div className="container pb-4 px-0">
                    <div className="col-lg-12 px-0">
                      <div className="tab w-100">
                        <div className="table-responsive user__all__active__inactive__card">
                          <table className="table table--lines table--striped">
                            <thead className="table__header table__header--sticky text-center">
                              <tr>
                                <th className="col-2">
                                  <span>User</span>
                                </th>
                                <th className="col-2">
                                  <span>Specialist</span>
                                </th>
                                <th className="col-2">
                                  <span>Order Id</span>
                                </th>
                                <th className="col-2">
                                  <span>Duration</span>
                                </th>
                                <th className="col-2">
                                  <span>Amount</span>
                                </th>
                                <th className="col-2">
                                  <span>REFUND</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {refundList.length !== 0 &&
                              refundList !== null ? (
                                refundList
                                  .filter((elem: any) => elem.chargeId !== null)
                                  .map((elem: any) => (
                                    <tr className="table__row" key={elem.id}>
                                      <td className="table__td">
                                        <div className="media-item">
                                          <div className="media-item__right">
                                            <h5 className="media-item__title text-truncate font-14 pl-3 text-uppercase">
                                              {elem?.user_name}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-truncate font-14">
                                          {elem?.specialist_name}
                                        </span>
                                      </td>
                                      <td className="table__td">
                                        <span className="text-grey text-truncate font-14">
                                          {elem?.orderId}
                                        </span>
                                      </td>
                                      <td className="table__td text-center">
                                        <span className="text-grey text-truncate font-14">
                                          {elem?.duration}
                                        </span>
                                      </td>
                                      <td className="table__td text-center">
                                        <span className="text-grey text-truncate font-14">
                                          {elem?.amount}
                                        </span>
                                      </td>
                                      <td className="table__td text-center">
                                        <button
                                          className="btn btn-dark btn-sm"
                                          onClick={() => createRefund(elem)}
                                        >
                                          REFUND
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td className="p-0">
                                    <div className="d-flex justify-content-center align-items-center">
                                      <img
                                        src={noDataFoundImage}
                                        alt="No Data Available"
                                        className="img-fluid"
                                        style={{
                                          maxWidth: "300px", // Adjust size as needed
                                          maxHeight: "200px",
                                          objectFit: "contain",
                                        }}
                                        loading="lazy"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        {/* <div className="row mt-3 mt-lg-0">
                            <div className="col-lg-12 text-right">
                              <nav
                                aria-label="Page navigation example"
                                className="pagination__card"
                              >
                                <ul className="pagination">
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Previous"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }`}
                                        onClick={() => pagination("first", "")}
                                        disabled={paginationCount === 1}
                                      >
                                        &laquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount === 1
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() =>
                                          pagination("previous", "")
                                        }
                                        disabled={paginationCount === 1}
                                      >
                                        Previous
                                      </button>
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount === 1
                                          ? "next__arrow--icon"
                                          : "text-white next--page"
                                      }  page-link previous--page `}
                                      onClick={() => pagination("previous", "")}
                                      disabled={paginationCount === 1}
                                    >
                                      Previous
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className={`${
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        userData.length < 6
                                          ? "next--btn"
                                          : "text-white next--page"
                                      } page-link `}
                                      onClick={() => pagination("next", "")}
                                      disabled={
                                        paginationCount ===
                                          Math.ceil(totalPages) ||
                                        userData.length < 6
                                      }
                                    >
                                      Next
                                    </button>
                                  </li>
                                  <li className="page-item">
                                    <button
                                      className="page-link"
                                      aria-label="Next"
                                    >
                                      <button
                                        aria-hidden="true"
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                            ? "next__arrow--icon"
                                            : "text-white"
                                        }  `}
                                        onClick={() =>
                                          pagination("last", userListCount / 6)
                                        }
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                        }
                                      >
                                        &raquo;
                                      </button>
                                      <button
                                        className={`${
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                            ? "next--btn"
                                            : "text-white"
                                        }  sr-only`}
                                        onClick={() => pagination("next", "")}
                                        disabled={
                                          paginationCount ===
                                            Math.ceil(totalPages) ||
                                          userData.length < 6
                                        }
                                      >
                                        Next
                                      </button>
                                    </button>
                                  </li>
                                </ul>
                              </nav>
                            </div>
                          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RefundList;
