import React, { useCallback, useEffect, useState } from "react";
import { useToken } from "../../authContext";
import axios from "axios";
import noDataFoundImage from "../../../src/img/content/no_data_available/no-data-found.png";
import Shimmer from "../../pages/AdminDashboard/Shimmer";
import { toast } from "react-toastify";
import moment from "moment";

const NotificationList: React.FC = () => {
  const [notificationList, setNotificationList] = useState<any>("");
  const [loader, setLoader] = useState<any>(false);
  const authContext = useToken();

  const getNotificationList = async () => {
    setLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 500,
              filters: [
                {
                  field: "status",
                  table: "notification",
                  operator: "eq",
                  value: "0",
                },
              ],
              sort: [
                {
                  field: "id",
                  table: "notification",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "notificationList",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setNotificationList(result.data?.data);
          setLoader(false);
        } else {
          setNotificationList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getList = useCallback(getNotificationList, [authContext.token]);

  useEffect(() => {
    getList();
  }, []);

  return loader ? (
    <Shimmer />
  ) : (
    <>
      <main className="page-content user_wrapper specialists__wrapper categories-wrpper user_wrapper overflow-auto">
        <div className="container pt-5 pt-lg-0">
          <div className="row pb-lg-0">
            <div className="col-lg-12 px-0">
              <ul
                className="nav nav-pills mb-3 my__bookings__tabs justify-content-sm-between"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <span
                    className={`nav-link active mr-4`}
                    id="pills-active-tab"
                    data-toggle="pill"
                    aria-controls="pills-active"
                    aria-selected="false"
                  >
                    LIST
                  </span>
                </li>
              </ul>
              {/* New tab Container */}
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-all"
                  role="tabpanel"
                  aria-labelledby="pills-all-tab"
                >
                  <div className="tab table-responsive">
                    <table className="table table--lines table--striped active">
                      <colgroup>
                        <col className="colgroup-2" />
                        <col className="colgroup-10" />
                      </colgroup>
                      <thead className="table__header table__header--sticky text-center">
                        <tr>
                          <th className="text-truncate col-2">
                            <span>Date</span>
                          </th>
                          <th className="text-truncate col-10">
                            <span>Message</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-center">
                        {notificationList.length !== 0 &&
                        notificationList !== null ? (
                          notificationList.map((elem: any, index: any) => (
                            <tr className="table__row" key={index}>
                              <td className="table__td">
                                <div className="media-item">
                                  <div className="media-item__right">
                                    <h5 className="media-item__title text-truncate font-14 pl-2 pl-lg-0">
                                      {moment(elem.onCreate).format(
                                        "YYYY-MM-DD"
                                      )}
                                    </h5>
                                  </div>
                                </div>
                              </td>
                              <td className="table__td">
                                <span className="text-grey text-truncate font-14">
                                  {elem.message}
                                </span>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <div className="row">
                            <div className="col-lg-12">
                              <img
                                src={noDataFoundImage}
                                alt=""
                                className="img-fluid user__all__active__inactive__card--image"
                                style={{ width: "auto", height: "500px" }}
                                loading="lazy"
                              />
                            </div>
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default NotificationList;
