import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";

const PersistentTimer = ({ duration, startTime, endTime, timezone }: any) => {
  const [timeLeft, setTimeLeft] = useState(0); // in seconds

  const getSecondsBetween = (from: any, to: any) =>
    Math.max(0, Math.floor(to.diff(from, "seconds").seconds));

  useEffect(() => {
    const initializeTimer = () => {
      const storedEndTime = localStorage.getItem("timerEndTime");
      const parsedStoredEndTime =
        storedEndTime && storedEndTime !== "null" ? storedEndTime : null;

      const startDateUTC = DateTime.fromISO(startTime, { zone: "utc" });
      const endDateUTC = DateTime.fromISO(endTime, { zone: "utc" });

      console.log("Parsed startDateUTC:", startDateUTC.toISO());
      console.log("Parsed endDateUTC:", endDateUTC.toISO());

      let finalEndTime: any;

      if (parsedStoredEndTime) {
        finalEndTime = DateTime.fromISO(parsedStoredEndTime, { zone: "utc" });
        console.log("Using storedEndTime:", finalEndTime.toISO());
      } else {
        const durationMinutes = Number(duration) === 0.5 ? 30 : 60;
        console.log("Duration minutes:", durationMinutes);

        const calculatedEndTime = startDateUTC.plus({
          minutes: durationMinutes,
        });
        console.log(
          "Calculated end time from duration:",
          calculatedEndTime.toISO()
        );

        // Use the earlier one
        finalEndTime =
          calculatedEndTime > endDateUTC ? endDateUTC : calculatedEndTime;
        console.log("Final end time chosen:", finalEndTime.toISO());

        localStorage.setItem("timerEndTime", finalEndTime.toISO());
      }

      const nowUTC = DateTime.now().setZone("utc");
      console.log("Now UTC:", nowUTC.toISO());

      const secondsLeft = Math.max(0, getSecondsBetween(nowUTC, finalEndTime));
      console.log("Seconds left calculated:", secondsLeft);

      setTimeLeft(secondsLeft);
    };

    initializeTimer();

    // Countdown interval
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          localStorage.removeItem("timerEndTime"); // Clear when done
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [duration, startTime, endTime]);

  const formatTime = (seconds: any) => {
    const m = String(Math.floor(seconds / 60)).padStart(2, "0");
    const s = String(seconds % 60).padStart(2, "0");
    return `${m}:${s}`;
  };

  const userTimeNow = DateTime.now()
    .setZone(timezone)
    .toFormat("yyyy-MM-dd HH:mm:ss");

  return (
    <div className="d-flex">
      <div style={{ fontSize: "1rem", fontWeight: "bold" }}>
        TIME LEFT - {formatTime(timeLeft)} MIN
      </div>
      {timeLeft === 0 && <p>Timer completed.</p>}
    </div>
  );
};

export default PersistentTimer;
