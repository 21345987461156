import React, { useEffect, useMemo, useState } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
// Icons
import Filter from "../../../../../../../src/img/content/FilterIcon";
import Search from "../../../../../../../src/img/content/SearchIcon";
import ArrowLeft from "../../../../../../../src/img/content/BackIcon";
import Download from "../../../../../../../src/img/content/DownloadIcon";
import { getSubscriptionsDetails } from "../ApiServices/ReportsAPI";
import moment from "moment";
import { useToken } from "../../../../../../authContext";
import FilterDropdown from "./FilterDropdown";
import Pagination from "./Pagination";
import Shimmer from "../../../../Shimmer";

const filterMapping: Record<string, any> = {
  userName: {
    field: "userName",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
  specialistName: {
    field: "specialistName",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
  appointmentsPending: {
    field: "numberOfAppointments",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
  pendingAppn: {
    field: "pendingAppn",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
  orderId: {
    field: "orderId",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
  amount: {
    field: "subscription_amount",
    table: "SubscriptionList",
    operator: "contain",
    value: "",
  },
};
interface Subscription {
  userName: string;
  specialistName: string;
  subscriptionStatus: string;
  orderId: string;
  amount: string;
  appointmentsPending: string;
  recurringDate: string;
}

// Define your AntD columns
const columns: ColumnsType<Subscription> = [
  { title: "USER NAME", dataIndex: "userName", key: "userName" },
  {
    title: "SPECIALIST NAME",
    dataIndex: "specialistName",
    key: "specialistName",
  },
  {
    title: "SUBSCRIPTION STATUS",
    dataIndex: "subscriptionStatus",
    key: "subscriptionStatus",

    render: (status: string) => {
      // const className = status === "1" ? "status-active" : "status-inactive";
      return (
        <div
        // className={className}
        >
          {status === "1" ? "ACTIVE" : "INACTIVE"}
        </div>
      );
    },
  },
  { title: "ORDER ID", dataIndex: "orderId", key: "orderId" },
  { title: "AMOUNT", dataIndex: "amount", key: "amount" },
  {
    title: "APPOINTMENTS PENDING",
    dataIndex: "appointmentsPending",
    key: "appointmentsPending",
  },
  { title: "RECURRING DATE", dataIndex: "recurringDate", key: "recurringDate" },
];

const SubscriptionReport: React.FC = () => {
  const authContext = useToken();
  const [loader, setLoader] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [SubscriptionCount, setSubscriptionCount] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Multi-select filters
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>([]);
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);

  // Immediate vs Debounced search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Show/hide filter dropdown
  const [filterVisible, setFilterVisible] = useState(false);

  // Debounce effect for searchTerm
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPaginationCount(1); // reset to first page on new search
    }, 1000); // adjust delay as needed

    return () => clearTimeout(timer);
  }, [searchTerm]);
  // Build final filters for the API
  const filterType = useMemo(() => {
    return appliedFilterKeys.map((key) => {
      const obj = { ...filterMapping[key] };
      obj.value = debouncedSearchTerm; // only apply after debounce
      return obj;
    });
  }, [appliedFilterKeys, debouncedSearchTerm]);

  // Filter options
  const filterOptions = [
    { label: "USER NAME", value: "userName" },
    { label: "SPECIALIST NAME", value: "specialistName" },
    { label: "ORDER ID", value: "orderId" },
    { label: "AMOUNT", value: "amount" },
    { label: "TOTAL APPOINTMENTS", value: "appointmentsPending" },
    { label: "PENDING APPOINTMENTS", value: "pendingAppn" },
  ];

  // When user applies filters
  const handleFilterApply = () => {
    setAppliedFilterKeys(selectedFilterKeys);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // When user clears filters
  const handleFilterClear = () => {
    setSelectedFilterKeys([]);
    setAppliedFilterKeys([]);
    setPaginationCount(1);
    setFilterVisible(false);
  };
  // Utility function to check null, undefined, empty string, or space-only strings
  const isValidData = (value: any): boolean => {
    return (
      value !== null && value !== undefined && value.toString().trim() !== ""
    );
  };

  // Utility function to format values with fallback
  const formatValue = (value: any, fallback: string = "-") => {
    return isValidData(value) ? value.toString().trim() : fallback;
  };

  // Toggling checkboxes
  const handleCheckboxChange = (updated: string[]) => {
    setSelectedFilterKeys(updated);
  };

  const handleFetchPaymentDetails = async () => {
    try {
      setLoader(true);
      const responseData = await getSubscriptionsDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        searchTerm: "",
      });

      const rawPayments = responseData?.data || [];

      const formattedPayments: Subscription[] = rawPayments?.map(
        (item: any) => {
          const recurring_date = isValidData(item?.recurring_date)
            ? moment.utc(item?.recurring_date)
            : null; // Set to `null` if invalid

          return {
            userName: formatValue(item?.userName).toUpperCase(),
            specialistName: formatValue(item?.specialistName).toUpperCase(),
            subscriptionStatus: formatValue(item?.subscription_status),
            orderId: formatValue(item?.orderId),
            amount: `$ ${formatValue(item?.subscription_amount)}`,
            appointmentsPending: `${formatValue(
              item?.pendingAppn
            )}/${formatValue(item?.numberOfAppointments)}`,
            recurringDate:
              recurring_date && recurring_date.isValid()
                ? recurring_date.format("DD/MM/YYYY")
                : "-",
          };
        }
      );

      setSubscriptions(formattedPayments);
      setSubscriptionCount(responseData?.data?.totalCount || 0);
      setTotalPages(Math.ceil((responseData?.data?.totalCount || 0) / 4));
    } catch (error) {
      console.error("Failed to fetch cancellation Report details:", error);
    } finally {
      setLoader(false);
    }
  };

  const downloadExcel = async () => {
    try {
      setLoader(true);
      await getSubscriptionsDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        excelDownload: true,
      });
    } catch (error) {
      console.error("Failed to download Excel:", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    handleFetchPaymentDetails();
  }, [filterType, paginationCount]);

  // Pagination logic
  const pagination = (type: string, total: number) => {
    let newOffset = paginationCount;
    if (type === "next") {
      newOffset = paginationCount + 1;
    } else if (type === "previous") {
      newOffset = paginationCount - 1;
    } else if (type === "first") {
      newOffset = 1;
    } else if (type === "last") {
      newOffset = Math.ceil(total);
    }
    setPaginationCount(newOffset);
  };

  if (loader) return <Shimmer />;

  return (
    <div className="subscription-container">
      {/* Back button */}
      <Link to="/dashboard/analytics/reports" className="sr-back-button">
        <ArrowLeft width={18} height={18} />
        <span>ACTIVE/CANCELLATION USER SUBSCRIPTION REPORT</span>
      </Link>

      {/* Action bar */}

      <div className="ubdr-action-bar">
        <button
          className="ubdr-filter-button"
          onClick={() => {
            setFilterVisible(!filterVisible);
            setSelectedFilterKeys(appliedFilterKeys);
          }}
        >
          <Filter width={18} height={18} />
        </button>

        {/* Multi-select FilterDropdown */}
        <FilterDropdown
          filterOptions={filterOptions}
          visible={filterVisible}
          selectedValues={selectedFilterKeys}
          onChange={handleCheckboxChange}
          onApply={handleFilterApply}
          onClear={handleFilterClear}
          onVisibleChange={setFilterVisible}
        />

        <div className="ubdr-search-bar">
          <span className="ubdr-search-icon">
            <Search width={16} height={16} />
          </span>
          <input
            type="text"
            className="ubdr-search-reports"
            placeholder="SEARCH..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="ubdr-report-button" onClick={downloadExcel}>
          <Download width={18} height={18} />
          <span>REPORT</span>
        </button>
      </div>

      {/* Table container */}
      <div className="sr-table-container">
        <Table<Subscription>
          columns={columns}
          dataSource={subscriptions}
          rowKey="orderId"
          pagination={false}
          // Add custom row classes for striping
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>
      <Pagination
        pagination={pagination}
        paginationCount={paginationCount}
        setPaginationCount={setPaginationCount}
        totalPages={totalPages}
        list={subscriptions}
        ListCount={SubscriptionCount}
      />
    </div>
  );
};

export default SubscriptionReport;
