import React, { useEffect, useState } from "react";
import "./Dashboard.css";

// Import your custom components
import TimeFilter from "./components/TimeFilter";
import StatsGrid from "./components/StatsGrid";
import TotalSalesOverTimeChart from "./components/TotalSalesOverTimeChart";
import SalesBreakdown from "./components/SalesBreakdown";
import TotalSalesChart from "./components/TotalSalesChart";
import TotalOrdersChart from "./components/TotalOrdersChart";
import AverageOrderValueChart from "./components/AverageOrderValueChart";
import TopSellingProductsChart from "./components/TopSellingProductsChart";
import CustomerCohortAnalysisChart from "./components/CustomerCohortAnalysisChart";
import ReturningCustomerRateChart from "./components/ReturningCustomerRateChart";
import FulfilledOrdersOverTimeChart from "./components/FulfilledOrdersOverTimeChart";
import Shimmer from "../../../Shimmer";
import axios, { AxiosResponse } from "axios";
import { useToken } from "../../../../../authContext";
import moment from "moment";
const AnalyticsReports: React.FC = () => {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"];
  const [loader, setLoader] = useState<any>(false);
  const [graphData, setGraphData] = useState<any>([]);
  const authContext = useToken();
  const startDate = localStorage.getItem("startDate");
  const endDate = localStorage.getItem("endDate");
  const [range, setRange] = useState<any>({ startDate: "", endDate: "" });
  const today = new Date().toISOString().split("T")[0];

  const getGraphsData = async (filters: any) => {
    setLoader(true);
    const response: AxiosResponse<any> = await axios.post(
      `${process.env.REACT_APP_API}/pipeline`,
      {
        filter: {
          filter: {
            filters: [
              {
                startDate: startDate,
                endDate: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
              },
            ],
          },
        },
        analytics: {},
        action: "command",
        command: [
          {
            agent: "getAnalyticsHeadersCount",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getTotalSalesGraph",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getTotalSalesBreakDown",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getTotalMonthlyOrder",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getMonthlyAverageOrderValue",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getMonthlyRepeatedCustomer",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getMonthlyCompletedOrder",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getTopSellingProductCount",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getMonthlyCohortGraph",
            appName: "selfMaximized",
            folder: "analytics",
          },
          {
            agent: "getAll",
            appName: "selfMaximized",
            folder: "analytics",
          },
        ],
      },
      {
        headers: { "x-access-token": `${authContext.token}` },
      }
    );

    setGraphData(response.data.data);
    setLoader(false);
  };
  useEffect(() => {
    if (
      (startDate && endDate && range.startDate !== startDate) ||
      range.endDate !== endDate
    ) {
      setRange({
        startDate: startDate,
        endDate: endDate,
      });
    } else {
      setRange({
        startDate: today,
        endDate: today,
      });
    }
  }, []);
  useEffect(() => {
    if (startDate && endDate) {
      getGraphsData(null);
    }
  }, [startDate, endDate]);
  useEffect(() => {
    getGraphsData(null);
  }, []);

  const handleDateChange = (date: any) => {
    setRange({
      startDate: date?.startDate,
      endDate: date?.endDate,
    });
    localStorage.setItem("startDate", date?.startDate);
    localStorage.setItem("endDate", date?.endDate);
  };

  return loader ? (
    <Shimmer />
  ) : (
    <div className="dashboard-container">
      {/* Time Filter Section */}
      <TimeFilter handleDateChange={handleDateChange} />

      {/* Top Stats Section */}
      <StatsGrid data={graphData?.getAnalyticsHeadersCount} />

      {/* Main Charts Grid */}
      <div className="charts-layout">
        {/* Large Chart Section */}
        <TotalSalesOverTimeChart data={graphData?.getTotalMonthlyOrder} />

        {/* Sales Breakdown Section */}
        <SalesBreakdown data={graphData?.getTotalSalesBreakDown} />
      </div>

      {/* Bottom Charts Grid */}
      <div className="bottom-charts-grid">
        <TotalSalesChart data={graphData?.getTotalSalesGraph} />
        {/* <OnlineStoreConversionRateChart months={months} /> */}
        <TotalOrdersChart data={graphData?.getTotalMonthlyOrder} />
        <AverageOrderValueChart data={graphData?.getMonthlyAverageOrderValue} />
        <TopSellingProductsChart data={graphData?.getTopSellingProductCount} />
        <CustomerCohortAnalysisChart data={graphData?.getMonthlyCohortGraph} />

        <ReturningCustomerRateChart
          data={graphData?.getMonthlyRepeatedCustomer}
        />

        <FulfilledOrdersOverTimeChart
          data={graphData?.getMonthlyCompletedOrder}
        />
      </div>
    </div>
  );
};

export default AnalyticsReports;
