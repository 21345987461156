import axios from "axios";
import React from "react";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Policies {
  policies: any;
  specialistPolicies: any;
  policiesArray: any;
  currentSlide: any;
  specialistInfo: any;
  onHandleCheckBoxChange: any;
  onHandleNextClick: any;
  onHandlePrevClick: any;
}
const RenderPolicies: React.FC<Policies> = React.memo(
  ({
    policies,
    policiesArray,
    specialistInfo,
    specialistPolicies,
    currentSlide,
    onHandleCheckBoxChange,
    onHandleNextClick,
    onHandlePrevClick,
  }) => {
    const authContext = useToken();
    const userInfo = authContext.userInfo;
    const parsedInfo =
      typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
    const navigate = useNavigate();
    const createUserPolicy = async () => {
      await axios
        .post(
          `${process.env.REACT_APP_API}/pipeline`,
          {
            user_id: specialistInfo.uuid,
            terms_and_conditions: 1,
            privacy_policy: 1,
            cancellation_policy: 1,
            menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
            action: "command",
            command: [
              {
                agent: "createUserPolicies",
                appName: "selfMaximized",
                folder: "policies",
              },
            ],
          },
          {
            headers: { "x-access-token": `${authContext.token}` },
          }
        )
        .then((result) => {
          if (result?.data?.statusCode === 200) {
            toast.success(
              result?.data?.message +
                `${parsedInfo.role === "specialist" ? " DR." : ""} ${
                  specialistInfo.name
                }`,
              {
                icon: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="60"
                    height="60"
                    viewBox="0 0 30 30"
                  >
                    <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
                  </svg>
                ),
                className: "custom-toast",
                bodyClassName: "custom-toast",
              }
            );
            navigate(
              parsedInfo.role === "specialist" ? "/my-calendar" : "/dashboard"
            );
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    };

    const decodeHTML = (html: any) => {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value.toUpperCase();
    };
    return (
      <div>
        <body>
          <div className="container">
            <div className="d-flex justify-content-between">
              <img
                src={
                  "https://serverqodequay.s3.amazonaws.com/profile/1725900572150-145.png"
                }
                className="img-fluid admin__logo mt-3 ml-0 pl-0"
                alt="logo"
                style={{ width: "220px", height: "auto" }}
                loading="lazy"
              />
            </div>

            <div
              id="carouselExampleControls"
              className="carousel"
              data-interval="false"
            >
              <div className="carousel-inner">
                {policies
                  .filter((elem: any) => elem.pageDataSpecialist !== "")
                  .map((elem: any, index: any) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={index}
                    >
                      <div className="pl-5 pt-4">
                        <h2
                          style={{
                            fontFamily: "Helvetica, Arial, sans-serif",
                            fontWeight: "200",
                            marginBottom: "20px",
                            marginTop: "10px",
                            marginLeft: "18px",
                          }}
                        >
                          {elem.pageName
                            ? elem?.pageName.split("").map((elem: any) => {
                                return elem?.toUpperCase();
                              })
                            : null}{" "}
                          <span className="pl-2">{policiesArray.length}/3</span>
                        </h2>
                        <iframe
                          srcDoc={`
                        
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>${elem.pageName}</title>
            <style>
              body {
                color: white;
                margin: 0;
                padding: 20px;
                background-color: transparent;
                letter-spacing: 1px;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 18px;
              }
            </style>
          </head>
          <body>
            ${decodeHTML(elem.pageDataSpecialist)}
          </body>
          </html>
        `}
                          style={{
                            width: "100%",
                            height: "300px",
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        ></iframe>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div className="form-check mb-3 mt-5 d-flex flex-column flex-lg-row justify-content-between align-items-start align-items-lg-center">
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "-18px" }}
              >
                <div>
                  <label
                    className="custom-checkbox term-and-condition-checkbox"
                    style={{ marginLeft: "60px" }}
                  >
                    <input
                      type="checkbox"
                      className="cursor_pointer"
                      checked={policiesArray.includes(
                        policies &&
                          policies.length > 0 &&
                          policies[currentSlide].id
                      )}
                      onChange={() => {
                        onHandleCheckBoxChange(
                          policies &&
                            policies.length > 0 &&
                            policies[currentSlide].id
                        );
                      }}
                    />
                    <span className="checkmark cursor_pointer"></span>
                  </label>

                  <label className="form-check-label text-white ml-2 text-uppercase">
                    I agree to all{" "}
                    {policies &&
                      policies.length > 0 &&
                      policies[currentSlide].pageName.toLowerCase()}{" "}
                    to proceed.
                  </label>
                  <br />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-end mb-3">
                  {specialistPolicies.length > 1 ? (
                    <nav aria-label="Page navigation example">
                      <ul className="paginationOne  d-flex align-items-center mr-4 mb-0">
                        {currentSlide > 0 && policiesArray.length !== 3 ? (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#carouselExampleControls"
                              data-slide="prev"
                              style={{ color: "black" }}
                              onClick={onHandlePrevClick}
                            >
                              PREVIOUS
                            </a>
                          </li>
                        ) : null}

                        {(currentSlide === 0 &&
                          policiesArray.includes(
                            policies &&
                              policies.length > 0 &&
                              policies[currentSlide].id
                          )) ||
                        (currentSlide === 1 &&
                          policiesArray.includes(
                            policies &&
                              policies.length > 0 &&
                              policies[currentSlide].id
                          )) ? (
                          <li className="page-item">
                            <a
                              href="#carouselExampleControls"
                              className="page-link"
                              style={{ color: "black" }}
                              data-slide="next"
                              onClick={onHandleNextClick}
                            >
                              NEXT
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </nav>
                  ) : null}

                  <button
                    className="btn proceed-btn"
                    onClick={createUserPolicy}
                    disabled={
                      specialistPolicies.length === policiesArray.length
                        ? false
                        : true
                    }
                  >
                    PROCEED
                  </button>
                </div>
              </div>
            </div>
          </div>
        </body>
      </div>
    );
  }
);

export default RenderPolicies;
