import axios from "axios";
import { useEffect, useState } from "react";
import { useToken } from "../authContext";

const usePageEditorData = (pageName: any) => {
  const [puckData, setPuckData] = useState<any[]>([]);
  const [puckDataLoader, setPuckDataLoader] = useState<boolean>(false);
  const authContext = useToken();

  useEffect(() => {
    if (
      pageName !== "LOGIN" &&
      pageName !== "FORGOT PASSWORD" &&
      !authContext?.token
    )
      return;
    puckDataPageWise();
  }, [authContext?.token]);

  const puckDataPageWise = async () => {
    setPuckDataLoader(true);
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          name: pageName,
          action: "command",
          menuId: "9f83c856-be8c-48f7-abe3-a7d29ac4816e",
          command: [
            { agent: "pageByName", appName: "selfMaximized", folder: "pages" },
          ],
        },
        {
          headers: { "x-access-token": authContext?.token },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          const data = JSON.parse(result?.data?.data?.response[0]?.data);
          setPuckData(data);
          setPuckDataLoader(false);
        }
      })
      .catch((err) => {
        setPuckDataLoader(false);
        return err;
      });
  };

  return { puckData, puckDataLoader };
};

export default usePageEditorData;
