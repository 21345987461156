import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";

const SpecialistAnalytics: React.FC = () => {
  const authContext = useToken();
  const specialistInfo =
    typeof authContext.userInfo === "string"
      ? JSON.parse(authContext.userInfo)
      : authContext.userInfo;
  const [walletBalance, setWalletBalance] = useState<any>([]);
  const [amount, setAmount] = useState<any>("");

  const getWalletBalance = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API}/pipeline?action=getcommand&command=[%20{%20%22agent%22%3A%20%22getWalletBalance%22%2C%20%22appName%22%3A%20%22selfMaximized%22%2C%20%22folder%22%3A%20%22users%22%20}%20]&callback=http%3A%2F%2Flocalhost%3A3000`,
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setWalletBalance(result?.data?.data[0]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const payOutRequest = async () => {
    if (amount > walletBalance.amount) {
      return toast.info(
        "You can only credit the amount available in your wallet."
      );
    }
    if (!amount) {
      return toast.info("Please enter value first");
    }
    if (amount <= 0) {
      return toast.info("Please enter positive value");
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          amount: amount,
          action: "command",
          command: [
            {
              agent: "writeLedgar",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result.data.statusCode === 200) {
          return toast.success(result?.data?.message);
          setAmount("");
        } else if (result?.data?.statusCode === 400) {
          return toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getWalletBalance();
  }, [authContext.token]);

  const handleAmount = (event: any) => {
    setAmount(event.target.value);
  };

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper user_wrapper">
        <div className="container pt-5 pt-lg-0">
          <div className="fittness-wrapper-background-image-one">
            <div className="container px-0">
              <div className="row d-flex align-items-center ">
                <div className="col-lg-6 px-0 pb-3 pb-lg-0">
                  <div className="payout__wrapper py-4">
                    <h4 className="text-center your__credits--payout mb-0 text-uppercase">
                      Your credits payout :{" "}
                    </h4>
                    <p className="mb-0 payout__amount text-center text-success">
                      {walletBalance?.wallet}
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 px-0 px-lg-2">
                  <div className="make__request--wrapper py-3">
                    <div className="row d-flex align-items-center">
                      <div className="col-lg-8">
                        <div className="form-group">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="make--request pb-1 text-uppercase"
                          >
                            Make a request payout
                          </label>
                          <input
                            type="number"
                            value={amount}
                            placeholder="Enter amount"
                            className="payout__enter__amount text-uppercase"
                            onChange={handleAmount}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 text-right">
                        <button
                          className="send__request"
                          onClick={payOutRequest}
                        >
                          SEND REQUEST
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container pb-3 pt-5 pt-lg-0 mt-3 mt-lg-0 px-0">
            <div className="row">
              <div className="col-lg-12 px-0">
                <p className="mb-0 py-4 appointment--data text-uppercase">
                  Appointment Data
                </p>
                <div className="tab table-responsive">
                  <table className="table table--lines table--striped">
                    <colgroup>
                      <col className="colgroup-1" />
                      <col className="colgroup-2" />
                      <col className="colgroup-3" />
                      <col className="colgroup-4" />
                      <col className="colgroup-5" />
                      <col className="colgroup-6" />
                      <col />
                    </colgroup>
                    <thead className="table__header table__header--sticky text-center">
                      <tr>
                        <th className="text-truncate">
                          <span className="font-14">Name</span>
                        </th>
                        <th className="text-truncate">
                          <span className="font-14">Gender</span>
                        </th>
                        <th className="text-truncate">
                          <span className="font-14">Email- Id</span>
                        </th>
                        <th className="text-truncate">
                          <span className="font-14">No.of Sessions</span>
                        </th>
                        <th className="text-truncate">
                          <span className="font-14">Subscription</span>
                        </th>
                        <th className="table__actions text-truncate" />
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      <tr className="table__row">
                        <td className="table__td">
                          <div className="media-item">
                            <div className="media-item__right">
                              <h5 className="media-item__title text-truncate font-14 text-truncate pl-3 pl-lg-0 text-uppercase">
                                Akshay Mahajan
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td className="table__td text-truncate">
                          <span className="text-grey text-truncate font-14 text-uppercase">
                            Male
                          </span>
                        </td>
                        <td className="table__td text-truncate">
                          <span className="text-truncate font-14 text-transform-unset">
                            akshay.mahajan@gmail.com
                          </span>
                        </td>
                        <td className="table__td text-truncate">
                          <span className="text-grey text-truncate font-14">
                            91
                          </span>
                        </td>
                        <td className="table__td text-truncate">
                          <div className="table__status text-truncate font-14 text-uppercase">
                            Primium
                          </div>
                        </td>
                        <td className="table__td table__actions">
                          <div className="items-more font-14 user__view--more text-truncate pr-0 pr-lg-4 text-uppercase">
                            <Link to={"/app/users/userdetails"}> View</Link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default SpecialistAnalytics;
