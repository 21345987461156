import axios from "axios";
import React, { useEffect, useState } from "react";
import { useToken } from "../../authContext";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const EditProduct: React.FC = () => {
  const [updateData, setUpdateProduct] = useState<any>({
    duration: "",
    shorttitle: "",
    details: "",
    price: "",
    compareatprice: "",
  });
  const [shortTitleValidation, setShortTitleValidation] = useState<any>("");
  const [blogTitleInputValue, setBlogTitleInputValue] = useState<any>("");
  const [durationList, setDurationList] = useState<any>("");
  const authContext = useToken();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getProductDetailsById();
  }, [authContext.token]);

  const getProductDetailsById = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 1,
              filters: [
                {
                  field: "uuid",
                  operator: "eq",
                  value: id,
                },
              ],
              sort: [
                {
                  field: "id",
                  dir: "desc",
                },
              ],
            },
          },
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          action: "command",
          command: [
            {
              agent: "productList",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUpdateProduct(result?.data?.data[0]);
        } else {
          return toast.info(result?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const handleProductData = (event: any) => {
    const { name, value } = event.target;
    setUpdateProduct({ ...updateData, [name]: value });
    setShortTitleValidation("");
  };

  const editProduct = async () => {
    let priceRegex = /^\d{1,20}(?:\.\d{1,2})?$/;
    let compareAtPriceValid = /^\d{1,20}(?:\.\d{1,2})?$/;

    let isPriceValid = priceRegex.test(updateData.price);
    let isComparePriceValid = compareAtPriceValid.test(
      updateData.compareatprice
    );

    let errorMessage = "";

    if (
      !updateData.duration ||
      !blogTitleInputValue ||
      !updateData.details ||
      !updateData.price ||
      !updateData.compareatprice ||
      !isPriceValid ||
      !isComparePriceValid
    ) {
      if (!updateData.duration) {
        errorMessage += "Duration is mandatory. ";
      }
      if (!blogTitleInputValue && !updateData.shorttitle) {
        errorMessage += "Short Title is mandatory. ";
      }
      if (!updateData.details) {
        errorMessage += "Details is mandatory. ";
      }
      if (!updateData.price) {
        errorMessage += "Price is mandatory. ";
      } else if (!isPriceValid) {
        errorMessage += "Please enter price in number format. ";
      }
      if (!updateData.compareatprice) {
        errorMessage += "Compare at price is mandatory. ";
      } else if (!isComparePriceValid) {
        errorMessage += "Please enter compare at price in number format. ";
      }
      // If all fields are empty
      if (
        !updateData.duration &&
        !blogTitleInputValue &&
        !updateData.details &&
        !updateData.price &&
        !updateData.compareatprice
      ) {
        errorMessage = "All fields are mandatory.";
      }
    }

    if (errorMessage === "") {
      // If no error, proceed with your code
    } else {
      return toast.warning(errorMessage);
    }

    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          duration: `${updateData.duration}`,
          shorttitle: `${updateData.shorttitle}`,
          details: `${updateData.details}`,
          categoryUuid: "8b353a1f-7b12-11ef-a247-9061ae48faa3",
          currencyid: 1,
          price: `${updateData.price}`,
          compareatprice: `${updateData.compareatprice}`,
          isActive: 1,
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          uuid: id,
          action: "command",
          command: [
            {
              agent: "editProduct",
              appName: "selfMaximized",
              folder: "specialist",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setUpdateProduct({
            duration: "",
            details: "",
            price: "",
            compareatprice: "",
            shorttitle: "",
          });
          const selectElement = document.getElementById(
            "select"
          ) as HTMLSelectElement;
          if (selectElement) {
            selectElement.value = "";
          }
          setBlogTitleInputValue("");
          toast.success(result?.data?.message);
          navigate("/product");
        } else {
          return toast.error(result?.data?.message);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const getSpecialistDurationList = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/pipeline`, {
        params: {
          action: "getcommand",
          menuId: "a2039418-bbd0-4b25-b314-7b5af09b8937",
          command:
            '[ { "agent": "getSpecialistTime", "appName": "selfMaximized", "folder": "specialist" } ]',
        },
        headers: { "x-access-token": `${authContext.token}` },
      })
      .then((result) => {
        if (result?.data?.data?.status === "resolve") {
          setDurationList(result?.data?.data?.response);
        } else {
          setDurationList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getSpecialistDurationList();
  }, [authContext.token, authContext.role, authContext.refreshToken]);

  return (
    <>
      <main className="page-content doctot-detail-user_wrapper">
        <div className="container pb-3">
          <div className="row">
            <div className="col-lg-12 text-right px-0 pb-3 mt-4">
              <button
                type="button"
                className="font-weight-bold publish-btn product__publish__btn"
                onClick={editProduct}
              >
                UPDATE
              </button>
            </div>
          </div>
          <div className="row user_details-inner_wrapper pb-4">
            <div className="col-lg-12">
              <div className="row pb-4 py-4">
                <label className="mb-3">NOTE - ONLY PRICE IS EDITABLE</label>
                <div className="col-12">
                  <div className="form-group mb-0">
                    <p className="mb-0 short__title__product font-14 pb-1">
                      CATEGORY
                    </p>
                    <input
                      type="text"
                      className="form-control w-100"
                      id="shortTitleProduct"
                      name="shorttitle"
                      autoComplete="off"
                      value={blogTitleInputValue || updateData.shorttitle}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="short__title__product pb-1 font-14">
                    Duration
                  </label>
                  <div id="createProduct">
                    <select
                      id="select"
                      name="duration"
                      value={updateData.duration}
                    >
                      <option value={""}>
                        {updateData.duration
                          ? updateData.duration
                          : "Select duration"}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 pt-3 pt-lg-0">
                      <div className="form-group mb-0">
                        <p className="mb-0 short__title__product font-14 pb-1">
                          Price
                        </p>
                        <input
                          type="text"
                          className="form-control w-100"
                          id="price"
                          name="price"
                          autoComplete="off"
                          onChange={handleProductData}
                          value={updateData.price}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditProduct;
