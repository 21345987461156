import React from "react";

type PaginationProps = {
  pagination: (type: string, value: any) => void; // or your exact function signature
  paginationCount: number;
  setPaginationCount: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  list: any[];
  ListCount: number;
};

const Pagination: React.FC<PaginationProps> = ({
  pagination,
  paginationCount,
  setPaginationCount,
  totalPages,
  list,
  ListCount,
}) => {
  return (
    <div className="row">
      <div className="col-lg-12 text-right px-0 my-3">
        <nav aria-label="Page navigation example" className="pagination__card">
          <ul className="pagination">
            <li className="page-item">
              <button className="page-link" aria-label="Previous">
                <button
                  aria-hidden="true"
                  className={`${
                    paginationCount === 1 ? "next__arrow--icon" : "text-white"
                  }`}
                  onClick={() => pagination("first", "")}
                  disabled={paginationCount === 1}
                >
                  &laquo;
                </button>
                <button
                  className={`${
                    paginationCount === 1 ? "next__arrow--icon" : "text-white"
                  }  sr-only`}
                  onClick={() => pagination("previous", "")}
                  disabled={paginationCount === 1}
                >
                  Previous
                </button>
              </button>
            </li>
            <li className="page-item">
              <button
                className={`${
                  paginationCount === 1
                    ? "next__arrow--icon"
                    : "text-white next--page"
                }  page-link previous--page`}
                onClick={() => pagination("previous", "")}
                disabled={paginationCount === 1}
              >
                Previous
              </button>
            </li>
            <li className="page-item">
              <button
                className={`${
                  paginationCount === Math.ceil(totalPages) || list.length < 4
                    ? "next--btn"
                    : "text-white next--page"
                } page-link`}
                onClick={() => pagination("next", "")}
                disabled={
                  paginationCount === Math.ceil(totalPages) || list.length < 4
                }
              >
                Next
              </button>
            </li>
            <li className="page-item">
              <button className="page-link" aria-label="Next">
                <button
                  aria-hidden="true"
                  className={`${
                    paginationCount === Math.ceil(totalPages) || list.length < 4
                      ? "next__arrow--icon"
                      : "text-white"
                  }`}
                  onClick={() => pagination("last", ListCount / 6)}
                  disabled={
                    paginationCount === Math.ceil(totalPages) || list.length < 4
                  }
                >
                  &raquo;
                </button>
                <button
                  className={`${
                    paginationCount === Math.ceil(totalPages) || list.length < 4
                      ? "next--btn"
                      : "text-white"
                  }  sr-only`}
                  onClick={() => pagination("next", "")}
                  disabled={
                    paginationCount === Math.ceil(totalPages) || list.length < 4
                  }
                >
                  Next
                </button>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
