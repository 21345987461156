import React from "react";
import { CChartBar } from "@coreui/react-chartjs";
import "../Dashboard.css";
import { color } from "jodit/esm/plugins/color/color";

interface MonthlyOrderItem {
  month: string;
  monthName: string;
  totalMonthlyOrdersCount: number;
}

interface ChartProps {
  data: MonthlyOrderItem[]; // or any[] if your data is untyped
}

const TotalSalesOverTimeChart: React.FC<ChartProps> = ({ data }) => {
  // If there's no data, render nothing or a fallback message
  if (!data || data.length === 0) {
    return (
      <div className="chart-card-large total-sales-over-time ">
        <h3 className="chart-title">TOTAL SALES OVER TIME</h3>
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Create label array from monthName
  const labels = data?.map((item) => item?.monthName);

  // Create data array from totalMonthlyOrdersCount
  const values = data?.map((item) => item?.totalMonthlyOrdersCount);
  const totalCount = data?.reduce((acc, item) => {
    return acc + (item?.totalMonthlyOrdersCount || 0);
  }, 0);

  return (
    <div className="chart-card-large total-sales-over-time">
      {" "}
      <div className="chart-header">
        <div>
          <h3 className="chart-title">TOTAL SALES OVER TIME</h3>
          <p className="chart-subtitle">$ {totalCount} </p>
        </div>
      </div>
      <CChartBar
        data={{
          labels: labels,

          datasets: [
            {
              label: "Monthly Orders",
              backgroundColor: "#666",
              data: values,
              barThickness: 20,
              borderRadius: 6,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                callback: (value) => `$${value}`,
              },
              min: 0,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
        }}
      />
    </div>
  );
};

export default TotalSalesOverTimeChart;
