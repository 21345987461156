import React from "react";
import { useParams } from "react-router-dom";
import ReportsList from "./components/ReportsList";
import PaymentAnalyticsReport from "./components/PaymentAnalyticsReport";

import UserBookingDetailsReport from "./components/UserBookingDetailsReport";
import CancellationReport from "./components/CancellationReport";
import SubscriptionReport from "./components/SubscriptionReport";
import "./Reports.css";
const ReportsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const renderReport = () => {
    if (!id) {
      return <ReportsList />;
    }

    switch (id) {
      case "1":
        return <PaymentAnalyticsReport />;
      case "2":
        return <UserBookingDetailsReport />;
      case "3":
        return <CancellationReport />;
      case "4":
        return <SubscriptionReport />;
      default:
        return <ReportsList />;
    }
  };

  return (
    <main className="page-content user_wrapper specialists__wrapper categories-wrpper user_wrapper overflow-auto">
      {renderReport()}
    </main>
  );
};

export default ReportsPage;
