import axios from "axios";
import { useEffect, useState } from "react";
import { useToken } from "../authContext";
import { toast } from "react-toastify";

const useGetTermsAndConditions = (userType: string) => {
  const authContext = useToken();
  const [policies, setPolicies] = useState<any[]>([]);
  const [userTypePolicy, setUserTypePolicy] = useState<any[]>([]);

  useEffect(() => {
    if (!authContext?.token) return;
    getPolicies();
  }, [authContext?.token]);

  const getPolicies = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getTermsAndConditions",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authContext.token,
          },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setPolicies(result?.data?.data);
          setUserTypePolicy(
            result?.data?.data?.filter((item: any) => item[userType])
          );
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };
  return { policies, userTypePolicy };
};

export default useGetTermsAndConditions;
