import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface CompletedOrderItem {
  month: string; // "2024-10"
  monthName: string; // "October"
  totalMonthlyCompletedOrders: number;
}

interface ChartProps {
  data?: CompletedOrderItem[]; // The array from getMonthlyCompletedOrder
}

const FulfilledOrdersOverTimeChart: React.FC<ChartProps> = ({ data }) => {
  // Fallback if no data
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">FULFILLED ORDERS OVER TIME</h3>
          <p className="chart-subtitle">$ 0.00</p>
        </div>
        {/* </div> */}
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Build the labels array from monthName
  const labels = data.map((item) => item.monthName);
  // Build the dataset array from totalMonthlyCompletedOrders
  const values = data.map((item) => item.totalMonthlyCompletedOrders);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">FULFILLED ORDERS OVER TIME</h3>
          {/* Optionally display total in the subtitle */}
          <p className="chart-subtitle">
            {values.reduce((acc, cur) => acc + cur, 0)} total
          </p>
        </div>
      </div>
      <CChartLine
        data={{
          labels: labels,
          datasets: [
            {
              label: "Fulfilled Orders",
              borderColor: "#999",
              backgroundColor: "rgba(153, 153, 153, 0.2)",
              data: values,
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // If you want plain numeric values, remove the `$` prefix:
                callback: (value) => value,
              },
              // Remove or adjust max so the chart can autoscale
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default FulfilledOrdersOverTimeChart;
