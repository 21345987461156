import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import useMeetingDetails from "../../customHooks/useMeetingDetails";

const RatingPage: React.FC = () => {
  const [rating, setRatingValue] = useState<any>(0);
  const [rateText, setRateText] = useState<any>("");
  const handleRatingChange = (value: any) => {
    setRatingValue(value);
  };
  const userInfo: any = localStorage.getItem("userInfo");
  const userMenu: any = localStorage.getItem("userMenu");
  const token: any = localStorage.getItem("token");
  const hash = window.location.hash; // Get everything after the #
  const queryString = hash.split("?")[1]; // Extract the part after '?'
  const urlParams = new URLSearchParams(queryString);
  const meetingId = urlParams.get("meetingid");
  const navigate = useNavigate();

  const userRole: any =
    typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;

  const meetingDetails = useMeetingDetails(`${meetingId}`);

  const giveRating = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userId: userRole.id,
          doctorId: meetingDetails?.doctorId,
          rating: rating,
          review: rateText,
          action: "command",
          command: [
            {
              agent: "createRatings",
              appName: "selfMaximized",
              folder: "ratings",
            },
          ],
        },
        {
          headers: { "x-access-token": `${token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setRateText("");
          setRatingValue(0);
          navigate("/dashboard");
          return toast.info("THANK YOU FOR YOUR VALUABLE FEEDBACK!", {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="60"
                height="60"
                viewBox="0 0 30 30"
              >
                <path d="M15,3C8.373,3,3,8.373,3,15c0,6.627,5.373,12,12,12s12-5.373,12-12C27,8.373,21.627,3,15,3z M16,21h-2v-7h2V21z M15,11.5 c-0.828,0-1.5-0.672-1.5-1.5s0.672-1.5,1.5-1.5s1.5,0.672,1.5,1.5S15.828,11.5,15,11.5z"></path>
              </svg>
            ),
            className: "custom-toast",
            bodyClassName: "custom-toast",
          });
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  return (
    <main className="page-content overflow-auto">
      <div className="container">
        <div className="row rating__wrapper py-5">
          <div className="col-lg-12 py-3">
            <div className="rating-container">
              <div className="">
                <h4 className="how__you__rate rate__size text-uppercase">
                  How would you rate your overall experience meeting with Dr.?
                </h4>
              </div>
              <div className="stars">
                <Rating
                  onPointerMove={handleRatingChange}
                  allowFraction
                  initialValue={rating}
                  fillColor="black"
                  emptyColor="white"
                  className="custom-rating"
                />
              </div>
              <h5 className="how__you__rate rate--description">
                Did the doctor explain your diagnosis and treatment plan in a
                way that was easy to understand?
              </h5>
              <h5 className="how__you__rate rate--description">
                If you have any suggestions for how we can improve our services,
                kindly type in the feedback section given below.
              </h5>
              <textarea
                className="form-control text-uppercase custom-textarea"
                rows={4}
                placeholder="Enter your feedback here..."
                onChange={(e) => {
                  setRateText(e.target.value);
                }}
                value={rateText}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-12 text-right" onClick={giveRating}>
            <button className="submit__feedback">SUBMIT</button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default RatingPage;
