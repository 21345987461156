import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { getBookingDetails } from "../ApiServices/ReportsAPI";
import FilterDropdown from "./FilterDropdown";
import ArrowLeft from "../../../../../../../src/img/content/BackIcon";
import Filter from "../../../../../../../src/img/content/FilterIcon";
import Search from "../../../../../../../src/img/content/SearchIcon";
import Download from "../../../../../../../src/img/content/DownloadIcon";
import Pagination from "./Pagination";
import Shimmer from "../../../../Shimmer";
import type { ColumnsType } from "antd/es/table";
import { useToken } from "../../../../../../authContext";

// Base filter definitions (no searchTerm yet)
const filterMapping: Record<string, any> = {
  userName: {
    field: "name",
    table: "U",
    operator: "contain",
    value: "",
  },
  specialistName: {
    field: "name",
    table: "S",
    operator: "contain",
    value: "",
  },
  amount: {
    field: "grandTotal",
    table: "O",
    operator: "contain",
    value: "",
  },
  orderId: {
    field: "uuid",
    table: "O",
    operator: "contain",
    value: "",
  },
  status: {
    field: "transactionStatus",
    table: "O",
    operator: "contain",
    value: "",
  },
  type: {
    field: "appnt_type",
    table: "C",
    operator: "contain",
    value: "",
  },
};

interface Booking {
  userName: string;
  specialistName: string;
  type: string;
  orderId: string;
  amount: string;
  status: string;
}

const UserBookingDetailsReport: React.FC = () => {
  const authContext = useToken();
  const [loader, setLoader] = useState(false);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingCount, setBookingCount] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Multi-select filters
  const [appliedFilterKeys, setAppliedFilterKeys] = useState<string[]>([]);
  const [selectedFilterKeys, setSelectedFilterKeys] = useState<string[]>([]);

  // Immediate vs Debounced search
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Show/hide filter dropdown
  const [filterVisible, setFilterVisible] = useState(false);

  // Debounce effect for searchTerm
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPaginationCount(1); // reset to first page on new search
    }, 500); // adjust delay as needed

    return () => clearTimeout(timer);
  }, [searchTerm]);

  // Build final filters for the API
  const filterType = useMemo(() => {
    return appliedFilterKeys.map((key) => {
      const obj = { ...filterMapping[key] };
      obj.value = debouncedSearchTerm; // only apply after the debounce
      return obj;
    });
  }, [appliedFilterKeys, debouncedSearchTerm]);

  // Filter options
  const filterOptions = [
    { label: "USER NAME", value: "userName" },
    { label: "SPECIALIST NAME", value: "specialistName" },
    { label: "TYPE", value: "type" },
    { label: "ORDER ID", value: "orderId" },
    { label: "AMOUNT", value: "amount" },
    { label: "STATUS", value: "status" },
  ];

  // When user applies filters
  const handleFilterApply = () => {
    setAppliedFilterKeys(selectedFilterKeys);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // When user clears filters
  const handleFilterClear = () => {
    setSelectedFilterKeys([]);
    setAppliedFilterKeys([]);
    setPaginationCount(1);
    setFilterVisible(false);
  };

  // Toggling checkboxes
  const handleCheckboxChange = (updated: string[]) => {
    setSelectedFilterKeys(updated);
  };

  // Fetch data (called whenever filterType or pagination changes)
  const handleFetchBookingDetails = async () => {
    try {
      setLoader(true);
      const responseData = await getBookingDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        searchTerm: "",
      });

      const rawBookings = responseData?.data || [];
      const formattedBookings: Booking[] = rawBookings.map((item: any) => ({
        userName: item?.userName?.toUpperCase() || "-",
        specialistName: item?.specialistName?.toUpperCase() || "-",
        type: item?.purchasedType?.toUpperCase() || "",
        orderId: item?.orderId || "-",
        amount: `$ ${item?.amount}` || "-",
        status: item?.status?.toUpperCase() || "-",
      }));

      setBookings(formattedBookings);
      setBookingCount(responseData?.data?.totalCount || 0);
      setTotalPages(Math.ceil((responseData?.data?.totalCount || 0) / 5));
    } catch (error) {
      console.error("Failed to fetch booking details:", error);
    } finally {
      setLoader(false);
    }
  };

  // Trigger fetch when filterType or pagination changes (and also if debouncedSearchTerm changes, but that's inside filterType's useMemo)
  useEffect(() => {
    handleFetchBookingDetails();
  }, [filterType, paginationCount]);

  // Pagination logic
  const pagination = (type: string, total: number) => {
    let newOffset = paginationCount;
    if (type === "next") {
      newOffset = paginationCount + 1;
    } else if (type === "previous") {
      newOffset = paginationCount - 1;
    } else if (type === "first") {
      newOffset = 1;
    } else if (type === "last") {
      newOffset = Math.ceil(total);
    }
    setPaginationCount(newOffset);
  };

  // Table Columns
  const columns: ColumnsType<Booking> = [
    { title: "USER NAME", dataIndex: "userName", key: "userName" },
    {
      title: "SPECIALIST NAME",
      dataIndex: "specialistName",
      key: "specialistName",
    },
    { title: "TYPE", dataIndex: "type", key: "type" },
    { title: "ORDER ID", dataIndex: "orderId", key: "orderId" },
    { title: "AMOUNT", dataIndex: "amount", key: "amount" },
    { title: "STATUS", dataIndex: "status", key: "status" },
  ];
  const downloadExcel = async () => {
    try {
      setLoader(true);
      await getBookingDetails({
        token: authContext?.token,
        filters: filterType,
        offset: paginationCount,
        excelDownload: true,
      });
    } catch (error) {
      console.error("Failed to download Excel:", error);
    } finally {
      setLoader(false);
    }
  };
  if (loader) return <Shimmer />;

  return (
    <div className="user-booking-container">
      <Link to="/dashboard/analytics/reports" className="ubdr-back-button">
        <ArrowLeft width={18} height={18} />
        <span>USER BOOKING DETAILS WITH DOCTOR</span>
      </Link>

      <div className="ubdr-action-bar">
        <button
          className="ubdr-filter-button"
          onClick={() => {
            setFilterVisible(!filterVisible);
            // Show currently applied filters as checked
            setSelectedFilterKeys(appliedFilterKeys);
          }}
        >
          <Filter width={18} height={18} />
        </button>

        {/* Multi-select FilterDropdown */}
        <FilterDropdown
          filterOptions={filterOptions}
          visible={filterVisible}
          selectedValues={selectedFilterKeys}
          onChange={handleCheckboxChange}
          onApply={handleFilterApply}
          onClear={handleFilterClear}
          onVisibleChange={setFilterVisible}
        />

        <div className="ubdr-search-bar">
          <span className="ubdr-search-icon">
            <Search width={16} height={16} />
          </span>
          <input
            type="text"
            className="ubdr-search-reports"
            placeholder="SEARCH..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <button className="ubdr-report-button" onClick={downloadExcel}>
          <Download width={18} height={18} />
          <span>REPORT</span>
        </button>
      </div>

      <div className="ubdr-table-container">
        <Table<Booking>
          columns={columns}
          dataSource={bookings}
          rowKey="orderId"
          pagination={false}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
        />
      </div>

      <Pagination
        pagination={pagination}
        paginationCount={paginationCount}
        setPaginationCount={setPaginationCount}
        totalPages={totalPages}
        list={bookings}
        ListCount={bookingCount}
      />
    </div>
  );
};

export default UserBookingDetailsReport;
