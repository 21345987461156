import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useToken } from "../../authContext";
import Modal from "../../SpecialistDashboard/Layout/Modal";
import SpecialistProduct from "./SpecialistProduct";

interface CartProductData {
  cartProductData: { [key: string]: any };
  isOpen: boolean;
}
interface DoctorProfile {
  doctor: { [key: string]: any };
  categoryId: string;
  cartProductData: any;
}

const BookButton: React.FC<DoctorProfile> = ({
  doctor,
  categoryId,
  cartProductData,
}) => {
  const [openProductModal, setOpenProductModal] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setOpenProductModal(false);
  }, []);
  const handleProduct = () => {
    setOpenProductModal(true);
    const userInfo = localStorage.getItem("userInfo");
    const parsedUserInfo =
      typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
    if (
      (parsedUserInfo && parsedUserInfo?.role === "admin") ||
      (parsedUserInfo && parsedUserInfo?.role === "specialist")
    ) {
      localStorage.clear();
    }
  };

  return (
    <>
      <span onClick={handleProduct} className="btn book-btn" id="bookBtn">
        BOOK
      </span>
      <Modal isOpen={openProductModal}>
        <>
          <SpecialistProduct
            doctor={doctor}
            closeModal={closeModal}
            categoryId={categoryId}
            cartProductData={cartProductData?.metadata}
          />
        </>
      </Modal>
    </>
  );
};

const DoctorDetails: React.FC<DoctorProfile> = ({
  doctor,
  categoryId,
  cartProductData,
}) => {
  console.log(doctor);
  const renderAreaOfExp = (areaOfExp: any): React.ReactNode => {
    let filteredAreaOfExp: string[] = [];

    if (areaOfExp) {
      const parsedAreaOfExp = JSON.parse(areaOfExp);
      filteredAreaOfExp = parsedAreaOfExp.map((elem: any) => elem.category);
    }

    if (!filteredAreaOfExp.length) {
      return null;
    }

    const displayedCategories = filteredAreaOfExp.slice(0, 2); // First 2 categories
    const remainingCount =
      filteredAreaOfExp.length - displayedCategories.length;

    if (remainingCount > 0) {
      return (
        <p
          className="mb-0 specialst_degree pb-2 text-uppercase"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={filteredAreaOfExp.join(" | ")}
        >
          {displayedCategories.join(" | ")} +{remainingCount}
        </p>
      );
    }

    return (
      <p
        className="mb-0 specialst_degree pb-2 text-uppercase"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
      >
        {filteredAreaOfExp.join(" | ")}
      </p>
    );
  };

  const renderLanguage = (language: any): React.ReactNode => {
    let filteredLanguage: any;
    if (language) {
      const parsedLang = JSON.parse(language);
      filteredLanguage = parsedLang.map((elem: any) => elem).join(" | ");
    }
    return filteredLanguage ? filteredLanguage : null;
  };
  return (
    <div className="hidden-content-when-hover-wrapper grassmorphism-pattern black-background">
      <div className="explore-thumb">
        <div className="cs-image">
          <img
            src={doctor?.profile}
            alt="pregency"
            className="w-100"
            style={{
              height: "200px",
              minHeight: "200px",
              overflow: "hidden",
              objectFit: "cover",
              objectPosition: "top",
            }}
            loading="lazy"
          />
        </div>
      </div>
      <h3 className="explore-heading mb-0">{doctor?.name?.toUpperCase()}</h3>
      {renderAreaOfExp(
        doctor?.specialistExprience.length !== 0
          ? doctor?.specialistExprience[0].areaofexpertise
          : null
      )}

      <p className="specialist-year-of-experience mb-0 text-uppercase">
        {doctor?.specialistExprience.length !== 0 && doctor?.specialistExprience
          ? "ABOVE " +
            doctor?.specialistExprience[0].yearofexperience +
            " YEARS"
          : null}
      </p>

      <p className="mb-0 specialist-langauge pb-2 text-uppercase">
        {renderLanguage(
          doctor?.specialistExprience.length !== 0
            ? doctor?.specialistExprience[0].language
            : null
        )}
      </p>
      <p className="mb-0 specialist-langauge pb-2 text-uppercase">
        {doctor?.specialistExprience.length !== 0 ? (
          "$" + doctor?.specialistExprience[0]?.min_price
        ) : (
          <span className="text-light letter_spacing">
            NO PRODUCTS AVAILABLE
          </span>
        )}{" "}
      </p>
      <BookButton
        doctor={doctor}
        categoryId={categoryId}
        cartProductData={cartProductData}
      />
    </div>
  );
};

const DoctorList: React.FC<DoctorProfile> = ({
  doctor,
  categoryId,
  cartProductData,
}) => {
  return (
    <div className={`col-lg-3 position-relative px-0 overflow-hidden `}>
      <div className="explore_more_wrapper_one explore_more_wrapper_one-new">
        <DoctorDetails
          doctor={doctor}
          categoryId={categoryId}
          cartProductData={cartProductData}
        />
      </div>
    </div>
  );
};

const FindADifferentSpecialist: React.FC<CartProductData> = ({
  cartProductData,
  isOpen,
}) => {
  const authContext = useToken();
  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [categoryUuid, setCategoryUuid] = useState<string>("");

  useEffect(() => {
    if (!authContext?.token) return;
    getOtherSpecialists();
  }, [cartProductData, authContext?.token]);

  async function getOtherSpecialists() {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API}/pipeline`, {
        filter: {
          filter: {
            logic: "and",
            offset: 0,
            limit: 10,
            filters: [
              {
                field: "isActive",
                operator: "eq",
                value: 1,
              },
              {
                field: "id",
                operator: "eq",
                value: cartProductData?.categoryid,
              },
            ],
            sort: [
              {
                field: "id",
                dir: "desc",
              },
            ],
          },
        },
        menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf2",
        action: "command",
        command: [
          {
            agent: "categoryLists",
            appName: "selfMaximized",
            folder: "category",
          },
          {
            agent: "categoryDoctorList",
            appName: "selfMaximized",
            folder: "category",
          },
        ],
      });

      if (result?.data?.statusCode === 200) {
        const data = await result?.data?.data[0]?.doctorList;
        const categoryUuid = await result?.data?.data[0]?.uuid;
        setDoctorList(data);
        setCategoryUuid(categoryUuid);
      }
    } catch (err: any) {
      throw new Error(err);
    }
  }
  return (
    <>
      <Modal isOpen={isOpen}>
        <section className="pt-2 explore__details__wrapper_one pb-0">
          <div className="container-fluid">
            <div className="row">
              {doctorList.length !== 0 && doctorList !== null ? (
                doctorList.map((elem: any) => (
                  <DoctorList
                    key={elem?.id}
                    doctor={elem}
                    categoryId={categoryUuid}
                    cartProductData={cartProductData}
                  />
                ))
              ) : (
                <div className="col-lg-12 left-right-space text-center">
                  <span className="d-block text-white letter_spacing text-uppercase">
                    No Data Available
                  </span>
                </div>
              )}
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default FindADifferentSpecialist;
