import React, { useEffect, useState } from "react";
import admin_logo from "../../../src/img/content/logo/admin-logo.png";
import backgroundFitnessImage from "../../../src/img/content/bg/fittness-bg.png";
import { useNavigate } from "react-router-dom";
import { useToken } from "../../authContext";
import axios from "axios";
import { toast } from "react-toastify";
import usePageEditorData from "../../customHooks/usePageEditorData";
import Shimmer from "../../pages/AdminDashboard/Shimmer";

const WelcomeMessage: React.FC = () => {
  const authContext = useToken();
  const navigate = useNavigate();
  const userInfo = localStorage.getItem("userInfo");
  const parseduserInfo =
    typeof userInfo === "string" ? JSON.parse(userInfo) : userInfo;
  const { puckData, puckDataLoader } = usePageEditorData("WELCOME MESSAGE");
  const parsePuckData =
    typeof puckData === "string" ? JSON.parse(puckData) : puckData;
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (parsePuckData?.content && parsePuckData?.content?.length > 0) {
      setData(parsePuckData?.content[0]?.props);
    }
  }, [parsePuckData?.content]);

  const fitnessbackgroundimage = {
    backgroundImage: `url(${
      data?.fitnessbackgroundimage
        ? data?.fitnessbackgroundimage
        : data === undefined
        ? ""
        : backgroundFitnessImage
    })`,
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const checkUserPolicies = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          user_id: parseduserInfo.uuid,
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getUserPolicies",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authContext.token,
          },
        }
      )
      .then(async (result) => {
        if (
          result?.data?.statusCode === 200 &&
          result?.data?.data?.length !== 0
        ) {
          navigate("/dashboard");
          return;
        } else {
          await getUserPolicy(parseduserInfo.role);
          return;
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  const getUserPolicy = async (roleType: any) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          menuId: "325015d4-90f6-4fcb-98bf-49c71fbfd2fa",
          action: "command",
          command: [
            {
              agent: "getTermsAndConditions",
              appName: "selfMaximized",
              folder: "policies",
            },
          ],
        },
        {
          headers: {
            "x-access-token": authContext.token,
          },
        }
      )
      .then(async (result) => {
        if (result?.data?.statusCode === 200) {
          if (
            result?.data?.data?.length > 0 &&
            result?.data?.data?.some(
              (elem: any) =>
                elem.pageDataSpecialist !== "" && roleType === "specialist"
            )
          ) {
            navigate("/termsandconditions");
          } else if (
            result?.data?.data?.length > 0 &&
            roleType === "user" &&
            result?.data?.data?.some((elem: any) => elem.pageDataUser !== "")
          ) {
            navigate("/usertermsandconditions");
          } else {
            navigate("/dashboard");
          }
        } else {
          navigate("/dashboard");
        }
      })
      .catch((err: any) => {
        toast.error("Something went wrong", err);
      });
  };

  return puckDataLoader ? (
    <Shimmer />
  ) : (
    <>
      <div className="fittness-wrapper overflow-auto position-relative">
        <div className="container-fluid p-0">
          <div className="common-full-srceen" style={fitnessbackgroundimage}>
            <img
              className="sidebar__logo-icon cursor_pointer top-logo"
              src={
                data?.admin_logo
                  ? data?.admin_logo
                  : data === undefined
                  ? ""
                  : admin_logo
              }
              alt="#"
              style={{ width: "auto", height: "100px" }}
              loading="lazy"
            />
            <div className="container-fluid fitness__inner__wrapper">
              <div className="row d-flex">
                <div className="col-lg-12 justify-content-end self-align-center text-center">
                  <h3 className="text-white pb-3">
                    {parseduserInfo?.role === "user" ? "HELLO" : "DR."}{" "}
                    {parseduserInfo.name.toUpperCase()}
                  </h3>
                  <h2 className="text-white pb-2">
                    {data?.welcomeMessage
                      ? data.welcomeMessage
                      : data === undefined
                      ? ""
                      : "WELCOME TO SELFMAXIMIZED"}
                  </h2>
                  <h6 className="text-white">
                    {data?.text1
                      ? data?.text1
                      : data === undefined
                      ? ""
                      : "WE ARE HAPPY TO HAVE YOU ONBOARD"}
                  </h6>
                  <p className="text-white">
                    {data?.text2
                      ? data?.text2
                      : data === undefined
                      ? ""
                      : "LETS GET YOUR JOURNEY STARTED"}
                  </p>
                  <button className="btn btn-light" onClick={checkUserPolicies}>
                    {data?.buttonText
                      ? data?.buttonText
                      : data === undefined
                      ? ""
                      : "CLICK HERE"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeMessage;
