import React from "react";

import moment from "moment-timezone";
interface SlotItem {
  uuid: string;
  StartTime: string;
  EndTime: string;
  Duration: number;
}

interface SlotData {
  dayOfWeek: string;
  slots: SlotItem[];
}

interface SlotProps {
  slot: SlotData;
  userTimezone: string;
  selectedSlot: SlotItem | null;
  passSlots: (value: SlotItem) => void;
}
const Slots: React.FC<SlotProps> = ({
  slot,
  userTimezone,
  selectedSlot,
  passSlots,
}) => {
  return (
    <div className="d-flex flex-wrap gap-4 justify-content-between mt-3 mb-3 gap-14 w-100 text-start">
      <h3 className="text-white explore-heading-two flex-100 mb-0 line-height-20 mt-1">
        {slot.dayOfWeek}
      </h3>
      {slot?.slots
        .sort((a: any, b: any) => {
          const timeA = moment(
            a.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
            "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
          ).tz(userTimezone);

          const timeB = moment(
            b.StartTime.replace(/:000Z$/, ":00Z"), // Fix extra milliseconds
            "YYYY-MM-DDTHH:mm:ssZ" // Parse full datetime format
          ).tz(userTimezone);

          // Prioritize AM before PM
          if (timeA.format("A") !== timeB.format("A")) {
            return timeA.format("A") === "AM" ? -1 : 1;
          }

          // Sort by time within the same period
          return timeA.diff(timeB);
        })
        .map((elem: any) => (
          <div
            className="slot-box user-body-slot-box slot-box-new col-md-6 cursor_pointer p-0"
            key={elem.uuid}
            onClick={() => passSlots(elem)}
          >
            <div
              className="slot-box-inner border pt-2 pb-2 pl-3 pr-3"
              style={{
                backgroundColor: selectedSlot === elem ? "white" : "",
                color: selectedSlot === elem ? "black" : "",
              }}
            >
              <h5 className="mb-0 explore-heading-two pb-0">
                AVAILABILITY | {elem?.Duration} HR
              </h5>
              <p
                className="mb-0 explore-heading-two pb-0"
                style={{
                  color: selectedSlot === elem ? "black" : "white",
                }}
              >
                {userTimezone && elem?.StartTime
                  ? moment
                      .tz(
                        elem.StartTime.replace(/:000Z$/, ":00Z"),

                        userTimezone
                      )
                      .format("hh:mm A")
                  : "Date Not Available"}
                <span className="to"> TO </span>
                {userTimezone && elem?.EndTime
                  ? moment
                      .tz(
                        elem.EndTime.replace(/:000Z$/, ":00Z"),

                        userTimezone
                      )
                      .format("hh:mm A")
                  : "Date Not Available"}
              </p>
              <p
                className="mb-0 explore-heading-two pb-0"
                style={{
                  color: selectedSlot === elem ? "black" : "white",
                }}
              >
                {userTimezone && elem?.StartTime
                  ? moment
                      .tz(elem.StartTime.replace(/:000Z$/, ":00Z"), "UTC")
                      .tz(userTimezone)
                      .format("YYYY-MM-DD")
                  : "Date Not Available"}
                <span className="day-name-right floar-right">
                  {userTimezone && elem?.StartTime
                    ? moment
                        .tz(elem.StartTime.replace(/:000Z$/, ":00Z"), "UTC")
                        .tz(userTimezone)
                        .format("dddd")
                    : "Date Not Available"}
                </span>
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Slots;
