import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useToken } from "../../../../authContext";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

const UserDetails: React.FC = () => {
  const [userDetails, setUserDetails] = useState<any>([]);
  const [specialistList, setSpecialistList] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const [categoryId, setCategoryId] = useState<any>("");
  const [categoryName, setCategoryName] = useState<any>("");
  const [isActive, setIsActive] = useState<any>(false);
  const { id } = useParams();
  const location = useLocation();
  const uuid = location?.state?.uuid;
  const authContext = useToken();

  const getUserDetails = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          userId: id,
          action: "command",
          command: [
            {
              agent: "userDatailInfo",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          result?.data?.data?.userData[0].isActive === 1
            ? setIsActive(true)
            : setIsActive(false);
          setUserDetails(result?.data?.data?.userData[0]);
          setCategory(result?.data?.data?.category);
        } else {
          setUserDetails([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const getUserDetailsCb = useCallback(getUserDetails, [authContext.token]);

  useEffect(() => {
    getUserDetailsCb();
  }, [getUserDetailsCb, id]);

  const getSpecialistListCategoryWise = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          filter: {
            filter: {
              logic: "and",
              offset: 0,
              limit: 50,
              filters: [],
              sort: [
                {
                  field: "doctorId",
                  table: "CategoriesData",
                  dir: "desc",
                },
              ],
            },
          },
          categoriesId: categoryId,
          userId: id,
          menuId: "9c4ea45e-e7ce-4f93-b7fa-4839f02e6bf4",
          action: "command",
          command: [
            {
              agent: "userCategoriesOrderData",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: { "x-access-token": authContext.token },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          setSpecialistList(result?.data?.data?.categoriesData);
        } else {
          setSpecialistList([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  useEffect(() => {
    getSpecialistListCategoryWise();
  }, [categoryId]);

  const handleCategory = (categoryId: any, categoryname: any) => {
    setCategoryId(categoryId);
    setCategoryName(categoryname);
  };

  const toggleUser = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          uuid: uuid,
          isActive: isActive ? 0 : 1,
          action: "command",
          command: [
            {
              agent: "userActiveInactive",
              appName: "selfMaximized",
              folder: "users",
            },
          ],
        },
        {
          headers: {
            "x-access-token": `${authContext.token}`,
          },
        }
      )
      .then((result) => {
        if (result?.data?.code === 1) {
          return toast.success(result?.data?.message);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  const toggleSwitch = () => {
    setIsActive(!isActive);
    toggleUser();
  };
  return (
    <>
      <main className="page-content user_wrapper overflow-auto">
        <div className="container pt-3 pt-lg-0">
          <div className="mb-3 d-flex justify-content-end">
            <label className={`switch ${isActive ? "active" : "inactive"}`}>
              <input
                type="checkbox"
                onChange={toggleSwitch}
                checked={isActive}
              />
              <span className="slider round"></span>
              <span
                className={`status ${isActive ? "mr-4" : "ml-4 text-dark"}`}
              >
                {isActive ? "Active" : "Inactive"}
              </span>
            </label>
          </div>
          <div className="row pb-3">
            <div className="col-lg-12">
              <h1 className="user-details">DETAILS</h1>
            </div>
          </div>
          <div className="row user_details-inner_wrapper mb-4">
            <div className="col-lg-12">
              <div className="row details-top_section py-4 px-2 position-relative">
                <div className="col-lg-6 px-4 pb-3 pb-lg-0 mt-4 mt-lg-0">
                  <div className="row specialist__details user__details">
                    <table className="table mb-0 w-100">
                      <tbody>
                        <tr>
                          <td className="align-top">
                            <p className="mb-0 font-12  specialist-user_details text-uppercase">
                              Name :
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 details_title font-12 ">
                              {userDetails.name}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="align-top">
                            <p className="mb-0 font-12  specialist-user_details text-uppercase">
                              Mobile no :
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 details_title font-12 pb-1 ">
                              {userDetails.phone}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="align-top">
                            <p className="mb-0 font-12  specialist-user_details ">
                              Email ID :
                            </p>
                          </td>
                          <td>
                            <p className="mb-0 details_title font-12 text-transforn-unset">
                              {userDetails.email}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-6 pt-3 pt-md-0">
                  <div className="row">
                    <div className="col-12">
                      <p className="mb-0 details_title color-ADADAD pb-2 text-uppercase">
                        Wellness Category
                      </p>
                      <div className="wellness-catergeory">
                        <ul className="pl-0 mb-0">
                          {category.length !== 0 && category !== null ? (
                            category.map((elem: any) => (
                              <li className="font-12 pb-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="6"
                                  height="6"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-circle text-white"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                </svg>{" "}
                                {elem.name}
                              </li>
                            ))
                          ) : (
                            <div className="col-lg-12 text-center">
                              <span className="no__data__available text-uppercase">
                                No Data Available
                              </span>
                            </div>
                          )}
                        </ul>
                        {/* <div className="row">
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              RADIOLOGISTS
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              NEUROLOGY
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              RADIOLOGISTS
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              PEDIATRICIANS
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              SURGEONS
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              GUT HEALTH
                            </p>
                          </div>
                          <div className="col-lg-4 pb-2">
                            <p className="mb-0 font-14">
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6"
                                height="6"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-circle text-white"
                              >
                                <circle cx="12" cy="12" r="10"></circle>
                              </svg>{" "}
                              SLEEP HEALTH
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pb-3">
            <div className="col-lg-12">
              <h1 className="user-details">WELLNESS CATEGORY</h1>
            </div>
          </div>
          <div className="row wellness-catergeory_wrppaer">
            <div className="col-lg-12">
              <div className="row">
                {category.length !== 0 && category !== null ? (
                  category.map((elem: any) => (
                    <div
                      className="col-lg-2 col-md-4 col-6 pb-4"
                      onClick={() => handleCategory(elem.id, elem.name)}
                      key={elem.id}
                    >
                      <div
                        className={`position-relative wellness__category__box ${
                          categoryId === elem.id ? "active" : ""
                        } `}
                      >
                        <div className="thumb">
                          <img
                            src={elem.image}
                            alt=""
                            className="img-fluid"
                            style={{ width: "auto", height: "auto" }}
                            loading="lazy"
                          />
                        </div>
                        <div className="content text-center w-100">
                          <h1 className="mb-0 font-12 font-weight-bold">
                            {elem.name}
                          </h1>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-lg-12 text-center pb-3">
                    <span className="no__data__available pb-3 text-uppercase">
                      No Data Available
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
          <div className="row">
            <div className="tab table-responsive">
              <table className="table table--lines table--striped">
                <thead className="table__header table__header--sticky text-center">
                  <tr>
                    <th className="col-2">
                      <span className="text-truncate">SPECIALIST</span>
                    </th>
                    <th className="col-2">
                      <span className="text-truncate">SUBSCRIPTION</span>
                    </th>
                    <th className="col-2">
                      <span className="text-truncate">TOTAL AMOUNT SPENT</span>
                    </th>
                    <th className="col-2">
                      <span className="text-truncate">TRANSACTION STATUS</span>
                    </th>
                    <th className="col-2">
                      <span className="text-truncate">DATE</span>
                    </th>
                    <th className="col-2">
                      <span className="text-truncate">WELLNESS CATEGORY</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {specialistList.length !== 0 && specialistList !== null ? (
                    specialistList.map((elem: any) => (
                      <tr className="table__row">
                        <td className="table__td col-2">
                          <div className="media-item">
                            <div className="media-item__right">
                              <h5 className="media-item__title font-14 text-truncate">
                                {elem.name}
                              </h5>
                            </div>
                          </div>
                        </td>
                        <td className="table__td col-2">
                          <span className="text-grey font-14 text-truncate">
                            {elem.duration}HR Meeting
                          </span>
                        </td>
                        <td className="table__td col-2">
                          <span className="font-14 text-truncate">
                            ${elem.grandTotal}
                          </span>
                        </td>
                        <td className="table__td col-2">
                          <span className="text-grey font-14 text-truncate">
                            {elem.transactionStatus}
                          </span>
                        </td>
                        <td className="table__td col-2">
                          <span className="text-grey font-14 text-truncate">
                            {elem.calendarDate}
                          </span>
                        </td>
                        <td className="table__td table__actions col-2">
                          <div className="font-14 text-truncate text-center">
                            {categoryName.toUpperCase()}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center position-absolute w-100">
                      <span className="no__data__available mt-2 text-center d-block font-14 text-uppercase">
                        No data available
                      </span>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserDetails;
