import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import "../Dashboard.css";

interface SalesGraphItem {
  month: string; // e.g. "2024-10"
  monthName: string; // e.g. "October"
  totalAmount: string; // e.g. "2240.00"
}

interface ChartProps {
  data: SalesGraphItem[]; // from getTotalSalesGraph
}

const TotalSalesChart: React.FC<ChartProps> = ({ data }) => {
  // If there's no data or an empty array, you can safely return null or a fallback
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">TOTAL SALES</h3>
          <p className="chart-subtitle">$ 0.00</p>
        </div>
        {/* </div>{" "} */}
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  // Map out labels and amounts from the data
  const labels = data.map((item) => item.monthName); // ["October","November","December","January"]
  const values = data.map((item) => parseFloat(item.totalAmount)); // [2240, 4480, 104882.40, 17582.80]
  console.log("valuesvalues", values);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">TOTAL SALES</h3>
          {/* Optionally sum or display another statistic */}
          <p className="chart-subtitle">
            $ {values.reduce((a, b) => a + b, 0).toLocaleString()}
          </p>
        </div>
      </div>
      <CChartLine
        data={{
          labels: labels,
          datasets: [
            {
              label: "Sales Amount",
              borderColor: "#666",
              data: values,
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // If you need a $ prefix on the axis:
                callback: (value) => `$${value}`,
              },
              // Auto-set the max or specify a value:
              // max: 120000,
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default TotalSalesChart;
