import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import UserHeader from "./UserHeader";
import AuthContext, { useToken } from "../../authContext";
import Footer from "../../pages/AdminDashboard/Footer";

const UserDashboard: React.FC = () => {
  const navigate = useNavigate();
  const authCtx = useToken();

  const goToDashboardPage = useCallback(() => {
    if (authCtx.token || authCtx.isLoggedIn) {
      return false;
    } else if (!authCtx.token || !authCtx.isLoggedIn) {
      navigate("/", { replace: true }); // or return <LoadingSpinner /> if you have a loading component
    }
  }, [authCtx.token, authCtx.isLoggedIn, navigate]);

  useEffect(() => {
    goToDashboardPage();
  }, [goToDashboardPage]);

  const logOut = () => {
    localStorage.clear();
    localStorage.clear();
  };
  let timeoutId = useRef<number | undefined>(undefined);

  const handleActivityOrVisibilityChange = useCallback(() => {
    if (timeoutId.current !== undefined) {
      clearTimeout(timeoutId.current);
    }

    if (!document.hidden) {
      timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    document.addEventListener("mousemove", handleActivityOrVisibilityChange);
    document.addEventListener("keydown", handleActivityOrVisibilityChange);
    document.addEventListener(
      "visibilitychange",
      handleActivityOrVisibilityChange
    );

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId.current);
      }
      document.removeEventListener(
        "mousemove",
        handleActivityOrVisibilityChange
      );
      document.removeEventListener("keydown", handleActivityOrVisibilityChange);
      document.removeEventListener(
        "visibilitychange",
        handleActivityOrVisibilityChange
      );
    };
  }, [handleActivityOrVisibilityChange]);

  const [sidebarClass, setSideBarClass] = useState<boolean>(false);
  const [dashboardClass, setDashboardClass] = useState<boolean>(false);

  const updateDashboardClass = (isActive: boolean) => {
    setDashboardClass(isActive);
  };

  return (
    <>
      <div
        id="app"
        className="app"
        // style={{ fontFamily: "Roboto, sans-serif" }}
      >
        <div
          className={`page-home load ${
            dashboardClass ? "sidebar-show sidebar-collapse sidebar-active" : ""
          } `}
        >
          <div className="page-wrapper">
            <UserHeader
              isActive={sidebarClass}
              setActive={setSideBarClass}
              updateDashboardClass={updateDashboardClass}
            />
            <UserSidebar isActive={sidebarClass} />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
