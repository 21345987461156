import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useToken } from "../../../../authContext";

const EditFaq = () => {
  const [isActive, setIsActive] = useState<any>();
  const [faq, setFaqData] = useState<any>({ title: "", description: "" });
  const authContext = useToken();
  const location = useLocation();
  const faqId = location?.state?.faqId;
  const navigate = useNavigate();

  const toggleSwitch = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    getDataById();
  }, []);

  const handleFields = (e: any) => {
    setFaqData({ ...faq, [e.target.name]: e.target.value });
  };
  const updateFaq = async () => {
    if (faq.title === "") {
      return toast.warning("Please add question first");
    }
    if (faq.description === "") {
      return toast.warning("Please add answer first");
    }
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          id: faqId,
          data: {
            title: `${faq.title}`,
            description: `${faq.description}`,
          },
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "editFAQ",
              appName: "selfMaximized",
              folder: "faq",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          toast.success("Faq updated successfully!");
          navigate("/dashboard/faq_List");
        } else {
          return;
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };
  const getDataById = async () => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/pipeline`,
        {
          id: faqId,
          menuId: "e85b200e-24dd-425f-ad38-1a097bdf1da7",
          action: "command",
          command: [
            {
              agent: "faqById",
              appName: "selfMaximized",
              folder: "faq",
            },
          ],
        },
        {
          headers: { "x-access-token": `${authContext.token}` },
        }
      )
      .then((result) => {
        if (result?.data?.statusCode === 200) {
          setFaqData(result?.data?.data[0]);
        } else {
          setFaqData([]);
        }
      })
      .catch((err) => {
        return toast.error("Something went wrong", err);
      });
  };

  return (
    <main className="page-content user_wrapper overflow-auto">
      <div className="container pt-5 pt-lg-0">
        <div className="row">
          <div className="site-wrapper w-100 position-relative magazine_wrapper-dashboard px-0">
            <div>
              <div className="magazine__wrapper_publish magazine__wrapper_publish--magazine ml-2 ">
                <button
                  type="button"
                  className="font-weight-bold publish-btn magazine--btn "
                  onClick={updateFaq}
                >
                  UPDATE
                </button>
              </div>
              {/* <div className="d-flex justify-content-end active__inactive--switch">
                <label
                  className={`switch publish-btn magazine--btn ${
                    isActive ? "active" : "inactive"
                  }`}
                >
                  <input
                    type="checkbox"
                    onChange={toggleSwitch}
                    checked={isActive}
                  />
                  <span className="slider round"></span>
                  <span
                    className={`status ${isActive ? "mr-4" : "ml-4 text-dark"}`}
                  >
                    {isActive ? "Active" : "Inactive"}
                  </span>
                </label>
              </div> */}
            </div>
            <section
              className="tabs-wrapper magazine_wrapper-tabs"
              id="magazine_wrapper-view_more"
            >
              <div className="tabs-container">
                <div className="tabs-block">
                  <div className="tabs edit__blog__tab">
                    <input type="radio" name="tabs" id="tab1" checked />
                    <label htmlFor="tab1 pb-2">
                      <span className="material-icons text-uppercase">
                        Edit Faq
                      </span>{" "}
                    </label>
                    <div className="tab magazine_wrapper px-4 my-4 magazine_wrapper_upload_container">
                      <div className="row mb-0">
                        <div className="col-lg-12 px-0">
                          <label
                            htmlFor="exampleFormControlInput1 mb-0 pb-0 font-12 blog-title_border"
                            id="blogTitle text-white blog__title--edit"
                            className="text-uppercase pb-0"
                          >
                            Question
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 pl-0 pr-0 pr-lg-3">
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              value={
                                faq.title ? faq.title.toUpperCase() : faq.title
                              }
                              onChange={handleFields}
                              className="form-control w-100 text-uppercase"
                              id="blogTitle"
                              name="title"
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-group mb-4 mb-md-0">
                            <label
                              htmlFor="exampleFormControlTextarea1"
                              id="blogTitle text-white"
                              className="text-uppercase pb-0"
                            >
                              Answer
                            </label>
                            <textarea
                              className="form-control text-uppercase"
                              value={
                                faq.description
                                  ? faq.description.toUpperCase()
                                  : faq.description
                              }
                              onChange={handleFields}
                              id="exampleFormControlTextarea1"
                              rows={4}
                              name="description"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditFaq;
