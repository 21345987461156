import React from "react";
import { CChartLine } from "@coreui/react-chartjs";
import "../Dashboard.css";
import { tr } from "date-fns/locale";

interface TopSellingProduct {
  name: string;
  totalBooking: number;
}

interface ChartProps {
  data?: TopSellingProduct[];
}

const TopSellingProductsChart: React.FC<ChartProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="chart-card">
        {/* <div className="chart-header"> */}
        <div>
          <h3 className="chart-title">TOP SELLING PRODUCTS</h3>
          <p className="chart-subtitle"></p>
        </div>
        {/* </div> */}
        <div className="no-data-container">
          <p className="no-data-image">NO DATA AVAILABLE</p>
        </div>
      </div>
    );
  }

  const labels = data.map((item) => item.name);
  const values = data.map((item) => item.totalBooking);

  return (
    <div className="chart-card">
      <div className="chart-header">
        <div>
          <h3 className="chart-title">TOP SELLING PRODUCTS</h3>
          <p className="chart-subtitle">
            {values.reduce((acc, cur) => acc + cur, 0)} total bookings
          </p>
        </div>
      </div>
      <CChartLine
        data={{
          labels: labels, // e.g. ["ORAL", "YOGA"]
          datasets: [
            {
              label: "Top Selling Products",
              borderColor: "#999",
              backgroundColor: "rgba(153, 153, 153, 0.2)",
              data: values, // e.g. [9, 1]
              tension: 0.4,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              // grid: {
              //   color: "#333",
              // },
              ticks: {
                color: "#8c8c8c",
              },
            },
            y: {
              grid: {
                color: "#333",
              },
              ticks: {
                color: "#8c8c8c",
                // For counts, we don't add a dollar sign.
                callback: (value) => value,
              },
              min: 0,
            },
          },
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
    </div>
  );
};

export default TopSellingProductsChart;
