import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useToken } from "../../../authContext";
import { useNavigate, Outlet } from "react-router-dom";
import Footer from "../Footer";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [sidebarClass, setSideBarClass] = useState<boolean>(false);
  const [dashboardClass, setDashboardClass] = useState<boolean>(false);
  let timeoutId = useRef<number | undefined>(undefined);
  const authCtx = useToken();

  useEffect(() => {
    goToDashboardPage();
  }, [authCtx.token, authCtx.isLoggedIn, navigate]);

  const goToDashboardPage = () => {
    if (authCtx.token || authCtx.isLoggedIn) {
      return false;
    } else if (!authCtx.token || !authCtx.isLoggedIn) {
      navigate("/", { replace: true });
    }
  };

  const updateDashboardClass = (isActive: boolean) => {
    setDashboardClass(isActive);
  };

  const logOut = () => {
    localStorage.clear();
  };

  const handleActivityOrVisibilityChange = useCallback(() => {
    if (timeoutId.current !== undefined) {
      clearTimeout(timeoutId.current);
    }

    if (!document.hidden) {
      timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    }
  }, []);

  useEffect(() => {
    timeoutId.current = window.setTimeout(logOut, 60 * 60 * 1000);
    document.addEventListener("mousemove", handleActivityOrVisibilityChange);
    document.addEventListener("keydown", handleActivityOrVisibilityChange);
    document.addEventListener(
      "visibilitychange",
      handleActivityOrVisibilityChange
    );

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId.current);
      }
      document.removeEventListener(
        "mousemove",
        handleActivityOrVisibilityChange
      );
      document.removeEventListener("keydown", handleActivityOrVisibilityChange);
      document.removeEventListener(
        "visibilitychange",
        handleActivityOrVisibilityChange
      );
    };
  }, [handleActivityOrVisibilityChange]);

  return (
    <>
      <div id="app" className="app">
        <div
          className={`page-home load ${
            dashboardClass ? "sidebar-show sidebar-collapse sidebar-active" : ""
          } `}
        >
          <div className="page-wrapper">
            <Header
              isActive={sidebarClass}
              setActive={setSideBarClass}
              updateDashboardClass={updateDashboardClass}
            />
            <Sidebar isActive={sidebarClass} />
            <Outlet />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
